import classnames from 'classnames';
import { useContext } from 'react';
import { usePagination, DOTS } from './usePagination';
import GlobalState from '../contexts/GlobalState';

const Pagination = props => {
    const { locale } = useContext(GlobalState);

    const {
        onPageChange,
        totalCount,
        siblingCount = 1,
        currentPage,
        pageSize,
        className
    } = props;

    const paginationRange = usePagination({
        currentPage,
        totalCount,
        siblingCount,
        pageSize
    });

    // If there are less than 2 times in pagination range we shall not render the component
    if (currentPage === 0 || paginationRange.length < 2) {
        return null;
    }

    const onNext = () => {
        if (currentPage < lastPage) {
            onPageChange(currentPage + 1);
        }
    };

    const onPrevious = () => {
        if (currentPage > 1) {
            onPageChange(currentPage - 1);
        }
    };

    let lastPage = paginationRange[paginationRange.length - 1];
    return (
        <>
            <div
                className={classnames('pagination-container justify-content-center d-flex', { [className]: className })}
            >
                {/* Left navigation arrow */}
                <div
                    className={"pagination-arrow " + classnames('', {
                        disabled: currentPage === 1
                    })}
                    onClick={onPrevious}
                >
                    <div className="">
                        {locale === 'ar' ?
                            ">"
                            :
                            "<"
                        }
                    </div>
                </div>
                {paginationRange.map((pageNumber, index) => {

                    // If the pageItem is a DOT, render the DOTS unicode character
                    if (pageNumber === DOTS) {
                        return <div className="pagination-item dots" key={index}>&#8230;</div>;
                    }

                    // Render our Page Pills
                    return (
                        <div
                            className={"pagination-number px-1 " + classnames('pagination-item', {
                                selected: pageNumber === currentPage
                            })} key={index}
                            onClick={() => onPageChange(pageNumber)}
                        >
                            {pageNumber}
                        </div>
                    );
                })}
                {/*  Right Navigation arrow */}
                <div
                    className={classnames('pagination-arrow', {
                        disabled: currentPage === lastPage
                    })}
                    onClick={onNext}
                >
                    <div className="">
                        {locale === 'ar' ?
                            "<"
                            :
                            ">"
                        }
                    </div>
                </div>
            </div>
        </>

    );
};

export default Pagination;