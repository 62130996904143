import { useContext, useState, useEffect } from "react";
import GlobalState from '../contexts/GlobalState';
import { useNavigate, useParams } from "react-router";
import React, { useCallback } from 'react';
import Layout from "../components/Layout";
import Api from "../components/Api";
import EditInput from "../components/EditInput";
import Documents from "../components/Documents";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
import GoogleMaps from "../components/GoogleMaps";
import UserCosts from "../components/UserCosts";
import { Wrapper } from "@googlemaps/react-wrapper";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import UserRemiders from "../components/UserReminders";
import getCroppedImg from '../components/CropImage';
import Cropper from 'react-easy-crop';
import { getIndex, formatDate } from '../Helpers';



import { registerLocale } from "react-datepicker";
import ar from 'date-fns/locale/ar';
import { CloseIcon, EditPencil, MinusIconFd, PasswordHidden, PasswordVisible, PlusIconFd } from "../components/Icons";
registerLocale('ar', ar)

export default function Profile() {

    const { generalDataGlobal, updateMapGlobal, authUserGlobal, changeInputGlobal, currentInputGlobal, currentInputValueGlobal, profileUserGlobal } = useContext(GlobalState);
    const [generalDataApiOptions, setGeneralDataApiOptions] = useState({});
    let { slug, user_id, folder_id } = useParams();
    const [generalData, setGeneralData] = generalDataGlobal;
    const [authUser, setAuthUser] = authUserGlobal;
    const [usersApiOptions,] = useState({});
    const [profileUser, setProfileUser] = profileUserGlobal;
    const [currentInput, setCurrentInput] = currentInputGlobal;
    const [currentInputValue, setCurrentInputValue] = currentInputValueGlobal;
    const history = useNavigate();
    const [errorsReturned, setErrorsReturned] = useState({});
    const [profilePicture, setProfilePicture] = useState();
    const [changeInput, setChangeInput] = changeInputGlobal;
    const [uploadImagePopup, setUploadImagePopup] = useState(false);
    const [formData, setFormData] = useState({});
    const [birthDate, setBirthDate] = useState('');
    const [jobTitle, setJobTitle] = useState();
    const [userNationality, setUserNationality] = useState();
    const [userGender, setUserGender] = useState();
    const [deletePopup, setDeletePopup] = useState(null);
    const [userLat, setUserLat] = useState();
    const [userLng, setUserLng] = useState();
    const [updateLocation, setUpdateLocation] = useState(1);
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [changePassword, setChangePassword] = useState(false);
    const [showCurrentPassword, setShowCurrentPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);
    const [successMessage, setSuccessMessage] = useState(false);
    const [forgotPassword, setForgotPassword] = useState(false);
    const [swiper, setSwiper] = useState(null);
    const [, setUpdateMap] = updateMapGlobal;
    const [securityCheck, setSecurityCheck] = useState(0);
    const [tempPicture, setTempPicture] = useState('');
    const [croppedFinalImage, setCroppedFinalImage] = useState(null);


    /* transactions start */

    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [rotation,] = useState(0);
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState('');

    //handle crop image end
    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    //set cropped image
    const showCroppedImage = useCallback(async () => {
        let imageToCrop = (tempPicture ? tempPicture : (profileUser?.picture ? profileUser?.full_path_picture : null));
        try {
            const croppedImage = await getCroppedImg(
                imageToCrop,
                croppedAreaPixels,
                rotation
            )
            setCroppedFinalImage(croppedImage);
        } catch (e) {
            console.error(e);
        }
    }, [croppedAreaPixels, rotation, tempPicture]);// eslint-disable-line react-hooks/exhaustive-deps

    // target backend call to change the profile picture
    useEffect(() => {
        if (croppedFinalImage) {
            setProfilePicture(croppedFinalImage);
            setCurrentInputValue(croppedFinalImage);

            setTimeout(() => {
                setChangeInput(1);
            }, 100)
        }
    }, [croppedFinalImage]);// eslint-disable-line react-hooks/exhaustive-deps

    //check if user is allowed to open this page
    useEffect(() => {
        if (authUser?.id && profileUser?.id) {
            if (authUser?.user_role_id !== 1) {
                if (authUser?.id !== profileUser?.id) {
                    let acc = 0;
                    if (authUser?.user_role?.role_permissions?.length > 0) {
                        authUser?.user_role?.role_permissions.map(element => {
                            if (element?.user_pages['slug'] === slug && element?.['browse_others'] === 1) {
                                acc = 1;
                            }
                            return null;
                        });
                    }
                    if (acc === 0) {
                        history('/not-found');
                    }
                }
            }
            setSecurityCheck(1);
        }
    }, [authUser, profileUser]);// eslint-disable-line react-hooks/exhaustive-deps

    //get all job titles
    const allJobTitles = () => {
        const jobOptions = generalData?.job_titles?.map(
            singleOption => ({ value: singleOption.id, label: singleOption.title }))
        return jobOptions;
    }

    //function to add years to a date
    function addYears(date, years) {
        date.setFullYear(date.getFullYear() + years);
        return date;
    }

    // get all nationalities
    const allNationalities = () => {
        const natOptions = generalData?.nationalities?.map(
            singleNat => ({ value: singleNat.id, label: singleNat.title }))
        return natOptions;
    }

    // get all genders
    const allGenders = () => {
        const genders = generalData?.genders?.map(
            singleGender => ({ value: singleGender.id, label: singleGender.title }))
        return genders;
    }

    //get geolocations and post to backend
    useEffect(() => {
        if (navigator?.geolocation) {
            navigator.geolocation?.getCurrentPosition((location) => {
                if (location) {
                    setUserLng(location?.coords?.longitude);
                    setUserLat(location?.coords?.latitude);
                }
            });
        } else {
        }
        if (updateLocation > 1) {
            setGeneralDataApiOptions({
                url: 'update-user-location',
                method: 'post',
                data: {
                    lat: userLat,
                    lng: userLng,
                },
                callback: res => {
                    setProfileUser(res?.data?.user);
                    setAuthUser(res?.data?.user);
                    setUpdateMap(1);
                },
                catch: err => {
                    if (err) {
                    }
                }
            });
        }
    }, [updateLocation]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (slug) {
            if(window.location.search) {
                const queryString = window.location.search;

                // Create a URLSearchParams object to parse the query string
                const urlParams = new URLSearchParams(queryString);
            
                // Get the value of the 'date' parameter
                const dateParam = urlParams.get('date');

                if(dateParam){
                    history('/profile/' + user_id + '/' + slug+'?date='+dateParam);
                }else{
                    history('/profile/' + user_id + '/' + slug);
                }
            }else{
                history('/profile/' + user_id + '/' + slug);
            }
        }
    }, [slug]);// eslint-disable-line react-hooks/exhaustive-deps

    //change swiper current slide to match current selected tab 
    useEffect(() => {
        if (slug && swiper) {
            if (slug === 'general-info') {
                setTimeout(() => {
                    swiper?.slideTo(1, 400, true);
                }, 600)
            }
            if (slug === 'documents') {
                setTimeout(() => {
                    swiper?.slideTo(2, 400, true);
                }, 600)
            }
            if (slug === 'reminder') {
                setTimeout(() => {
                    swiper?.slideTo(3, 400, true);
                }, 600)
            }
            if (slug === 'costs') {
                setTimeout(() => {
                    swiper?.slideTo(4, 400, true);
                }, 600)
            }
        }
    }, [slug, swiper]);// eslint-disable-line react-hooks/exhaustive-deps

    //custom select styles
    const customStyles = {
        placeholder: (defaultStyles) => {
            return {
                ...defaultStyles,
                display: "flex",
                fontFamily: 'Poppins',
                fontWeight: '400',
                fontSize: '16px',
                color: '#707070 !important',
                border: 'none !important',
                minHeight: "26px",
                maxHeight: "26px",
            }
        },
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                display: "flex",
                color: '#00366A',
                fontFamily: 'Poppins',
                fontWeight: '400',
                backgroundColor: 'white',
                textTransform: "capitalize",
                margin: "auto",
                paddingBottom: "0",
                paddingTop: "0",
            };
        },
        singleValue: base => ({
            ...base,
            color: "#707070",
            display: "flex",
            fontSize: "14px",
            fontFamily: "Poppins",
            fontWeight: "400",
            minHeight: "26px",
            maxHeight: "26px",
            textTransform: "capitalize",
        }),
        control: (base, provided) => ({
            ...base,
            border: "0 !important",
            boxShadow: 'none !important',
            minHeight: '26px !important',
            height: '26px !important',
            '&:hover': { borderColor: 'transparent', border: 'none' },
            backgroundColor: 'white',
            outline: "none",
            width: "190px",
            borderRadius: "7px",
            fontFamily: 'Poppins',
            fontWeight: '400',
            fontSize: '16px',
        }),
        menu: (provided, state) => ({
            ...provided,
            borderRadius: "7px",
            textTransform: 'uppercase',
            border: "1px solid transparent",
            maxWidth: '250px',
            marginTop: '0px',
            boxShadow: '0px 3px 6px rgb(0 0 0 / 16%)',
        }),
        dropdownIndicator: base => ({
            ...base,
            color: "#00366A !important",
            padding: "4px",
            paddingTop: "1px",
        }),
        container: (base) => ({
            ...base,
            height: "26px",
            minHeight: "26px",
            maxHeight: "26px",
        }),
        valueContainer: (base) => ({
            ...base,
            height: "26px",
            minHeight: "26px",
            maxHeight: "26px",
        }),
        input: (base, state) => ({
            ...base,
            backgroundColor: "transparent !important",
            width: 'auto',
            textTransform: "none !important",
            border: "none !important",
            color: "#707070",
            fontSize: "16px",
            height: "23px",
            fontFamily: 'Poppins',
            fontWeight: '400',
            marginTop: "14px",
            '& input': {
                font: 'inherit',
            },
        })
    };

    //handle profile picture upload
    const profilePictureHandler = (uploadedFiles) => {
        if (uploadedFiles.length > 0) {
            setProfilePicture(uploadedFiles[0]);
            setCurrentInput('picture');
            setCurrentInputValue(uploadedFiles[0]);
            let file = URL.createObjectURL(uploadedFiles[0]);
            setTempPicture(file);
        }
    }

    //target remove profile picture backend call
    const removePicture = (e) => {
        setProfilePicture(null);
        setCurrentInput('picture');
        setCurrentInputValue('');
        setChangeInput(1);
        setUploadImagePopup(false);
    }

    //target backend call on birthdate change
    useEffect(() => {
        if (birthDate) {
            setCurrentInput('dob');
            setCurrentInputValue(birthDate);
        }
    }, [birthDate]);// eslint-disable-line react-hooks/exhaustive-deps

    //target backend call on job title change
    useEffect(() => {
        if (jobTitle) {
            setCurrentInput('job_title_id');
            setCurrentInputValue(jobTitle);
        }
    }, [jobTitle]);// eslint-disable-line react-hooks/exhaustive-deps

    //target backend call on nationality change
    useEffect(() => {
        if (userNationality) {
            setCurrentInput('nationality_id');
            setCurrentInputValue(userNationality);
        }
    }, [userNationality]);// eslint-disable-line react-hooks/exhaustive-deps

    //target backend call on gender change
    useEffect(() => {
        if (userGender) {
            setCurrentInput('gender_id');
            setCurrentInputValue(userGender);
        }
    }, [userGender]);// eslint-disable-line react-hooks/exhaustive-deps

    // get user data
    useEffect(() => {
        if (authUser) {
            //when user is same as the auth user we directly set profile user from auth user
            if (authUser?.id === Number(user_id)) {
                setProfileUser(authUser);
                setFormData(authUser);
                setBirthDate(authUser?.dob ? new Date(authUser?.dob) : '');
                setJobTitle(allJobTitles()[getIndex(authUser?.job_title_id, allJobTitles(), 'value')]);
                setUserGender(allGenders()[getIndex(authUser?.gender_id, allGenders(), 'value')]);
                setUserNationality(allNationalities()[getIndex(authUser?.nationality_id, allNationalities(), 'value')]);
            } else {
                setGeneralDataApiOptions({
                    url: 'get-user-data',
                    method: 'post',
                    data: {
                        user_id: user_id,
                    },
                    callback: res => {
                        setChangeInput(0);
                        setProfileUser(res.data);
                        setFormData(res.data);
                        setBirthDate(res.data?.dob ? new Date(res?.data?.dob) : '');
                        setJobTitle(allJobTitles()[getIndex(res.data?.job_title_id, allJobTitles(), 'value')]);
                        setUserGender(allGenders()[getIndex(res.data?.gender_id, allGenders(), 'value')]);
                        setUserNationality(allNationalities()[getIndex(res.data?.nationality_id, allNationalities(), 'value')]);
                    },
                    catch: err => {
                        if (err) {
                            history('/not-found');
                        }
                    }
                })
            }
        }
    }, [user_id]);// eslint-disable-line react-hooks/exhaustive-deps

    // submit edit user data to backend
    useEffect(() => {
        if (changeInput > 0 && slug === 'general-info') {
            let fd = new FormData();
            fd.append('current_user', user_id);
            fd.append('current_field', currentInput);
            if (currentInput === 'emails' || currentInput === 'phones' || currentInput === 'addresses') {
                fd.append('current_field_value', [currentInputValue['id'], currentInputValue['value']]);
            } else {
                fd.append('current_field_value', currentInputValue);
            }
            fd.append('name', formData?.name ? formData?.name : '');
            fd.append('picture', profilePicture ? profilePicture : '');
            fd.append('mof_number', formData?.mof_number ? formData?.mof_number : '');
            fd.append('dob', birthDate ? formatDate(birthDate) : '');
            fd.append('job_title_id', jobTitle ? jobTitle.value : null);
            fd.append('gender_id', userGender ? userGender.value : null);
            fd.append('nationality_id', userNationality ? userNationality.value : null);
            setGeneralDataApiOptions({
                url: 'edit-user-data',
                method: 'post',
                data: fd,
                callback: res => {
                    setErrorsReturned({});
                    setFormData(res?.data?.user);
                    if (authUser?.id === Number(user_id)) {
                        setAuthUser(res.data.user);
                        setProfileUser(res.data.user);
                    } else {
                        setProfileUser(res.data.user);
                    }
                    setCurrentInputValue('');
                    setFormData(res.data?.user);
                    setCurrentInput('');
                    setErrorsReturned({});
                    setDeletePopup();
                    setChangeInput(0);
                    setUploadImagePopup(false);
                    setTempPicture('');
                    setZoom(1);
                    setGeneralData(res?.data?.generalData);

                },
                catch: err => {
                    if (err) {
                        if (currentInput === 'nationalities') {
                            setFormData(profileUser);
                        }
                        setErrorsReturned(err);
                        setChangeInput(0);
                    }
                }
            })
        }
    }, [changeInput]);// eslint-disable-line react-hooks/exhaustive-deps

    //remove from formdata dynamic inputs
    const removeFormData = (single, index, key) => {
        if (authUser?.id === profileUser?.id || authUser?.user_role_id === 1) {
            setErrorsReturned({});
            if (key === 'nationalities') {
                if (!formData?.nationalities[index]?.id) {
                    let newFd = { ...formData };
                    newFd[key].splice(index, 1);
                    setFormData(newFd);
                }
                else {
                    setDeletePopup({ single, index, key });
                }
            }
            if (key === 'emails') {
                if (!formData?.emails[index]?.id) {
                    let newFd = { ...formData };
                    newFd[key].splice(index, 1);
                    setFormData(newFd);
                }
                else {
                    setDeletePopup({ single, index, key });
                }
            }
            if (key === 'phones') {
                if (!formData?.phones[index]?.id) {
                    let newFd = { ...formData };
                    newFd[key].splice(index, 1);
                    setFormData(newFd);
                }
                else {
                    setDeletePopup({ single, index, key });
                }
            }
            if (key === 'addresses') {
                if (!formData?.addresses[index]?.id) {
                    let newFd = { ...formData };
                    newFd[key].splice(index, 1);
                    setFormData(newFd);
                }
                else {
                    setDeletePopup({ single, index, key });
                }
            }
        }
    }

    // delete already fetched dynamic input from backend
    const deleteElement = () => {
        if (deletePopup) {
            if (deletePopup['key'] === 'nationalities') {
                setCurrentInput('delete_nationalities');
                setCurrentInputValue(deletePopup['single'].id ? deletePopup['single'].id : 0);
                setChangeInput(1);
            }
            if (deletePopup['key'] === 'emails') {
                setCurrentInput('delete_emails');
                setCurrentInputValue(deletePopup['single'].id ? deletePopup['single'].id : 0);
                setChangeInput(1);
            }
            if (deletePopup['key'] === 'phones') {
                setCurrentInput('delete_phones');
                setCurrentInputValue(deletePopup['single'].id ? deletePopup['single'].id : 0);
                setChangeInput(1);
            }
            if (deletePopup['key'] === 'addresses') {
                setCurrentInput('delete_addresses');
                setCurrentInputValue(deletePopup['single'].id ? deletePopup['single'].id : 0);
                setChangeInput(1);
            }
        }
    }

    //add to formdata dynamic inputs
    const addToFormData = (key) => {
        if (authUser?.id === profileUser?.id || authUser?.user_role_id === 1) {
            let emptyObject = {};
            let added = 1;
            if (key === 'nationalities') {
                if (!profileUser?.nationality_id) {
                    added = 0;
                }
                if (formData?.nationalities?.length > 0) {
                    formData?.nationalities?.forEach(single => {
                        if (single?.nationality_id === null || single?.nationality_id === '') {
                            added = 0;
                        }
                    });
                }
                if (added === 1) {
                    emptyObject = { id: null, nationality_id: null };
                    let newFormData = { ...formData };
                    newFormData[key].push(emptyObject);
                    setFormData(newFormData);
                }
            }


            if (key === 'emails') {
                if (!profileUser?.email) {
                    added = 0;
                }
                if (formData?.emails?.length > 0) {
                    formData?.emails?.forEach(single => {
                        if (single?.email === null || single?.email === '') {
                            added = 0;
                        }
                    });
                }
                if (added === 1) {
                    emptyObject = { id: 0, email: '' };
                    let newFormData = { ...formData };
                    newFormData[key].push(emptyObject);
                    setFormData(newFormData);
                }
            }

            if (key === 'phones') {
                if (!profileUser?.main_phone_number) {
                    added = 0;
                }
                if (formData?.phones?.length > 0) {
                    formData?.phones?.forEach(single => {
                        if (single?.phone === null || single?.phone === '') {
                            added = 0;
                        }
                    });
                }
                if (added === 1) {
                    emptyObject = { id: 0, phone: '' };
                    let newFormData = { ...formData };
                    newFormData[key].push(emptyObject);
                    setFormData(newFormData);
                }
            }

            if (key === 'addresses') {
                if (!profileUser?.main_address) {
                    added = 0;
                }
                if (formData?.addresses?.length > 0) {
                    formData?.addresses?.forEach(single => {
                        if (single?.address === null || single?.address === '') {
                            added = 0;
                        }
                    });
                }
                if (added === 1) {
                    emptyObject = { id: 0, address: '' };
                    let newFormData = { ...formData };
                    newFormData[key].push(emptyObject);
                    setFormData(newFormData);
                }
            }
        }
    }

    //handle dynamic input changes
    const handleDynamicInputChange = (e, single, index, key) => {
        let formDt = { ...formData };
        if (key === 'nationalities') {
            formData.nationalities[index].nationality_id = e.value;
        }
        setFormData(formDt);
        setCurrentInput('nationalities');
        setCurrentInputValue([
            single.id ? single?.id : 0,
            e.value
        ]);
        setChangeInput(1);
    }

    //close the delete popup
    const cancelDeletePopup = () => {
        setDeletePopup();
    }

    //close change password popup and reset states
    const closeChangePassword = () => {
        setShowConfirmNewPassword(false);
        setShowCurrentPassword(false);
        setShowNewPassword(false);
        setNewPassword('');
        setCurrentPassword('');
        setConfirmNewPassword('');
        setChangePassword(false);
        setErrorsReturned({});
        setSuccessMessage(false);
    }

    // submit forgot password request 
    const submitForgotPassword = (e) => {
        e.preventDefault();
        setGeneralDataApiOptions({
            url: 'user-forgot-password',
            method: 'post',
            data: {
                current_user: profileUser?.id,
            },
            callback: res => {
                setErrorsReturned({});
                setSuccessMessage(true);
                setTimeout(() => {
                    setForgotPassword(false);
                    closeChangePassword();
                }, 2000);
            },
            catch: err => {
                if (err) {
                    setSuccessMessage(false);
                    setErrorsReturned(err);
                }
            }
        })
    }

    //submit change password request
    const submitChangePassword = (e) => {
        e.preventDefault();
        setGeneralDataApiOptions({
            url: 'change-user-password',
            method: 'post',
            data: {
                current_password: currentPassword,
                new_password: newPassword,
                new_password_confirmation: confirmNewPassword,
            },
            callback: res => {
                setErrorsReturned({});
                setSuccessMessage(true);
                setTimeout(() => {
                    closeChangePassword();
                }, 2000)
            },
            catch: err => {
                if (err) {
                    setSuccessMessage(false);
                    setErrorsReturned(err);
                }
            }
        })
    }
    return (
        <>

            <Layout activePage="profile">
                {
                    authUser?.id && profileUser?.id && securityCheck === 1 ?
                        <>
                            <div className="row">
                                <div className="col-12 col-sm-auto">
                                    <h1 style={{ textTransform: "capitalize" }} className="page-titles color-dark-blue pb-3">{profileUser?.name}</h1>
                                </div>
                                {
                                    slug === 'general-info' && authUser?.id === profileUser?.id ?
                                        <div className="col">
                                            <div className="row">
                                                <div className="col-12 d-flex justify-content-end pb-3">
                                                    <button onClick={() => setChangePassword(true)} className="popup-button my-auto mx-2 w-auto px-3">{generalData?.website_titles['change-password']}</button>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        null
                                }
                            </div>

                            <Swiper
                                onSwiper={setSwiper}
                                className="titles-swiper w-100 new-titles-styling-syling"
                                // spaceBetween={20}
                                slidesPerView={2}
                                breakpoints={{
                                    490: {
                                        slidesPerView: 4,
                                    },
                                    355: {
                                        slidesPerView: 3
                                    }
                                }}
                            >
                                <div className="row padding-top-table-settings" >

                                    <SwiperSlide className={(slug === 'general-info' ? " active-custom-slide" : "")} key={1}>
                                        <div className="col-auto">
                                            <h2 onClick={() => history('/profile/' + profileUser?.id + '/general-info')} className={"settings-titles color-dark-blue cursor-pointer" + (slug === 'general-info' ? " active" : "")}>{generalData?.website_titles['general-info']}</h2>
                                        </div>
                                    </SwiperSlide>
                                    {
                                        authUser?.id === profileUser?.id || authUser?.user_role_id === 1 ?
                                            <>
                                                <SwiperSlide className={(slug === 'documents' ? " active-custom-slide" : "")}  key={2}>
                                                    <div className="col-auto">
                                                        <h2 onClick={() => history('/profile/' + profileUser?.id + '/documents')} className={"settings-titles color-dark-blue cursor-pointer" + (slug === 'documents' ? " active" : "")}>{generalData?.website_titles['documents-individual']}</h2>
                                                    </div>
                                                </SwiperSlide>
                                                <SwiperSlide className={(slug === 'reminder' ? " active-custom-slide" : "")} key={3}>
                                                    <div className="col-auto">
                                                        <h2 onClick={() => history('/profile/' + profileUser?.id + '/reminder')} className={"settings-titles color-dark-blue cursor-pointer" + (slug === 'reminder' ? " active" : "")}>{generalData?.website_titles['reminder']}</h2>
                                                    </div>
                                                </SwiperSlide>
                                                <SwiperSlide className={(slug === 'costs' ? " active-custom-slide" : "")} key={4}>
                                                    <div className="col-auto">
                                                        <h2 onClick={() => history('/profile/' + profileUser?.id + '/costs')} className={"settings-titles color-dark-blue cursor-pointer" + (slug === 'costs' ? " active" : "")}>{generalData?.website_titles['costs']}</h2>
                                                    </div>
                                                </SwiperSlide>
                                            </>
                                            :
                                            null
                                    }
                                </div>
                            </Swiper>
                            <div className="management-table pt-0">
                                {
                                    (slug === 'general-info') && profileUser ?
                                        <>
                                            <div className="container-fluid min-height-table">
                                                <div className="row min-height-table">
                                                    <div className="col-12 col-lg-4 pt-4 drop-shadow-div">
                                                        <div className="row">
                                                            <div className="col-12 d-flex justify-content-center pb-3">
                                                                <div onClick={() => authUser?.id === formData?.id || authUser?.user_role_id === 1 ? setUploadImagePopup(true) : null} className="position-relative">
                                                                    <img className="profile-image-general" src={formData.picture ? formData.full_path_picture : generalData?.fixed_settings?.full_path_no_profile_picture} alt="profile" />
                                                                    {
                                                                        authUser?.id === formData?.id || authUser?.user_role_id === 1 ?
                                                                            <div className="edit-prof-back">
                                                                                <EditPencil />
                                                                            </div>
                                                                            :
                                                                            null
                                                                    }
                                                                </div>
                                                            </div>

                                                            {
                                                                errorsReturned && errorsReturned?.current_field_value ?
                                                                    <div className="col-12 my-auto align-items-center pb-4">
                                                                        <h5 className="pop-errors-text">{errorsReturned?.current_field_value}</h5>
                                                                    </div>
                                                                    :
                                                                    null
                                                            }
                                                            <div className="col-12 my-auto align-items-center pb-4">
                                                                <div className="row">
                                                                    <div className="col-12 col-sm-6  pt-3">
                                                                        <div className="row">
                                                                            <div className="col-12 d-flex">
                                                                                <img className="profile-general-svg me-2" src="/assets/images/name-tag.svg" alt="name" />
                                                                                <p className="profile-general-input-title">{generalData?.website_titles['name']}</p>
                                                                            </div>
                                                                            <div className="col-12 col-sm-8 col-lg-10 col-xl-9 pt-1">
                                                                                <EditInput withPadd={1} inputType={'text'} postUrl="edit-user-data" data={profileUser} value={formData?.name ? formData?.name : ''} inputName={"name"} userId={user_id} />
                                                                            </div>
                                                                            {
                                                                                errorsReturned && errorsReturned?.name ?
                                                                                    <div className="col-12 col-sm-8 col-lg-10 col-xl-9 pt-1">
                                                                                        <h5 className=" ps-4 pop-errors-text">{errorsReturned?.name}</h5>
                                                                                    </div>
                                                                                    :
                                                                                    null
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 col-sm-6  pt-3">
                                                                        <div className="row">
                                                                            <div className="col-12 d-flex">
                                                                                <img className="profile-general-svg me-2" src="/assets/images/name-tag.svg" alt="name" />
                                                                                <p className="profile-general-input-title">{generalData?.website_titles['name'] + ' AR'}</p>
                                                                            </div>
                                                                            <div className="col-12 col-sm-8 col-lg-10 col-xl-9 pt-1">
                                                                                <EditInput
                                                                                    withPadd={1}
                                                                                    inputType={'text'}
                                                                                    postUrl="edit-user-data"
                                                                                    data={profileUser}
                                                                                    value={formData?.name_ar ? formData?.name_ar : ''}
                                                                                    inputName={"name_ar"}
                                                                                    userId={user_id}
                                                                                    placeholder={generalData?.website_titles['name'] + ' AR'}
                                                                                />
                                                                            </div>
                                                                            {
                                                                                errorsReturned && errorsReturned?.name ?
                                                                                    <div className="col-12 col-sm-8 col-lg-10 col-xl-9 pt-1">
                                                                                        <h5 className=" ps-4 pop-errors-text">{errorsReturned?.name}</h5>
                                                                                    </div>
                                                                                    :
                                                                                    null
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 col-sm-6 col-lg-12 pt-3">
                                                                        <div className="row">
                                                                            <div className="col-12 d-flex">
                                                                                <img className="profile-general-svg me-2" src="/assets/images/date-icon.svg" alt="date" />
                                                                                <p className="profile-general-input-title">{generalData?.website_titles['date-of-birth']}</p>
                                                                            </div>
                                                                            <div style={{ zIndex: '99999999' }} className="col-12 col-sm-8 col-lg-10 col-xl-8 pt-1">
                                                                                <DatePicker
                                                                                    disabled={authUser?.id === profileUser?.id || authUser?.user_role_id === 1 ? 0 : 1}
                                                                                    className="birthdate-profile"
                                                                                    placeholderText={generalData?.website_titles['select-date-of-birth']}
                                                                                    value={birthDate ? birthDate : ''}
                                                                                    showMonthDropdown
                                                                                    showYearDropdown
                                                                                    dropdownMode="select"
                                                                                    selected={birthDate}
                                                                                    dateFormat="d MMMM yyyy"
                                                                                    onChange={(date) => { setBirthDate(date); setTimeout(() => { setChangeInput(1) }, 100) }}
                                                                                    maxDate={addYears(new Date(), -21)}
                                                                                    minDate={addYears(new Date(), -100)}
                                                                                    locale={localStorage.getItem("BlpLanguage")}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-12 col-sm-6 col-lg-12 pt-3">
                                                                        <div className="row">
                                                                            <div className="col-12 d-flex">
                                                                                <img className="profile-general-svg me-2" src="/assets/images/nationality.svg" alt="date" />
                                                                                <p className="profile-general-input-title">{generalData?.website_titles['nationality']}</p>
                                                                            </div>
                                                                            <div className="col-12 col-sm-8 col-lg-10 col-xl-8 pt-1">
                                                                                <div className="row">
                                                                                    <div style={{ zIndex: "999" }} className="col-auto" onClick={(e) => addToFormData('nationalities')}>
                                                                                        <PlusIconFd style={{ marginTop: "-6px", marginLeft: "2px !important" }} />
                                                                                    </div>
                                                                                    <div style={{ marginLeft: "-4px", marginRight: "-4px" }} className="col-10 ps-0">
                                                                                        <Select
                                                                                            isDisabled={authUser?.id === profileUser?.id || authUser?.user_role_id === 1 ? false : true}
                                                                                            isSearchable={true}
                                                                                            classNamePrefix="profile-select"
                                                                                            className="profile-select w-100 my-auto"
                                                                                            styles={customStyles}
                                                                                            options={allNationalities()}
                                                                                            components={{
                                                                                                IndicatorSeparator: () => null
                                                                                            }}
                                                                                            placeholder={generalData?.website_titles['select-nationality']}
                                                                                            value={userNationality ? userNationality : null}
                                                                                            onChange={(e) => { setUserNationality(e); setTimeout(() => { setChangeInput(1) }, 100) }}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            {
                                                                                formData?.nationalities?.length > 0 ?
                                                                                    formData?.nationalities?.map((singleNat, index) => (
                                                                                        <div className="col-12 col-sm-8 col-lg-10 col-xl-8 pt-1" key={index}>
                                                                                            <div className="row">
                                                                                                <div style={{ zIndex: "999" }} className="col-auto" onClick={(e) => removeFormData(singleNat, index, 'nationalities')}>
                                                                                                    <MinusIconFd style={{ marginTop: "-6px", marginLeft: "2px !important" }} />
                                                                                                </div>
                                                                                                <div style={{ marginLeft: "-4px", marginRight: "-4px" }} className="col-10 ps-0">
                                                                                                    <Select
                                                                                                        isDisabled={authUser?.id === profileUser?.id || authUser?.user_role_id === 1 ? false : true}
                                                                                                        classNamePrefix="profile-select"
                                                                                                        className="profile-select w-100 my-auto"
                                                                                                        styles={customStyles}
                                                                                                        options={allNationalities()}
                                                                                                        components={{
                                                                                                            IndicatorSeparator: () => null
                                                                                                        }}
                                                                                                        placeholder={generalData?.website_titles['select-nationality']}
                                                                                                        isSearchable={true}
                                                                                                        value={singleNat ? allNationalities()[getIndex(singleNat?.nationality_id, allNationalities(), 'value')] : null}
                                                                                                        onChange={(e) => handleDynamicInputChange(e, singleNat, index, 'nationalities')} />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    ))
                                                                                    :
                                                                                    null
                                                                            }
                                                                            {
                                                                                (errorsReturned && errorsReturned?.nationalities) || (errorsReturned && errorsReturned?.nationality_id) ?
                                                                                    <>
                                                                                        <p className="m-0 ps-4 errors-auth-profile">{errorsReturned?.nationalities}</p>
                                                                                        <p className="m-0 ps-4 errors-auth-profile">{errorsReturned?.nationality_id}</p>
                                                                                    </>
                                                                                    :
                                                                                    null
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 col-sm-6 col-lg-12 pt-3">
                                                                        <div className="row">
                                                                            <div className="col-12 d-flex">
                                                                                <img className="profile-general-svg me-2" src="/assets/images/emails.svg" alt="date" />
                                                                                <p className="profile-general-input-title">{generalData?.website_titles['email']}</p>
                                                                            </div>
                                                                            <div className="col-12 pt-1">
                                                                                <div className="row">
                                                                                    <div style={{ zIndex: "9999" }} className="col-auto" onClick={(e) => addToFormData('emails')}>
                                                                                        <PlusIconFd style={{ marginLeft: "2px !important" }} />
                                                                                    </div>
                                                                                    <div style={{ marginLeft: "-4px", marginRight: "-4px" }} className="col-10 ps-0">
                                                                                        <h5 className="locked-inputs w-100">{formData?.email}</h5>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            {
                                                                                formData?.emails?.length > 0 ?
                                                                                    formData?.emails?.map((singleEmail, index) => (
                                                                                        <div className="col-12 pt-1" key={index}>
                                                                                            <div className="row">
                                                                                                <div style={{ zIndex: "999" }} className="col-auto" onClick={(e) => removeFormData(singleEmail, index, 'emails')}>
                                                                                                    <MinusIconFd style={{ marginTop: "-6px", marginLeft: "2px !important" }} />
                                                                                                </div>
                                                                                                <div style={{ marginRight: "-4px" }} className="col ps-0">
                                                                                                    <EditInput placeholder={generalData?.website_titles['enter-email']} className="w-100" singleRec={singleEmail} withPadd={0} inputType={'text'} data={formData} value={singleEmail?.email ? singleEmail?.email : ''} inputName={"emails"} userId={user_id} />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    ))
                                                                                    :
                                                                                    null
                                                                            }
                                                                            {
                                                                                errorsReturned && errorsReturned?.emails ?
                                                                                    <p className="m-0 ps-4 errors-auth-profile">{errorsReturned?.emails}</p>
                                                                                    :
                                                                                    null
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 col-sm-6 col-lg-12 pt-3">
                                                                        <div className="row">
                                                                            <div className="col-12 d-flex">
                                                                                <img className="profile-general-svg me-2" src="/assets/images/phone.svg" alt="date" />
                                                                                <p className="profile-general-input-title">{generalData?.website_titles['phone']}</p>
                                                                            </div>
                                                                            <div className="col-12 pt-1">
                                                                                <div className="row">
                                                                                    <div style={{ zIndex: "9999" }} className="col-auto" onClick={(e) => addToFormData('phones')}>
                                                                                        <PlusIconFd style={{ marginLeft: "2px !important" }} />
                                                                                    </div>
                                                                                    <div style={{ marginRight: "-4px" }} className="col-10 ps-0">
                                                                                        <EditInput placeholder={generalData?.website_titles['enter-phone']} inputType={'phones'} postUrl="edit-user-data" data={profileUser} value={formData?.main_phone_number ? formData?.main_phone_number : ''} inputName={"main_phone_number"} userId={user_id} />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            {
                                                                                formData?.phones?.length > 0 ?
                                                                                    formData?.phones?.map((singlePhone, index) => (
                                                                                        <div className="col-12 pt-1" key={index}>
                                                                                            <div className="row">
                                                                                                <div style={{ zIndex: "9999" }} className="col-auto" onClick={(e) => removeFormData(singlePhone, index, 'phones')}>
                                                                                                    <MinusIconFd style={{ marginTop: "-6px", marginLeft: "2px !important" }} />
                                                                                                </div>
                                                                                                <div style={{ marginRight: "-4px" }} className="col ps-0">
                                                                                                    <EditInput placeholder={generalData?.website_titles['enter-phone']} className="w-100" singleRec={singlePhone} withPadd={0} inputType={'phones'} data={formData} value={singlePhone?.phone ? singlePhone?.phone : ''} inputName={"phones"} userId={user_id} />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    ))
                                                                                    :
                                                                                    null
                                                                            }
                                                                            {
                                                                                errorsReturned && errorsReturned?.phones ?
                                                                                    <p className="m-0 ps-4 errors-auth-profile">{errorsReturned?.phones}</p>
                                                                                    :
                                                                                    null
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 col-sm-6 col-lg-12 pt-3">
                                                                        <div className="row">
                                                                            <div className="col-12 d-flex">
                                                                                <img className="profile-general-svg me-2" src="/assets/images/location.svg" alt="date" />
                                                                                <p className="profile-general-input-title">{generalData?.website_titles['address']}</p>
                                                                            </div>
                                                                            <div className="col-12 pt-1">
                                                                                <div className="row">
                                                                                    <div style={{ zIndex: "9999" }} className="col-auto" onClick={(e) => addToFormData('addresses')}>
                                                                                        <PlusIconFd style={{ marginLeft: "2px !important" }} />
                                                                                    </div>
                                                                                    <div style={{ marginRight: "-4px" }} className="col-10 ps-0">
                                                                                        <EditInput placeholder={generalData?.website_titles['enter-address']} inputType={'text'} postUrl="edit-user-data" data={profileUser} value={formData?.main_address ? formData?.main_address : ''} inputName={"main_address"} userId={user_id} />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            {
                                                                                formData?.addresses?.length > 0 ?
                                                                                    formData?.addresses?.map((singleAdd, index) => (
                                                                                        <div className="col-12 pt-1" key={index}>
                                                                                            <div className="row">
                                                                                                <div style={{ zIndex: "9999" }} className="col-auto" onClick={(e) => removeFormData(singleAdd, index, 'addresses')}>
                                                                                                    <MinusIconFd style={{ marginTop: "-6px", marginLeft: "2px !important" }} />
                                                                                                </div>
                                                                                                <div style={{ marginRight: "-4px" }} className="col ps-0">
                                                                                                    <EditInput placeholder={generalData?.website_titles['enter-address']} className="w-100" singleRec={singleAdd} withPadd={0} inputType={'text'} data={formData} value={singleAdd?.address ? singleAdd?.address : ''} inputName={"addresses"} userId={user_id} />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    ))
                                                                                    :
                                                                                    null
                                                                            }
                                                                            {
                                                                                errorsReturned && (errorsReturned?.addresses || errorsReturned?.main_address) ?
                                                                                    <>
                                                                                        <p className="m-0 ps-4 errors-auth-profile">{errorsReturned?.addresses}</p>
                                                                                        <p className="m-0 ps-4 errors-auth-profile">{errorsReturned?.main_address}</p>
                                                                                    </>
                                                                                    :
                                                                                    null
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-lg-8 pt-4 px-lg-4 pb-5">
                                                        <div className="row">
                                                            <div className="col-auto">
                                                                <h1 className="profile-big-title">{generalData?.website_titles['additional-info']}</h1>
                                                            </div>
                                                        </div>
                                                        <div className="row pt-2">
                                                            <div className="col-12 col-md-6 pb-2">
                                                                <p className="profile-general-input-title pb-1">{generalData?.website_titles['job-title']}</p>
                                                                <Select
                                                                    isDisabled={authUser?.id === profileUser?.id || authUser?.user_role_id === 1 ? false : true}
                                                                    classNamePrefix="profile-select"
                                                                    className="profile-select w-100 my-auto"
                                                                    styles={customStyles}
                                                                    options={allJobTitles()}
                                                                    components={{
                                                                        IndicatorSeparator: () => null
                                                                    }}
                                                                    placeholder={generalData?.settings_section_settings['settings-job-title']}
                                                                    isSearchable={false}
                                                                    value={jobTitle ? jobTitle : null}
                                                                    onChange={(e) => { setJobTitle(e); setTimeout(() => { setChangeInput(1) }, 100) }}
                                                                />
                                                            </div>
                                                            <div className="col-12 col-md-6 pb-2">
                                                                <p className="profile-general-input-title pb-1">{generalData?.website_titles['mof-registration-number']}</p>
                                                                <div className="row">
                                                                    <div className="col-12 col-lg-10 col-xl-8">
                                                                        <EditInput placeholder={generalData?.website_titles['mof-registration-number']} inputType={'text'} postUrl="edit-user-data" data={profileUser} value={profileUser?.mof_number ? profileUser?.mof_number : ''} inputName={"mof_number"} userId={user_id} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row pt-2">
                                                            <div className="col-12 col-md-6 pb-2">
                                                                <p className="profile-general-input-title pb-1">{generalData?.website_titles['gender']}</p>
                                                                <Select
                                                                    isDisabled={authUser?.id === profileUser?.id || authUser?.user_role_id === 1 ? false : true}
                                                                    classNamePrefix="profile-select"
                                                                    className="profile-select w-100 my-auto"
                                                                    styles={customStyles}
                                                                    options={allGenders()}
                                                                    components={{
                                                                        IndicatorSeparator: () => null
                                                                    }}
                                                                    placeholder={generalData?.website_titles['gender']}
                                                                    isSearchable={false}
                                                                    value={userGender ? userGender : null}
                                                                    onChange={(e) => { setUserGender(e); setTimeout(() => { setChangeInput(1) }, 100) }} />
                                                            </div>
                                                        </div>
                                                        <div className="row pt-2">
                                                            <div className="col-12 pb-2">
                                                                <h1 className="profile-big-title">{generalData?.website_titles['location']}</h1>
                                                            </div>
                                                            <div className="col-12">
                                                                <div className="row">
                                                                    <div className="col-12 col-md-8 pb-2">
                                                                        <p className="profile-general-input-title pb-1">{generalData?.website_titles['current-location']}</p>
                                                                        <div className="row">
                                                                            <div className="col-12 col-lg-10 col-xl-8">
                                                                                <h5 className="locked-inputs">{profileUser?.current_location}</h5>
                                                                                {/* <EditInput placeholder={generalData?.website_titles['current-location']} inputType={'text'} postUrl="edit-user-data" data={profileUser} value={profileUser?.current_location_description ? profileUser?.current_location_description : ''} inputName={"current_location_description"} userId={user_id} /> */}
                                                                            </div>
                                                                        </div>
                                                                        {
                                                                            errorsReturned && errorsReturned?.current_location_description ?
                                                                                <div className="col-12 col-sm-8 col-lg-10 col-xl-9 pt-1">
                                                                                    <h5 className=" ps-4 pop-errors-text">{errorsReturned?.current_location_description}</h5>
                                                                                </div>
                                                                                :
                                                                                null
                                                                        }
                                                                    </div>
                                                                    <div className="col-12 col-md-4">

                                                                        {
                                                                            profileUser?.location_date ?
                                                                                <div className="col-12 d-flex justify-content-end">
                                                                                    <h5 className="my-0 mx-2 location-text-date pb-2">{profileUser?.location_date}</h5>
                                                                                </div>
                                                                                :
                                                                                null
                                                                        }
                                                                        {
                                                                            authUser?.id === profileUser?.id ?
                                                                                <div className="col-12 d-flex justify-content-end">
                                                                                    <button onClick={() => setUpdateLocation(updateLocation + 1)} type="button" className="popup-button my-auto mx-2 w-auto px-3">{generalData?.website_titles['update-location']}</button>
                                                                                </div>
                                                                                :
                                                                                null
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 py-3">
                                                                <div className="w-100 ">
                                                                    {
                                                                        updateLocation ?
                                                                            <>
                                                                                <Wrapper apiKey="AIzaSyDtj6MVZsopQI4aztiRpP9-M44URr6TCXw">
                                                                                    <GoogleMaps center={{ lat: profileUser?.lat ? Number(profileUser?.lat) : 33.8775484, lng: profileUser?.lng ? Number(profileUser?.lng) : 35.4615153 }} zoom={profileUser?.lng ? 16 : 9} />
                                                                                </Wrapper>
                                                                            </>

                                                                            :
                                                                            null
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {
                                                    changePassword ?
                                                        <div id="errors-popup" className=" error-popup-api d-flex align-items-center justify-content-center px-3 fade-in">
                                                            <div className="pop-up-api-box change-pass-width">
                                                                <CloseIcon className="cursor-pointer" onClick={() => closeChangePassword()} />
                                                                <div className="text-center ">
                                                                    <div className="containter-fluid">
                                                                        <div className="row">
                                                                            <div className="col-12">
                                                                                <h1 className="pop-up-title m-0 py-2 color-dark-blue">{generalData?.website_titles['change-password']}</h1>
                                                                            </div>
                                                                            <form onSubmit={submitChangePassword}>
                                                                                <div className="col-12 pt-2 pb-4">
                                                                                    <div className="row justify-content-center">
                                                                                        <div className="col-12 col-md-10 pt-2 position-relative">
                                                                                            <input type={showCurrentPassword ? 'text' : 'password'} name="current_password" className="add-user-inputs" placeholder={generalData?.website_titles['old-password']} value={currentPassword} onChange={(e) => setCurrentPassword(e.target.value)} />
                                                                                            {
                                                                                                showCurrentPassword ?
                                                                                                    <PasswordVisible onClick={() => setShowCurrentPassword(!showCurrentPassword)} style={{ top: "20px" }} />
                                                                                                    :
                                                                                                    <PasswordHidden onClick={() => setShowCurrentPassword(!showCurrentPassword)} style={{ top: "17px" }} />

                                                                                            }
                                                                                            {
                                                                                                errorsReturned?.current_password ?
                                                                                                    <h5 className="text-start pt-2 m-0 d-flex flex-start pop-errors-text">{errorsReturned?.current_password}</h5>
                                                                                                    :
                                                                                                    null
                                                                                            }
                                                                                        </div>
                                                                                        <div className="col-12 col-md-10 pt-2 position-relative">
                                                                                            <input type={showNewPassword ? 'text' : 'password'} name="new_password" className="add-user-inputs" placeholder={generalData?.authentication_pages_settings['new-password']} value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
                                                                                            {
                                                                                                showNewPassword ?
                                                                                                    <PasswordVisible onClick={() => setShowNewPassword(!showNewPassword)} style={{ top: "20px" }} />
                                                                                                    :
                                                                                                    <PasswordHidden onClick={() => setShowNewPassword(!showNewPassword)} style={{ top: "17px" }} />

                                                                                            }
                                                                                            {
                                                                                                errorsReturned?.new_password ?
                                                                                                    <h5 className="text-start pt-2 m-0 d-flex flex-start pop-errors-text">{errorsReturned?.new_password}</h5>
                                                                                                    :
                                                                                                    null
                                                                                            }
                                                                                        </div>
                                                                                        <div className="col-12 col-md-10 pt-2 position-relative">
                                                                                            <input type={showConfirmNewPassword ? 'text' : 'password'} name="new_password_confirmation" className="add-user-inputs" placeholder={generalData?.authentication_pages_settings['confirm-new-password']} value={confirmNewPassword} onChange={(e) => setConfirmNewPassword(e.target.value)} />
                                                                                            {
                                                                                                showConfirmNewPassword ?
                                                                                                    <PasswordVisible onClick={() => setShowConfirmNewPassword(!showConfirmNewPassword)} style={{ top: "20px" }} />
                                                                                                    :
                                                                                                    <PasswordHidden onClick={() => setShowConfirmNewPassword(!showConfirmNewPassword)} style={{ top: "17px" }} />

                                                                                            }
                                                                                            {
                                                                                                errorsReturned?.new_password_confirmation ?
                                                                                                    <h5 className="text-start pt-2 m-0 d-flex flex-start pop-errors-text">{errorsReturned?.new_password_confirmation}</h5>
                                                                                                    :
                                                                                                    null
                                                                                            }
                                                                                            {
                                                                                                errorsReturned && !errorsReturned.new_password_confirmation && !errorsReturned.new_password && !errorsReturned.current_password ?
                                                                                                    <h5 className="text-start pt-2 m-0 d-flex flex-start pop-errors-text">{errorsReturned[0]}</h5>
                                                                                                    :
                                                                                                    null
                                                                                            }
                                                                                            {
                                                                                                errorsReturned?.error ?
                                                                                                    <h5 className="text-start pt-2 m-0 d-flex flex-start pop-errors-text">{errorsReturned?.error}</h5>
                                                                                                    :
                                                                                                    null
                                                                                            }

                                                                                            {
                                                                                                successMessage ?
                                                                                                    <h5 className="text-start pt-2 m-0 d-flex flex-start success-auth">{generalData?.website_titles['password-changed-successfully']}</h5>
                                                                                                    :
                                                                                                    null
                                                                                            }
                                                                                            {/* <div className="col-12 text-end">
                                                                                                <h5 onClick={(e) => { e.preventDefault(); closeChangePassword(); setForgotPassword(true) }} className="forgot-pass-text-profile">{generalData?.authentication_pages_settings['forgot-password']}</h5>
                                                                                            </div> */}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-12 d-flex justify-content-center">
                                                                                    <button type="submit" className="popup-button my-auto mx-2">{generalData?.website_titles['save']}</button>
                                                                                    <button onClick={(e) => { e.preventDefault(); closeChangePassword() }} type="button" className="auth-button border-dark-blue my-auto mx-2 background-transparent w-auto px-3">{generalData?.website_titles['cancel']}</button>
                                                                                </div>
                                                                            </form>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        :
                                                        null
                                                }
                                                {
                                                    forgotPassword ?
                                                        <div id="errors-popup" className=" error-popup-api d-flex align-items-center justify-content-center px-3 fade-in">
                                                            <div className="pop-up-api-box change-pass-width">
                                                                <CloseIcon className="cursor-pointer" onClick={() => { setForgotPassword(false); closeChangePassword() }} />
                                                                <div className="text-center ">
                                                                    <div className="containter-fluid">
                                                                        <div className="row">
                                                                            <div className="col-12">
                                                                                <h1 className="pop-up-title m-0 py-2 color-dark-blue">{generalData?.authentication_pages_settings['forgot-password']}</h1>
                                                                            </div>
                                                                            <form onSubmit={submitForgotPassword}>
                                                                                <div className="col-12 pt-2 pb-4">
                                                                                    <div className="row justify-content-center">
                                                                                        <div className="col">
                                                                                            {
                                                                                                successMessage ?
                                                                                                    <h5 className="text-start pt-2 m-0 d-flex flex-center justify-content-center text-center success-auth">{generalData?.authentication_pages_settings['forgot-password-success']}</h5>
                                                                                                    :
                                                                                                    null
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-12 d-flex justify-content-center">
                                                                                    <button type="submit" className="popup-button my-auto mx-2 w-auto px-2">{generalData?.authentication_pages_settings['forgot-password-button']}</button>
                                                                                </div>
                                                                            </form>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        :
                                                        null
                                                }
                                                {
                                                    deletePopup ?
                                                        <div id="errors-popup" className=" error-popup-api d-flex align-items-center justify-content-center px-3 fade-in">
                                                            <div className="pop-up-api-box">
                                                                <CloseIcon className="cursor-pointer" onClick={() => cancelDeletePopup()} />
                                                                <div className="text-center ">
                                                                    <div className="containter-fluid">
                                                                        <div className="row d-flex justify-content-center align-items-center">
                                                                            <div className="col-9 col-sm-12 d-flex justify-content-center">
                                                                                <h1 className="pop-up-title m-0 pt-3 color-dark-blue">{generalData?.settings_section_settings['delete-popup-title']}</h1>
                                                                            </div>
                                                                            <div className="col-12 d-flex justify-content-center pt-4 pb-2">
                                                                                <button onClick={() => deleteElement()} type="button" className="popup-button my-auto mx-2 w-auto px-3">{generalData?.settings_section_settings['delete-popup-first-button']}</button>
                                                                                <button onClick={() => cancelDeletePopup()} type="button" className="auth-button border-dark-blue my-auto mx-2 background-transparent w-auto px-3">{generalData?.settings_section_settings['delete-popup-second-button']}</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        :
                                                        null
                                                }
                                                {
                                                    uploadImagePopup ?
                                                        <div id="errors-popup" className=" error-popup-api d-flex align-items-center justify-content-center px-3 fade-in">
                                                            <div className="pop-up-api-box">
                                                                <CloseIcon className="cursor-pointer" onClick={(e) => { e.preventDefault(); setUploadImagePopup(false); setTempPicture(''); setProfilePicture(''); setZoom(1); }} />
                                                                <div className="text-center ">
                                                                    <div className="containter-fluid">
                                                                        <div className="row">
                                                                            <div className="col-12">
                                                                                <h1 className="pop-up-title m-0 pt-2 color-dark-blue">Profile Picture</h1>
                                                                            </div>
                                                                            <div className="col-12 d-flex justify-content-center py-3">
                                                                                {
                                                                                    (formData?.picture || tempPicture) && tempPicture ?
                                                                                        <div className="image-crop-custom-containter">
                                                                                            <Cropper
                                                                                                image={tempPicture ? tempPicture : (profileUser?.picture ? profileUser?.full_path_picture : null)}
                                                                                                crop={crop}
                                                                                                zoom={zoom}
                                                                                                maxZoom={10}
                                                                                                cropShape={'round'}
                                                                                                aspect={1 / 1}
                                                                                                onCropChange={(e) => setCrop(e)}
                                                                                                onCropComplete={onCropComplete}
                                                                                                onZoomChange={setZoom}
                                                                                                showGrid={true}
                                                                                            />
                                                                                        </div>
                                                                                        :
                                                                                        <img className="popup-profile-image" src={formData?.picture ? formData?.full_path_picture : generalData?.fixed_settings?.full_path_no_profile_picture} alt="profile" />
                                                                                }

                                                                            </div>

                                                                            <form>
                                                                                <div className="col-12 d-flex justify-content-center">
                                                                                    <input hidden className="upload-comment-files" id='upload-comment-files' type="file" onChange={(e) => { e.preventDefault(); e.stopPropagation(); profilePictureHandler(e.target.files) }} />
                                                                                    <label htmlFor="upload-comment-files" >
                                                                                        <p className="popup-button my-auto mx-2">{profilePicture || profileUser?.picture ? 'Edit' : 'Add'}</p>
                                                                                    </label>
                                                                                    {
                                                                                        formData?.picture ?
                                                                                            <button onClick={(e) => removePicture(e)} type="button" className="popup-button my-auto mx-2">Remove</button>
                                                                                            :
                                                                                            null
                                                                                    }
                                                                                    {
                                                                                        tempPicture ?
                                                                                            <button onClick={(e) => showCroppedImage()} type="button" className="popup-button my-auto mx-2">Save</button>
                                                                                            :
                                                                                            null
                                                                                    }
                                                                                </div>
                                                                            </form>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        :
                                                        null
                                                }
                                            </div>
                                        </>
                                        :
                                        null
                                }
                                {
                                    slug === 'reminder' ?
                                        <UserRemiders marks={profileUser?.marks} userId={user_id} />
                                        :
                                        null
                                }
                                {
                                    slug === 'documents' ?
                                        <div className="col-12 pt-4">
                                            <Documents currentFolder={folder_id} currentUser={user_id} mainFolder={1} mainRoute={'profile'} subRoute={'documents'} user_id={user_id} />
                                        </div>
                                        :
                                        null
                                }
                                {
                                    slug === 'costs' ?
                                        <>
                                            <UserCosts />
                                        </>
                                        :
                                        null
                                }
                            </div>
                        </>
                        :
                        null
                }
                <Api options={usersApiOptions} />
                <Api options={generalDataApiOptions} />
            </Layout >
        </>
    )
}