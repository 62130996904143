import React, { useContext, useState } from 'react';
import GlobalState from '../contexts/GlobalState';
import Select from 'react-select';
import Api from './Api';
import ClientComments from './ClientComments';
import { getIndex } from '../Helpers';
import { BankIcon, ThreeDotsMenu, XIcon } from './Icons';

export default function ClientBanks(props) {

    const { generalDataGlobal, profileClientGlobal } = useContext(GlobalState);
    const [profileClient, setProfileClient] = profileClientGlobal;
    const [generalData,] = generalDataGlobal;
    const [generalDataApiOptions, setGeneralDataApiOptions] = useState({});
    const [bankAccount, setBankAccount] = useState(false);
    const [errorsReturned, setErrorsReturned] = useState({});


    /* Account Details*/
    const [currentAccount, setCurrentAccount] = useState({});
    const [bankName, setBankName] = useState('');
    const [bankAddress, setBankAddress] = useState('');
    const [bankPhone, setBankPhone] = useState('');
    const [bankAccountNumber, setBankAccountNumber] = useState('');
    const [bankAccountCurrency, setBankAccountCurrency] = useState('');
    const [accountSwift, setAccountSwift] = useState('');
    const [accountIban, setAccountIban] = useState('');
    const [accountName, setAccountName] = useState('');
    const [editMenu, setEditMenu] = useState({});
    const [bankBranch, setBankBranch] = useState('');
    const [accountComment, setAccountComment] = useState('');
    const [deleteAccount, setDeleteAccount] = useState({});
    /* Account Details End*/

    //get bank currencies
    const bankCurrencies = () => {
        const currencies = generalData?.bank_currencies?.map(
            curr => ({ value: curr.id, label: curr.title }))
        return currencies;
    }

    //reset states when account popup is closed
    const closeAddAccount = (e) => {
        setBankAccount(false);
        setBankName('');
        setBankAddress('');
        setBankPhone('');
        setBankAccountNumber('');
        setBankAccountCurrency('');
        setAccountSwift('');
        setAccountIban('');
        setAccountName('');
        setAccountComment('');
        setBankBranch('');
        setErrorsReturned({});
        setCurrentAccount({});
        setEditMenu({});
        setDeleteAccount({});
    };

    // set states when editing single account
    const editAccount = (e, single) => {
        setCurrentAccount(single);
        setBankName(single?.bank_name);
        setBankAddress(single?.bank_address);
        setBankPhone(single?.phone_number);
        setBankAccountNumber(single?.account_number);
        setBankAccountCurrency(bankCurrencies()[getIndex(single?.bank_currencies_id, bankCurrencies(), 'value')]);
        setAccountSwift(single?.swift_number);
        setBankBranch(single?.bank_branch);
        setAccountIban(single?.iban);
        setAccountName(single?.account_name);
        setAccountComment(single?.comment);
        setErrorsReturned({});
        setBankAccount(true);
    }

    // submit bank data to backend
    const submitBank = (e) => {
        e.preventDefault();

        let fd = new FormData();

        fd.append('bank_name', bankName ? bankName : '');
        fd.append('bank_address', bankAddress ? bankAddress : '');
        fd.append('phone_number', bankPhone ? bankPhone : '');
        fd.append('account_number', bankAccountNumber ? bankAccountNumber : '');
        fd.append('currency', bankAccountCurrency ? bankAccountCurrency?.value : '');
        fd.append('swift_number', accountSwift ? accountSwift : '');
        fd.append('iban', accountIban ? accountIban : '');
        fd.append('account_name', accountName ? accountName : '');
        fd.append('bank_branch', bankBranch ? bankBranch : '');
        fd.append('comment', accountComment ? accountComment : '');
        if (currentAccount?.id) {
            fd.append('account_id', currentAccount?.id);
        }

        if (props.clientId) {
            fd.append('client_id', props?.clientId);
            fd.append('user_id', props?.clientId);
        }

        setGeneralDataApiOptions({
            url: 'add-edit-bank-account',
            method: 'post',
            data: fd,
            callback: res => {
                setProfileClient(res?.data);
                closeAddAccount(e);
                setErrorsReturned({});
            },
            catch: err => {
                if (err) {
                    setErrorsReturned(err);
                }
            }
        });

    }


    // custom styles for the select
    const customStylesPopup = {
        placeholder: (defaultStyles) => {
            return {
                ...defaultStyles,
                display: "flex",
                fontFamily: 'Poppins',
                fontWeight: '400',
                textTransform: "capitalize",
                fontSize: '14px',
                color: '#07407C',
            }
        },
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                color: '#07407C',
                fontFamily: 'Poppins',
                fontWeight: '400',
                backgroundColor: 'white',
                textTransform: "capitalize",
                margin: "auto",
                paddingRight: "10px",
                maxWidth: "100%",
                paddingLeft: "10px",
                paddingBottom: "0",
                paddingTop: "0",
            };
        },
        singleValue: base => ({
            ...base,
            color: "#00366A",
            display: "flex",
            fontSize: "14px",
            fontFamily: "Poppins",
            fontWeight: "400",
            textTransform: "capitalize",
        }),
        control: provided => ({
            ...provided,
            height: "40px",
            border: "none",
            backgroundColor: 'white',
            outline: "none",
            '&:hover': { borderColor: 'transparent' },
            textTransform: 'uppercase',
            width: "100%",
            borderRadius: "7px",
            fontFamily: 'Poppins',
            fontWeight: '400',
            boxShadow: "0px 3px 6px rgb(0 0 0 / 16%)",
        }),
        menu: (provided, state) => ({
            ...provided,
            borderRadius: "7px",
            textTransform: 'uppercase',
            border: "1px solid transparent",
        }),
        dropdownIndicator: base => ({
            ...base,
            color: "#00366A !important",
        }),
        input: (base, state) => ({
            ...base,
            color: "#393939",
            fontSize: "16px",
            height: "100%",
            fontFamily: 'Poppins',
            textTransform: 'uppercase',
            fontWeight: '400',
            marginTop: "14px",
            '& input': {
                font: 'inherit',
            },
        })
    };

    //delete account post backend
    const deleteAccountProcess = (e, single) => {
        e.preventDefault();
        if (single?.id) {
            let fd = new FormData();

            if (single?.id) {
                fd.append('account_id', single?.id);
            }

            if (props.clientId) {
                fd.append('client_id', props?.clientId);
                fd.append('user_id', props?.clientId);
            }

            setGeneralDataApiOptions({
                url: 'delete-bank-account',
                method: 'post',
                data: fd,
                callback: res => {
                    setProfileClient(res?.data);
                    closeAddAccount(e);
                    setErrorsReturned({});
                },
                catch: err => {
                    if (err) {
                        setErrorsReturned(err);
                    }
                }
            });
        }
    }

    return (
        <>
            <div className="container-fluid documents-div pt-3">
                <div className="row">
                    <div className="col-12">
                        <div className="row">

                            <div className="col-auto my-auto d-flex align-items-center">
                                <h1 className="m-0 active">{generalData?.website_titles['bank-accounts']}</h1>
                                <img style={{ height: "30px" }} className="cursor-pointer shadow ms-2" src={'/assets/images/plus.svg'} onClick={(e) => setBankAccount(true)} alt="add" />
                            </div>
                            <div className="col-12 pt-2 pb-3">
                                {
                                    //validate id bank accounts lenght > 0
                                    profileClient?.bank_accounts?.length > 0 ?
                                        <div className="row">
                                            {
                                                profileClient?.bank_accounts?.map((singleBank, accInd) => (
                                                    <div key={accInd} className="col-12 col-md-6 col-xl-4 my-2 h-auto">
                                                        <div className="costs-box-styling custom-shadow px-2 py-2 position-relative h-100">
                                                            <ThreeDotsMenu className="folders-buttons" onClick={(e) => { e.preventDefault(); e.stopPropagation(); editMenu?.id === singleBank?.id ? setEditMenu({}) : setEditMenu(singleBank) }} />
                                                            <div style={{ zIndex: '999' }} className={"settings-clickmenu text-start " + (editMenu?.id === singleBank?.id ? ' d-block' : '')}>
                                                                <p style={{ zIndex: "999" }} onClick={(e) => { e.stopPropagation(); e.preventDefault(); editAccount(e, singleBank) }} className="p-0 py-1 m-0 ms-2">{generalData?.settings_section_settings['edit-title']}</p>
                                                                <p style={{ zIndex: "999" }} onClick={(e) => { e.stopPropagation(); setDeleteAccount(singleBank) }} className="p-0 py-1 m-0 ms-2">{generalData?.settings_section_settings['delete-title']}</p>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-12 py-2">
                                                                    <h4 className="profile-general-input-title d-flex align-items-center" >
                                                                        <BankIcon className="me-2" />
                                                                        {generalData?.website_titles['bank-account-name']} {accInd + 1}</h4>
                                                                </div>
                                                                <div className="col-12 col-sm-6 pt-2">
                                                                    <p className="profile-general-input-title">{generalData?.website_titles['bank-name']}</p>
                                                                    <h5 className="locked-inputs w-100 fs-14">{singleBank?.bank_name}</h5>
                                                                </div>
                                                                {
                                                                    singleBank?.bank_address &&
                                                                    <div className="col-12 col-sm-6 pt-2">
                                                                        <p className="profile-general-input-title">{generalData?.website_titles['bank-address']}</p>
                                                                        <h5 className="locked-inputs w-100 fs-14">{singleBank?.bank_address}</h5>
                                                                    </div>
                                                                }
                                                                {
                                                                    singleBank?.phone_number &&
                                                                    <div className="col-12 col-sm-6 pt-2">
                                                                        <p className="profile-general-input-title">{generalData?.website_titles['phone-number']}</p>
                                                                        <h5 className="locked-inputs w-100 fs-14">{singleBank?.phone_number}</h5>
                                                                    </div>
                                                                }
                                                                {
                                                                    singleBank?.account_number &&
                                                                    <div className="col-12 col-sm-6 pt-2">
                                                                        <p className="profile-general-input-title">{generalData?.website_titles['bank-account-number']}</p>
                                                                        <h5 className="locked-inputs w-100 fs-14">{singleBank?.account_number}</h5>
                                                                    </div>
                                                                }
                                                                {
                                                                    singleBank?.bank_currencies &&
                                                                    <div className="col-12 col-sm-6 pt-2">
                                                                        <p className="profile-general-input-title">{generalData?.website_titles['bank-account-currency']}</p>
                                                                        <h5 className="locked-inputs w-100 fs-14">{singleBank?.bank_currencies?.title}</h5>
                                                                    </div>
                                                                }
                                                                {
                                                                    singleBank?.swift_number &&
                                                                    <div className="col-12 col-sm-6 pt-2">
                                                                        <p className="profile-general-input-title">{generalData?.website_titles['account-swift-number']}</p>
                                                                        <h5 className="locked-inputs w-100 fs-14">{singleBank?.swift_number}</h5>
                                                                    </div>
                                                                }
                                                                {
                                                                    singleBank?.iban &&
                                                                    <div className="col-12 col-sm-6 pt-2">
                                                                        <p className="profile-general-input-title">{generalData?.website_titles['account-iban']}</p>
                                                                        <h5 className="locked-inputs w-100 fs-14">{singleBank?.iban}</h5>
                                                                    </div>
                                                                }
                                                                {
                                                                    singleBank?.account_name &&
                                                                    <div className="col-12 col-sm-6 pt-2">
                                                                        <p className="profile-general-input-title">{generalData?.website_titles['bank-account-account-name']}</p>
                                                                        <h5 className="locked-inputs w-100 fs-14">{singleBank?.account_name}</h5>
                                                                    </div>
                                                                }
                                                                {
                                                                    singleBank?.bank_branch &&
                                                                    <div className="col-12 col-sm-6 pt-2">
                                                                        <p className="profile-general-input-title">{generalData?.website_titles['bank-branch']}</p>
                                                                        <h5 className="locked-inputs w-100 fs-14">{singleBank?.bank_branch}</h5>
                                                                    </div>
                                                                }
                                                                {
                                                                    singleBank?.comment &&
                                                                    <div className="col-12 pt-2">
                                                                        <p className="profile-general-input-title">{generalData?.website_titles['comment']}</p>
                                                                        <h5 className="locked-inputs w-100 fs-14">{singleBank?.comment}</h5>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                        :
                                        <p>{generalData?.website_titles['no-bank-accounts-yet']}</p>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row pt-2">
                    <div className="col-12 pb-3">
                        {/* show client comments section */}
                        <ClientComments section={'banks'} clientId={props.clientId} />
                    </div>
                </div>
            </div>
            {
                //popup bank accounts
                bankAccount ?
                    <div id="errors-popup" className=" error-popup-api d-flex align-items-center justify-content-center px-3 fade-in">
                        <div className="pop-up-api-box">
                            <XIcon onClick={(e) => closeAddAccount(e)} />
                            <div className="text-center ">
                                <div className="containter-fluid">
                                    <div className="row">
                                        <div className="col-12">
                                            <h1 className="pop-up-title m-0 py-2 color-dark-blue">{currentAccount?.id ? generalData?.website_titles['edit-bank-account'] : generalData?.website_titles['add-bank-account']}</h1>
                                        </div>
                                        <form onSubmit={submitBank}>
                                            <div className="col-12 pt-2 pb-4">
                                                <div className="row">
                                                    <div className="col-12 col-md-6 pt-2">
                                                        <input required name="bank_name" value={bankName} onChange={(e) => setBankName(e.target.value)} className="add-user-inputs" placeholder={generalData?.website_titles['bank-name']} />
                                                        {
                                                            errorsReturned?.bank_name ?
                                                                <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text">{errorsReturned?.bank_name}</h5>
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                    <div className="col-12 col-md-6 pt-2">
                                                        <input name="bank_address" value={bankAddress} onChange={(e) => setBankAddress(e.target.value)} className="add-user-inputs" placeholder={generalData?.website_titles['bank-address']} />
                                                        {
                                                            errorsReturned?.bank_address ?
                                                                <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text">{errorsReturned?.bank_address}</h5>
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                    <div className="col-12 col-md-6 pt-2">
                                                        <input name="phone_number" value={bankPhone} onChange={(e) => setBankPhone(e.target.value)} className="add-user-inputs" placeholder={generalData?.website_titles['phone-number']} />
                                                        {
                                                            errorsReturned?.phone_number ?
                                                                <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text">{errorsReturned?.phone_number}</h5>
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                    <div className="col-12 col-md-6 pt-2">
                                                        <input name="account_number" value={bankAccountNumber} onChange={(e) => setBankAccountNumber(e.target.value)} className="add-user-inputs" placeholder={generalData?.website_titles['bank-account-number']} />
                                                        {
                                                            errorsReturned?.account_number ?
                                                                <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text">{errorsReturned?.account_number}</h5>
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                    <div className="col-12 col-md-6 pt-2">
                                                        <div className="position-relative text-start justify-content-start w-100">
                                                            <Select
                                                                classNamePrefix="popup-select"
                                                                className="popup-select w-100"
                                                                styles={customStylesPopup}
                                                                options={bankCurrencies()}
                                                                components={{
                                                                    IndicatorSeparator: () => null
                                                                }}
                                                                placeholder={generalData?.website_titles['currency']}
                                                                isSearchable={false}
                                                                value={bankAccountCurrency}
                                                                onChange={setBankAccountCurrency} />
                                                            <input
                                                                type="text"
                                                                className='position-x-center'
                                                                style={{
                                                                    opacity: 0,
                                                                    width: 0,
                                                                    height: 0,
                                                                }}
                                                                value={bankAccountCurrency || ''}

                                                            />
                                                        </div>
                                                        {
                                                            errorsReturned?.currency ?
                                                                <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text">{errorsReturned?.currency}</h5>
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                    <div className="col-12 col-md-6 pt-2">
                                                        <input name="swift_number" value={accountSwift} onChange={(e) => setAccountSwift(e.target.value)} className="add-user-inputs" placeholder={generalData?.website_titles['account-swift-number']} />
                                                        {
                                                            errorsReturned?.swift_number ?
                                                                <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text">{errorsReturned?.swift_number}</h5>
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                    <div className="col-12 col-md-6 pt-2">
                                                        <input name="iban" value={accountIban} onChange={(e) => setAccountIban(e.target.value)} className="add-user-inputs" placeholder={generalData?.website_titles['account-iban']} />
                                                        {
                                                            errorsReturned?.iban ?
                                                                <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text">{errorsReturned?.iban}</h5>
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                    <div className="col-12 col-md-6 pt-2">
                                                        <input name="account_name" value={accountName} onChange={(e) => setAccountName(e.target.value)} className="add-user-inputs" placeholder={generalData?.website_titles['bank-account-account-name']} />
                                                        {
                                                            errorsReturned?.account_name ?
                                                                <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text">{errorsReturned?.account_name}</h5>
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                    <div className="col-12 col-md-6 pt-2">
                                                        <input name="bank_branch" value={bankBranch} onChange={(e) => setBankBranch(e.target.value)} className="add-user-inputs" placeholder={generalData?.website_titles['bank-branch']} />
                                                        {
                                                            errorsReturned?.bank_branch ?
                                                                <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text">{errorsReturned?.bank_branch}</h5>
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                    <div className="col-12 pt-2">
                                                        <textarea type="text-area" name="comment" className="add-user-inputs" placeholder={generalData?.website_titles['comment']} value={accountComment} onChange={(e) => setAccountComment(e.target.value)}></textarea>
                                                        {
                                                            errorsReturned?.comment ?
                                                                <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text">{errorsReturned?.comment}</h5>
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 d-flex justify-content-center">
                                                <button type="submit" className="popup-button my-auto">{currentAccount?.id ? generalData?.website_titles['edit'] : generalData?.website_titles['add']}</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    null
            }
            {
                //delete popup
                deleteAccount?.id ?
                    <div id="errors-popup" className=" error-popup-api d-flex align-items-center justify-content-center px-3 fade-in">
                        <div className="pop-up-api-box">
                            <XIcon onClick={(e) => closeAddAccount(e)} />
                            <div className="text-center ">
                                <div className="containter-fluid">
                                    <div className="row d-flex justify-content-center align-items-center">
                                        <div className="col-9 col-sm-12 d-flex justify-content-center">
                                            <h1 className="pop-up-title m-0 pt-3 color-dark-blue">{generalData?.settings_section_settings['delete-popup-title']}</h1>
                                        </div>
                                        <div className="col-12 d-flex justify-content-center pt-4 pb-2">
                                            <button onClick={(e) => deleteAccountProcess(e, deleteAccount)} type="button" className="popup-button my-auto mx-2 w-auto px-3">{generalData?.settings_section_settings['delete-popup-first-button']}</button>
                                            <button onClick={(e) => closeAddAccount(e)} type="button" className="auth-button border-dark-blue my-auto mx-2 background-transparent w-auto px-3">{generalData?.settings_section_settings['delete-popup-second-button']}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    null
            }
            <Api options={generalDataApiOptions} />
        </>
    )
}