import { useContext, useState, useEffect } from "react";
import GlobalState from "../contexts/GlobalState";
import { TrueSign, XSign } from "./Icons";

export default function EditInputFixedSettings(props) {
    const { activeFixedInputValueGlobal } = useContext(GlobalState);
    const [activeFixedInputValue, setActiveFixedInputValue] = activeFixedInputValueGlobal;
    const [tempValue, setTempValue] = useState(props?.value);

    function handleSubmit(e) {
        e.preventDefault();
        setActiveFixedInputValue(tempValue);
        props?.setTargetFixedInputChanges(Number(props?.targetFixedInputChanges) + 1);
    }
    
    function handleClose(e){
        setActiveFixedInputValue(''); 
        props?.setActiveFixedInput(''); 
        props?.setErrorsReturned({});
    }

    useEffect(() => {
        if(activeFixedInputValue !== tempValue){
            setTempValue(props?.value);
        }
    }, [activeFixedInputValue]);// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="col pt-2">
            <div className="row">
                <div className="col-12 col-md-6 ">
                    <h5 className="locked-inputs border-hidden w-100 m-0 p-0">{props?.title}</h5>
                </div>
                <div className="col-12 col-md-6 ">
                    {
                        props?.activeFixedInput === props?.field ?
                            <>
                                <div className="row">
                                    <div className="col ps-0">
                                        <input autoFocus className="profile-general-input ms-2 w-100"  
                                        // onKeyDown={(e) => props?.setTargetFixedInputChanges(Number(props?.targetFixedInputChanges) + 1)} 
                                        onKeyDown={(e) => e?.key === 'Enter' && handleSubmit(e)}
                                        value={tempValue} onChange={(e) => {setTempValue(e.target.value)}} />
                                    </div>
                                    <div className={'col-auto ps-2'}>
                                        <div className="d-flex">
                                            <div className="input-profile-buttons ms-1" onClick={(e) => handleSubmit(e)}>
                                                <TrueSign />
                                            </div>
                                            <div className="input-profile-buttons ms-1" onClick={(e) => { handleClose(e) }}>
                                                <XSign />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                            :
                            <h5 onClick={() => { setActiveFixedInputValue(props?.value); props?.setActiveFixedInput(props?.field) }} className="locked-inputs border-hidden w-100 m-0 p-0">{props?.value}</h5>
                    }
                </div>
                {
                    props?.errorsReturned?.value && props?.activeFixedInput === props?.field &&
                    <div className="row">
                        <div className="col-12 px-4">
                            <p className="errors-returned-profile  p-0">{props?.errorsReturned?.value}</p>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}