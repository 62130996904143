import React, { useContext, useState } from 'react';
import GlobalState from '../contexts/GlobalState';
import Api from './Api';
import { AttachIcon, CloseIcon, SignatureIcons, ThreeDotsMenu } from './Icons';
import { getIndex } from '../Helpers';
import Select from 'react-select';

export default function ClientSignatures(props) {

    const { generalDataGlobal, profileClientGlobal } = useContext(GlobalState);
    const [profileClient, setProfileClient] = profileClientGlobal;
    const [generalData,] = generalDataGlobal;
    const [generalDataApiOptions, setGeneralDataApiOptions] = useState({});
    const [signature, setSignature] = useState(false);
    const [errorsReturned, setErrorsReturned] = useState({});

    /* Account Details*/
    const [currentSignature, setCurrentSignature] = useState({});
    const [signatureName, setSignatureName] = useState('');
    const [signatureType, setSignatureType] = useState('');
    const [authorizationTerm, setAuthorizationTerm] = useState('');
    const [jointSignatureWith, setJointSignatureWith] = useState('');
    const [comment, setComment] = useState('');
    const [capacity, setCapacity] = useState('');
    const [editMenu, setEditMenu] = useState({});
    const [documentLink, setDocumentLink] = useState();
    const [document, setDocument] = useState();
    const [deleteSignature, setDeleteSignature] = useState({});
    /* Account Details End*/

    //get bank currencies
    const allSignatures = () => {
        const signatures = generalData?.signature_types?.map(
            sign => ({ value: sign.id, label: sign.title }))
        return signatures;
    }

    // custom styles for the select
    const customStylesPopup = {
        placeholder: (defaultStyles) => {
            return {
                ...defaultStyles,
                display: "flex",
                fontFamily: 'Poppins',
                fontWeight: '400',
                textTransform: "capitalize",
                fontSize: '14px',
                color: '#07407C',
            }
        },
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                color: '#07407C',
                fontFamily: 'Poppins',
                fontWeight: '400',
                backgroundColor: 'white',
                textTransform: "capitalize",
                margin: "auto",
                paddingRight: "10px",
                maxWidth: "100%",
                paddingLeft: "10px",
                paddingBottom: "0",
                paddingTop: "0",
            };
        },
        singleValue: base => ({
            ...base,
            color: "#00366A",
            display: "flex",
            fontSize: "14px",
            fontFamily: "Poppins",
            fontWeight: "400",
            textTransform: "capitalize",
        }),
        control: provided => ({
            ...provided,
            height: "40px",
            border: "none",
            backgroundColor: 'white',
            outline: "none",
            '&:hover': { borderColor: 'transparent' },
            textTransform: 'uppercase',
            width: "100%",
            borderRadius: "7px",
            fontFamily: 'Poppins',
            fontWeight: '400',
            boxShadow: "0px 3px 6px rgb(0 0 0 / 16%)",
        }),
        menu: (provided, state) => ({
            ...provided,
            borderRadius: "7px",
            textTransform: 'uppercase',
            border: "1px solid transparent",
        }),
        dropdownIndicator: base => ({
            ...base,
            color: "#00366A !important",
        }),
        input: (base, state) => ({
            ...base,
            color: "#393939",
            fontSize: "16px",
            height: "100%",
            fontFamily: 'Poppins',
            textTransform: 'uppercase',
            fontWeight: '400',
            marginTop: "14px",
            '& input': {
                font: 'inherit',
            },
        })
    };

    // close signature popup and reset states
    const closeAddSignatue = (e) => {
        setSignature(false);
        setSignatureName('');
        setSignatureType('');
        setAuthorizationTerm('');
        setJointSignatureWith('');
        setComment('');
        setCapacity('');
        setDocument('');
        setComment('');
        setDocumentLink('');
        setErrorsReturned({});
        setCurrentSignature({});
        setEditMenu({});
        setDeleteSignature({});
    };

    //set states with current edit signature data
    const editSignature = (e, single) => {
        setSignature(false);
        setCurrentSignature(single);
        setSignatureName(single?.name);
        setAuthorizationTerm(single?.term_of_authorization);
        setJointSignatureWith(single?.joint_signature_with);
        setSignatureType(allSignatures()[getIndex(single?.signature_type_id, allSignatures(), 'value')]);
        setCapacity(single?.capacity);
        setDocumentLink(single?.file);
        setComment(single?.comment);
        setErrorsReturned({});
        setSignature(true);
    }

    //submit signature data 
    const submitSignature = (e) => {
        e.preventDefault();
        let fd = new FormData();
        fd.append('name', signatureName ? signatureName : '');
        
        fd.append('signature_type_id', signatureType ? signatureType?.value : '');
        fd.append('term_of_authorization', authorizationTerm ? authorizationTerm : '');
        fd.append('joint_signature_with', jointSignatureWith ? jointSignatureWith : '');
        fd.append('capacity', capacity ? capacity : '');
        fd.append('comment', comment ? comment : '');

        fd.append('parent_folder_id', '');
        fd.append('files_type_id', 5);
        fd.append('main_directory', 2);
        fd.append('file_name', signatureName ? signatureName : '');
        fd.append('user_id', profileClient?.id);
        fd.append('folder_name', 'Signatures');

        fd.append('file_id', currentSignature?.uploaded_files_id ? currentSignature?.uploaded_files_id : '');
        if (currentSignature?.id) {
            fd.append('signature_id', currentSignature?.id);
        }
        if (props.clientId) {
            fd.append('client_id', props?.clientId);
        }
        if (document) {
            fd.append('file', document[0]);
        }
        setGeneralDataApiOptions({
            url: 'add-edit-signature',
            method: 'post',
            data: fd,
            callback: res => {
                setProfileClient(res?.data);
                closeAddSignatue(e);
                setErrorsReturned({});
            },
            catch: err => {
                if (err) {
                    setErrorsReturned(err);
                }
            }
        });

    }

    //delete signature
    const deleteSignatureProcess = (e, single) => {
        e.preventDefault();
        if (single?.id) {
            let fd = new FormData();

            if (single?.id) {
                fd.append('signature_id', single?.id);
            }

            if (props.clientId) {
                fd.append('client_id', props?.clientId);
                fd.append('user_id', props?.clientId);
            }

            setGeneralDataApiOptions({
                url: 'delete-signature',
                method: 'post',
                data: fd,
                callback: res => {
                    setProfileClient(res?.data);
                    closeAddSignatue(e);
                    setErrorsReturned({});
                },
                catch: err => {
                    if (err) {
                        setErrorsReturned(err);
                    }
                }
            });
        }
    }

    //handle document upload
    const documentHandler = (files) => {
        if (files.length > 0) {
            setDocument(files);
            let file = URL.createObjectURL(files[0]);
            setDocumentLink(file);
        }
    }

    return (
        <>
            <div className="container-fluid documents-div pt-3">
                <div className="row">
                    <div className="col-12">
                        <div className="row">
                            <div className="col-auto my-auto d-flex align-items-center">
                                <h1 className="m-0 active">{generalData?.website_titles['signature-authority']}</h1>
                                <img style={{ height: "30px" }} className="cursor-pointer shadow ms-2" src={'/assets/images/plus.svg'} onClick={() => setSignature(true)} alt="add" />
                            </div>
                            <div className="col-12 pt-2 pb-3">
                                {
                                    profileClient?.signatures?.length > 0 ?
                                        <div className="row">
                                            {
                                                profileClient?.signatures?.map((singleSignature, accInd) => (
                                                    <div key={accInd} className="col-12 col-md-6 col-xl-4 my-2 h-auto">
                                                        <div className="costs-box-styling custom-shadow px-2 py-2 position-relative h-100">
                                                            <ThreeDotsMenu className="folders-buttons" onClick={(e) => { e.preventDefault(); e.stopPropagation(); editMenu?.id === singleSignature?.id ? setEditMenu({}) : setEditMenu(singleSignature) }} />
                                                            <div style={{ zIndex: '999' }} className={"settings-clickmenu text-start " + (editMenu?.id === singleSignature?.id ? ' d-block' : '')}>
                                                                <p style={{ zIndex: "999" }} onClick={(e) => { e.stopPropagation(); e.preventDefault(); editSignature(e, singleSignature) }} className="p-0 py-1 m-0 ms-2">{generalData?.settings_section_settings['edit-title']}</p>
                                                                <p style={{ zIndex: "999" }} onClick={(e) => { e.stopPropagation(); setDeleteSignature(singleSignature) }} className="p-0 py-1 m-0 ms-2">{generalData?.settings_section_settings['delete-title']}</p>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-12 py-2">
                                                                    <h4 className="profile-general-input-title d-flex align-items-center" >
                                                                        <SignatureIcons />
                                                                        {generalData?.website_titles['signature-title']} {accInd + 1}</h4>
                                                                </div>
                                                                <div className="col-12 col-sm-6 pt-2">
                                                                    <p className="profile-general-input-title">{generalData?.website_titles['name']}</p>
                                                                    <h5 className="locked-inputs w-100 fs-14">{singleSignature?.name}</h5>
                                                                </div>
                                                                {
                                                                    singleSignature?.signature_type &&
                                                                    <div className="col-12 col-sm-6 pt-2">
                                                                        <p className="profile-general-input-title">{generalData?.website_titles['type-of-signature']}</p>
                                                                        <h5 className="locked-inputs w-100 fs-14">{singleSignature?.signature_type?.title}</h5>
                                                                    </div>
                                                                }
                                                                {
                                                                    singleSignature?.term_of_authorization &&
                                                                    <div className="col-12 col-sm-6 pt-2">
                                                                        <p className="profile-general-input-title">{generalData?.website_titles['term-of-authorization']}</p>
                                                                        <h5 className="locked-inputs w-100 fs-14">{singleSignature?.term_of_authorization}</h5>
                                                                    </div>
                                                                }
                                                                {
                                                                    singleSignature?.joint_signature_with &&
                                                                    <div className="col-12 col-sm-6 pt-2">
                                                                        <p className="profile-general-input-title">{generalData?.website_titles['joint-signature-with']}</p>
                                                                        <h5 className="locked-inputs w-100 fs-14">{singleSignature?.joint_signature_with}</h5>
                                                                    </div>
                                                                }
                                                                {
                                                                    singleSignature?.capacity &&
                                                                    <div className="col-12 col-sm-6 pt-2">
                                                                        <p className="profile-general-input-title">{generalData?.website_titles['capacity']}</p>
                                                                        <h5 className="locked-inputs w-100 fs-14">{singleSignature?.capacity}</h5>
                                                                    </div>
                                                                }
                                                                <div className="col-12 col-sm-6 pt-2">
                                                                    <p className="profile-general-input-title">{generalData?.website_titles['file']}</p>
                                                                    <a target="_blank" rel="noreferrer" style={{ textDecoration: 'underline' }} href={singleSignature?.uploaded_files?.full_path_file} className="locked-inputs w-100 fs-14">{singleSignature?.uploaded_files?.file_name}</a>
                                                                </div>
                                                                {
                                                                    singleSignature?.comment ?
                                                                        <div className="col-12 py-1">
                                                                            <p className="profile-general-input-title">{generalData?.website_titles['comment']}</p>
                                                                            <h5 className="locked-inputs w-100 fs-14">
                                                                                {singleSignature?.comment}
                                                                            </h5>
                                                                        </div>
                                                                        :
                                                                        null
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                        :
                                        <p>{generalData?.website_titles['no-registered-signatures-yet']}</p>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                //signature popup
                signature ?
                    <div id="errors-popup" className=" error-popup-api d-flex align-items-center justify-content-center px-3 fade-in">
                        <div className="pop-up-api-box">
                            <CloseIcon className="cursor-pointer" onClick={(e) => closeAddSignatue(e)} />
                            <div className="text-center ">
                                <div className="containter-fluid">
                                    <div className="row">
                                        <div className="col-12">
                                            <h1 className="pop-up-title m-0 py-2 color-dark-blue">{currentSignature?.id ? generalData?.website_titles['edit-signature'] : generalData?.website_titles['add-new-signature']}</h1>
                                        </div>
                                        <form onSubmit={submitSignature}>
                                            <div className="col-12 pt-2 pb-4">
                                                <div className="row">
                                                    <div className="col-12 col-md-6 pt-2">
                                                        <input required name="name" value={signatureName} onChange={(e) => setSignatureName(e.target.value)} className="add-user-inputs" placeholder={generalData?.website_titles['name']} />
                                                        {
                                                            errorsReturned?.name ?
                                                                <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text">{errorsReturned?.name}</h5>
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                    <div className="col-12 col-md-6 pt-2">
                                                        <input name="term_of_authorization" value={authorizationTerm} onChange={(e) => setAuthorizationTerm(e.target.value)} className="add-user-inputs" placeholder={generalData?.website_titles['term-of-authorization']} />
                                                        {
                                                            errorsReturned?.term_of_authorization ?
                                                                <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text">{errorsReturned?.term_of_authorization}</h5>
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                    <div className="col-12 col-md-6 pt-2">
                                                        <div className="position-relative text-start justify-content-start w-100">
                                                            <Select
                                                                classNamePrefix="popup-select"
                                                                className="popup-select w-100"
                                                                styles={customStylesPopup}
                                                                options={allSignatures()}
                                                                components={{
                                                                    IndicatorSeparator: () => null
                                                                }}
                                                                placeholder={generalData?.website_titles['type-of-signature']}
                                                                isSearchable={false}
                                                                value={signatureType}
                                                                onChange={setSignatureType} />
                                                            <input
                                                                type="text"
                                                                className='position-x-center'
                                                                style={{
                                                                    opacity: 0,
                                                                    width: 0,
                                                                    height: 0,
                                                                }}
                                                                required
                                                                value={signatureType || ''}

                                                            />
                                                        </div>
                                                        {
                                                            errorsReturned?.signature_type_id ?
                                                                <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text">{errorsReturned?.signature_type_id}</h5>
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                    {
                                                        signatureType?.value === 1 &&
                                                        <div className="col-12 col-md-6 pt-2">
                                                            <input required name="joint_signature_with" value={jointSignatureWith} onChange={(e) => setJointSignatureWith(e.target.value)} className="add-user-inputs" placeholder={generalData?.website_titles['joint-signature-with']} />
                                                            {
                                                                errorsReturned?.joint_signature_with ?
                                                                    <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text">{errorsReturned?.joint_signature_with}</h5>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                    }
                                                    <div className="col-12 col-md-6 pt-2">
                                                        <input name="capacity" value={capacity} onChange={(e) => setCapacity(e.target.value)} className="add-user-inputs" placeholder={generalData?.website_titles['capacity']} />
                                                        {
                                                            errorsReturned?.capacity ?
                                                                <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text">{errorsReturned?.capacity}</h5>
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                    <div className="col-12 col-md-6 pt-2 position-relative">
                                                        <input hidden id='upload-document-file' type="file" onChange={(e) => { documentHandler(e.target.files) }} />
                                                        <label className="pop-upload-file position-relative" htmlFor="upload-document-file">{documentLink ? generalData?.documentsSectionSettings['change-file-placeholder'] : generalData?.documentsSectionSettings['upload-file-placeholder']}</label>

                                                        <AttachIcon />
                                                        {
                                                            errorsReturned?.file ?
                                                                <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text text-start">{errorsReturned?.file}</h5>
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                    <div className="col-12 pt-2">
                                                        <textarea required type="text-area" name="description" className="add-user-inputs" placeholder={generalData?.website_titles['comment']} value={comment} onChange={(e) => setComment(e.target.value)}></textarea>
                                                        {
                                                            errorsReturned?.comment ?
                                                                <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text">{errorsReturned?.comment}</h5>
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 d-flex justify-content-center">
                                                <button type="submit" className="popup-button my-auto">{currentSignature?.id ? generalData?.website_titles['edit'] : generalData?.website_titles['add']}</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    null
            }
            {
                //delete signature popup
                deleteSignature?.id ?
                    <div id="errors-popup" className=" error-popup-api d-flex align-items-center justify-content-center px-3 fade-in">
                        <div className="pop-up-api-box">
                            <CloseIcon className="cursor-pointer" onClick={(e) => closeAddSignatue(e)} />
                            <div className="text-center ">
                                <div className="containter-fluid">
                                    <div className="row d-flex justify-content-center align-items-center">
                                        <div className="col-9 col-sm-12 d-flex justify-content-center">
                                            <h1 className="pop-up-title m-0 pt-3 color-dark-blue">{generalData?.settings_section_settings['delete-popup-title']}</h1>
                                        </div>
                                        <div className="col-12 d-flex justify-content-center pt-4 pb-2">
                                            <button onClick={(e) => deleteSignatureProcess(e, deleteSignature)} type="button" className="popup-button my-auto mx-2 w-auto px-3">{generalData?.settings_section_settings['delete-popup-first-button']}</button>
                                            <button onClick={(e) => closeAddSignatue(e)} type="button" className="auth-button border-dark-blue my-auto mx-2 background-transparent w-auto px-3">{generalData?.settings_section_settings['delete-popup-second-button']}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    null
            }
            <Api options={generalDataApiOptions} />
        </>
    )
}