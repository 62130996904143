import React, { useState, useRef, useEffect, useContext } from 'react';
import GlobalState from '../contexts/GlobalState';
import { AccordionArrow, DeleteIcon } from './Icons';

export default function AccordionComponent(props) {
    const { profileClientGlobal } = useContext(GlobalState);
    const [height, setHeight] = useState(0);
    const elementRef = useRef(null);
    const [profileClient,] = profileClientGlobal;
    const [openCardId, ] = useState();
    const [cardHeight, ] = useState(0);
    const [addedHeight, ] = useState(0);
    const [initialSize, setInitialSize] = useState(0);
    const [width, setWidth] = useState(window.innerWidth);
    const { onClick, updateOnChange, openId, index, children, title,handleButtonClick } = props

    //update inner width end to calculate div initial height when width changes
    const updateDimensions = () => {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);

    useEffect(() => {
        setInitialSize(elementRef?.current?.clientHeight);
    }, [width, profileClient]);
    //update inner width end

    // reset active menu when card is changed
    // useEffect(() => {
    //     setActiveMenu({});
    // }, [openCardId]);

    //change height
    useEffect(() => {
        if (!openCardId) {
            setHeight(initialSize);
        } else {
            setHeight(initialSize + cardHeight);
        }
    }, [cardHeight, addedHeight, openCardId, profileClient, initialSize]);// eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
        setHeight(elementRef?.current?.clientHeight);
    }, [updateOnChange]);

    return (
        <>
            <div className={`faqs position-relative mt-2 ${Number(openId) === Number(index) ? ' active ' : ''}`} >
                <div className={`accordion-title container-fluid`} >
                    <div
                        className="row justify-content-between position-relative"
                        onClick={(e) => {
                            setHeight(elementRef.current.clientHeight);
                            onClick(e);
                        }}
                    >
                        <div className="col-12">
                            <h3 className="cursor-pointer accordion-title color-dark-blue d-flex align-items-center ">
                                {title}
                                <AccordionArrow className="ms-2 " style={{ rotate: Number(openId) === Number(index) ? '' : '180deg' }} />
                                {/* <img style={{ zIndex: '9999999' }} onClick={(e) => { e.preventDefault(); e.stopPropagation();}} src={'/assets/images/plus.svg'} alt="add" /> */}
                                {
                                    Number(openId) === Number(index) &&
                                    <button onClick={handleButtonClick} className='ms-3 btn shadow p-1 btn-delete'>
                                        <DeleteIcon style={{ width:15, height:15 }} />
                                    </button>
                                }

                            </h3>
                        </div>
                    </div>
                    <div className="col-12">
                        <div
                            style={{
                                height: Number(openId) === Number(index) ? height : 0,
                                overflow: Number(openId) !== Number(index) ? 'hidden' : ''
                            }}
                            className={` ${Number(openId) === Number(index) ? 'accordion-content open' : 'accordion-content '}`}
                        >
                            <div ref={elementRef} className="">
                                {children}
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}