import React, { useRef, useEffect, useContext } from 'react';
import GlobalState from '../contexts/GlobalState';
import { ThreeDotsMenu } from './Icons'

export default function EntityContactCard(props) {
    const { generalDataGlobal, entityContactPopupGlobal, deleteContactPopupGlobal } = useContext(GlobalState);
    const [generalData] = generalDataGlobal;
    const [, setEntityContactPopup] = entityContactPopupGlobal;
    const [, setDeleteEntityContactPopup] = deleteContactPopupGlobal;
    const cardElementRef = useRef({});


    //change card height when a card is opened
    useEffect(() => {
        if (Number(props.openCardId) === props?.data?.id) {
            props.setCardHeight(cardElementRef.current.clientHeight);
        }
    }, [props.openCardId]);// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <div className="col-12 col-md-6 cursor-pointer position-relative">
                <div className={'faqs position-relative ' + (props?.singleRem?.id === Number(props.openCardId) ? ' active ' : ' ') + (props?.indRem !== 0 ? ' mt-3 ' : ' ')} onClick={() => { props.data?.id === Number(props.openCardId) ? props.setOpenCardId(null) : props.setOpenCardId(props.data?.id); props.setCardHeight(cardElementRef.current.clientHeight) }}>
                    <div className={'accordion-title'} onClick={(e) => { e.preventDefault(); e.stopPropagation(); props.setOpenCardId(Number(props.openCardId) === props.data?.id ? null : props.data?.id) }}>
                        <div className="cms-table-content position-relative h-100">
                            {
                                props?.data?.client_stat_id !== 1 ?
                                    <div className="overlay-not-active" />
                                    :
                                    null
                            }
                            <div className="row">
                                <div className="col-12">
                                    <div className="gray-background">
                                        <div className="row py-2 px-3">
                                            <div className="col-auto">
                                                <img className="image-user-chat" src={props?.data?.contact ? (props?.data?.contact?.picture ? props?.data?.contact?.full_path_picture : generalData?.fixed_settings?.full_path_no_profile_picture) : (props?.data?.contact_user?.picture ? props?.data?.contact_user?.full_path_picture : generalData?.fixed_settings?.full_path_no_profile_picture)} alt="chat user" />
                                            </div>
                                            <div className="col ps-0">
                                                <div className="row justify-content-between">
                                                    <div className="col-auto">
                                                        <p className="m-0 font-semi-bold">{props?.data?.contact ? props?.data?.contact?.name : props?.data?.contact_user?.name}</p>
                                                        <h5 className="locked-inputs w-100 fs-14">{props?.data?.contact ? props?.data?.contact?.client_type?.title : generalData?.website_titles['individual']}</h5>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-auto pe-0 d-flex align-items-center cursor-pointer">
                                                <div className="text-end p-0 position-relative" >
                                                    <ThreeDotsMenu onClick={(e) => { e.stopPropagation(); e.preventDefault(); props.setActiveMenu(props?.activeMenu === props?.data?.id ? {} : props?.data?.id) }} />
                                                    <div style={{ top: '0px' }} className={"settings-clickmenu text-start " + (props?.data?.id === props?.activeMenu ? ' d-block' : '')}>
                                                        <p style={{ zIndex: "99" }} onClick={(e) => { e.stopPropagation(); e.preventDefault(); setEntityContactPopup(props?.data); }} className="p-0 py-1 m-0 ms-2">{generalData?.settings_section_settings['edit-title']}</p>
                                                        <p style={{ zIndex: "200" }} onClick={(e) => { e.stopPropagation(); e.preventDefault(); setDeleteEntityContactPopup(props?.data); }} className="p-0 py-1 m-0 ms-2">{generalData?.settings_section_settings['delete-title']}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row px-3 py-2">
                                {
                                    props?.data?.designation_date ?
                                        <div className="col-12 col-sm-6 py-1">
                                            <p className="profile-general-input-title">{generalData?.website_titles['designation-date']}</p>
                                            <h5 className="locked-inputs w-100 fs-14">
                                                {props?.data?.designation_date}
                                            </h5>
                                        </div>
                                        :
                                        null
                                }
                                {
                                    props?.data?.client_stat?.title ?
                                        <div className="col-12 col-sm-6 py-1">
                                            <p className="profile-general-input-title">{generalData?.website_titles['status']}</p>
                                            <h5 className="locked-inputs w-100 fs-14">
                                                {props?.data?.client_stat?.title}
                                            </h5>
                                        </div>
                                        :
                                        null
                                }
                                {
                                    <div style={{ height: Number(props.openCardId) === props.data?.id ? props?.cardHeight : 0 }} className={Number(props.openCardId) === props.data?.id ? 'accordion-content open' : 'accordion-content '}>
                                        <div ref={cardElementRef} className="row">
                                            <div className="col-12 py-1">
                                                <p className="profile-general-input-title">{generalData?.website_titles['comment']}</p>
                                                <h5 className="locked-inputs w-100 fs-14">
                                                    {props?.data?.comment}
                                                </h5>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}