import React, { useState } from 'react'
import { useContext } from 'react'
import GlobalState from '../contexts/GlobalState'
import { Link } from 'react-router-dom';
import RejectTransactionPopup from './RejectTransactionPopup';
import Api from './Api';

export default function NotificationComponent({ currentNotification,setNotifications, notification, index, className, setCurrentNotifications }) {
    const { authUserGlobal, generalDataGlobal: [generalData] } = useContext(GlobalState);
    const [authUser,] = authUserGlobal;
    const [rejectPopup, setRejectPopup] = useState(false);
    const [apiOptions, setApiOptions] = useState({});
    const [transactionRejectReason, setTransactionRejectReason] = useState('');
    // const [activeNotification, setActiveNotification] = useState();


    const handleRejectClick = (e, activeNotification) => {
        setRejectPopup(true)
        // setActiveNotification(activeNotification)
    }

    const submitReject = (activeNotification) => {
        setApiOptions({
            url: '/reject-user-transaction',
            method: 'post',
            data:{
                transaction_id: activeNotification?.transaction_id,
                notification_id: activeNotification?.id,
                notifications: 1,
                rejection_reason: transactionRejectReason,
            },
            callback: (res) => {
                setTransactionRejectReason('');
                setNotifications(prevNotifications => {
                    return prevNotifications.map(notification => {
                        // If the notification ID matches, replace it with the new data
                        if (notification.id === res?.data.id) {
                            return res.data; // Replace with the entire res.data for that notification
                        }
                        return notification;
                    });
                });
            }
        })
    }

    const handleApproveClick = (e, activeNotification) => {
        e.preventDefault();
        setApiOptions({
            url: '/approve-user-transaction',
            method: 'post',
            data:{
                transaction_id: activeNotification?.transaction_id,
                notification_id: activeNotification?.id,
                notifications: 1,
            },
            callback: (res) => {
                setNotifications(prevNotifications => {
                    return prevNotifications.map(notification => {
                        // If the notification ID matches, replace it with the new data
                        if (notification.id === res?.data.id) {
                            return res.data; // Replace with the entire res.data for that notification
                        }
                        return notification;
                    });
                });
            }
        })
    }

    const cancelStatusTransaction = () => {
        setRejectPopup(false)
    }
    const submitRejectTransaction = (e, reason) => {
        e.preventDefault()
        setRejectPopup(false);
        submitReject(notification)
    }

    return (

        <>
            <div className={`w-100 ${className ? className : ''}`}>
                <div key={index} className={`row gx-3 mt-1`}>
                    <div className="col-auto">
                        <img className="table-user-image" src={notification?.create_user?.picture ? notification?.create_user?.full_path_picture : generalData?.fixed_settings?.full_path_no_profile_picture} alt="user" />
                    </div>
                    <div className="col" >
                        <div className="row gx-0">
                            <div className="col-8 my-auto">
                                <p className="m-0 name-notification">{notification?.create_user?.name}</p>
                                {
                                    (notification?.notification_types_id === 1 || notification?.notification_types_id === 3) &&
                                    <p className="m-0 details-notifications">{notification?.notification_types?.title}  <Link className='link' to={`/profile/${authUser?.id}/reminder?date=${notification?.reminder?.date_only}`}>{notification?.reminder?.name}</Link></p>
                                }
                                {
                                    (notification?.notification_types_id === 6 || notification?.notification_types_id === 10 || notification?.notification_types_id === 11) &&
                                    <>
                                        <p className="m-0 details-notifications">{notification?.notification_types?.title} {notification?.transaction?.amount} {notification?.transaction?.currency?.title}</p>
                                        {
                                            notification?.transaction?.transaction_stat_id === 3 ?
                                                <div className="col-12 justify-content-center align-items-center py-2">
                                                    <div className='w-100 d-flex '>
                                                        <button onClick={(e) => { handleApproveClick(e, notification) }} className="popup-button my-auto">{generalData?.users_cost_settings['approve']}</button>
                                                        <button onClick={(e) => { handleRejectClick(e, notification) }} className="ms-2 reverse popup-button my-auto">{generalData?.users_cost_settings['reject']}</button>
                                                    </div>
                                                </div>
                                                : <p className={notification?.transaction?.transaction_stat_id === 2 ? 'text-danger' : ''}>{notification?.transaction?.transaction_stat?.title}</p>
                                        }
                                    </>
                                }
                                {
                                    (notification?.notification_types_id === 7 || notification?.notification_types_id === 8 || notification?.notification_types_id === 9) &&
                                    <p className="m-0 details-notifications">{notification?.notification_types?.title}  <Link className='link' to={'/tasks/' + notification?.reminder?.task_id}>{notification?.reminder?.task?.name}</Link></p>
                                }
                                {
                                    (notification?.notification_types_id === 12) &&
                                    <p className="m-0 details-notifications">{notification?.notification_types?.title}  <Link className='link' to={'/tasks/' + notification?.reminder?.task_id}>{notification?.reminder?.task?.name}</Link> {notification?.old_task_priority?.title} {'->'} {notification?.new_task_priority?.title}</p>
                                }
                                {
                                    (notification?.notification_types_id === 13) &&
                                    <p className="m-0 details-notifications">{notification?.notification_types?.title}  <Link className='link' to={'/tasks/' + notification?.reminder?.task_id}>{notification?.reminder?.task?.name}</Link> {notification?.old_task_statuses?.title} {'->'} {notification?.new_task_statuses?.title}</p>
                                }
                                {
                                    (notification?.notification_types_id === 14 || notification?.notification_types_id === 15) &&
                                    <p className="m-0 details-notifications">{notification?.notification_types?.title}  <Link className='link' to={'/profile/' + authUser?.id + '/reminder?date='+notification?.reminder?.date_only }>{notification?.reminder?.name}</Link></p>
                                }
                                {
                                    (notification?.notification_types_id === 16 || notification?.notification_types_id === 17) &&
                                    <p className="m-0 details-notifications">{notification?.notification_types?.title.replace('%%status%%',notification?.client_stat?.title).replace('%%type%%',notification?.entity_contacts_types?.title)}  <Link className='link' to={'/client/'+notification?.client_id+'/entity-management' }>{notification?.client?.name}</Link></p>
                                }
                                {
                                    (notification?.notification_types_id === 18 || notification?.notification_types_id === 19) &&
                                    <p className="m-0 details-notifications">{notification?.notification_types?.title}  <Link className='link' to={'/matters/litigations/'+notification?.litigation_id+'/general-info'}>{notification?.litigation?.title}</Link></p>
                                }
                                {
                                    (notification?.notification_types_id === 20 ) &&
                                    <p className="m-0 details-notifications">{notification?.notification_types?.title}  <Link className='link' to={'/matters/litigations/'+notification?.litigation_id+'/general-info'}>{notification?.litigation?.title}</Link> {notification?.old_litigation_priority?.title} {'->'} {notification?.new_litigation_priority?.title}</p>
                                }
                                {
                                    (notification?.notification_types_id === 21 ) &&
                                    <p className="m-0 details-notifications">{notification?.notification_types?.title}  <Link className='link' to={'/matters/litigations/'+notification?.litigation_id+'/general-info'}>{notification?.litigation?.title}</Link> {notification?.old_litigation_status?.title} {'->'} {notification?.new_litigation_status?.title}</p>
                                }
                                {
                                    (notification?.notification_types_id === 22 || notification?.notification_types_id === 23) &&
                                    <p className="m-0 details-notifications">{notification?.notification_types?.title}  <Link className='link' to={'/matters/litigations/'+notification?.hearing?.litigation_id+'/general-info'}>{notification?.hearing?.number}</Link></p>
                                }
                                {
                                    (notification?.notification_types_id === 24 || notification?.notification_types_id === 25) &&
                                    <p className="m-0 details-notifications">{notification?.notification_types?.title}  <Link className='link' to={'/tasks/' +notification?.task_id}>{notification?.task?.name}</Link></p>
                                }
                                {/* {notification?.type === 'transaction' ? <span className='text-success'> {notification?.amount} {notification?.currency || ''}</span> : notification?.link ? <Link className='link' to={notification?.link}>{notification?.linkTo}</Link> : ''}</p> */}
                            </div>
                            <div className="col-4 text-end">
                                <p className="m-0 date-notifications">{notification?.date_human}</p>
                            </div>
                        </div>
                    </div>
                    {/* {
                        notLast &&
                        <div className='w=100 px-2'>
                            <hr />
                        </div>
                    } */}


                </div>
            </div>

            {
                rejectPopup &&
                <RejectTransactionPopup
                    cancelStatusTransaction={cancelStatusTransaction}
                    submitRejectTransaction={submitRejectTransaction}
                    transactionRejectReason={transactionRejectReason}
                    setTransactionRejectReason={setTransactionRejectReason}
                />
            }
            <Api options={apiOptions} />
        </>
    )
}
