import { useContext, useEffect, useState } from "react";
import GlobalState from "../contexts/GlobalState";
import Api from "./Api";
import { CloseIcon, RemoveFd, TrueSign, XSign } from './Icons';
import showCommaSeperator from "../useCommaSeparator";

export default function EditInputTranslatable(props) {
    const { allCmsDataGlobal, trigDeleteGlobal, generalDataGlobal, currentInputGlobal, currentInputValueGlobal } = useContext(GlobalState);
    const [generalDataApiOptions, setGeneralDataApiOptions] = useState({});
    const [trigDelete, setTrigDelete] = trigDeleteGlobal;
    const [generalData, setGeneralData] = generalDataGlobal;
    const [currentInput, setCurrentInput] = currentInputGlobal;
    const [currentInputValue, setCurrentInputValue] = currentInputValueGlobal;
    const [, setAllCmsData] = allCmsDataGlobal;
    const [tempEnglish, setTempEnglish] = useState();
    const [tempArabic, setTempArabic] = useState();
    const [errorsReturned, setErrorsReturned] = useState([]);
    const [deletePopup, setDeletePopup] = useState(false);
    const [additionalFieldValue, setAditionalFieldValue] = useState();

    useEffect(() => {
        setTempEnglish(props?.data['translations'][0]['title'] || '');
        setTempArabic(props?.data['translations'][1]['title'] || '')
        if (props?.additionalField?.type === 'color') {
            setAditionalFieldValue(props?.data[props?.additionalField?.name] || '#000000')
        } else {
            setAditionalFieldValue(props?.data[props?.additionalField?.name] || null)
        }
    }, [props?.data]);// eslint-disable-line react-hooks/exhaustive-deps

    //reset input when closed
    const closeInputEdit = (e) => {
        setCurrentInputValue('');
        setCurrentInput('');
        setErrorsReturned([]);
        setTempArabic(props?.data['translations'][1]['title'] ? props?.data['translations'][1]['title'] : '');
        setTempEnglish(props?.data['translations'][0]['title'] ? props?.data['translations'][0]['title'] : '');
    }


    //handle input in edit mode on click
    const disabledInputClick = (name, value) => {
        if (currentInput === '' || currentInputValue === '') {
            setCurrentInput(name);
            setCurrentInputValue(value);
        }
    }

    //handle delete clicked
    const deleteClicked = (name, value) => {
        if (value?.id === 0) {
            setCurrentInput(name);
            setErrorsReturned([]);
            setDeletePopup(false);
            setTrigDelete(trigDelete + 1);
        } else {
            setCurrentInput(name);
            setCurrentInputValue(value);
            setDeletePopup(true);
        }
    }

    //reset on delete cancel
    const cancelDeletePopup = () => {
        setCurrentInputValue('');
        setCurrentInput('');
        setErrorsReturned([]);
        setDeletePopup(false);
    }

    //delete post to backend
    const deleteElement = (e) => {
        e.preventDefault();
        setGeneralDataApiOptions({
            url: 'delete-from-cms',
            method: 'post',
            data: {
                model: props?.model,
                id: currentInputValue?.id ? currentInputValue?.id : '',
            },
            callback: res => {
                setAllCmsData(res.data);
                setCurrentInput('');
                setCurrentInputValue('');
                setDeletePopup(false);
            },
            catch: err => {
            }
        });
    }


    //submit changes to backend on confirm edit click
    const submitChanges = (e) => {
        e.preventDefault();
        const fd = new FormData();

        fd.append('model', props?.model);
        fd.append('id', currentInputValue?.id ? currentInputValue?.id : '');
        fd.append('title_en', tempEnglish);
        fd.append('title_ar', tempArabic);
        if (props?.additionalField) {
            fd.append('additionalField', props?.additionalField?.name)
            fd.append(props?.additionalField?.name, additionalFieldValue);
        }
        setGeneralDataApiOptions({
            url: 'add-edit-cms',
            method: 'post',
            data: fd,

            callback: res => {
                setAllCmsData(res.data.cms);
                setGeneralData(res.data.general)
                setCurrentInput('');
                setCurrentInputValue('');
                setErrorsReturned({});
            },
            catch: err => {
                let newValidationMessages = [];
                for (const key in err) {
                    for (let i = 0; i < err[key].length; i++) {
                        newValidationMessages.push(err[key][i]);
                    }
                }
                setErrorsReturned(newValidationMessages);
            }
        });
    }

    return (
        <>
            {
                currentInput === props.model && (props.data?.id === currentInputValue?.id) ?
                    <>
                        <div className="row m-0">
                            {
                                (props?.withSlugs && props?.withSlugs === 1) ?
                                    <div className="col-12 pb-2 pt-3">
                                        <h5 className={'locked-inputs border-hidden w-100 capitalize-first-letter bold-text ' + (props.withPadd === 1 ? '' : 'm-0 p-0')}>{props?.data?.slug.replace(/-/g, ' ')}: </h5>
                                    </div>
                                    :
                                    null
                            }
                            {
                                props?.delete === 1 ?
                                    <div style={{ zIndex: "999" }} className="col-auto" onClick={() => deleteClicked(props?.model, props?.data)}>
                                        <RemoveFd />
                                    </div>
                                    :
                                    null
                            }
                            <div className="col" onClick={(e) => { disabledInputClick(props?.model, props?.data) }} >
                                <div className="row">
                                    <div className={`col-12 ${props?.additionalField ? 'col-md-4' : 'col-md-6'} pb-2`}>
                                        <input autoFocus placeholder={props?.placeholderEn} onKeyDown={(e) => (e.key === "Enter" ? submitChanges(e) : (e.key === "Escape" ? closeInputEdit(e) : null))} value={tempEnglish} onChange={(e) => setTempEnglish(e.target.value)} className={'profile-general-input w-100 ' + (props.withPadd === 1 ? '' : 'm-0 p-0')} />
                                    </div>
                                    <div className={`col-12 ${props?.additionalField ? 'col-md-4' : 'col-md-6'} pb-2`}>
                                        <input placeholder={props?.placeholderAr} onKeyDown={(e) => (e.key === "Enter" ? submitChanges(e) : (e.key === "Escape" ? closeInputEdit(e) : null))} value={tempArabic} onChange={(e) => setTempArabic(e.target.value)} className={'profile-general-input w-100 ' + (props.withPadd === 1 ? '' : 'm-0 p-0')} />
                                    </div>
                                    {
                                        !!props?.additionalField &&

                                        <div className={` col-12 ${props?.additionalField?.type === 'color' ? 'col-md-1' : 'col-md-4'}  pb-2   `} >
                                            <input placeholder={props?.additionalField?.placeholder} type={props?.additionalField?.type} onKeyDown={(e) => (e.key === "Enter" ? submitChanges(e) : (e.key === "Escape" ? closeInputEdit(e) : null))} value={additionalFieldValue} onChange={(e) => setAditionalFieldValue(e.target.value)} className={'profile-general-input w-100 ' + (props.withPadd === 1 ? '' : 'm-0 p-0')} />
                                        </div>
                                    }

                                </div>
                            </div>
                            <div className={'col-auto ' + (props?.withPadd !== 1 ? 'ps-0' : '')}>
                                <div className="d-flex">
                                    <div className="input-profile-buttons ms-1" onClick={(e) => submitChanges(e)}>
                                        <TrueSign />
                                    </div>
                                    <div className="input-profile-buttons ms-1" onClick={(e) => closeInputEdit(e)}>
                                        <XSign />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            {
                                errorsReturned?.length > 0 ?
                                    <div className="col-12 px-4">
                                        {
                                            errorsReturned?.map((singleError, index) => (
                                                <p key={index} className={'errors-returned-profile ' + (props.withPadd === 1 ? '' : ' p-0')}>{singleError}</p>
                                            ))
                                        }
                                    </div>
                                    :
                                    null
                            }
                        </div>
                    </>
                    :
                    <>
                        <div className="row m-0">
                            {
                                props?.delete === 1 ?
                                    <div style={{ zIndex: "999" }} className="col-auto" onClick={() => deleteClicked(props?.model, props?.data)}>
                                        <RemoveFd />
                                    </div>
                                    :
                                    null
                            }
                            <div className="col" onClick={(e) => { disabledInputClick(props?.model, props?.data) }} >
                                <div className="row">
                                    {
                                        (props?.withSlugs && props?.withSlugs === 1) ?
                                            <div className="col-12 pb-2 pt-3">
                                                <h5 className={'locked-inputs border-hidden w-100 capitalize-first-letter bold-text ' + (props.withPadd === 1 ? '' : 'm-0 p-0')}>{props?.data?.slug.replace(/-/g, ' ')}: </h5>
                                            </div>
                                            :
                                            null
                                    }
                                    <div className={`col-12 ${props?.additionalField ? 'col-md-4' : 'col-md-6'}  pb-2`}>
                                        <h5 className={'locked-inputs border-hidden w-100 ' + (props.withPadd === 1 ? '' : 'm-0 p-0')}>{props?.data?.translations[0]?.title ? props?.data?.translations[0]?.title : props.placeholderEn}</h5>
                                    </div>
                                    <div className={`col-12 ${props?.additionalField ? 'col-md-4' : 'col-md-6'} pb-2`}>
                                        <h5 className={'locked-inputs border-hidden w-100 ' + (props.withPadd === 1 ? '' : 'm-0 p-0')}>{props?.data?.translations[1]?.title ? props?.data?.translations[1]?.title : props.placeholderAr}</h5>
                                    </div>
                                    {
                                        !!props?.additionalField &&
                                        <>
                                            {

                                                props?.additionalField?.type === 'color' ?
                                                    <div className="col-12 col-md-4 pb-2">
                                                        <div className="rounded" style={{ width: 20, height: 20, backgroundColor: additionalFieldValue || '#000000' }} ></div>
                                                    </div>
                                                    :
                                                    <div className="col-12 col-md-4 pb-2">
                                                        <h5 className={'locked-inputs border-hidden w-100 ' + (props.withPadd === 1 ? '' : 'm-0 p-0')}>{props?.model === 'LegalType' && additionalFieldValue ? showCommaSeperator(additionalFieldValue) + ' LBP' : ((additionalFieldValue && additionalFieldValue !== 'null') ? additionalFieldValue : 'LBP')}</h5>
                                                    </div>
                                            }

                                        </>

                                    }
                                </div>
                            </div>
                            <div style={{ opacity: 0 }} className={'col-auto ' + (props?.withPadd !== 1 ? 'ps-0' : '')}>
                                <div className="d-flex">
                                    <div className="input-profile-buttons ms-1">
                                        <TrueSign />
                                    </div>
                                    <div className="input-profile-buttons ms-1">
                                        <XSign />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            {
                                errorsReturned?.length > 0 ?
                                    <div className="col-12 px-4">
                                        {
                                            errorsReturned?.map((singleError, index) => (
                                                <p key={index} className={'errors-returned-profile ' + (props.withPadd === 1 ? '' : ' p-0')}>{singleError}</p>
                                            ))
                                        }
                                    </div>
                                    :
                                    null
                            }
                        </div>
                    </>
            }

            {
                // delete popup
                deletePopup ?
                    <div id="errors-popup" className=" error-popup-api d-flex align-items-center justify-content-center px-3 fade-in">
                        <div className="pop-up-api-box">
                            <CloseIcon className="cursor-pointer" onClick={() => cancelDeletePopup()} />
                            <div className="text-center ">
                                <div className="containter-fluid">
                                    <div className="row d-flex justify-content-center align-items-center">
                                        <div className="col-9 col-sm-12 d-flex justify-content-center">
                                            <h1 className="pop-up-title m-0 pt-3 color-dark-blue">{generalData?.settings_section_settings['delete-popup-title']}</h1>
                                        </div>
                                        <div className="col-12 d-flex justify-content-center pt-4 pb-2">
                                            <button onClick={(e) => deleteElement(e)} type="button" className="popup-button my-auto mx-2 w-auto px-3">{generalData?.settings_section_settings['delete-popup-first-button']}</button>
                                            <button onClick={() => cancelDeletePopup()} type="button" className="auth-button border-dark-blue my-auto mx-2 background-transparent w-auto px-3">{generalData?.settings_section_settings['delete-popup-second-button']}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    null
            }
            <Api options={generalDataApiOptions} />
        </>
    )
}