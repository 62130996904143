import { useContext, useState, useEffect } from "react";
import GlobalState from '../contexts/GlobalState';
import React from 'react';
import Layout from "../components/Layout";
import Api from "../components/Api";
import "react-datepicker/dist/react-datepicker.css";
import 'swiper/css';
import { registerLocale } from "react-datepicker";
import ar from 'date-fns/locale/ar';
import { BoardIcon, ListIcon } from "../components/Icons";
import ListViewComponent from "../components/ListViewComponent";
import BoardViewComponent from "../components/BoardViewComponent";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

import { Doughnut } from "react-chartjs-2";
import TaskBoxComponent from "../components/TaskBoxComponent";
import TaskPopupComponent from "../components/TaskPopupComponent";
import { Link } from "react-router-dom";
registerLocale('ar', ar)

ChartJS.register(ArcElement, Tooltip, Legend);

export default function Profile() {

    const { generalDataGlobal: [generalData] } = useContext(GlobalState);

    const [activeTasksType, setActiveTasksType] = useState('my-tasks');

    const [activeViewType, setActiveViewType] = useState('list');

    const [apiOptions, setApiOptions] = useState({});

    const [cols, setCols] = useState();

    const [searchValue, setSearchValue] = useState('');
    const [chartData, setChartData] = useState();
    const [totalTasks, setTotalTasks] = useState(0);
    const [dueTodayTasks, setDueTodayTasks] = useState();


    useEffect(() => {
        setApiOptions({
            url: 'get-tasks',
            method: 'post',
            data: {
                type: activeTasksType,
            },
            callback: ({ data }) => {
                setCols(data?.cols);
                setDueTodayTasks(data?.dueTodayTasks)
            }
        })
    }, [activeTasksType]);


    const handleSearchChange = (value) => {
        setSearchValue(value)
    }

    useEffect(() => {
        const submitData = setTimeout(() => {
            const fd = new FormData();
            fd.append('type', activeTasksType);
            fd.append('search_value', searchValue);

            setApiOptions({
                url: 'get-tasks',
                method: 'post',
                data: fd,
                showLoader: true,
                callback: res => {
                    setCols(res?.data?.cols);
                    setDueTodayTasks(res?.data?.dueTodayTasks);
                },
            })
        }, 500)
        return () => clearTimeout(submitData);
    }, [searchValue]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (cols) {
            setChartData({
                datasets: [
                    {
                        data: cols?.map((col) => ([col?.tasks?.length])),
                        borderRadius: 0,
                        borderColor: "transparent",
                        backgroundColor: [
                            ...cols?.map(col => (col?.tasks?.length > 0 ? col?.color : '#0000'))
                        ],
                    },
                ],
            });
        }
        setTotalTasks(() => {
            let x = 0
            cols?.map(col => {
                x += col?.tasks?.length
                return null
            })
            return x;
        })
    }, [cols]);


    const handleAssigneesChange = () => {
        setApiOptions({
            url: 'get-tasks',
            method: 'post',
            data: {
                type: activeTasksType
            },
            showLoader: false,
            callback: ({ data }) => {
                setCols(data?.cols);
                setDueTodayTasks(data?.dueTodayTasks)

            }
        })
    }

    const [editTask, setEditTask] = useState();
    const [ , setDeleteTask] = useState();
    const [addPopup, setAddPopup] = useState(false);


    const handleEditClick = (task) => {
        setEditTask(task);
        setAddPopup(
            {
                open: true,
                type: 'task'
            }
        )
    }

    const deleteFunction = (task) => {
        setDeleteTask(task);
    }

    const onSuccess = (res) => {
        setAddPopup(null);
        setCols(res?.data?.cols);
        setDueTodayTasks(res?.data?.dueTodayTasks)

    }


    return (
        <>
            <Api options={apiOptions} />
            {
                addPopup?.open && addPopup?.type === 'task' &&
                <TaskPopupComponent
                    setAddPopup={setAddPopup}
                    onSuccess={onSuccess}
                    activeTasksType={activeTasksType}
                    editTask={editTask}
                    setEditTask={setEditTask}
                />
            }
            <Layout activePage="tasks">
                <div className='row mx-0'>

                    <div className="col-lg-4 my-3 d-block d-lg-none">
                        <div className="h-100 w-100 d-flex flex-column">
                            <div className="row justify-content-end">
                                <div className="col-12 col-xl-12 mt-2 mt-md-0">
                                    <input name="search" className="table-search add-user-inputs" placeholder={generalData?.fixed_titles['search']} value={searchValue} onChange={(e) => handleSearchChange(e?.target?.value)} />
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-12 d-flex flex-column">
                                    <div>
                                        <h2 className="cost-big-titles pb-1">{generalData?.users_cost_settings['quick-overview']}</h2>
                                    </div>
                                    <div className="costs-box-styling position-relative height-100 d-flex flex-column bg-white rounded">
                                        <div className="row h-100 aligh-items-center">
                                            <div className="col-12">
                                                <div className="w-100 h-100 d-flex align-items-center">
                                                    <div className="chart-container mx-auto d-flex justify-content-center postion-relative">
                                                        {
                                                            chartData &&
                                                            <>
                                                                <Doughnut
                                                                    data={chartData}
                                                                    options={{
                                                                        plugins: {
                                                                            tooltip: {
                                                                                enabled: false
                                                                            }
                                                                        },
                                                                        cutout: "85%",
                                                                    }}
                                                                    plugins={[{
                                                                        afterUpdate: function (chart) {
                                                                            const arcs = chart.getDatasetMeta(0).data;

                                                                            arcs.forEach(function (arc) {
                                                                                arc.round = {
                                                                                    x: (chart.chartArea.left + chart.chartArea.right) / 2,
                                                                                    y: (chart.chartArea.top + chart.chartArea.bottom) / 2,
                                                                                    radius: (arc.outerRadius + arc.innerRadius) / 2,
                                                                                    thickness: (arc.outerRadius - arc.innerRadius) / 2,
                                                                                    backgroundColor: arc.options.backgroundColor
                                                                                }
                                                                            });
                                                                        },
                                                                        afterDraw: (chart) => {
                                                                            const {
                                                                                ctx,
                                                                            } = chart;
                                                                            chart.getDatasetMeta(0).data.forEach(arc => {
                                                                                const endAngle = Math.PI / 2 - arc.endAngle;

                                                                                ctx.save();
                                                                                ctx.translate(arc.round.x, arc.round.y);
                                                                                ctx.fillStyle = arc.options.backgroundColor;
                                                                                ctx.beginPath();
                                                                                ctx.arc(arc.round.radius * Math.sin(endAngle), arc.round.radius * Math.cos(endAngle), arc.round.thickness, 0, 2 * Math.PI);
                                                                                ctx.closePath();
                                                                                ctx.fill();
                                                                                ctx.restore();
                                                                            });
                                                                        }
                                                                    }]}
                                                                />
                                                                <div className="title-middle-chart">
                                                                    {totalTasks} {generalData?.website_titles['tasks']}
                                                                    <span>{generalData?.website_titles['total']}</span>
                                                                </div>
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-3 justify-content-center">

                                            {
                                                cols?.map((col,colIndex) => (
                                                    <div key={colIndex} className="col-auto">
                                                        <div className="d-flex align-items-center">
                                                            <div className="circle me-2" style={{ width: 10, height: 10, backgroundColor: col?.color }}></div>
                                                            {col?.title}
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='col-12'>
                        <div className='d-flex justify-content-between'>
                            <div>
                                <button className={`btn settings-titles color-dark-blue cursor-pointer me-4 ${activeTasksType === 'my-tasks' ? 'active' : ''}`} onClick={() => { setActiveTasksType('my-tasks') }}>{generalData?.tasksFixedTitles['my-tasks']}</button>
                                <button className={`btn settings-titles color-dark-blue cursor-pointer ${activeTasksType === 'requested-tasks' ? 'active' : ''}`} onClick={() => { setActiveTasksType('requested-tasks') }}>{generalData?.tasksFixedTitles['requested-by-me']}</button>
                            </div>
                        </div>
                    </div>

                    <div className='col-lg-8 '>
                        <div className='w-100 bg-white shadow position-relative scrollable-profile-height border-top border-primary' style={{ minHeight: `calc(100vh-220px) !important` }}>
                            <div className="container-fluid pt-4 h-100">
                                <div className="row  gx-0">
                                    <div className="col-auto currency-box-padd my-auto d-flex flex-row font-dark-blue bg-white">
                                        <div className="row ">
                                            <div className={`col-auto pe-0`}>
                                                <div onClick={() => { setActiveViewType('list') }} className={`smaller-font currency-text cursor-pointer d-flex align-items-center me-1 ${activeViewType === 'list' ? 'active' : ''}`}>
                                                    <ListIcon className="me-2" />
                                                    {generalData?.tasksFixedTitles['list']}
                                                </div>

                                            </div>
                                            <div className={`col-auto ps-0`}>
                                                <div onClick={() => { setActiveViewType('board') }} className={`smaller-font currency-text cursor-pointer d-flex align-items-center ${activeViewType === 'board' ? 'active' : ''}`}>
                                                    <BoardIcon className="me-2" />
                                                    {generalData?.tasksFixedTitles['board']}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {
                                    cols &&
                                    (

                                        activeViewType === 'list' ?
                                            <ListViewComponent
                                                activeTasksType={activeTasksType}
                                                cols={cols}
                                                setCols={setCols}
                                                dueTodayTasks={dueTodayTasks}
                                                setDueTodayTasks={setDueTodayTasks}
                                            />
                                            :

                                            <BoardViewComponent
                                                activeTasksType={activeTasksType}
                                                cols={cols}
                                                setCols={setCols}
                                                dueTodayTasks={dueTodayTasks}
                                                setDueTodayTasks={setDueTodayTasks}
                                            />
                                    )

                                }
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 " style={{ maxHeight: 'calc(100vh - 220px)' }}>
                        <div className="h-100 w-100 d-flex flex-column">
                            <div className="row justify-content-end d-none d-lg-flex">
                                <div className="col-12 col-xl-12 mt-2 mt-md-0">
                                    <input name="search" className="table-search add-user-inputs" placeholder={generalData?.fixed_titles['search']} value={searchValue} onChange={(e) => handleSearchChange(e?.target?.value)} />
                                </div>
                            </div>
                            <div className="row mt-4 d-none d-lg-flex">
                                <div className="col-12 d-flex flex-column">
                                    <div>
                                        <h2 className="cost-big-titles pb-1">{generalData?.users_cost_settings['quick-overview']}</h2>
                                    </div>
                                    <div className="costs-box-styling position-relative height-100 d-flex flex-column bg-white rounded">
                                        <div className="row h-100 aligh-items-center">
                                            <div className="col-12">
                                                <div className="w-100 h-100 d-flex align-items-center">
                                                    <div className="chart-container mx-auto d-flex justify-content-center postion-relative">
                                                        {
                                                            chartData &&
                                                            <>
                                                                <Doughnut
                                                                    data={chartData}
                                                                    options={{
                                                                        plugins: {
                                                                            tooltip: {
                                                                                enabled: false
                                                                            }
                                                                        },
                                                                        cutout: "85%",
                                                                    }}
                                                                    plugins={[{
                                                                        afterUpdate: function (chart) {
                                                                            const arcs = chart.getDatasetMeta(0).data;

                                                                            arcs.forEach(function (arc) {
                                                                                arc.round = {
                                                                                    x: (chart.chartArea.left + chart.chartArea.right) / 2,
                                                                                    y: (chart.chartArea.top + chart.chartArea.bottom) / 2,
                                                                                    radius: (arc.outerRadius + arc.innerRadius) / 2,
                                                                                    thickness: (arc.outerRadius - arc.innerRadius) / 2,
                                                                                    backgroundColor: arc.options.backgroundColor
                                                                                }
                                                                            });
                                                                        },
                                                                        afterDraw: (chart) => {
                                                                            const {
                                                                                ctx,
                                                                            } = chart;
                                                                            chart.getDatasetMeta(0).data.forEach(arc => {
                                                                                const endAngle = Math.PI / 2 - arc.endAngle;

                                                                                ctx.save();
                                                                                ctx.translate(arc.round.x, arc.round.y);
                                                                                ctx.fillStyle = arc.options.backgroundColor;
                                                                                ctx.beginPath();
                                                                                ctx.arc(arc.round.radius * Math.sin(endAngle), arc.round.radius * Math.cos(endAngle), arc.round.thickness, 0, 2 * Math.PI);
                                                                                ctx.closePath();
                                                                                ctx.fill();
                                                                                ctx.restore();
                                                                            });
                                                                        }
                                                                    }]}
                                                                />
                                                                <div className="title-middle-chart">
                                                                    {totalTasks} {generalData?.website_titles['tasks']}
                                                                    <span>{generalData?.website_titles['total']}</span>
                                                                </div>
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-3 justify-content-center">

                                            {
                                                cols?.map((col,colIndex) => (
                                                    <div key={colIndex} className="col-auto">
                                                        <div className="d-flex align-items-center">
                                                            <div className="circle me-2" style={{ width: 10, height: 10, backgroundColor: col?.color }}></div>
                                                            {col?.title}
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className=" col-12 mb-2 pb-1" >
                                    <div className="d-flex align-items-center">
                                        <h2 className="cost-big-titles  me-3 mb-0  ">{generalData?.tasksFixedTitles['due-today']}</h2>
                                        <div className='text-gray circle border-gray border d-flex align-items-center justify-content-center' style={{ width: 15, height: 15, }}>
                                            <p style={{ fontSize: 10 }} className='text-gray'>{dueTodayTasks?.length}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex-grow-1 overflow-auto">
                                <div className="container-fluid h-100 d-flex flex-column gx-0">

                                    <div className="row gx-0">
                                        {
                                            dueTodayTasks?.map((task, i) => (
                                                <div className="col-12" key={i}>
                                                    <Link to={`${task?.id}/general-info`}>
                                                        <TaskBoxComponent
                                                            hideDate={true}
                                                            dueBox={true}
                                                            task={task}
                                                            index={i}
                                                            editFunction={handleEditClick}
                                                            deleteFunction={deleteFunction}
                                                            setCols={setCols}
                                                            activeTasksType={activeTasksType}
                                                            handleAssigneesChange={handleAssigneesChange}
                                                            dueTodayTasks={dueTodayTasks}
                                                            setDueTodayTasks={setDueTodayTasks}
                                                        />
                                                    </Link>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}