import { useContext, useEffect, useState } from "react";
import GlobalState from '../contexts/GlobalState';
import "react-datepicker/dist/react-datepicker.css";
import 'react-phone-number-input/style.css';
import { CloseIcon } from '../components/Icons'


import { registerLocale } from "react-datepicker";
import ar from 'date-fns/locale/ar';
import Api from "../components/Api";
registerLocale('ar', ar)


export default function HearingCommonPopup(props) {
    const { generalDataGlobal, setHearings, setLitigations, activeMatter, setActiveMatter
    } = useContext(GlobalState);

    const [generalData] = generalDataGlobal;

    const [generalDataApiOptions, setGeneralDataApiOptions] = useState({});

    const { popupTitle, setCommonPopup, popupContent, setPopupContent, sort, page, direction, type, onSuccess } = props;

    const [description, setDescription] = useState('');

    useEffect(() => {
        if (popupContent) {
            setDescription(popupContent?.record[popupContent?.fieldName]);
        }
    }, [popupContent])

    const handleClosePopupClick = () => {
        setCommonPopup(false);
        setPopupContent(null)
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        let fd = new FormData();
        fd.append('target_id', popupContent?.record?.id);
        fd.append('model', 'Hearing');
        // fd.append('relation_field', currentInputValue?.relationField)
        fd.append('current_field', popupContent?.fieldName);
        fd.append('current_field_value', description);

        fd.append('returnIndex', popupContent?.returnIndex);
        fd.append('type', type || 'hearings');
        fd.append('sort', sort);
        fd.append('page', page || '');
        fd.append('direction', direction || '');
        fd.append('main_id', activeMatter?.id)

        // if (currentInputValidations) {
        //     fd.append('validations', currentInputValidations)
        // }
        if(props?.returnDashboard && props?.returnDashboard === 1){
            fd.append('return_dashboard',1);
        }

        if(props?.tasksType){
            fd.append('tasksType',props?.tasksType);
        }
        setGeneralDataApiOptions({
            url: 'edit-input-data',
            method: 'post',
            data: fd,
            callback: res => {
                    if(onSuccess){
                        onSuccess(res);
                    }else if(popupContent?.returnIndex){

                        setHearings(res?.data?.hearings)
                        setLitigations(res?.data?.litigations)
                    }else{
                        setActiveMatter(res?.data);
                    }

                    setCommonPopup(false);

                // setErrorsReturned({});
                // setActiveMatter(res?.data);
                // setProfileClient(res.data);
                // setCurrentInputValue('');
                // setCurrentInput('');
                // setErrorsReturned({});
                // setDeletePopup();
                // setChangeInput(0);
                // setZoom(1);
            },
            catch: err => {
                if (err) {
                    // setErrorsReturned(err);
                    // setChangeInput(0);
                }
            }
        })
    }

    return (
        <>
            <div id="errors-popup" className=" error-popup-api d-flex align-items-center justify-content-center px-3 fade-in" >
                <div className="pop-up-api-box" style={{ maxHeight: 540, overflow: 'auto', maxWidth: 'auto !important' }}>
                    <div className="d-flex">
                        <CloseIcon className="cursor-pointer" onClick={(e) => { handleClosePopupClick() }} />
                    </div>
                    <div className="text-center ">
                        <div className="containter-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <h1 className="pop-up-title m-0 py-2 color-dark-blue">{popupTitle}</h1>
                                </div>
                                <form onSubmit={handleSubmit}>

                                    <div className="col-12 pt-2 pb-4">
                                        {
                                            <>
                                                <div className="row">

                                                    <div className="col-12 pt-2">
                                                        <textarea type="text-area" name="description" className="add-user-inputs resizable-vertical" placeholder={generalData?.matterSectionsSettings['description']} value={description} onChange={(e) => { setDescription(e.target.value) }}></textarea>
                                                        {/* {
                                                            errorsReturned?.comment ?
                                                                <h5 className="pt-2 m-0 d-flex flex-start text-start pop-errors-text">{errorsReturned?.comment}</h5>
                                                                :
                                                                null
                                                        } */}

                                                    </div>
                                                </div>

                                            </>
                                        }

                                    </div>
                                    <div className="col-12 d-flex justify-content-center">
                                        <button type="submit" className="popup-button my-auto">{'Add'}</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Api options={generalDataApiOptions} />
        </>
    )
}