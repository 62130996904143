import { useContext, useState, useEffect } from "react";
import GlobalState from '../contexts/GlobalState';
import { useNavigate, useParams } from "react-router";
import Select from 'react-select';
import React from 'react';
import Api from "./Api";
import EditInput from "./EditInput";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import 'swiper/css';
import DocumentsSmallTab from "./DocumentsSmallTab";
import UsersSelect from "./UsersSelect";
import EntityContacts from "./EntityContacts";
import EntityContactPopup from "./EntityContactPopup";
import { addYears, formatDate, getIndex } from "../Helpers";

import { registerLocale } from "react-datepicker";
import ar from 'date-fns/locale/ar';
import { CloseIcon, ThreeDotsMenu } from "./Icons";
registerLocale('ar', ar)

export default function NgoGeneralInfo(props) {

    const { generalDataGlobal, changeInputGlobal, deleteContactPopupGlobal, entityContactPopupGlobal, currentInputGlobal, currentInputValueGlobal, profileClientGlobal } = useContext(GlobalState);
    const [generalDataApiOptions, setGeneralDataApiOptions] = useState({});
    let { slug, user_id } = useParams();
    const [generalData] = generalDataGlobal;
    const [profileClient, setProfileClient] = profileClientGlobal;
    const [currentInput, setCurrentInput] = currentInputGlobal;
    const [currentInputValue, setCurrentInputValue] = currentInputValueGlobal;
    const history = useNavigate();
    const [errorsReturned, setErrorsReturned] = useState({});
    const [changeInput, setChangeInput] = changeInputGlobal;
    const [formData, setFormData] = useState({});
    const [registrationDate, setRegistrationDate] = useState('');
    const [activeEditUsers, setActiveEditUsers] = useState('');
    const [entityContactPopup,] = entityContactPopupGlobal;
    const [deleteContactPopup, setDeleteContactPopup] = deleteContactPopupGlobal;


    //get all licenses types
    const licenseTypes = () => {
        const licenseType = generalData?.license_waiver_types?.map(
            type => ({ value: type.id, label: type.title }))
        return licenseType;
    }

    //get users for relations
    const allUsers = () => {
        const curren = generalData?.users?.map(
            singleOption => ({ value: singleOption.id, label: singleOption?.name }))
        return curren;
    }

    //custom select styles
    const customStyles = {
        dropdownIndicator: base => ({
            ...base,
            color: "#00366A"
        }),
        placeholder: (defaultStyles) => {
            return {
                ...defaultStyles,
                fontFamily: 'Poppins',
                fontWeight: '400',
                color: '#00366A',
            }
        },
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                color: '#00366A',
                fontFamily: 'Poppins',
                fontWeight: '400',
                backgroundColor: 'white',
                "&:hover": {
                    backgroundColor: "#f2f9fc"
                },
            };
        },
        singleValue: base => ({
            ...base,
            backgroundColor: "red"
        }),
        control: provided => ({
            ...provided,
            minHeight: 40,
            width: '100%',
            border: "none",
            backgroundColor: 'white',
            outline: "none",
            '&:hover': { borderColor: '#393939' },
            borderRadius: "7px",
            fontFamily: 'Poppins',
            fontWeight: '400',
            boxShadow: "0px 3px 6px rgb(0 0 0 / 16%)",
        }),
        menu: (provided, state) => ({
            ...provided,
            borderRadius: "1px",
        }),
        input: (base, state) => ({
            ...base,
            color: '#00366A',
            fontSize: "14px",
            height: "100%",
            fontFamily: 'Poppins',
            fontWeight: '400',
            marginTop: "-8px",
            '& input': {
                font: 'inherit',
            },
        })
    };

    // custom styles for the select
    const customStylesPopup = {
        placeholder: (defaultStyles) => {
            return {
                ...defaultStyles,
                display: "flex",
                fontFamily: 'Poppins',
                fontWeight: '400',
                textTransform: "capitalize",
                fontSize: '14px',
                color: '#07407C',
            }
        },
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                color: '#07407C',
                fontFamily: 'Poppins',
                fontWeight: '400',
                backgroundColor: 'white',
                textTransform: "capitalize",
                margin: "auto",
                paddingRight: "10px",
                maxWidth: "100%",
                paddingLeft: "10px",
                paddingBottom: "0",
                paddingTop: "0",
            };
        },
        singleValue: base => ({
            ...base,
            color: "#00366A",
            display: "flex",
            fontSize: "14px",
            fontFamily: "Poppins",
            fontWeight: "400",
            textTransform: "capitalize",
        }),
        control: provided => ({
            ...provided,
            height: "40px",
            border: "none",
            backgroundColor: 'white',
            outline: "none",
            '&:hover': { borderColor: 'transparent' },
            textTransform: 'uppercase',
            width: "100%",
            borderRadius: "7px",
            fontFamily: 'Poppins',
            fontWeight: '400',
            boxShadow: "0px 3px 6px rgb(0 0 0 / 16%)",
        }),
        menu: (provided, state) => ({
            ...provided,
            borderRadius: "7px",
            textTransform: 'uppercase',
            border: "1px solid transparent",
        }),
        dropdownIndicator: base => ({
            ...base,
            color: "#00366A !important",
        }),
        input: (base, state) => ({
            ...base,
            color: "#393939",
            fontSize: "16px",
            height: "100%",
            fontFamily: 'Poppins',
            textTransform: 'uppercase',
            fontWeight: '400',
            marginTop: "14px",
            '& input': {
                font: 'inherit',
            },
        })
    };


    /* license Waiver Popup*/

    const [licensePopup, setLicensePopup] = useState(false);
    const [licenseType, setLicenseType] = useState('');
    const [licenseRemindUsers, setLicenseRemindUsers] = useState('');
    const [releasedOn, setReleasedOn] = useState('');
    const [expiresOn, setExpiresOn] = useState('');
    const [licenseComment, setLicenseComment] = useState('');
    const [openEditLicenseMenu, setOpenEditLicenseMenu] = useState({});
    const [currentLicense, setCurrentLicense] = useState({});
    const [deleteLicense, setDeleteLicense] = useState({});

    /* license Waiver Popup end */

    /* Conacts */
    const [openId, setOpenId] = useState();
    /* Contact */

    //set states with current license data once edit is clicked
    const editLicenseClick = (e, single) => {
        if (single?.reminders?.remind_users?.length > 0) {
            setLicenseRemindUsers(
                single?.reminders?.remind_users?.map(
                    singleUser => ({ value: singleUser.id, label: singleUser.name })
                )
            );
        }
        setLicenseType(licenseTypes()[getIndex(single?.license_type_id, licenseTypes(), 'value')]);
        setReleasedOn(single?.released_on ? new Date(single?.released_on) : '');
        setExpiresOn(single?.expires_on ? new Date(single?.expires_on) : '');
        setLicenseComment(single?.comment ? single?.comment : '');
        setCurrentLicense(single);
        setErrorsReturned({});
        setLicensePopup(true);
    }

    //reset license states once add or edit is canceled
    const closeLicense = (e) => {
        setLicensePopup(false);
        setLicenseType('');
        setLicenseRemindUsers('');
        setReleasedOn('');
        setExpiresOn('');
        setLicenseComment('');
        setOpenEditLicenseMenu({});
        setCurrentLicense({});
        setErrorsReturned({});
        setDeleteLicense({});
    }

    // delete contact post to backend
    const deleteContactProcess = (e, single) => {
        e.preventDefault();
        e.stopPropagation();

        let fd = new FormData();
        if (deleteContactPopup?.id) {
            fd.append('contact_id', deleteContactPopup?.id);
        }

        fd.append('client_id', deleteContactPopup?.client_id);
        fd.append('user_id', deleteContactPopup?.client_id);

        setGeneralDataApiOptions({
            url: 'delete-entity-contact',
            method: 'post',
            data: fd,
            callback: res => {
                setProfileClient(res?.data);
                setDeleteContactPopup({});
                setErrorsReturned({});
            },
            catch: err => {
                if (err) {
                    setErrorsReturned(err);
                }
            }
        });
    }

    //delete license post to backend
    const deleteLicenseProcess = (e, single) => {
        e.preventDefault();
        e.stopPropagation();
        let fd = new FormData();

        if (deleteLicense?.id) {
            fd.append('license_id', deleteLicense?.id);
        }

        fd.append('client_id', user_id);
        fd.append('user_id', user_id);

        setGeneralDataApiOptions({
            url: 'delete-license',
            method: 'post',
            data: fd,
            callback: res => {
                setProfileClient(res?.data);
                setCurrentLicense({});
                closeLicense(e);
                setErrorsReturned({});
            },
            catch: err => {
                if (err) {
                    setErrorsReturned(err);
                }
            }
        });
    }

    // submit license to backend
    const submitLicense = (e) => {
        e.preventDefault();
        e.stopPropagation();
        let fd = new FormData();

        fd.append('license_type_id', licenseType ? licenseType?.value : '');
        fd.append('released_on', releasedOn ? formatDate(releasedOn) : '');
        fd.append('expires_on', expiresOn ? formatDate(expiresOn) : '');
        fd.append('comment', licenseComment ? licenseComment : '');
        if (licenseRemindUsers?.length > 0) {
            fd.append('remind_users', (licenseRemindUsers?.length > 0 ? licenseRemindUsers?.map((single, index) => (
                single.value
            )).toString() : ''));
        }

        if (currentLicense?.id) {
            fd.append('license_id', currentLicense?.id);
        }

        fd.append('client_id', user_id);
        fd.append('user_id', user_id);

        fd.append('license_page', 1);
        fd.append('reminder_type_id', 4);
        fd.append('name', licenseType ? licenseType?.label : '');
        fd.append('date', expiresOn ? formatDate(expiresOn) : '');
        fd.append('notify_before', 1);
        fd.append('notify_before_type_id', 2);
        fd.append('summary', licenseComment ? licenseComment : '');

        if (currentLicense?.id) {
            fd.append('reminders_id', currentLicense?.reminders_id || '');
            fd.append('reminder_id', currentLicense?.reminders_id || '');
        }

        setGeneralDataApiOptions({
            url: 'add-edit-license',
            method: 'post',
            data: fd,
            callback: res => {
                setProfileClient(res?.data);
                setCurrentLicense({});
                closeLicense(e);
                setErrorsReturned({});
            },
            catch: err => {
                if (err) {
                    setErrorsReturned(err);
                }
            }
        });
    }

    //on slug change
    useEffect(() => {
        if (slug) {
            history('/client/' + user_id + '/' + slug);
        }
    }, [slug]);// eslint-disable-line react-hooks/exhaustive-deps


    //handle changes in data using the dynamic inputs and post them  to backend
    useEffect(() => {
        if (changeInput > 0 && slug === 'entity-management') {
            let fd = new FormData();

            if (registrationDate) {
                fd.append('registration_date', formatDate(registrationDate));
            }
            fd.append('current_user', user_id);
            fd.append('current_field', currentInput);
            fd.append('current_field_value', currentInputValue);

            fd.append('client_id', user_id);
            fd.append('user_id', user_id);

            setGeneralDataApiOptions({
                url: 'edit-client-data',
                method: 'post',
                data: fd,
                callback: res => {
                    setErrorsReturned({});
                    setFormData(res?.data);
                    setProfileClient(res.data);
                    setCurrentInputValue('');
                    setCurrentInput('');
                    setErrorsReturned({});
                    setChangeInput(0);
                },
                catch: err => {
                    if (err) {
                        setErrorsReturned(err);
                        setChangeInput(0);
                    }
                }
            })
        }
    }, [changeInput]);// eslint-disable-line react-hooks/exhaustive-deps

    //set page data when profileclient changes or user_id changes
    useEffect(() => {
        setFormData(profileClient);
        if (profileClient?.registration_date) {
            setRegistrationDate(new Date(profileClient?.registration_date));
        }
    }, [profileClient, user_id]);// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <div className="container-fluid">
                <div className="row client-profile-height">

                    {/* registration section starts */}
                    <div className="col-12 col-lg-3 pt-4 drop-shadow-div scrollable-client-profile-height">
                        <div className="row">
                            <div className="col-auto">
                                <h1 className="profile-big-title m-0">{generalData?.website_titles['registration']}</h1>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 my-auto align-items-center pb-4">
                                <div className="row">
                                    <div className="col-12 pt-3">
                                        <div className="row">
                                            <div className="col-12 d-flex">
                                                <p className="profile-general-input-title">{generalData?.website_titles['registration-number-title']}</p>
                                            </div>
                                            <div className="col-12 col-sm-8 col-lg-10 col-xl-9 pt-1 w-100">
                                                <EditInput inputType={'text'} postUrl="edit-client-data" data={profileClient} value={formData?.registration_number ? formData?.registration_number : ''} inputName={"registration_number"} userId={user_id} placeholder={generalData?.website_titles['registration-number-title']} />
                                            </div>
                                            {
                                                errorsReturned && errorsReturned?.registration_number ?
                                                    <div className="col-12 col-sm-8 col-lg-10 col-xl-9 pt-1 w-100">
                                                        <h5 className=" ps-4 pop-errors-text">{errorsReturned?.registration_number}</h5>
                                                    </div>
                                                    :
                                                    null
                                            }
                                        </div>
                                    </div>
                                    <div className="col-12 pt-3">
                                        <div className="row">
                                            <div className="col-12 d-flex">
                                                <p className="profile-general-input-title">{generalData?.website_titles['registration-city']}</p>
                                            </div>
                                            <div className="col-12 col-sm-8 col-lg-10 col-xl-9 pt-1 w-100">
                                                <EditInput inputType={'text'} postUrl="edit-client-data" data={profileClient} value={formData?.registration_city ? formData?.registration_city : ''} inputName={"registration_city"} userId={user_id} placeholder={generalData?.website_titles['registration-city']} />
                                            </div>
                                            {
                                                errorsReturned && errorsReturned?.registration_city ?
                                                    <div className="col-12 col-sm-8 col-lg-10 col-xl-9 pt-1 w-100">
                                                        <h5 className=" ps-4 pop-errors-text">{errorsReturned?.registration_city}</h5>
                                                    </div>
                                                    :
                                                    null
                                            }
                                        </div>
                                    </div>
                                    <div className="col-12 pt-3">
                                        <div className="row">
                                            <div className="col-12 d-flex">
                                                <p className="profile-general-input-title">{generalData?.website_titles['registration-date']}</p>
                                            </div>
                                            <div className="col-12 col-sm-8 col-lg-10 col-xl-8 pt-1">
                                                <DatePicker
                                                    className="birthdate-profile ps-0"
                                                    placeholderText={generalData?.website_titles['registration-date']}
                                                    value={registrationDate}
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                    selected={registrationDate}
                                                    dateFormat="dd-MM-yyyy"
                                                    onChange={(date) => { setRegistrationDate(date); setCurrentInput('registration_date'); setTimeout(() => { setChangeInput(1) }, 100) }}
                                                    maxDate={new Date()}
                                                    minDate={addYears(new Date(), -100)}
                                                    locale={localStorage.getItem("BlpLanguage")}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="col-12 pt-3">
                                        <div className="row">
                                            <div className="col-12 d-flex">
                                                <p className="profile-general-input-title">{generalData?.website_titles['commercial-registration-number']}</p>
                                            </div>
                                            <div className="col-12 col-sm-8 col-lg-10 col-xl-9 pt-1 w-100">
                                                <EditInput inputType={'text'} postUrl="edit-client-data" data={profileClient} value={formData?.commercial_registration_number ? formData?.commercial_registration_number : ''} inputName={"commercial_registration_number"} userId={user_id} placeholder={generalData?.website_titles['commercial-registration-number']} />
                                            </div>
                                            {
                                                errorsReturned && errorsReturned?.commercial_registration_number ?
                                                    <div className="col-12 col-sm-8 col-lg-10 col-xl-9 pt-1 w-100">
                                                        <h5 className=" ps-4 pop-errors-text">{errorsReturned?.commercial_registration_number}</h5>
                                                    </div>
                                                    :
                                                    null
                                            }
                                        </div>
                                    </div> */}
                                    <div className="col-12 pt-3">
                                        <div className="row">
                                            <div className="col-12 d-flex">
                                                <p className="profile-general-input-title">{generalData?.website_titles['mof-registration-number']}</p>
                                            </div>
                                            <div className="col-12 col-sm-8 col-lg-10 col-xl-9 pt-1 w-100">
                                                <EditInput inputType={'text'} postUrl="edit-client-data" data={profileClient} value={formData?.mof_registration_number ? formData?.mof_registration_number : ''} inputName={"mof_registration_number"} userId={user_id} placeholder={generalData?.website_titles['mof-registration-number']} />
                                            </div>
                                            {
                                                errorsReturned && errorsReturned?.mof_registration_number ?
                                                    <div className="col-12 col-sm-8 col-lg-10 col-xl-9 pt-1 w-100">
                                                        <h5 className=" ps-4 pop-errors-text">{errorsReturned?.mof_registration_number}</h5>
                                                    </div>
                                                    :
                                                    null
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* registration section ends */}

                    <div className="col-12 col-lg-9 pt-4 px-lg-4 pb-5 scrollable-client-profile-height">
                        {/* table section starts  */}
                        <div className="row pb-3">
                            <div className="col-auto my-auto d-flex align-items-center mb-3">
                                <h3 className="profile-big-title m-0 ">{generalData?.website_titles['licenses-waivers']} </h3>
                                <img style={{ height: "30px" }} className="cursor-pointer shadow ms-2" src={'/assets/images/plus.svg'}  onClick={() => setLicensePopup(true)} alt="add" />
                            </div>
                            <div className="col-12">
                                <div style={{ height: "250px" }} className="table-wrapper-settings custom-shadow">

                                    <table className="users-table" width="100%">
                                        <thead>
                                            <tr>
                                                <td></td>
                                                <td style={{ minWidth: "170px" }}>{generalData?.website_titles['type']}</td>
                                                <td style={{ minWidth: "200px" }}>{generalData?.website_titles['released-on']}</td>
                                                <td style={{ minWidth: "190px" }}>{generalData?.website_titles['expires-on']}</td>
                                                <td style={{ minWidth: "200px", width: "200px", maxWidth: "200px" }}>{generalData?.website_titles['users-to-remind']}</td>
                                                <td style={{ minWidth: "600px" }}>{generalData?.website_titles['comment']}</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                profileClient?.licenses?.length > 0 &&
                                                profileClient?.licenses?.map((singleLicense, index) => (
                                                    <tr key={index}>
                                                        <td className="text-end p-0 pe-2 position-relative" >
                                                            <ThreeDotsMenu onClick={(e) => { e.stopPropagation(); singleLicense?.id === openEditLicenseMenu?.id ? setOpenEditLicenseMenu(null) : setOpenEditLicenseMenu(singleLicense) }} />
                                                            <div style={{ top: '0px' }} className={"settings-clickmenu first-td-buttons text-start " + (singleLicense?.id === openEditLicenseMenu?.id ? ' d-block' : '')}>
                                                                <p style={{ zIndex: "99" }} onClick={(e) => { e.stopPropagation(); e.preventDefault(); editLicenseClick(e, singleLicense) }} className="p-0 py-1 m-0 ms-2">{generalData?.settings_section_settings['edit-title']}</p>
                                                                <p style={{ zIndex: "200" }} onClick={(e) => { e.stopPropagation(); setDeleteLicense(singleLicense) }} className="p-0 py-1 m-0 ms-2">{generalData?.settings_section_settings['delete-title']}</p>
                                                            </div>
                                                        </td>
                                                        <td>{singleLicense.license_type?.title}</td>
                                                        <td>{singleLicense.released_on}</td>
                                                        <td>{singleLicense.expires_on}</td>
                                                        <td>
                                                            {
                                                                singleLicense?.reminders &&
                                                                <UsersSelect url={'edit-license-users'} activeEditUsers={activeEditUsers} setActiveEditUsers={setActiveEditUsers} data={singleLicense} users={singleLicense?.reminders?.remind_users} clientId={user_id} />
                                                            }
                                                        </td>
                                                        <td>{singleLicense?.comment}</td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                    {
                                        profileClient?.licenses?.length === 0 &&
                                        <>
                                            <div className="w-100 text-center mt-4">
                                                {generalData?.fixed_titles['you-didnt-add-any-company-yet'].replace('%record%', generalData?.website_titles['licenses-waivers'])}
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>

                        </div>
                        {/* table section starts  */}


                        <div className="row py-4">
                            {
                                generalData?.entity_contacts_types?.length > 0 ?
                                    generalData?.entity_contacts_types?.map((singleType, typeInd) => (

                                        <div key={typeInd} className="col-12">
                                            <EntityContacts
                                                count
                                                clientId={user_id}
                                                contactsData={profileClient?.contacts_data[singleType.slug]}
                                                openId={openId}
                                                setOpenId={setOpenId}
                                                title={singleType?.title}
                                                data={singleType}
                                            />
                                        </div>
                                    ))
                                    :
                                    null
                            }
                        </div>
                        <DocumentsSmallTab userId={user_id} files={profileClient?.home_files} />
                    </div>
                </div>
            </div>
            {
                //entity contact popup
                entityContactPopup?.id >= 0 ?
                    <EntityContactPopup />
                    :
                    null
            }

            {
                //delete contact popup
                deleteContactPopup?.id ?
                    <div id="errors-popup" className=" error-popup-api d-flex align-items-center justify-content-center px-3 fade-in">
                        <div className="pop-up-api-box">
                            <CloseIcon className="cursor-pointer" onClick={(e) => setDeleteContactPopup({})} />
                            <div className="text-center ">
                                <div className="containter-fluid">
                                    <div className="row d-flex justify-content-center align-items-center">
                                        <div className="col-9 col-sm-12 d-flex justify-content-center">
                                            <h1 className="pop-up-title m-0 pt-3 color-dark-blue">{generalData?.settings_section_settings['delete-popup-title']}</h1>
                                        </div>
                                        <div className="col-12 d-flex justify-content-center pt-4 pb-2">
                                            <button onClick={(e) => deleteContactProcess(e, deleteContactPopup)} type="button" className="popup-button my-auto mx-2 w-auto px-3">{generalData?.settings_section_settings['delete-popup-first-button']}</button>
                                            <button onClick={(e) => setDeleteContactPopup({})} type="button" className="auth-button border-dark-blue my-auto mx-2 background-transparent w-auto px-3">{generalData?.settings_section_settings['delete-popup-second-button']}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    null
            }

            {
                //license popup
                licensePopup ?
                    <div id="errors-popup" className=" error-popup-api d-flex align-items-center justify-content-center px-3 fade-in">
                        <div className="pop-up-api-box">
                            <CloseIcon className="cursor-pointer" onClick={(e) => closeLicense(e)} />
                            <div className="text-center ">
                                <div className="containter-fluid">
                                    <div className="row">
                                        <div className="col-12">
                                            <h1 className="pop-up-title m-0 py-2 color-dark-blue">{currentLicense?.id ? generalData?.website_titles['edit-licensewaiver'] : generalData?.website_titles['add-new-licensewaiver']}</h1>
                                        </div>
                                        <form onSubmit={submitLicense}>
                                            <div className="col-12 pt-2 pb-4">
                                                <div className="row">
                                                    <div className="col-12 col-md-6 pt-2">
                                                        <div className="position-relative text-start justify-content-start w-100">

                                                            <Select
                                                                classNamePrefix="popup-select"
                                                                className="popup-select w-100"
                                                                styles={customStylesPopup}
                                                                options={licenseTypes()}
                                                                components={{
                                                                    IndicatorSeparator: () => null
                                                                }}
                                                                placeholder={generalData?.website_titles['license-waiver-type']}
                                                                isSearchable={false}
                                                                value={licenseType}
                                                                onChange={setLicenseType}
                                                            />
                                                            <input className="position-x-center" type="text" style={{ width: 0, height: 0, opacity: 0 }} value={licenseType?.label || ''} required />

                                                        </div>
                                                        {
                                                            errorsReturned?.license_type_id ?
                                                                <h5 className="pt-2 m-0 d-flex flex-start text-start pop-errors-text">{errorsReturned?.license_type_id}</h5>
                                                                :
                                                                null
                                                        }
                                                    </div>


                                                    <div className="col-12 col-md-6 pt-2">
                                                        <div className="postion-relative text-start justify-content-start w-100">
                                                            <DatePicker
                                                                className="popup-date add-user-inputs"
                                                                placeholderText={generalData?.website_titles['released-on']}
                                                                value={releasedOn ? releasedOn : ''}
                                                                showMonthDropdown
                                                                showYearDropdown
                                                                dropdownMode="select"
                                                                selected={releasedOn}
                                                                dateFormat="dd-MM-yyyy"
                                                                onChange={(date) => setReleasedOn(date)}
                                                                maxDate={new Date()}
                                                                minDate={addYears(new Date(), -100)}
                                                                locale={localStorage.getItem("BlpLanguage")}
                                                                required
                                                            />
                                                        </div>
                                                        {
                                                            errorsReturned?.released_on ?
                                                                <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text">{errorsReturned?.released_on}</h5>
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                    <div className="col-12 col-md-6 pt-2">
                                                        <div className="postion-relative text-start justify-content-start w-100">
                                                            <DatePicker
                                                                className="popup-date add-user-inputs"
                                                                placeholderText={generalData?.website_titles['expires-on']}
                                                                value={expiresOn ? expiresOn : ''}
                                                                showMonthDropdown
                                                                showYearDropdown
                                                                dropdownMode="select"
                                                                selected={expiresOn}
                                                                dateFormat="dd-MM-yyyy"
                                                                onChange={(date) => setExpiresOn(date)}
                                                                maxDate={addYears(new Date(), +100)}
                                                                minDate={addYears(new Date(), -50)}
                                                                locale={localStorage.getItem("BlpLanguage")}
                                                            />
                                                        </div>
                                                        {
                                                            errorsReturned?.expires_on ?
                                                                <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text">{errorsReturned?.expires_on}</h5>
                                                                :
                                                                null
                                                        }
                                                    </div>

                                                    <div className="col-12 pt-2 justify-content-start">
                                                        <Select
                                                            classNamePrefix="select-multiple-users"
                                                            className="select-multiple-users w-100"
                                                            isClearable={false}
                                                            styles={customStyles}
                                                            isMulti={true}
                                                            placeholder={generalData?.website_titles['users-to-remind']}
                                                            options={allUsers()}
                                                            value={licenseRemindUsers}
                                                            isDisabled={expiresOn ? false : true}
                                                            onChange={setLicenseRemindUsers} />
                                                        {
                                                            errorsReturned?.remind_users ?
                                                                <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text">{errorsReturned?.remind_users}</h5>
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                    <div className="col-12 pt-2">
                                                        <textarea type="text-area" name="summary" className="add-user-inputs" placeholder={generalData?.website_titles['comment']} value={licenseComment} onChange={(e) => setLicenseComment(e.target.value)}></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 d-flex justify-content-center">
                                                <button type="submit" className="popup-button my-auto">{currentLicense?.id ? generalData?.website_titles['edit'] : generalData?.website_titles['add']}</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    null
            }
            {
                //delete license popup
                deleteLicense?.id ?
                    <div id="errors-popup" className=" error-popup-api d-flex align-items-center justify-content-center px-3 fade-in">
                        <div className="pop-up-api-box">
                            <CloseIcon className="cursor-pointer" onClick={(e) => closeLicense(e)} />
                            <div className="text-center ">
                                <div className="containter-fluid">
                                    <div className="row d-flex justify-content-center align-items-center">
                                        <div className="col-9 col-sm-12 d-flex justify-content-center">
                                            <h1 className="pop-up-title m-0 pt-3 color-dark-blue">{generalData?.settings_section_settings['delete-popup-title']}</h1>
                                        </div>
                                        <div className="col-12 d-flex justify-content-center pt-4 pb-2">
                                            <button onClick={(e) => deleteLicenseProcess(e, deleteLicense)} type="button" className="popup-button my-auto mx-2 w-auto px-3">{generalData?.settings_section_settings['delete-popup-first-button']}</button>
                                            <button onClick={(e) => closeLicense(e)} type="button" className="auth-button border-dark-blue my-auto mx-2 background-transparent w-auto px-3">{generalData?.settings_section_settings['delete-popup-second-button']}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    null
            }
            <Api options={generalDataApiOptions} />
        </>
    )
}