import React, { useContext, useState } from 'react'
import Layout from '../components/Layout'
import { Wrapper } from '@googlemaps/react-wrapper'
import GoogleMaps from '../components/GoogleMaps'
import GlobalState from '../contexts/GlobalState';

export default function Locations() {
    const {
        generalDataGlobal
    } = useContext(GlobalState);

    const [generalData, ] = generalDataGlobal;

    const [updateLocation, ] = useState(1);

    return (
        <Layout activePage='locations'>
            <div className='row'>
                <div className='col'>
                    <h2 className='mb-3'>
                        {generalData?.website_titles['locations']}
                    </h2>
                </div>
            </div>
            <div className='costs-box-styling position-relative height-100 d-flex flex-column bg-white rounded'>
                <div className='container-fluid'>
                    <div className='row'>
                        {
                            generalData?.users?.map((user,index) => (
                                user?.current_location &&
                                <div key={index} className="col-lg-6 py-3 ">
                                    <div className='shadow-primary w-100 h-100  bg-main'>
                                        <div className='row justify-content-between p-3'>
                                            <div className="col-auto">
                                                <div className='d-flex align-items-center'>
                                                    <img style={{ maxWidth: '50px', maxHeight: '50px' }} className="profile-image-general border-0" src={user.picture ? user.full_path_picture : generalData?.fixed_settings?.full_path_no_profile_picture} alt="profile" />
                                                    <p className="m-0 ms-3 font-semi-bold">{user?.name}</p>
                                                </div>
                                            </div>
                                            <div className='col-auto'>
                                                <div className='d-flex align-items-center h-100 flex-column justify-content-center'>
                                                    <p>{user?.current_location}</p>
                                                    <p>{user?.location_date}</p>
                                                </div>
                                            </div>


                                        </div>
                                        <div className="w-100 ">
                                            {
                                                updateLocation ?
                                                    <>
                                                        <Wrapper apiKey="AIzaSyDtj6MVZsopQI4aztiRpP9-M44URr6TCXw">
                                                            <GoogleMaps center={{ lat: user?.lat ? Number(user?.lat) : 33.8775484, lng: user?.lng ? Number(user?.lng) : 35.4615153 }} zoom={user?.lng ? 16 : 9} />
                                                        </Wrapper>
                                                    </>

                                                    :
                                                    null
                                            }
                                        </div>
                                    </div>
                                </div>



                            ))
                        }
                        {

                            generalData?.users?.filter(user => (user?.current_location)).length === 0 &&
                            <div>
                                <p>{generalData?.website_titles['no-users-locations-available']}</p>
                            </div>
                        }

                    </div>
                </div>
            </div>
        </Layout>
    )
}
