import React, { useState } from 'react'
import { useContext } from 'react'
import GlobalState from '../contexts/GlobalState'
import TaskBoxComponent from './TaskBoxComponent';
import { AddIcon, XIcon } from './Icons';
import TaskPopupComponent from './TaskPopupComponent';
import Api from './Api';
import { useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Link } from 'react-router-dom';


export default function BoardViewComponent({ cols, setCols, activeTasksType, dueTodayTasks, setDueTodayTasks }) {
    const { generalDataGlobal: [generalData] } = useContext(GlobalState);
    const [apiOptions, setApiOptions] = useState({});
    const [addPopup, setAddPopup] = useState(false);
    const [popupTitle, setPopupTitle] = useState()
    const [defaultSelectedStatus, setDefaultSelectedStatus] = useState();
    const [editTask, setEditTask] = useState();
    const [deleteTask, setDeleteTask] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [, setDraggingDestination] = useState(null);
    const [, setIsDraging] = useState(false);

    const handleAddClick = (e, type, status = null) => {
        e.preventDefault();
        setAddPopup({
            open: true,
            type: type,
        });
        setPopupTitle(generalData?.website_titles[type]);
        if (status) {
            setDefaultSelectedStatus(status);
        }
    }

    const onSuccess = (res) => {
        setAddPopup(null);
        setCols(res?.data?.cols);
        setDueTodayTasks(res?.data?.dueTodayTasks)

    }

    const handleEditClick = (task) => {
        setEditTask(task);
        setAddPopup(
            {
                open: true,
                type: 'task'
            }
        )
    }

    const deleteFunction = (task) => {
        setDeleteTask(task);
    }

    const submitDelete = (task) => {
        setApiOptions({
            url: 'delete-task',
            method: 'post',
            data: {
                task_id: deleteTask?.id,
                type: activeTasksType
            },
            callback: (res) => {
                setCols(res?.data?.cols);
                setDueTodayTasks(res?.data?.dueTodayTasks)

                setDeleteTask(null);
            }
        })
    }

    const handleAssigneesChange = () => {
        setApiOptions({
            url: 'get-tasks',
            method: 'post',
            data: {
                type: activeTasksType
            },
            showLoader: false,
            callback: ({ data }) => {
                setCols(data?.cols);
                setDueTodayTasks(data?.dueTodayTasks)

            }
        })
    }


    const onDragEnd = (res) => {

        const { destination, source, draggableId } = res;
        setDraggingDestination(null);
        setIsDraging(false);


        if (!destination) return;

        if (Number(destination?.droppableId) === Number(source?.droppableId) && Number(destination?.index) === Number(source?.index)) return

        if (Number(source?.droppableId) === Number(destination?.droppableId)) {

            const column = cols?.filter(col => Number(col?.id) === Number(source?.droppableId))[0];
            const draggedTask = column?.tasks?.filter(task => Number(task?.id) === Number(draggableId))[0]
            const colTasks = Array.from(column?.tasks);


            colTasks?.splice(source?.index, 1)
            colTasks?.splice(destination?.index, 0, draggedTask)
            let newCols = cols?.map(col => {
                if (Number(col?.id) === Number(destination?.droppableId)) {
                    return { ...col, tasks: colTasks }
                } else {
                    return col;
                }
            })

            setCols(newCols)

            setApiOptions({
                url: 'update-cols-tasks',
                data: { cols: newCols },
                method: 'post',
                showLoader: false,
                callback: (res) => {
                    setCols(res?.data?.cols);
                    setDueTodayTasks(res?.data?.dueTodayTasks)
                }
            })
            setDraggingDestination(null);
        } else {
            let sourceCol = cols?.find(col => Number(col?.id) === Number(source?.droppableId));
            let destCol = cols?.find(col => Number(col?.id) === Number(destination?.droppableId));
            let draggedTask = sourceCol?.tasks?.find(task => Number(task?.id) === Number(draggableId))

            let newSourceColTasks = Array.from(sourceCol?.tasks);
            newSourceColTasks?.splice(source?.index, 1);
            sourceCol = { ...sourceCol, tasks: newSourceColTasks };

            let newDestColTasks = Array.from(destCol?.tasks);
            newDestColTasks?.splice(destination?.index, 0, draggedTask);

            destCol = { ...destCol, tasks: newDestColTasks };

            let newCols = cols?.map(col => {
                if (Number(col?.id) === Number(sourceCol?.id)) {
                    return sourceCol;
                } else if (Number(col?.id) === Number(destCol?.id)) {
                    return destCol;
                } else {
                    return col;
                }
            })

            setCols(newCols)
            setApiOptions({
                url: 'update-cols-tasks',
                data: { cols: newCols },
                method: 'post',
                showLoader: false,
                callback: (res) => {
                    setCols(res?.data?.cols);
                    setDueTodayTasks(res?.data?.dueTodayTasks)

                }
            })
            setDraggingDestination(null);

        }
    }

    useEffect(() => {
        setIsLoading(false);
    }, [cols])

    const onDragUpdate = (res) => {
        const { destination } = res;
        setDraggingDestination(destination)
    }

    const onDragStart = (res) => {
        const { source } = res;
        setIsDraging(true);
        setDraggingDestination(source)
    }

    return (
        <>
            <Api options={apiOptions} />
            {
                addPopup?.open && addPopup?.type === 'task' &&
                <TaskPopupComponent
                    popupTitle={popupTitle}
                    setAddPopup={setAddPopup}
                    defaultSelectedStatus={defaultSelectedStatus}
                    onSuccess={onSuccess}
                    editTask={editTask}
                    setEditTask={setEditTask}
                    activeTasksType={activeTasksType}
                />
            }
            <DragDropContext onBeforeDragStart={onDragStart} onDragUpdate={onDragUpdate} onDragEnd={onDragEnd}>
                <div className='w-100  mt-2 column-container  overflow-auto table-scrollbar-x ' style={{ height: ' calc(100vh - 295px)', }}>
                    <div className='row py-2 flex-nowrap sticky-top bg-white' style={{ minWidth: 975, }}>
                        {
                            cols?.map((status, i) => (

                                <div className="col-3" style={{ maxHeight: '100%' }}>
                                    <div className='d-flex flex-column h-100 overflow-hidden'>
                                        <div className='d-flex align-items-center px-2'>
                                            <h6 className='mb-1 me-3 fw-700'>
                                                {status?.title}
                                            </h6>
                                            <div className='text-gray circle border-gray border d-flex align-items-center justify-content-center' style={{ width: 15, height: 15, }}>
                                                <p style={{ fontSize: 10 }} className='text-gray'>{status?.tasks?.length}</p>
                                            </div>
                                        </div>
                                        <div className='px-2'>
                                            <div className='w-100 shadow flex-shrink-0 mt-2' style={{ height: '5px', backgroundColor: status?.color }}></div>
                                        </div>
                                    </div>

                                </div>
                            ))
                        }
                    </div>
                    <div className="row flex-nowrap table-scrollbar-x" style={{ minWidth: 975 }}>

                        {
                            cols?.map((status, i) => (

                                <div className="col-3" style={{ maxHeight: '100%' }}>
                                    <div className='d-flex flex-column h-100 overflow-hidden'>

                                        {/* <div className='d-flex align-items-center px-2'>
                                            <h6 className='mb-1 me-1 fw-700'>
                                                {status?.title}
                                            </h6>
                                            <div className='text-gray circle border-gray border d-flex align-items-center justify-content-center' style={{ width: 15, height: 15, }}>
                                                <p style={{ fontSize: 10 }} className='text-gray'>{status?.tasks?.length}</p>
                                            </div>
                                        </div>

                                        <div className='w-100 shadow flex-shrink-0 mx-2 mt-2' style={{ height: '5px', backgroundColor: status?.color }}></div> */}
                                        {
                                            !isLoading &&
                                            <Droppable key={status.id} droppableId={`${status?.id}`}>
                                                {(provided) => (
                                                    <div
                                                        {...provided?.droppableProps}
                                                        ref={provided?.innerRef}
                                                        className='tasks-container flex-grow-1 pt-2 px-2 table-scrollbar-x'
                                                        style={{
                                                            maxHeight: 'calc(100% - 32px )',
                                                        }}
                                                    >
                                                        {
                                                            status?.tasks?.map((task, i) => (
                                                                <Draggable key={task?.id} draggableId={`${task?.id}`} index={i}>
                                                                    {(provided, snapshot) => (
                                                                        <div
                                                                            {...provided?.draggableProps}
                                                                            {...provided?.dragHandleProps}
                                                                            ref={provided?.innerRef}
                                                                            className={snapshot?.isDragging ? 'is-dragging' : ''}

                                                                        >
                                                                            <Link to={`${task?.id}/general-info`}>
                                                                                <TaskBoxComponent
                                                                                    task={task}
                                                                                    index={i}
                                                                                    editFunction={handleEditClick}
                                                                                    deleteFunction={deleteFunction}
                                                                                    setCols={setCols}
                                                                                    activeTasksType={activeTasksType}
                                                                                    handleAssigneesChange={handleAssigneesChange}
                                                                                    dueTodayTasks={dueTodayTasks}
                                                                                    setDueTodayTasks={setDueTodayTasks}
                                                                                />
                                                                            </Link>

                                                                        </div>
                                                                    )}


                                                                </Draggable>

                                                            ))
                                                        }
                                                        {provided?.placeholder}

                                                        <button className='active btn border-dashed border-primary w-100 my-3 py-3' onClick={(e) => { handleAddClick(e, 'task', status) }}><AddIcon className={`active`} /></button>
                                                    </div>
                                                )}


                                            </Droppable>
                                        }


                                    </div>
                                </div>


                            ))}
                    </div>
                </div >
            </DragDropContext>
            {
                //delete litigation popup
                deleteTask &&
                <div id="errors-popup" className=" error-popup-api d-flex align-items-center justify-content-center px-3 fade-in">
                    <div className="pop-up-api-box">
                        <XIcon onClick={() => setDeleteTask(null)} />
                        <div className="text-center ">
                            <div className="containter-fluid">
                                <div className="row d-flex justify-content-center align-items-center">
                                    <div className="col-9 col-sm-12 d-flex justify-content-center">
                                        <h1 className="pop-up-title m-0 pt-3 color-dark-blue">{generalData?.settings_section_settings['delete-popup-title']}</h1>
                                    </div>
                                    <div className="col-12 d-flex justify-content-center pt-4 pb-2">
                                        <button onClick={() => submitDelete(deleteTask ? deleteTask : null)} type="button" className="popup-button my-auto mx-2 w-auto px-3">{generalData?.settings_section_settings['delete-popup-first-button']}</button>
                                        <button onClick={() => { setDeleteTask(null) }} type="button" className="auth-button border-dark-blue my-auto mx-2 background-transparent w-auto px-3">{generalData?.settings_section_settings['delete-popup-second-button']}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            }
        </>
    )
}
