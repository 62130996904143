import { EditPencil } from "./Icons";

export default function EditPictureInput(props) {

    return (
        <div className="row justify-content-center align-items-center pt-2">
            <div className="col-6">
                <h5 className="locked-inputs border-hidden w-100 m-0 p-0">{props?.title}</h5>
            </div>
            <div className="col-6 d-flex justify-content-center pb-3">
                <div
                    onClick={() => { props?.setSelectedEditPicture(props?.image); props?.setUploadImagePopup(props?.field) }}
                    className="position-relative">
                    <img style={{ width: '60px', height: '60px' }} className="profile-image-general" src={props?.image} alt="profile" />
                    <div className="edit-prof-back">
                        <EditPencil />
                    </div>
                </div>
            </div>
        </div>
    )
}