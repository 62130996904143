import React, { useContext } from 'react';
import GlobalState from '../contexts/GlobalState';
import { CloseIcon } from './Icons';

export default function RejectTransactionPopup({transactionRejectReason, setTransactionRejectReason, cancelStatusTransaction, submitRejectTransaction, errorsReturned  }) {
    const {generalDataGlobal :[generalData]} = useContext(GlobalState);


    return (
        <>
            <div id="errors-popup" className=" error-popup-api d-flex align-items-center justify-content-center px-3 fade-in">
                <div className="pop-up-api-box">
                    <CloseIcon className="cursor-pointer primary-color" onClick={(e) => {e.stopPropagation();cancelStatusTransaction(e)}} />
                    <div className="text-center ">
                        <div className="containter-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <h1 className="pop-up-title m-0 py-2 color-dark-blue">{generalData?.users_cost_settings['reject-popup-title']}</h1>
                                    <h4 style={{ fontSize: "14px", fontWeight: "500" }} className="pop-up-title m-0 py-2 color-dark-blue">{generalData?.users_cost_settings['reject-popup-subtitle']}</h4>
                                </div>
                                <form onSubmit={(e)=>{submitRejectTransaction(e, transactionRejectReason)}}>
                                    <div className="col-12 pt-2 pb-4">
                                        <div className="row">
                                            <div className="col-12 pt-2">
                                                <textarea required type="text-area" name="note" className="add-user-inputs" placeholder={generalData?.users_cost_settings['reject-placeholder']} value={transactionRejectReason} onChange={(e) => setTransactionRejectReason(e.target.value)}></textarea>
                                                {
                                                    errorsReturned?.rejection_reason ?
                                                        <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text">{errorsReturned?.rejection_reason}</h5>
                                                        :
                                                        null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 d-flex justify-content-center">
                                        <button type="submit" className="popup-button my-auto">{generalData?.users_cost_settings['reject']}</button>
                                        <button onClick={(e) =>  {e.stopPropagation();cancelStatusTransaction(e)}} type="button" className="auth-button border-dark-blue my-auto mx-2 background-transparent w-auto px-3">{generalData?.users_cost_settings['cancel']}</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
