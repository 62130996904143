import { Link } from "react-router-dom";
import { useContext } from "react";
import GlobalState from '../contexts/GlobalState';
import { Droppable } from 'react-drag-and-drop';

// this component will handle breacrumbs
export default function Breadcrumb(props) {
    const { dropElementGlobal, movePopupGlobal } = useContext(GlobalState);
    const [ , setDropElememt] = dropElementGlobal;
    const [ , setMovePopup] = movePopupGlobal;

    // handle drop element 
    const onDrop = (e, single) => {
        setDropElememt(single);
        setMovePopup(true);
    };

    return (
        <>
            {
                props.breadcrumb ?
                    <>
                        {
                            props?.breadcrumb?.parent?.id ?
                                <Breadcrumb currentFolder={props.currentFolder} mainRoute={props.mainRoute} subRoute={props.subRoute} currentUser={props.currentUser} breadcrumb={props.breadcrumb?.parent} />
                                :
                                null
                        }
                        <Link className="lower-op" to={'/' + props.mainRoute + '/' + props.currentUser + '/' + props.subRoute + '/' + props.breadcrumb?.id}>
                            <Droppable className="d-block lower-opacity" types={['folder', 'file']} onDrop={(e) => onDrop(e, props.breadcrumb)}>
                                <h1 className="m-0">
                                    /{props.breadcrumb?.folder_name}
                                </h1>
                            </Droppable>
                        </Link> &nbsp;
                    </>
                    :
                    null
            }
        </>
    )
}