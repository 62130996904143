import React, { useContext, useEffect, useRef, useState } from 'react';
import GlobalState from '../contexts/GlobalState';
import "react-datepicker/dist/react-datepicker.css";
import Api from './Api';
import ReminderPopup from './ReminderPopup';
import { formatDate, formatDateTime } from '../Helpers';

import { registerLocale } from "react-datepicker";
import ar from 'date-fns/locale/ar';
import { CloseIcon, LockIcon, SnoozeIcon, ThreeDotsMenu } from './Icons';
import UsersSelect from './UsersSelect';
import { useNavigate } from 'react-router-dom';
import CustomSnoozePopupComponent from './CustomSnoozePopupComponent';
registerLocale('ar', ar)

export default function ReminderCard(props) {
    const { returnUser, onActionSubmit = ()=>{} } = props
    const [height, setHeight] = useState(0);
    const elementRef = useRef(null);

    const {
        authUserGlobal,
        profileUserGlobal,
        profileClientGlobal,
        currentReminderGlobal,
        generalDataGlobal,
        currentReminderEditGlobal,
        setActiveMatter
    } = useContext(GlobalState);
    const [, setProfileClient] = profileClientGlobal;
    const [currentReminder, setCurrentReminder] = currentReminderGlobal;
    const [currentReminderEditGl, setCurrentReminderEditGl] = currentReminderEditGlobal;
    const [generalData,] = generalDataGlobal;
    const [generalDataApiOptions, setGeneralDataApiOptions] = useState({});
    const [authUser,] = authUserGlobal;
    const [deletePopup, setDeletePopup] = useState('');
    const [snoozeType, setSnoozeType] = useState('');
    const [snoozeDate, setSnoozeDate] = useState('');
    const [customDatesPopup, setCustomDatesPopup] = useState(false);
    const [activeEditUsers, setActiveEditUsers] = useState('');
    const [ , setProfileUser] = profileUserGlobal;

    const navigate = useNavigate();

    //get current element height
    useEffect(() => {
        setHeight(elementRef.current.scrollHeight);
    }, [currentReminder?.id]);

    //reset reminder states
    useEffect(() => {
        props.setCurrentReminderEdit(null);
        props.setCurrentReminderSnooze(null);
        setCurrentReminderEditGl(null);
        setActiveEditUsers('');
    }, [currentReminder]);// eslint-disable-line react-hooks/exhaustive-deps

    //check if a value is in array
    const checkInArray = (arra, key, val) => {
        let x = 0;
        arra.forEach(element => {
            if (element[key] === val) {
                x++;
            }
        });
        if (x > 0) {
            return true;
        } else {
            return false;
        }
    }

    //handle snooze cases
    useEffect(() => {
        if (snoozeType) {
            if (snoozeType !== 3) {
                submitSnooze();
            }
            if (snoozeType === 3) {
                setCustomDatesPopup(true);
            }
        }
    }, [snoozeType]);// eslint-disable-line react-hooks/exhaustive-deps

    //close active edit users when date changes
    useEffect(() => {
        setActiveEditUsers('');
    }, [props.selectedDate]);

    //snooze backend request
    const submitSnooze = () => {
        let fd = new FormData();

        if (props?.currentReminderSnooze?.id) {
            fd.append('reminder_id', props?.currentReminderSnooze?.id);
        }

        fd.append('snooze_type', snoozeType);

        if (props.clientId) {
            fd.append('client_id', props?.clientId);
            fd.append('user_id', props?.clientId);
        }

        if (props?.userId) {
            fd.append('user_id', props?.userId);
        }

        if (props?.litigationId) {
            fd.append('litigation_id', props?.litigationId);
        }
        if(props?.taskId){
            fd.append('litigation_id', props?.litigationId);
        }
        fd.append('reminder_date_selected', props.selectedDate ? formatDate(props.selectedDate) : '');

        if (snoozeDate) {
            fd.append('snooze_date', formatDateTime(snoozeDate));
        }
        if (returnUser) {
            fd.append('return_user', 1);
        }
        setGeneralDataApiOptions({
            url: 'snooze-reminder',
            method: 'post',
            data: fd,
            callback: res => {
                if (props.clientId) {
                    setProfileClient(res?.data);
                }
                if (returnUser) {
                    setProfileUser(res?.data);
                }
                if (props?.litigationId) {
                    setActiveMatter(res.data);
                }
                setCurrentReminderEditGl(null);
                props.setCurrentReminderSnooze(null);
                props.setCurrentReminderEdit(null);
                setSnoozeType('');
                setSnoozeDate('');
                setCurrentReminder(null);
                setCustomDatesPopup(false);
            },
            catch: err => {
                if (err) {
                    setSnoozeType('');
                    setSnoozeDate('');
                }
            }
        });
    }

    //reset reminder states
    const cancelDeleteReminder = (e) => {
        setCurrentReminderEditGl(null);
        props.setCurrentReminderSnooze(null);
        props.setCurrentReminderEdit(null);
        setSnoozeType('');
        setSnoozeDate('');
        setCurrentReminder(null);
        setCustomDatesPopup(false);
        setDeletePopup('');
        setActiveEditUsers('');
    }

    //delete reminder backend request
    const deleteAction = (e) => {
        let fd = new FormData();

        if (props?.singleRem?.id) {
            fd.append('reminder_id', props?.singleRem?.id);
        }

        if (props.clientId) {
            fd.append('client_id', props?.clientId);
            fd.append('user_id', props?.clientId);
        }

        if (props?.userId) {
            fd.append('user_id', props?.userId);
        }

        if (props?.litigationId) {
            fd.append('litigation_id', props?.litigationId);
        }

        if (returnUser) {
            fd.append('return_user', 1);
        }

        fd.append('reminder_date_selected', props.selectedDate ? formatDate(props.selectedDate) : '');

        setGeneralDataApiOptions({
            url: 'delete-reminder',
            method: 'post',
            data: fd,
            callback: res => {
                if (props.clientId) {
                    setProfileClient(res?.data);
                }
                if (props?.userId) {
                    setProfileUser(res?.data);
                }
                if (props?.litigationId) {
                    setActiveMatter(res?.data);
                }

                if(!returnUser){
                    onActionSubmit();
                }
                setCurrentReminderEditGl(null);
                props.setCurrentReminderSnooze(null);
                props.setCurrentReminderEdit(null);
                setSnoozeType('');
                setSnoozeDate('');
                setCurrentReminder(null);
                setCustomDatesPopup(false);
                setDeletePopup('');
            },
            catch: err => {
                if (err) {
                    setSnoozeType('');
                    setSnoozeDate('');
                }
            }
        });
    }

    return (
        <>
            <div className={'faqs position-relative reminder-cards ' + (props?.singleRem?.id === currentReminder?.id ? ' active ' : ' ') + (props?.indRem !== 0 ? ' mt-3 ' : ' ')} onClick={() => { props.singleRem?.id === currentReminder?.id ? setCurrentReminder(null) : setCurrentReminder(props.singleRem); setHeight(elementRef.current.clientHeight) }}>
                {
                    (authUser?.id === props.singleRem?.user?.id || checkInArray(props?.singleRem?.remind_users, 'id', authUser?.id)) ?
                        <>
                            {
                                !props?.singleRem?.hearing_id &&
                                <>
                                    <div>

                                        <ThreeDotsMenu className="folders-buttons" onClick={(e) => { e.preventDefault(); e.stopPropagation(); props.setCurrentReminderSnooze(null); props.setCurrentReminderEdit(props.currentReminderEdit?.id === props.singleRem?.id ? null : props.singleRem) }} />
                                    </div>

                                    <div style={{ zIndex: '999' }} className={"settings-clickmenu text-start " + (props?.currentReminderEdit?.id === props?.singleRem?.id ? ' d-block' : '')}>
                                        <p style={{ zIndex: "999" }} onClick={(e) => { e.stopPropagation(); e.preventDefault(); setCurrentReminderEditGl(props?.singleRem) }} className="p-0 py-1 m-0 ms-2">{generalData?.settings_section_settings['edit-title']}</p>
                                        <p style={{ zIndex: "999" }} onClick={(e) => { e.stopPropagation(); setDeletePopup(props.singleRem); }} className="p-0 py-1 m-0 ms-2">{generalData?.settings_section_settings['delete-title']}</p>
                                    </div>
                                    <div onClick={(e) => { e.preventDefault(); e.stopPropagation(); props.setCurrentReminderEdit(null); props.setCurrentReminderSnooze(props.currentReminderSnooze?.id === props.singleRem?.id ? null : props.singleRem) }} className="component-snooze-button">

                                        <SnoozeIcon />
                                    </div>

                                    <div style={{ zIndex: '999' }} className={"snooze-clickmenu text-start py-2 px-2 " + (props?.currentReminderSnooze?.id === props?.singleRem?.id ? ' d-block' : '')}>
                                        <p className="snooze-title m-0 pb-2">{generalData?.website_titles['snooze-until']}</p>
                                        {
                                            generalData?.snooze_types.map((singleType, snoozeInd) => (
                                                <div key={snoozeInd} onClick={(e) => { e.stopPropagation(); e.preventDefault(); setSnoozeType(singleType?.id) }} className="row justify-content-between">
                                                    <div className="col-auto">
                                                        <p key={snoozeInd} style={{ zIndex: "999" }} className="p-0 py-1 m-0 snooze-details">{singleType?.title}</p>
                                                    </div>
                                                    {
                                                        singleType?.explanation ?
                                                            <div className="col-auto">
                                                                <p style={{ zIndex: "999" }} className="p-0 py-1 m-0 snooze-details snooze-gray">{singleType?.explanation}</p>
                                                            </div>
                                                            :
                                                            null
                                                    }
                                                </div>
                                            ))
                                        }
                                    </div>
                                </>
                            }

                        </>
                        :
                        null
                }
                <div className={'accordion-title'} onClick={() => setCurrentReminder(currentReminder?.id === props.singleRem?.id ? null : props.singleRem)}>
                    <div className="row justify-content-between position-relative">
                        <div className="col-12 col-md-4">
                            <div
                                className='d-flex'

                            >
                                <p
                                    className="fw-700 m-0"
                                    onClick={() => {
                                        if (props.singleRem?.litigation_id) {
                                            navigate(`/matters/litigations/${props.singleRem?.litigation_id}/general-info`)
                                        }
                                    }}
                                >

                                    {props.singleRem?.name}
                                </p>
                            </div>
                        </div>
                        <div className="col-12 col-md-3">
                            <p className="m-0">{props.singleRem?.reminder_type?.title}</p>
                        </div>
                        <div className="col-12 col-md-4">
                            <p className="m-0 color-grey-light">{props.singleRem?.date_am_pm}</p>
                        </div>
                        <div className="col-12 col-md-1">
                        </div>
                    </div>
                    <div className="col-12">
                        {
                            currentReminder?.id === props.singleRem?.id ?
                                <div style={{ height: height }} className={currentReminder?.id === props.singleRem?.id ? 'accordion-content open' : 'accordion-content'}>
                                    <div ref={elementRef} className="row">
                                        <div className="col-12 col-md-4 py-2">
                                            <p className="m-0 ">
                                                <LockIcon className="me-1" style={{ position: 'relative', top: '-1px', width: 'auto', height: '12px' }} />
                                                {props.singleRem?.user?.name}
                                            </p>
                                        </div>
                                        <div className="col-12 col-md-4 my-auto ps-4 ps-md-auto">
                                            <UsersSelect
                                                editable={!props?.singleRem?.hearing_id}
                                                returnUser={returnUser}
                                                selectedDate={props.selectedDate}
                                                url={'edit-users-reminder'}
                                                activeEditUsers={activeEditUsers}
                                                setActiveEditUsers={setActiveEditUsers}
                                                data={props.singleRem}
                                                users={props.singleRem?.remind_users}
                                                clientId={props.clientId}
                                                userId={props.userId || props?.clientId}
                                                onChange={setProfileUser}
                                            />
                                        </div>
                                        <div className="col-12">
                                            <p className='m-0 pt-1' >{props.singleRem?.summary}</p>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className={currentReminder?.id === props.singleRem?.id ? 'accordion-content' : 'accordion-content'}>
                                    <div ref={elementRef} className="row">
                                        <div className="col-12 col-md-4 py-2">
                                            <p className="m-0 ">
                                                <LockIcon className="me-1" style={{ position: 'relative', top: '-1px', width: 'auto', height: '12px' }} />
                                                {props.singleRem?.user?.name}
                                            </p>
                                        </div>
                                        <div className="col-12">
                                            <p className='m-0 pt-1'>{props.singleRem?.summary}</p>
                                        </div>
                                    </div>
                                </div>
                        }
                    </div>
                </div>
            </div>
            {
                //reminder popup
                currentReminderEditGl?.id === props?.singleRem?.id ?
                    <ReminderPopup
                        selectedDate={props.selectedDate}
                        setCurrentReminderSnooze={props.setCurrentReminderSnooze}
                        currentReminderSnooze={props.currentReminderSnooze}
                        setCurrentReminderEdit={props.setCurrentReminderEdit}
                        currentReminderEdit={props.currentReminderEdit}
                        currentselectedDate={props.selectedDate}
                        clientId={props.clientId}
                        litigationId={props.litigationId}
                        onSuccess={onActionSubmit}
                        returnUser={returnUser ? true : false}
                    />
                    :
                    null
            }
            {
                //custom dates for snooze popup
                customDatesPopup && (props.currentReminderSnooze?.id === props.singleRem?.id) &&
                <CustomSnoozePopupComponent
                    setCustomSnoozePopup={setCustomDatesPopup}
                    setCurrentReminderEdit={props?.setCurrentReminderEdit}
                    setCurrentReminderSnooze={props?.setCurrentReminderSnooze}
                    setCurrentReminder={setCurrentReminder}
                    submitSnooze={submitSnooze}
                    snoozeDate={snoozeDate}
                    setSnoozeDate={setSnoozeDate}
                />
            }
            {
                //delete reminder popup
                deletePopup && (props.singleRem?.id === deletePopup?.id) ?
                    <div id="errors-popup" className=" error-popup-api d-flex align-items-center justify-content-center px-3 fade-in">
                        <div className="pop-up-api-box">
                            <CloseIcon className="cursor-pointer" onClick={(e) => cancelDeleteReminder(e)} />
                            <div className="text-center ">
                                <div className="containter-fluid">
                                    <div className="row d-flex justify-content-center align-items-center">
                                        <div className="col-9 col-sm-12 d-flex justify-content-center">
                                            <h1 className="pop-up-title m-0 pt-3 color-dark-blue">{generalData?.settings_section_settings['delete-popup-title']}</h1>
                                        </div>
                                        <div className="col-12 d-flex justify-content-center pt-4 pb-2">
                                            <button onClick={(e) => deleteAction(e, props.singleRem)} type="button" className="popup-button my-auto mx-2 w-auto px-3">{generalData?.settings_section_settings['delete-popup-first-button']}</button>
                                            <button onClick={(e) => { cancelDeleteReminder(e) }} type="button" className="auth-button border-dark-blue my-auto mx-2 background-transparent w-auto px-3">{generalData?.settings_section_settings['delete-popup-second-button']}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    null
            }
            <Api options={generalDataApiOptions} />
        </>
    )
}