import React, { useRef, useState } from 'react'
import { useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import GlobalState from '../contexts/GlobalState';
import AccordionComponent from './AccordionComponent'
import EditInput from './EditInput';
import Select from 'react-select';
import { addYears, formatDate, getOptions } from "../Helpers";
import { AddIconLt, CloseIcon, FileIcon, RemoveFd } from './Icons';
import { useEffect } from 'react';
import CardComponent from './CardComponent';
import UsersSelect from './UsersSelect';
import useOutsideClick from '../useOutsideClick';
import HearingPopupComponent from './HearingPopupComponent';
import DatePicker from "react-datepicker";
import AddStagePopupComponent from './AddStagePopupComponent';
import { getIndex } from '../Helpers'

import Api from './Api';
import HearingCommonPopup from '../pages/HearingCommonPopup';


export default function LitigationStageActivitiesComponent() {
    const {
        generalDataGlobal: [generalData],
        changeInputGlobal,
        currentInputGlobal,
        currentInputValueGlobal,
        currentInputValidationsGlobal,
        profileClientGlobal,
        activeMatter, setActiveMatter,
        authUserGlobal: [authUser],
    } = useContext(GlobalState);

    const navigate = useNavigate();
    let { user_id, type, matter_id } = useParams();

    const [profileClient, setProfileClient] = profileClientGlobal;
    const [changeInput, setChangeInput] = changeInputGlobal;

    const [currentInput, setCurrentInput] = currentInputGlobal;
    const [currentInputValue, setCurrentInputValue] = currentInputValueGlobal;
    const [currentInputValidations,] = currentInputValidationsGlobal;

    const [openId, setOpenId] = useState(-1);
    const [generalDataApiOptions, setGeneralDataApiOptions] = useState({});
    const [formData, setFormData] = useState([]);
    const [deletePopup, setDeletePopup] = useState();
    const [errorsReturned, setErrorsReturned] = useState();
    const [activeEditUsers, setActiveEditUsers] = useState('');
    const [target, setTarget] = useState();
    const [addMatterPopup, setAddMatterPopup] = useState({ open: false });
    const [editHearing, setEditHearing] = useState();
    const [popupTitle, setPopupTitle] = useState(generalData?.matterTypes[1]?.title);
    const [verdictDate, setVerdictDate] = useState('');
    const [hearingDate, setHearingDate] = useState();
    const [lastHearingDate, setLastHearingDate] = useState();
    const [addStagePopup, setAddStagePopup] = useState(false);
    const [selectedCourtType, setSelectedCourtType] = useState();
    const [selectedStage, setSelectedStage] = useState();
    const [excludeValues, setExcludeValues] = useState([]);
    const [stages, setStages] = useState([]);
    const cardRef = useRef();

    const [commonPopup, setCommonPopup] = useState(false);

    // const [popupTitle, setPopupTitle] = useState('')
    const [popupContent, setPopupContent] = useState(null);


    const courtTypes = getOptions(generalData?.courtTypes);

    const LitigationStages = getOptions(generalData?.litigationStages);

    useOutsideClick(cardRef, () => {
        setTarget(null);
    })

    //get individual realtions
    const individualRelations = () => {
        const relOptions = authUser?.individual_only?.map(
            singleRel => ({ type: singleRel.type, value: singleRel.slug + '-' + singleRel.id, label: singleRel?.title, slug: singleRel.slug, image: singleRel.image, client_type: singleRel.client_type }))
        return relOptions;
    }

    //get company relations
    const companyRelations = () => {
        const relOptions = authUser?.company_relations?.map(
            singleRel => ({ type: singleRel.type, value: singleRel.slug + '-' + singleRel.id, label: singleRel?.title, slug: singleRel.slug, image: singleRel.image, client_type: singleRel.client_type }))
        return relOptions;
    }

    //get ngro relations
    const ngoRelations = () => {
        const relOptions = authUser?.ngo_relations?.map(
            singleRel => ({ type: singleRel.type, value: singleRel.slug + '-' + singleRel.id, label: singleRel?.title, slug: singleRel.slug, image: singleRel.image, client_type: singleRel.client_type }))
        return relOptions;
    }


    useEffect(() => {
        setFormData(activeMatter);
        setSelectedCourtType(courtTypes[getIndex(activeMatter?.stages[openId]?.court_type_id, courtTypes, 'value')])
        setSelectedStage(LitigationStages[getIndex(activeMatter?.stages[openId]?.stage_id, LitigationStages, 'value')]);
        setStages(activeMatter?.stages.length > 0 ? activeMatter?.stages : '');
        setVerdictDate(activeMatter?.stages[openId]?.verdict_date ? new Date(activeMatter?.stages[openId]?.verdict_date) : '');
        setHearingDate(activeMatter?.stages[openId]?.hearing_date ? new Date(activeMatter?.stages[openId]?.hearing_date) : '')
        setLastHearingDate(activeMatter?.stages[openId]?.last_hearing_date ? new Date(activeMatter?.stages[openId]?.last_hearing_date) : '')

        let emptyObject = { ...formData, clients: [], opponents: [] };
        emptyObject['clients'] = activeMatter?.stages[openId]?.clients?.length > 0
            ?
            activeMatter?.stages[openId]?.clients.map((client, index) => (
                {
                    id: client.id,
                    selectedClient:
                        Number(client?.client?.client_type_id) === 1 ?
                            companyRelations()[getIndex('client-' + client?.client?.id, companyRelations(), 'value')]
                            :
                            Number(client?.client?.client_type_id) === 2 ?
                                individualRelations()[getIndex('client-' + client?.client?.id, individualRelations(), 'value')]
                                :
                                Number(client?.client?.client_type_id) === 3 ?
                                    ngoRelations()[getIndex('client-' + client?.client?.id, ngoRelations(), 'value')]
                                    :
                                    ''
                    ,
                    description: client.description
                }
            ))
            :
            [{ client: '', description: '' }];

        emptyObject['opponents'] = activeMatter?.stages[openId]?.opponents?.length > 0
            ?
            activeMatter?.stages[openId]?.opponents.map((opponent) => (
                {
                    id: opponent?.id,
                    selectedClient:
                        Number(opponent?.client?.client_type_id) === 1 ?
                            companyRelations()[getIndex('client-' + opponent?.client?.id, companyRelations(), 'value')]
                            :
                            Number(opponent?.client?.client_type_id) === 2 ?
                                individualRelations()[getIndex('client-' + opponent?.client?.id, individualRelations(), 'value')]
                                :
                                Number(opponent?.client?.client_type_id) === 3 ?
                                    ngoRelations()[getIndex('client-' + opponent?.client?.id, ngoRelations(), 'value')]
                                    :
                                    ''
                    ,
                    description: opponent.description
                }
            )) : [{ client: '', description: '' }];

        emptyObject['updates'] = activeMatter?.stages[openId]?.updates?.length > 0
            ?
            activeMatter?.stages[openId]?.updates.map((update) => (
                {
                    id: update?.id,
                    date: update.date,
                    description: update.description,
                    locked: update?.locked,
                }
            )) : [];

        setFormData((prev) => (
            {
                ...prev,
                clients: [
                    ...emptyObject.clients
                ],
                opponents: [
                    ...emptyObject.opponents
                ],

                updates: [
                    ...emptyObject.updates
                ],
            }
        ))

    }, [activeMatter, user_id, openId]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setExcludeValues([]);
    }, [formData]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (Number(excludeValues?.length) === 0) {
            if (formData?.clients) {
                formData?.clients?.map((client) => {
                    if (!excludeValues.includes(client?.selectedClient)) {
                        if (Number(client?.id) !== 0) {
                            setExcludeValues(prev => ([...prev, client?.selectedClient?.label]))
                        }
                    }
                    return null;
                })

            }
            if (formData?.opponents) {
                formData?.opponents?.map((opponent) => {
                    if (!excludeValues.includes(opponent?.selectedClient)) {
                        if (Number(opponent?.id) !== 0) {
                            setExcludeValues(prev => ([...prev, opponent?.selectedClient?.label]))
                        }
                    }
                    return null;
                })
            }
        }
    }, [excludeValues]);// eslint-disable-line react-hooks/exhaustive-deps

    //add new empty field to formdata by key
    const addToFormData = (key, autoFocus, callBack) => {
        let emptyObject = {};
        let added = 1;
        if (key === 'nationalities') {
            if (!profileClient?.nationality_id) {
                added = 0;
            }
            if (formData?.nationalities?.length > 0) {
                formData?.nationalities?.forEach(single => {
                    if (single?.nationality_id === null || single?.nationality_id === '') {
                        added = 0;
                    }
                });
            }
            if (added === 1) {
                emptyObject = { id: null, nationality_id: null };
                let newFormData = { ...formData };
                newFormData[key].push(emptyObject);
                setFormData(newFormData);
            }
        }

        if (key === 'emails') {
            if (!profileClient?.email) {
                added = 0;
            }
            if (formData?.emails?.length > 0) {
                formData?.emails?.forEach(single => {
                    if (single?.email === null || single?.email === '') {
                        added = 0;
                    }
                });
            }
            if (added === 1) {
                emptyObject = { id: 0, email: '' };
                let newFormData = { ...formData };
                newFormData[key].push(emptyObject);
                setFormData(newFormData);
            }
        }

        if (key === 'phones') {
            if (!profileClient?.main_phone_number) {
                added = 0;
            }
            if (formData?.phones?.length > 0) {
                formData?.phones?.forEach(single => {
                    if (single?.phone === null || single?.phone === '') {
                        added = 0;
                    }
                });
            }
            if (added === 1) {
                emptyObject = { id: 0, phone: '' };
                let newFormData = { ...formData };
                newFormData[key].push(emptyObject);
                setFormData(newFormData);
            }
        }

        if (key === 'addresses') {
            if (!profileClient?.main_address) {
                added = 0;
            }
            if (formData?.addresses?.length > 0) {
                formData?.addresses?.forEach(single => {
                    if (single?.address === null || single?.address === '') {
                        added = 0;
                    }
                });
            }
            if (added === 1) {
                emptyObject = { id: 0, address: '' };
                let newFormData = { ...formData };
                newFormData[key].push(emptyObject);
                setFormData(newFormData);
            }
        }

        if (key === 'assignees') {
            if (!activeMatter?.assignee) {
                added = 0;
            }
            if (formData?.assignees?.length > 0) {

                formData?.assignees?.forEach(single => {
                    if (single?.name === null || single?.name === '') {

                        added = 0;
                    }
                });
            }
            if (added === 1) {
                emptyObject = { id: 0, name: '', description: '' };
                let newFormData = { ...formData };
                newFormData[key].push(emptyObject);
                setFormData(newFormData);
            }
        } else {
            if (!formData[key]) {
                added = 0;
            }
            if (formData[key]?.length > 0) {

                formData[key]?.forEach(single => {
                    if (single?.name === null || single?.name === '') {
                        added = 0;
                    }
                });
            }
            if (added === 1) {
                emptyObject = { id: 0, name: '', description: '' };
                let newFormData = { ...formData };
                newFormData[key].push(emptyObject);
                setFormData(newFormData);
            }
        }
        if (autoFocus) {
            setTimeout(() => {
                setCurrentInput(key)
                setCurrentInputValue({
                    ...currentInputValue, id: 0, name: '', description: '',
                    relationModel: 'StageUpdate',
                    relationField: 'stage_id',
                    firstInputName: 'date',
                    firstInputValue: formatDate(new Date()),
                    secondInputName: 'description',
                    secondInputValue: '',
                })
            }, 100)
        }


    }

    const handleAccordionClick = (e, i) => {
        e.preventDefault();
        setOpenId(Number(openId) === Number(i) ? -1 : i)
        setCurrentInput('')
    }

    const removeFormData = (single, index, key, model) => {
        setErrorsReturned({});
        if (key === 'nationalities') {
            if (!formData?.nationalities[index]?.id) {
                let newFd = { ...formData };
                newFd[key].splice(index, 1);
                setFormData(newFd);
            }
            else {
                setDeletePopup({ single, index, key });
            }
        }
        if (key === 'emails') {
            if (!formData?.emails[index]?.id) {
                let newFd = { ...formData };
                newFd[key].splice(index, 1);
                setFormData(newFd);
            }
            else {
                setDeletePopup({ single, index, key });
            }
        }
        if (key === 'phones') {
            if (!formData?.phones[index]?.id) {
                let newFd = { ...formData };
                newFd[key].splice(index, 1);
                setFormData(newFd);
            }
            else {
                setDeletePopup({ single, index, key });
            }
        }
        if (key === 'addresses') {
            if (!formData?.addresses[index]?.id) {
                let newFd = { ...formData };
                newFd[key].splice(index, 1);
                setFormData(newFd);
            }
            else {
                setDeletePopup({ single, index, key });
            }
        }
        if (!formData[key][index]?.id) {
            let newFd = { ...formData };
            newFd[key].splice(index, 1);
            setFormData(newFd);
        }
        else {
            setDeletePopup({ single, index, key, model });
        }
    }
    //custom select styles
    const customStyles = {
        placeholder: (defaultStyles) => {
            return {
                ...defaultStyles,
                display: "flex",
                fontFamily: 'Poppins',
                fontWeight: '400',
                fontSize: '16px',
                color: '#707070 !important',
                border: 'none !important',
                minHeight: "26px",
                maxHeight: "26px",
            }
        },
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                display: "flex",
                color: '#00366A',
                fontFamily: 'Poppins',
                fontWeight: '400',
                backgroundColor: 'white',
                textTransform: "capitalize",
                margin: "auto",
                paddingBottom: "0",
                paddingTop: "0",
            };
        },
        singleValue: base => ({
            ...base,
            color: "#707070",
            display: "flex",
            fontSize: "14px",
            fontFamily: "Poppins",
            fontWeight: "400",
            minHeight: "26px",
            maxHeight: "26px",
            textTransform: "capitalize",
        }),
        control: (base, provided) => ({
            ...base,
            border: "0 !important",
            boxShadow: 'none !important',
            minHeight: '26px !important',
            height: '26px !important',
            '&:hover': { borderColor: 'transparent', border: 'none' },
            backgroundColor: 'white',
            outline: "none",
            width: "fit-content",
            borderRadius: "7px",
            fontFamily: 'Poppins',
            fontWeight: '400',
            fontSize: '16px',
        }),
        menu: (provided, state) => ({
            ...provided,
            borderRadius: "7px",
            textTransform: 'uppercase',
            border: "1px solid transparent",
            maxWidth: '250px',
            marginTop: '0px',
            boxShadow: '0px 3px 6px rgb(0 0 0 / 16%)',
        }),
        dropdownIndicator: base => ({
            ...base,
            color: "#00366A !important",
            padding: "4px",
            paddingTop: "1px",
        }),
        container: (base) => ({
            ...base,
            height: "26px",
            minHeight: "26px",
            maxHeight: "26px",
        }),
        valueContainer: (base) => ({
            ...base,
            height: "26px",
            minHeight: "26px",
            maxHeight: "26px",
        }),
        input: (base, state) => ({
            ...base,
            backgroundColor: "transparent !important",
            width: 'auto',
            textTransform: "none !important",
            border: "none !important",
            color: "#707070",
            fontSize: "16px",
            height: "23px",
            fontFamily: 'Poppins',
            fontWeight: '400',
            marginTop: "14px",
            '& input': {
                font: 'inherit',
            },
        })
    };

    useEffect(() => {
        if (authUser) {
            setGeneralDataApiOptions({
                url: 'get-matter-data',
                method: 'post',
                data: {
                    id: Number(matter_id),
                    type: type,
                },
                callback: res => {
                    setActiveMatter(res?.data);
                },
                catch: err => {
                    if (err) {
                    }
                }
            })
        }
    }, [authUser]);// eslint-disable-line react-hooks/exhaustive-deps

    const triggerEditMenu = (event, data) => {
        event.preventDefault()
        event.stopPropagation()
        setTarget(Number(target?.id) === Number(data?.id) ? null : data)
    }

    const handleEditClick = (e, hearing) => {
        e.preventDefault();
        e.stopPropagation();
        setAddMatterPopup({
            open: true,
            type: 'hearings',
            stage: hearing?.stage,
            returnIndex: 0
        })
        setEditHearing(hearing)
    }

    const handleAddStageClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setAddStagePopup({ open: true });
        setPopupTitle(generalData?.matterSectionsSettings['stage'])
    }

    const handleSelectChange = (e, fieldName, setState) => {
        setCurrentInput(fieldName);
        setCurrentInputValue(e.value);
        setTimeout(() => {
            setChangeInput(1)
        }, 100)
        setState(e);
    }

    const handleDatePickerChange = (date, fieldName, setState) => {
        setCurrentInput(fieldName);
        setCurrentInputValue(formatDate(date))
        setTimeout(() => {
            setChangeInput(1)
        }, 100)
        setState(date)
    }

    //trigger post to backend once inputs changes
    useEffect(() => {
        if (changeInput > 0) {

            let fd = new FormData();
            fd.append('target_id', activeMatter?.stages[openId]?.id);
            fd.append('model', 'LitigationsStage');
            fd.append('main_id', activeMatter.id);
            fd.append('relation_field', currentInputValue?.relationField)
            if (currentInputValue?.relationModel) {
                fd.append('relationModel', currentInputValue?.relationModel);
                fd.append('relation_id', currentInputValue.id);
                if (currentInputValue.firstInputName) {
                    fd.append('firstInputName', currentInputValue.firstInputName)
                    fd.append('firstInputValue', currentInputValue.firstInputValue)
                }
                if (currentInputValue.secondInputName) {
                    fd.append('secondInputName', currentInputValue.secondInputName)
                    fd.append('secondInputValue', currentInputValue.secondInputValue)
                }
            } else {

                fd.append('current_field', currentInput);

                // if (currentInput === 'emails' || currentInput === 'phones' || currentInput === 'addresses') {
                //     fd.append('current_field_value', [currentInputValue['id'], currentInputValue['value']]);
                // } else {
                //     fd.append('current_field_value', currentInputValue);
                // }
                fd.append('current_field_value', currentInputValue);
            }

            if (currentInputValidations) {
                fd.append('validations', currentInputValidations)
            }


            setGeneralDataApiOptions({
                url: 'edit-input-data',
                method: 'post',
                data: fd,
                callback: res => {
                    setErrorsReturned({});
                    setActiveMatter(res?.data);
                    setProfileClient(res.data);
                    setCurrentInputValue('');
                    setCurrentInput('');
                    setErrorsReturned({});
                    setDeletePopup();
                    setChangeInput(0);
                },
                catch: err => {
                    if (err) {
                        setErrorsReturned(err);
                        setChangeInput(0);
                    }
                }
            })
        }
    }, [changeInput]);// eslint-disable-line react-hooks/exhaustive-deps

    const submitDelete = () => {
        let fd = new FormData();
        fd.append('target_id', activeMatter?.stages[openId]?.id);
        fd.append('relationModel', deletePopup?.model);
        fd.append('relation_id', deletePopup.single.id);
        fd.append('main_id', activeMatter?.id);

        setGeneralDataApiOptions({
            url: 'delete-litigation-data',
            method: 'post',
            data: fd,
            callback: res => {
                setErrorsReturned({});
                setActiveMatter(res?.data);
                setCurrentInputValue('');
                setCurrentInput('');
                setErrorsReturned({});
                setDeletePopup();
                setChangeInput(0);
            },
            catch: err => {
                if (err) {

                }
            }
        })
    }

    //cancel delete popup
    const cancelDeletePopup = () => {
        setDeletePopup();
    }

    useEffect(() => {
        setCurrentInputValue({})
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const handleAssigneesChange = (data) => {
        setActiveMatter(data);
    }

    const handleCopyClick = (e, hearing) => {
        e.stopPropagation();
        e.preventDefault();
        setEditHearing(hearing);
        setAddMatterPopup({ open: true, type: 'hearings', action: 'copy', returnIndex: 0 });
        setPopupTitle(generalData?.matterTypes[1]?.title);
    }

    const handleCreateReportFunctionClick = (e, hearing) => {
        e.stopPropagation();
        e.preventDefault();
        handleTableEditSingleFieldClick(hearing, 'report', 'create-a-report', 0)
    }

    const handleDetermineJudgmentClick = (e, hearing) => {
        e.stopPropagation();
        e.preventDefault();
        setEditHearing(hearing);
        setAddMatterPopup({ open: true, type: 'hearings', showMore: true, returnIndex: 0 });
        setPopupTitle(generalData?.matterTypes[1]?.title);
    }

    const handleTableEditSingleFieldClick = (record, fieldName, fieldTitle) => {
        setCommonPopup(true);
        setPopupTitle(generalData?.hearingFixedTitles[fieldTitle]);
        setPopupContent({
            record: record,
            fieldName: fieldName,
            returnIndex: 0
        })
    }

    return (
        <>

            <Api options={generalDataApiOptions} />
            <div className='w-100  position-relative pt-4 scrollable-profile-height' style={{ minHeight: `calc(100vh-220px) !important`, }}>
                <div className='position-md-absolute end-0 top-0 p-2 pt-4' style={{ zIndex: 100 }}>
                    <button onClick={handleAddStageClick} className='px-3 popup-button w-auto'>{generalData?.stagesFixedTitles['change-litigation-stage']}</button>
                </div>

                {
                    stages?.length > 0 ?
                        stages?.map((stage, i) => (
                            <AccordionComponent
                                key={i}
                                openId={openId}
                                setOpenId={setOpenId}
                                onClick={(e) => handleAccordionClick(e, i)}
                                title={stage?.stage.title}
                                index={i}
                                className={`mt-3`}
                                updateOnChange={[openId, formData, activeMatter?.hearings]}
                                handleButtonClick={(e) => { e.stopPropagation(); setDeletePopup({ single: stage, index: i, key: 'stages', model: 'LitigationsStage' }) }}
                            >
                                {
                                    Number(openId) === Number(i) &&
                                    <div className="row  my-auto align-items-center pb-4">
                                        <div className="col-12">
                                            <div className="row pt-4">
                                                <div className="col-12 col-md-4 pb-3 pb-md-0">
                                                    <div className='row'>
                                                        <div className="col-12 d-flex">
                                                            <p className="profile-general-input-title">{generalData?.stagesFixedTitles['stage']}</p>
                                                        </div>
                                                        <div className="col-12 col-md-8 col-lg-10 col-xl-9 pt-1">
                                                            <Select
                                                                isSearchable={false}
                                                                isClearable={false}
                                                                classNamePrefix="select"
                                                                className="profile-select p-0"
                                                                styles={customStyles}
                                                                options={LitigationStages}
                                                                components={{
                                                                    IndicatorSeparator: () => null
                                                                }}
                                                                placeholder={generalData?.stagesFixedTitles['stage']}
                                                                value={selectedStage ? selectedStage : null}
                                                                onChange={(e) => { handleSelectChange(e, 'stage_id', setSelectedStage) }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-4">
                                                    {/* last hearing date  */}
                                                    <div className="row">
                                                        <div className="col-12 d-flex">
                                                            <p className="profile-general-input-title">{generalData?.stagesFixedTitles['last-hearing-date']}</p>
                                                        </div>
                                                        <div className="col-12 col-lg-10 col-xl-3 pt-1">
                                                            <div className=' position-relative'>

                                                                <DatePicker
                                                                    className="birthdate-profile ps-0"
                                                                    placeholderText={generalData?.stagesFixedTitles['last-hearing-date']}
                                                                    value={lastHearingDate ? lastHearingDate : ''}
                                                                    showMonthDropdown
                                                                    showYearDropdown
                                                                    dropdownMode="select"
                                                                    selected={lastHearingDate}
                                                                    dateFormat="d MM yyyy"
                                                                    onChange={(date) => { handleDatePickerChange(date, 'last_hearing_date', setLastHearingDate) }}
                                                                    maxDate={addYears(new Date(), +1)}
                                                                    minDate={addYears(new Date(), -100)}
                                                                    locale={localStorage.getItem("BlpLanguage")}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-xl-10 pt-3">


                                            <div className="row mt-3 ">
                                                {/* court name  */}
                                                <div className='col-sm-6 col-md-4 mb-3'>
                                                    <div className='row'>

                                                        <div className="col-12 d-flex">
                                                            <p className="profile-general-input-title">{generalData?.stagesFixedTitles['court-name']}</p>
                                                        </div>
                                                        <div className="col-12 col-sm-8 col-lg-10 col-xl-9 pt-1 w-100">
                                                            <EditInput
                                                                placeholder={generalData?.stagesFixedTitles['court-name']}
                                                                inputType={'text'}
                                                                positionAbsolute={1}
                                                                postUrl="edit-client-data"
                                                                data={stage}
                                                                validation={`required`}
                                                                value={stage?.court_name || ''}
                                                                errorsReturned={errorsReturned}
                                                                inputName={`court_name`}
                                                                userId={user_id}
                                                            />
                                                        </div>

                                                    </div>
                                                </div>

                                                {/* court type  */}
                                                <div className='col-sm-6 col-md-4 mb-3'>
                                                    <div className='row'>

                                                        <div className="col-12 d-flex">
                                                            <p className="profile-general-input-title">{generalData?.stagesFixedTitles['court-type']}</p>
                                                        </div>
                                                        <div className="col-12 col-md-8 col-lg-10 col-xl-9 pt-1">
                                                            <Select
                                                                isSearchable={false}
                                                                isClearable={false}
                                                                classNamePrefix="select"
                                                                className="profile-select p-0"
                                                                styles={customStyles}
                                                                options={courtTypes}
                                                                components={{
                                                                    IndicatorSeparator: () => null
                                                                }}
                                                                placeholder={generalData?.stagesFixedTitles['court-type']}
                                                                value={selectedCourtType ? selectedCourtType : null}
                                                                onChange={(e) => { handleSelectChange(e, 'court_type_id', setSelectedCourtType) }}
                                                            />
                                                        </div>



                                                    </div>

                                                </div>

                                                {/* court area  */}
                                                <div className='col-sm-6 col-md-4 mb-3'>
                                                    <div className='row'>

                                                        <div className="col-12 d-flex">
                                                            <p className="profile-general-input-title">{generalData?.stagesFixedTitles['court-area']}</p>
                                                        </div>
                                                        <div className="col-12 col-sm-8 col-lg-10 col-xl-9 pt-1 w-100">
                                                            <EditInput
                                                                placeholder={generalData?.stagesFixedTitles['court-area']}
                                                                inputType={'text'}
                                                                positionAbsolute={1}
                                                                postUrl="edit-client-data"
                                                                data={stage}
                                                                value={stage?.court_area}
                                                                inputName={`court_area`}
                                                                userId={user_id}
                                                            />
                                                        </div>



                                                    </div>

                                                </div>
                                            </div>

                                            <div className="row mt-3 ">
                                                {/* clients  */}
                                                <div className='col-sm-6 col-md-6 mb-3'>
                                                    <div className='row'>
                                                        <div className="col-12 d-flex">
                                                            <p className="profile-general-input-title">{generalData?.litigationFixedTitles['clients-names']}</p>
                                                        </div>

                                                        {
                                                            formData?.clients?.length > 0 &&
                                                            formData?.clients?.map((client, index) => (
                                                                <div className="col-12 pt-1" key={index}>
                                                                    <div className="row">
                                                                        {
                                                                            Number(index) === 0 ?
                                                                                <div className="col-auto" onClick={(e) => addToFormData('clients')}>
                                                                                    <AddIconLt style={{ marginLeft: "2px !important", marginTop: '-5px' }} />
                                                                                </div>
                                                                                :

                                                                                <div className="col-auto" onClick={(e) => removeFormData(client, index, 'clients', 'StageClient')}>
                                                                                    <RemoveFd style={{ marginTop: "-6px", marginLeft: "2px !important" }} />
                                                                                </div>
                                                                        }

                                                                        <div style={{ marginRight: "-4px" }} className="col ps-0">
                                                                            <EditInput
                                                                                className="w-100"
                                                                                withPadd={0}
                                                                                placeholder={generalData?.litigationFixedTitles['client-name-description']}
                                                                                singleRec={client}
                                                                                inputType={'user-description'}
                                                                                relationModel='StageClient'
                                                                                relationField="litigation_stage_id"
                                                                                data={formData}
                                                                                value={client?.name ? client?.name : ''}
                                                                                inputName={"clients"}
                                                                                userId={user_id}
                                                                                excludeMultipleValues={excludeValues}
                                                                                firstInputPlaceholder={generalData?.litigationFixedTitles['client-name-description']}
                                                                                secondInputPlaceholder={generalData?.litigationFixedTitles['description']}
                                                                                firstInputName={'client_id'}
                                                                                firstInputValue={client?.selectedClient?.value?.split('-')[1]}
                                                                                secondInputName={'description'}
                                                                                secondInputValue={client?.description || ''}
                                                                                selectedRelation={client?.selectedClient || ''}
                                                                                description={client?.description || ''}
                                                                                multiple
                                                                                excludeMatters
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>


                                                            ))
                                                        }
                                                    </div>
                                                </div>

                                                {/* opponents  */}
                                                <div className='col-sm-6 col-md-6 mb-3'>
                                                    <div className='row'>

                                                        <div className="col-12 d-flex">
                                                            <p className="profile-general-input-title">{generalData?.litigationFixedTitles['opponents-names']}</p>
                                                        </div>
                                                        {
                                                            formData?.opponents?.length > 0 ?
                                                                formData?.opponents?.map((opponent, index) => (
                                                                    <div className="col-12 pt-1" key={index}>
                                                                        <div className="row">
                                                                            {
                                                                                Number(index) === 0 ?
                                                                                    <div className="col-auto" onClick={(e) => addToFormData('opponents')}>
                                                                                        <AddIconLt style={{ marginLeft: "2px !important", marginTop: '-5px' }} />
                                                                                    </div>
                                                                                    :
                                                                                    <div className="col-auto" onClick={(e) => removeFormData(opponent, index, 'opponents', 'StageOpponent')}>
                                                                                        <RemoveFd style={{ marginTop: "-6px", marginLeft: "2px !important" }} />
                                                                                    </div>
                                                                            }

                                                                            <div style={{ marginRight: "-4px" }} className="col ps-0">
                                                                                <EditInput
                                                                                    className="w-100"
                                                                                    withPadd={0}
                                                                                    placeholder={generalData?.litigationFixedTitles['opponent-name-description']}
                                                                                    singleRec={opponent}
                                                                                    inputType={'user-description'}
                                                                                    relationModel='StageOpponent'
                                                                                    relationField="litigation_stage_id"
                                                                                    data={formData}
                                                                                    value={opponent?.name ? opponent?.name : ''}
                                                                                    inputName={"opponents"}
                                                                                    userId={user_id}
                                                                                    excludeMultipleValues={excludeValues}
                                                                                    firstInputPlaceholder={generalData?.litigationFixedTitles['opponent-name-description']}
                                                                                    secondInputPlaceholder={generalData?.litigationFixedTitles['description']}
                                                                                    firstInputName={'client_id'}
                                                                                    firstInputValue={opponent?.selectedClient?.value?.split('-')[1]}
                                                                                    secondInputName={'description'}
                                                                                    secondInputValue={opponent?.description || ''}
                                                                                    selectedRelation={opponent?.selectedClient || ''}
                                                                                    description={opponent?.description || ''}
                                                                                    multiple
                                                                                />

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ))
                                                                :
                                                                null
                                                        }

                                                        {/* {
                                                                errorsReturned && errorsReturned?.name ?
                                                                    <div className="col-12 col-sm-8 col-lg-10 col-xl-9 pt-1 w-100">
                                                                        <h5 className=" ps-4 pop-errors-text">{errorsReturned?.name}</h5>
                                                                    </div>
                                                                    :
                                                                    null
                                                            } */}
                                                    </div>

                                                </div>

                                            </div>

                                            <div className="row mt-3">
                                                {/* verdict_date  */}
                                                <div className='col-sm-6 col-md-4 mb-3'>
                                                    <div className='row'>

                                                        <div className="col-12 d-flex">
                                                            <p className="profile-general-input-title">{generalData?.stagesFixedTitles['verdict-date']}</p>
                                                        </div>
                                                        <div className="col-12 col-sm-8 col-lg-10 col-xl-3">
                                                            <div className='position-relative'>

                                                                <DatePicker
                                                                    className="birthdate-profile pb-0 ps-0"
                                                                    placeholderText={generalData?.stagesFixedTitles['verdict-date']}
                                                                    value={verdictDate ? verdictDate : ''}
                                                                    showMonthDropdown
                                                                    showYearDropdown
                                                                    dropdownMode="select"
                                                                    selected={verdictDate}
                                                                    dateFormat="d MM yyyy"
                                                                    onChange={(date) => { handleDatePickerChange(date, 'verdict_date', setVerdictDate) }}
                                                                    maxDate={addYears(new Date(), +10)}
                                                                    minDate={addYears(new Date(), -10)}
                                                                    locale={localStorage.getItem("BlpLanguage")}
                                                                />
                                                            </div>

                                                        </div>


                                                        {/* {
                                                            errorsReturned && errorsReturned?.name ?
                                                                <div className="col-12 col-sm-8 col-lg-10 col-xl-9 pt-1 w-100">
                                                                    <h5 className=" ps-4 pop-errors-text">{errorsReturned?.name}</h5>
                                                                </div>
                                                                :
                                                                null
                                                        } */}
                                                    </div>

                                                </div>

                                                {/* court degree  */}
                                                <div className='col-sm-6 col-md-4 mb-3'>
                                                    <div className='col'>

                                                        <div className="col-12 d-flex">
                                                            <p className="profile-general-input-title">{generalData?.stagesFixedTitles['court-degree']}</p>
                                                        </div>
                                                        <div className="col-12 col-sm-8 col-lg-10 col-xl-9 pt-1 w-100">
                                                            <EditInput
                                                                placeholder={generalData?.stagesFixedTitles['court-degree']}
                                                                inputType={'text'}
                                                                positionAbsolute={1}
                                                                postUrl="edit-client-data"
                                                                data={stage}
                                                                value={stage?.court_degree || ''}
                                                                inputName={`court_degree`}
                                                                userId={user_id}
                                                            />
                                                        </div>


                                                        {/* {
                                                            errorsReturned && errorsReturned?.name ?
                                                                <div className="col-12 col-sm-8 col-lg-10 col-xl-9 pt-1 w-100">
                                                                    <h5 className=" ps-4 pop-errors-text">{errorsReturned?.name}</h5>
                                                                </div>
                                                                :
                                                                null
                                                        } */}
                                                    </div>

                                                </div>

                                            </div>

                                            <div className="row mt-3">
                                                <>
                                                    {/* litigation number  */}
                                                    {/*                                                     
                                                        <div className='col-4'>
                                                            <div className='row'>

                                                                <div className="col-12 d-flex">
                                                                    <p className="profile-general-input-title">{generalData?.matterSectionsSettings['litigation-number']}</p>
                                                                </div>
                                                                <div className="col-12 col-sm-8 col-lg-10 col-xl-9 pt-1 w-100">
                                                                    <EditInput
                                                                        placeholder={generalData?.matterSectionsSettings['litigation-number']}
                                                                        inputType={'text'}
                                                                        postUrl="edit-client-data"
                                                                        data={activeMatter}
                                                                        value={''}
                                                                        inputName={`title8${i}`}
                                                                        userId={user_id}
                                                                    />
                                                                </div>


                                                                {/* {
                                                                errorsReturned && errorsReturned?.name ?
                                                                    <div className="col-12 col-sm-8 col-lg-10 col-xl-9 pt-1 w-100">
                                                                        <h5 className=" ps-4 pop-errors-text">{errorsReturned?.name}</h5>
                                                                    </div>
                                                                    :
                                                                    null
                                                            } 
                                                            </div>

                                                        </div> 
                                                    */}
                                                </>


                                                {/* hearing date  */}
                                                <div className='col-4'>
                                                    <div className='col'>

                                                        <div className="col-12 d-flex">
                                                            <p className="profile-general-input-title">{generalData?.hearingFixedTitles['hearing-date']}</p>
                                                        </div>
                                                        <div className="col-12 col-sm-8 col-lg-10 col-xl-3 pt-1 ">
                                                            <DatePicker
                                                                className="birthdate-profile ps-0"
                                                                placeholderText={generalData?.hearingFixedTitles['hearing-date']}
                                                                value={hearingDate ? hearingDate : ''}
                                                                showMonthDropdown
                                                                showYearDropdown
                                                                dropdownMode="select"
                                                                selected={hearingDate}
                                                                dateFormat="d MM yyyy"
                                                                onChange={(date) => { handleDatePickerChange(date, 'hearing_date', setHearingDate) }}
                                                                maxDate={addYears(new Date(), +10)}
                                                                minDate={addYears(new Date(), -10)}
                                                                locale={localStorage.getItem("BlpLanguage")}
                                                            />
                                                        </div>


                                                        {/* {
                                                            errorsReturned && errorsReturned?.name ?
                                                                <div className="col-12 col-sm-8 col-lg-10 col-xl-9 pt-1 w-100">
                                                                    <h5 className=" ps-4 pop-errors-text">{errorsReturned?.name}</h5>
                                                                </div>
                                                                :
                                                                null
                                                        } */}
                                                    </div>

                                                </div>
                                            </div>

                                            {/* updates  */}
                                            <div className="row py-2">
                                                <h1 className="profile-big-title">
                                                    {generalData?.litigationFixedTitles['latest-updates']}
                                                </h1>
                                                <div className="col-12">
                                                    <div className="row g-0 p-0 py-4 costs-box-styling custom-shadow px-2">
                                                        {
                                                            formData?.updates?.length > 0 &&
                                                            formData?.updates?.map((update, index) => (
                                                                <div className="row">
                                                                    <div style={{ zIndex: "9999" }} className="col-auto" onClick={(e) => removeFormData(update, index, 'updates', 'StageUpdate')}>
                                                                        <RemoveFd style={{ marginTop: "-6px", marginLeft: "2px !important" }} />
                                                                    </div>

                                                                    <div className="col ps-0">
                                                                        <EditInput
                                                                            className="w-100"
                                                                            withPadd={0}
                                                                            placeholder={generalData?.matterSectionsSettings['latest-updates']}
                                                                            singleRec={update}
                                                                            inputType={'date-description'}
                                                                            relationModel='StageUpdate'
                                                                            relationField='stage_id'
                                                                            data={formData}
                                                                            value={update?.name ? update?.name : ''}
                                                                            inputName={"updates"}
                                                                            userId={user_id}
                                                                            firstInputPlaceholder={'date'}
                                                                            secondInputPlaceholder={''}
                                                                            firstInputName={'date'}
                                                                            firstInputValue={update?.date || formatDate(new Date())}
                                                                            secondInputName={'description'}
                                                                            secondInputValue={update?.description || ''}
                                                                            multiple
                                                                            locked={update?.locked}
                                                                        />
                                                                        {
                                                                            update?.locked &&
                                                                            <p className='number-files-title ps-1 pt-0 mb-2 word-break fs-12 color-red'>{generalData?.hearingFixedTitles['judgment-edit-in-hearing']} </p>
                                                                        }
                                                                        {/* <EditInput
                                                                                placeholder={generalData?.matterSectionsSettings['latest-updates']}
                                                                                inputType={'date-description'}
                                                                                postUrl="edit-client-data"
                                                                                singleRec={update}
                                                                                data={activeMatter}
                                                                                value={activeMatter?.appeal_number ? activeMatter?.appeal_number : ''}
                                                                                inputName={"latest_update"}
                                                                                userId={user_id}
                                                                            /> */}
                                                                    </div>


                                                                </div>
                                                            ))

                                                        }

                                                        <div className="ms-auto col-auto">
                                                            <div className="cursor-pointer" onClick={() => { addToFormData('updates', true) }}>

                                                                <AddIconLt />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                            {/* hearings  */}
                                            <div className='row '>
                                                <div className='col-12 my-2'>
                                                    <div className='w-100 h-100 d-flex align-items-center '>
                                                        <p className='profile-general-input-title'>
                                                            {generalData?.matterSectionsSettings['hearings']}
                                                        </p>
                                                        <button className='ms-3 btn border-circle'
                                                            onClick={() => {
                                                                setAddMatterPopup({
                                                                    open: true,
                                                                    type: 'hearings',
                                                                    stage: stage,
                                                                    returnIndex: 0,
                                                                });
                                                                setEditHearing(null);
                                                            }}
                                                        >
                                                            <AddIconLt className={``} />
                                                        </button>
                                                    </div>
                                                </div>
                                                {
                                                    stage?.hearings?.map((hearing, index) => (
                                                        <>
                                                            <div className='col-xl-6 mb-3'>
                                                                <CardComponent
                                                                    index={index}
                                                                    image={'/assets/images/hearing-profile.png'}
                                                                    title={hearing?.hearing_date}
                                                                    subTitle={hearing?.hearing_type?.title}
                                                                    data={hearing}
                                                                    editMenuClick={(e) => triggerEditMenu(e, hearing)}
                                                                    target={target}
                                                                    setTarget={setTarget}


                                                                    editFunction={(e) => { handleEditClick(e, hearing) }}
                                                                    deleteFunction={() => { setDeletePopup({ single: hearing, index: i, key: 'hearings', model: 'Hearing' }) }}
                                                                    copyFunction={(e) => { handleCopyClick(e, hearing) }}
                                                                    createReportFunction={(e) => { handleCreateReportFunctionClick(e, hearing) }}
                                                                    determineJudgmentFunction={hearing?.result_description ? null : (e) => { handleDetermineJudgmentClick(e, hearing) }}
                                                                    viewJudgmentFunction={hearing?.result_description ? (e) => { handleDetermineJudgmentClick(e, hearing) } : null}
                                                                    costsFunction={(e) => { e.stopPropagation(); e.preventDefault(); navigate(`/matters/litigations/${activeMatter?.id}/costs`) }}
                                                                    remindersFunction={(e) => { e.stopPropagation(); e.preventDefault(); navigate(`/matters/litigations/${activeMatter?.id}/reminder`) }}
                                                                >
                                                                    <div className='row'>
                                                                        <div className='col-6'>
                                                                            <p className='profile-general-input-title'>{generalData?.matterSectionsSettings['assignees']}</p>
                                                                            <div className='w-100 h-100 mt-2'>
                                                                                {/* <UsersSelect
                                                                                        url={'edit-license-users'}
                                                                                        activeEditUsers={activeEditUsers}
                                                                                        setActiveEditUsers={setActiveEditUsers}
                                                                                        data={hearing}
                                                                                        usders={hearing?.assignees}
                                                                                        clientId={''}
                                                                                        className=""
                                                                                    /> */}
                                                                                <UsersSelect
                                                                                    key={index}
                                                                                    url={'/add-edit-assignees'}
                                                                                    activeEditUsers={activeEditUsers}
                                                                                    setActiveEditUsers={setActiveEditUsers}
                                                                                    data={hearing}
                                                                                    users={hearing?.assignees}
                                                                                    model={'Hearing'}
                                                                                    clientId={''}
                                                                                    className="litigation-user-select m-0"
                                                                                    onChange={handleAssigneesChange}
                                                                                    return={'nothing'}
                                                                                />

                                                                            </div>

                                                                        </div>
                                                                        {/* <div className='col-6'>
                                                                                <p className='profile-general-input-title'>{generalData?.matterSectionsSettings['opponent']}</p>
                                                                                <div className='w-100 h-100 ms-4 mt-2'>

                                                                                    <UsersSelect
                                                                                        url={'edit-license-users'}
                                                                                        activeEditUsers={activeEditUsers}
                                                                                        setActiveEditUsers={setActiveEditUsers}
                                                                                        data={hearing}
                                                                                        users={hearing?.assignees}
                                                                                        clientId={''}
                                                                                        className=""
                                                                                    />
                                                                                </div>
                                                                            </div> */}
                                                                    </div>
                                                                    <div className='row mt-2'>
                                                                        <div className='col-12 mb-2'>
                                                                            <p className='profile-general-input-title'>{generalData?.hearingFixedTitles['postponement-date']}</p>
                                                                            <h5 className='locked-inputs'>{hearing?.postponement_date || '-'}</h5>
                                                                        </div>
                                                                        <div className='col-12 mb-2'>
                                                                            <p className='profile-general-input-title'>{generalData?.hearingFixedTitles['postponement-reason']}</p>
                                                                            <h5 className='locked-inputs'>{hearing?.postponement_reason || '-'}</h5>
                                                                        </div>

                                                                        <div className='col-12 mb-2'>
                                                                            <p className='profile-general-input-title'>{generalData?.matterSectionsSettings['description']}</p>
                                                                            <h5 className='locked-inputs'>{hearing?.description || '-'}</h5>
                                                                        </div>
                                                                        <div className='col-12 mb-2'>
                                                                            <p className='profile-general-input-title'>{generalData?.hearingFixedTitles['assignee-summary']}</p>
                                                                            <h5 className='locked-inputs'>{hearing?.hearing_summary || '-'}</h5>
                                                                        </div>
                                                                        <div className='col-12 mb-2'>
                                                                            <p className='profile-general-input-title'>{generalData?.hearingFixedTitles['client-summary']}</p>
                                                                            <h5 className='locked-inputs'>{hearing?.client_summary || '-'}</h5>
                                                                        </div>


                                                                        <div className='col-12 mb-2'>
                                                                            <p className='profile-general-input-title'>{generalData?.hearingFixedTitles['hearing-file']}</p>
                                                                            {
                                                                                hearing?.uploaded_files ?
                                                                                    <>
                                                                                        <FileIcon style={{ maxWidth: 20, maxHeight: 20 }} className="me-2" />

                                                                                        <a target='_blank' rel="noreferrer" href={hearing?.uploaded_files ? hearing?.uploaded_files?.full_path_file : '-'} className='link locked-inputs'>{hearing?.uploaded_files ? hearing?.uploaded_files?.file_name : '-'}</a>
                                                                                    </>
                                                                                    :
                                                                                    <h5 className='locked-inputs'>-</h5>
                                                                            }
                                                                        </div>

                                                                        {
                                                                            hearing?.verdict_date &&
                                                                            <>
                                                                                <h1 className='profile-big-title mt-4'>{generalData?.hearingFixedTitles['judgment-info']}</h1>
                                                                                <div className='col-12 mb-2'>
                                                                                    <p className='profile-general-input-title'>{generalData?.hearingFixedTitles['hearing-result']}</p>
                                                                                    <h5 className='locked-inputs'>{hearing?.hearing_result?.title || '-'}</h5>
                                                                                </div>
                                                                                <div className='col-12 mb-2'>
                                                                                    <p className='profile-general-input-title'>{generalData?.hearingFixedTitles['stage-condition']}</p>
                                                                                    <h5 className='locked-inputs'>{hearing?.stage_condition?.title || '-'}</h5>
                                                                                </div>
                                                                                <div className='col-12 mb-2'>
                                                                                    <p className='profile-general-input-title'>{generalData?.hearingFixedTitles['verdict-date']}</p>
                                                                                    <h5 className='locked-inputs'>{hearing?.verdict_date || '-'}</h5>
                                                                                </div>
                                                                                <div className='col-12 mb-2'>
                                                                                    <p className='profile-general-input-title'>{generalData?.hearingFixedTitles['result-reason']}</p>
                                                                                    <h5 className='locked-inputs'>{hearing?.result_reason || '-'}</h5>
                                                                                </div>
                                                                                <div className='col-12 mb-2'>
                                                                                    <p className='profile-general-input-title'>{generalData?.hearingFixedTitles['result-description']}</p>
                                                                                    <h5 className='locked-inputs'>{hearing?.result_description || '-'}</h5>
                                                                                </div>
                                                                                <div className='col-12 mb-2'>
                                                                                    <p className='profile-general-input-title'>{generalData?.hearingFixedTitles['verdict-file']}</p>
                                                                                    {
                                                                                        hearing?.verdict_files ?
                                                                                            <>
                                                                                                <FileIcon style={{ maxWidth: 20, maxHeight: 20 }} className="me-2" />

                                                                                                <a target='_blank' rel="noreferrer" href={hearing?.verdict_files ? hearing?.verdict_files?.full_path_file : '-'} className='locked-inputs'>{hearing?.verdict_files ? hearing?.verdict_files?.file_name : '-'}</a>
                                                                                            </>
                                                                                            :
                                                                                            <h5 className='locked-inputs'>-</h5>
                                                                                    }
                                                                                </div>
                                                                            </>
                                                                        }


                                                                    </div>
                                                                </CardComponent>
                                                            </div >
                                                        </>
                                                    ))
                                                }

                                            </div>

                                        </div>
                                    </div>
                                }

                            </AccordionComponent >

                        ))
                        :
                        <div>no stages yet...</div>
                }

            </div >
            {
                addMatterPopup.open && addMatterPopup?.type === 'hearings' &&
                <HearingPopupComponent
                    editHearing={editHearing}
                    setEditHearing={setEditHearing}
                    popupTitle={popupTitle}
                    setAddMatterPopup={setAddMatterPopup}
                    addMatterPopup={addMatterPopup}
                    activeLitigation={activeMatter?.id}
                    activeStage={addMatterPopup?.stage}
                    type='litigations'
                    // page={clientsCurrentPage}
                    // sort={order}
                    // direction={sortedDirection}
                    activePage={'matters'}
                />
            }
            {
                addStagePopup.open &&
                <AddStagePopupComponent
                    popupTitle={popupTitle}
                    setAddStagePopup={setAddStagePopup}
                    addStagePopup={addStagePopup}
                    // page={clientsCurrentPage}
                    // sort={order}
                    // direction={sortedDirection}
                    activePage={'matters'}
                />
            }
            {
                commonPopup &&
                <HearingCommonPopup
                    popupTitle={popupTitle}
                    commonPopup={commonPopup}
                    setCommonPopup={setCommonPopup}
                    popupContent={popupContent}
                    setPopupContent={setPopupContent}
                    type="litigations"
                />
            }

            {
                //delete popup
                deletePopup &&
                <>
                    <div id="errors-popup" className=" error-popup-api d-flex align-items-center justify-content-center px-3 fade-in">
                        <div className="pop-up-api-box">
                            <CloseIcon className="cursor-pointer" onClick={() => cancelDeletePopup()} />
                            <div className="text-center ">
                                <div className="containter-fluid">
                                    <div className="row d-flex justify-content-center align-items-center">
                                        <div className="col-9 col-sm-12 d-flex justify-content-center">
                                            <h1 className="pop-up-title m-0 pt-3 color-dark-blue">{generalData?.settings_section_settings['delete-popup-title']}</h1>
                                        </div>
                                        <div className="col-12 d-flex justify-content-center pt-4 pb-2">
                                            <button onClick={submitDelete} type="button" className="popup-button my-auto mx-2 w-auto px-3">{generalData?.settings_section_settings['delete-popup-first-button']}</button>
                                            <button onClick={() => cancelDeletePopup()} type="button" className="auth-button border-dark-blue my-auto mx-2 background-transparent w-auto px-3">{generalData?.settings_section_settings['delete-popup-second-button']}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }

        </>
    )
}
