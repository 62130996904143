export const getOptions = (arr, slug = "slug", value = "id", lable = "title", additionalField = null) => {
    const relOptions = arr.map(
        single => ({ slug: single[slug], value: single[value], label: single[lable], additionalField: single[additionalField] }))
    return relOptions;
}

export const addYears = (date, years) => {
    date.setFullYear(date.getFullYear() + years);
    return date;
}

//function to add months to a date
export const addMonths = (date, months) => {
    date.setMonth(date.getMonth() + months);
    return date;
}


//formate date YYYY-MM-DD
export const formatDate = (date) => {
    return `${date.getFullYear()}-${date.getMonth() < 9 ? '0' + Number(Number(date.getMonth()) + 1) : Number(date.getMonth()) + 1}-${date.getDate() < 10 ? '0' + date.getDate() : date.getDate()}`;
}

//get index of certain value in an array
export const getIndex = (value, arr, prop) => {
    for (var i = 0; i < arr.length; i++) {
        if (arr[i][prop] === value) {
            return i;
        }
    }
    return -1; //to handle the case where the value doesn't exist
}


//format date and time YYYY-MM-DD HH:MM
export const formatDateTime = (date) => {
    return `${date.getFullYear()}-${date.getMonth() < 9 ? '0' + Number(Number(date.getMonth()) + 1) : Number(date.getMonth()) + 1}-${date.getDate() < 10 ? '0' + date.getDate() : date.getDate()} ${date.getHours() >= 10 ? date.getHours() : '0' + date.getHours()}:${date.getMinutes() === 0 ? '00:00' : '30:00'}`;
}


export const calcHeight = (value, padding) => {
    let numberOfLineBreaks = (value.match(/\n/g) || '').length;
    // min-height + lines x line-height + padding + border
    let newHeight = 20 + numberOfLineBreaks * 24 + (padding || 0) + 2;
    return newHeight;
}

export const isValidUrl = urlString => {
    try {
        return Boolean(new URL(urlString));
    }
    catch (e) {
        return false;
    }
}
