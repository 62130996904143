import { useContext, useState, useEffect } from "react";
import GlobalState from '../contexts/GlobalState';
import { useNavigate, useParams } from "react-router";
import React from 'react';
import Layout from "../components/Layout";
import Api from "../components/Api";
import Documents from "../components/Documents";
import "react-datepicker/dist/react-datepicker.css";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { registerLocale } from "react-datepicker";
import ar from 'date-fns/locale/ar';
import TaskGeneralInfo from "../components/TaskGeneralInfo";
import TasksReminders from "../components/TasksReminders";
import TaskRelations from "../components/TaskRelations";
import TaskCosts from "../components/TaskCosts";
import { CloseIcon } from "../components/Icons";
registerLocale('ar', ar)

export default function MattersDetails() {

    const {
        generalDataGlobal,
        authUserGlobal,
        activeTask, setActiveTask,
    } = useContext(GlobalState);
    const [generalDataApiOptions, setGeneralDataApiOptions] = useState({});
    let { slug, task_id, folder_id } = useParams();
    const [generalData] = generalDataGlobal;
    const [authUser,] = authUserGlobal;
    const history = useNavigate();
    const [swiper, setSwiper] = useState(null);
    const [deleteTask, setDeleteTask] = useState('');

    //handle swiper menu by slug selected
    useEffect(() => {

        // if (slug && swiper && profileClient?.client_type_id === 3) {
        if (slug === 'general-info') {
            setTimeout(() => {
                swiper?.slideTo(0, 400, true);
            }, 600)
        }
        if (slug === 'entity-management') {
            setTimeout(() => {
                swiper?.slideTo(1, 400, true);
            }, 600)
        }
        if (slug === 'administrative-body') {
            setTimeout(() => {
                swiper?.slideTo(2, 400, true);
            }, 600)
        }
        if (slug === 'documents') {
            setTimeout(() => {
                swiper?.slideTo(3, 400, true);
            }, 600)
        }
        if (slug === 'bank-account') {
            setTimeout(() => {
                swiper?.slideTo(4, 400, true);
            }, 600)
        }
        if (slug === 'relations') {
            setTimeout(() => {
                swiper?.slideTo(5, 400, true);
            }, 600)
        }
        if (slug === 'signature-authority') {
            setTimeout(() => {
                swiper?.slideTo(6, 400, true);
            }, 600)
        }
        if (!slug || slug === 'reminder') {
            setTimeout(() => {
                swiper?.slideTo(7, 400, true);
            }, 600)
        }
        if (slug === 'costs') {
            setTimeout(() => {
                swiper?.slideTo(8, 400, true);
            }, 600)
        }
        // }
    }, [slug, swiper]);// eslint-disable-line react-hooks/exhaustive-deps

    //get client data
    useEffect(() => {
        if (authUser) {
            setGeneralDataApiOptions({
                url: 'get-task-data',
                method: 'post',
                data: {
                    id: Number(task_id),
                },
                callback: res => {
                    setActiveTask(res?.data?.task);
                },
                catch: err => {
                    if (err) {
                        history('/not-found');
                    }
                }
            })
        }
    }, [task_id, slug]);// eslint-disable-line react-hooks/exhaustive-deps

    const submitDelete = (e, submitted_id) => {
        e.preventDefault();
        e.stopPropagation();
        if (submitted_id) {
            let fd = new FormData();
            fd.append('task_id', submitted_id);
            fd.append('dont_return',1);
            setGeneralDataApiOptions({
                url: 'delete-task',
                method: 'post',
                data: fd,
                callback: res => {
                    setDeleteTask('');
                    history('/tasks');
                },
                catch: err => {
                    if (err) {
                    }
                }
            })
        }
    };
    return (
        <>
            <Layout activePage="tasks">
                {
                    <>
                        <div className="row">
                            <div className="col-12 col-sm-auto">
                                <h1 style={{ textTransform: "capitalize" }} className="page-titles color-dark-blue pb-3">{activeTask?.name}</h1>
                            </div>

                            {
                                authUser?.id && authUser?.user_role?.slug === 'admin' ?
                                    <div className="col">
                                        <div className="row">
                                            <div className="col-12 d-flex justify-content-end pb-3">
                                                <button onClick={() => setDeleteTask(activeTask?.id)} className="popup-button my-auto mx-2 w-auto px-3">{generalData?.website_titles['delete']}</button>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    null
                            }
                        </div>
                        {

                            <Swiper
                                onSwiper={setSwiper}
                                className="big-swiper w-100 titles-swiper new-titles-styling-syling"
                                // spaceBetween={30}
                                slidesPerView={"auto"}
                                centeredSlides={false}
                                resistanceRatio={0}
                            >
                                <div className="row padding-top-table-settings"  >
                                    <SwiperSlide className={(slug === 'general-info' ? " active-custom-slide" : "")} key={1}>
                                        <h2 onClick={() => history(`/tasks/${activeTask?.id}/general-info`)} className={`settings-titles color-dark-blue cursor-pointer ${(!slug || slug === 'general-info') ? " active" : ""}`}>{generalData?.website_titles['general-info']}</h2>
                                    </SwiperSlide>
                                    <SwiperSlide className={(slug === 'documents' ? " active-custom-slide" : "")} key={4}>
                                        <h2 onClick={() => history(`/tasks/${activeTask?.id}/documents/`)} className={`settings-titles color-dark-blue cursor-pointer ${slug === 'documents' ? " active" : ""}`}>{generalData?.website_titles['documents-individual']}</h2>
                                    </SwiperSlide>
                                    <SwiperSlide className={(slug === 'relations' ? " active-custom-slide" : "")} key={6}>
                                        <h2 onClick={() => history(`/tasks/${activeTask?.id}/relations`)} className={`settings-titles color-dark-blue cursor-pointer ${slug === 'relations' ? " active" : ""}`}>{generalData?.website_titles['relations']}</h2>
                                    </SwiperSlide>
                                    <SwiperSlide className={(slug === 'reminder' ? " active-custom-slide" : "")} key={8}>
                                        <h2 onClick={() => history(`/tasks/${activeTask?.id}/reminder`)} className={`settings-titles color-dark-blue cursor-pointer ${slug === 'reminder' ? " active" : ""}`}>{generalData?.website_titles['reminder']}</h2>
                                    </SwiperSlide>
                                    <SwiperSlide className={(slug === 'costs' ? " active-custom-slide" : "")} key={9}>
                                        <h2 onClick={() => history(`/tasks/${activeTask?.id}/costs`)} className={`settings-titles color-dark-blue cursor-pointer ${slug === 'costs' ? " active" : ""}`}>{generalData?.website_titles['costs']}</h2>
                                    </SwiperSlide>
                                </div>
                            </Swiper>
                        }

                        <div className="management-table pt-0" >
                            {
                                (!slug || slug === 'general-info') && (activeTask?.id === Number(task_id)) &&
                                <>
                                    <TaskGeneralInfo activeTask={activeTask} setActiveTask={setActiveTask} />
                                </>
                            }
                            {
                                slug === 'documents' ?
                                    <div className="col-12 pt-4">
                                        <Documents
                                            currentFolder={folder_id}
                                            currentUser={task_id}
                                            mainFolder={4}
                                            mainRoute={'tasks'}
                                            activePage="tasks"
                                            subRoute={'documents'}
                                            user_id={activeTask?.id}
                                        />
                                    </div>
                                    :
                                    null
                            }
                            {
                                (slug === 'relations') ?
                                    <>
                                        <TaskRelations
                                            data={activeTask}
                                            setData={setActiveTask}
                                            taskId={task_id}
                                        />
                                    </>
                                    :
                                    null
                            }

                            {
                                slug === 'reminder' ?
                                    <TasksReminders
                                        marks={activeTask?.marks}
                                        taskId={task_id}
                                        activeTask={activeTask}
                                        setActiveTask={setActiveTask}
                                    />
                                    :
                                    null
                            }
                            {
                                slug === 'costs' ?
                                    <>
                                        <TaskCosts activeTask={activeTask} />
                                    </>
                                    :
                                    null
                            }
                        </div>
                    </>
                }
                {
                    // once delete is pressed we show the popup
                    deleteTask ?
                        <div id="errors-popup" className=" error-popup-api d-flex align-items-center justify-content-center px-3 fade-in">
                            <div className="pop-up-api-box">
                                <CloseIcon className="cursor-pointer" onClick={(e) => setDeleteTask('')} />
                                <div className="text-center ">
                                    <div className="containter-fluid">
                                        <div className="row d-flex justify-content-center align-items-center">
                                            <div className="col-9 col-sm-12 d-flex justify-content-center">
                                                <h1 className="pop-up-title m-0 pt-3 color-dark-blue">{generalData?.settings_section_settings['delete-popup-title']}</h1>
                                            </div>
                                            <div className="col-12 d-flex justify-content-center pt-4 pb-2">
                                                <button onClick={(e) => submitDelete(e, deleteTask)} type="button" className="popup-button my-auto mx-2 w-auto px-3">{generalData?.settings_section_settings['delete-popup-first-button']}</button>
                                                <button onClick={(e) => setDeleteTask('')} type="button" className="auth-button border-dark-blue my-auto mx-2 background-transparent w-auto px-3">{generalData?.settings_section_settings['delete-popup-second-button']}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        null
                }
                <Api options={generalDataApiOptions} />
            </Layout >
        </>
    )
}