import { useContext, useState, useEffect } from "react";
import GlobalState from '../contexts/GlobalState';
import { useParams } from "react-router";
import React from 'react';
import Api from "../components/Api";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
import 'swiper/css';
import Pagination from "../components/Pagination";
import Layout from "../components/Layout";
import { Link } from "react-router-dom";
import { registerLocale } from "react-datepicker";
import { getIndex, formatDate } from "../Helpers";
import ar from 'date-fns/locale/ar';
import { AttachIcon, BackIcon, CloseIcon } from "../components/Icons";
registerLocale('ar', ar)

export default function DateReport(props) {

    const { generalDataGlobal, authUserGlobal, profileClientGlobal } = useContext(GlobalState);
    const [generalDataApiOptions, setGeneralDataApiOptions] = useState({});
    const [expensesCall, setExpensesCall] = useState({});
    const [generalData] = generalDataGlobal;
    const [authUser,] = authUserGlobal;
    const [usersApiOptions,] = useState({});
    const [profileClient, setProfileClient] = profileClientGlobal;
    const { user_id, slug } = useParams();
    const [errorsReturned, setErrorsReturned] = useState({});
    const [costType, ] = useState(2);
    const [costCurrency, setCostCurrency] = useState('usd');

    //get all currencies
    const allCurrenciesFilters = () => {
        let curren = generalData?.currencies?.map(
            singleOption => ({ value: singleOption.id, label: singleOption.title, slug: singleOption.slug }))
        curren = [...curren, { value: 0, label: localStorage.getItem("BlpLanguage") === "en" ? 'ALL' : 'الكل', slug: 'all' }];
        return curren;
    }
    const [filterCurrency, ] = useState(allCurrenciesFilters()[0]);
    const [ , setExpenses] = useState({
        'total': 'x',
    });

    const [operations, setOperations] = useState([]);

    const [wallet, setWallet] = useState({
        "total": 'x',
        "salary": 0,
        "other": 0,
        "bonuses": 0,
    });
    /* expenses start */
    const [addExpenses, setAddExpenses] = useState(false);
    const [ , setEditExpense] = useState(null);
    const [currenEditExpense, setCurrenEditExpense] = useState('');
    const [addExpensesType, setAddExpensesType] = useState('');
    const [addExpensesAmount, setAddExpensesAmount] = useState('');
    const [addExpensesCurrency, setAddExpensesCurrency] = useState('');
    const [addExpensesDate, setAddExpensesDate] = useState(new Date());
    const [addExpensesNote, setAddExpensesNote] = useState('');
    const [addExpensesBillable, setAddExpensesBillable] = useState(0);
    const [ , setDocument] = useState();
    const [documentLink, setDocumentLink] = useState('');
    const [, setOperationsLink] = useState([]);
    const [operationsCurrentPage, setOperationsCurrentPage] = useState(1);
    const [operationsLastPage, setOperationsLastPage] = useState(1);
    const [filterDateFrom, setFilteDateFrom] = useState();
    const [filterDateTo, setFilteDateTo] = useState();

    //get all expenses filters
    const allExpenseFilters = () => {
        const expFilter = generalData?.expense_filters?.map(
            singleOption => ({ value: singleOption.id, label: singleOption.title }))
        return expFilter;
    }

    const [currentFilterExpenses, setCurrentFilterExpenses] = useState(allExpenseFilters()[2]);
    const [changeExpenses, setChangeExpenses] = useState(0);
    const [changeWallet, setChangeWallet] = useState(0);
    const [customDatesPopup, setCustomDatesPopup] = useState(false);
    const [searchValue, ] = useState('');
    /* expenses end */

    //get all transactions types
    const allTransactionTypes = () => {
        const transactionType = generalData?.transaction_types?.map(
            singleOption => ({ value: singleOption.id, label: singleOption.title }))
        return transactionType;
    }

    /* transactions start */
    const [addTransaction, setAddTransaction] = useState(false);
    const [ , setOpenEditTransaction] = useState(null);
    const [transactionType, setTransactionType] = useState(allTransactionTypes()[getIndex(1, allTransactionTypes(), 'value')]);
    const [transactionCurrency, setTransactionCurrency] = useState('');
    const [transactionTo, setTransactionTo] = useState('');
    const [currenEditTransaction, setCurrenEditTransaction] = useState('');
    const [transactionAmount, setTransactionAmount] = useState('');
    const [addTransactionDate, setAddTransactionDate] = useState(new Date());
    const [addTransactionNote, setAddTransactionNote] = useState('');
    const [, setTransactionLinks] = useState([]);
    const [transactionCurrentPage, setTransactionCurrentPage] = useState(1);
    const [ , setTransactionLastPage] = useState(1);
    const [deletePopup, setDeletePopup] = useState(null);
    const [ , setDeleteType] = useState(null);
    const [filterByUser, ] = useState(null);
    const [filterTransactionStatus, ] = useState('');
    const [targetOperationUpdate, setTargetOperationUpdate] = useState();

    /* transactions start */

    //reset states
    useEffect(() => {
        setExpenses({
            'total': 'x',
        });
        setWallet({
            'total': 'x',
        });
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    //handle date change
    const onChangeDate = (dates) => {
        const [start, end] = dates;
        setFilteDateFrom(start);
        setFilteDateTo(end);
    };

    //handle filtering transacion types
    const filterTransactions = (e) => {
        if (e.value !== 4) {
            return true;
        }
    }

    // document handler
    const documentHandler = (files) => {
        if (files.length > 0) {
            setDocument(files);
            let file = URL.createObjectURL(files[0])
            // setTempFile(file);
            setDocumentLink(file);
        }
    }

    //close expenses popup and reset states
    function closeAddExpense(e) {
        e.preventDefault();
        setAddExpenses(false);
        setCurrenEditExpense('');
        setAddExpensesType('');
        setAddExpensesAmount('');
        setAddExpensesCurrency('');
        setAddExpensesDate(new Date());
        setEditExpense(null);
        setAddExpensesNote('');
        setAddExpensesBillable('');
        setDocument();
        setDocumentLink('');
        setErrorsReturned({});
    }

    //close transactions popup and reset states
    function closeAddTransaction(e) {
        e.preventDefault();
        setAddTransaction(false);
        setTransactionAmount('');
        setTransactionCurrency('');
        setAddTransactionDate(new Date());
        setCurrenEditTransaction(null);
        setAddTransactionNote('');
        setOpenEditTransaction(null);
        setErrorsReturned({});
    }

    // get all expenses types
    const allExpensesTypes = () => {
        const expensesTypes = generalData?.expense_types?.map(
            singleOption => ({ value: singleOption.id, label: singleOption.title }))
        return expensesTypes;
    }

    //get all currencies
    const allCurrencies = () => {
        const curren = generalData?.currencies?.map(
            singleOption => ({ value: singleOption.id, label: singleOption.title, slug: singleOption.slug }))
        return curren;
    }

    //get all users
    const allUsers = () => {
        const curren = generalData?.users?.map(
            singleOption => ({ value: singleOption.id, label: <div><img className="select-image me-1" src={singleOption?.picture ? singleOption?.full_path_picture : generalData?.fixed_settings?.full_path_no_profile_picture} alt={singleOption?.name} />{singleOption?.name}</div> }))
        return curren;
    }

    //function to add years to a date
    function addYears(date, years) {
        date.setFullYear(date.getFullYear() + years);
        return date;
    }

    // get operations data
    useEffect(() => {
        setGeneralDataApiOptions({
            url: 'get-share-operation?page=' + operationsCurrentPage,
            method: 'post',
            data: {
                user_id: Number(user_id),
                client_id: Number(user_id),
            },
            callback: ({ data }) => {
                setOperations(data.operations);
                setProfileClient(data.client_user);
            },
        });
    }, [operationsCurrentPage,]);// eslint-disable-line react-hooks/exhaustive-deps

    //set expenses pagination data
    useEffect(() => {
        if (operations?.links?.length > 0) {
            let links = [];
            operations?.links?.forEach((element, index) => {
                links.push(index);
            });
            setOperationsLink(links);
            setOperationsCurrentPage(operations?.current_page);
            setOperationsLastPage(operations?.last_page);
        }
    }, [operations]);

    //set wallet pagination data
    useEffect(() => {
        if (wallet?.data?.links?.length > 0) {
            let links = [];
            wallet?.data?.links?.forEach((element, index) => {
                links.push(index);
            });
            setTransactionLinks(links);
            setTransactionCurrentPage(wallet?.data?.current_page);
            setTransactionLastPage(wallet?.data?.last_page);
        }
    }, [wallet]);

    // //submit expenses
    // const submitExpenses = (e) => {
    //     e.preventDefault();
    //     e.stopPropagation();
    //     let fd = new FormData();
    //     if (currenEditExpense?.id) {
    //         fd.append('expense_id', currenEditExpense?.id);
    //     }

    //     fd.append('expense_type_id', addExpensesType ? addExpensesType?.value : '');
    //     fd.append('currency_id', addExpensesCurrency ? addExpensesCurrency?.value : '');
    //     fd.append('currency', costCurrency ? costCurrency : '');
    //     fd.append('user_id', Number(user_id));
    //     fd.append('amount', addExpensesAmount ? addExpensesAmount : '');
    //     fd.append('date', addExpensesDate ? formatDate(addExpensesDate) : '');
    //     fd.append('note', addExpensesNote ? addExpensesNote : '');
    //     fd.append('billable', addExpensesBillable ? addExpensesBillable : 0);
    //     fd.append('date_from', filterDateFrom ? formatDate(filterDateFrom) : formatDate(new Date()));
    //     fd.append('date_to', filterDateTo ? formatDate(filterDateTo) : formatDate(new Date()));
    //     fd.append('filter_type', currentFilterExpenses ? currentFilterExpenses?.value : '');
    //     fd.append('page', expensesCurrentPage ? expensesCurrentPage : 1);
    //     fd.append('finance_page', 1);
    //     fd.append('search_value', searchValue ? searchValue : '');

    //     if (document) {
    //         fd.append('file', document[0]);
    //     }
    //     fd.append('client_id', Number(user_id));

    //     setGeneralDataApiOptions({
    //         url: 'add-client-expense',
    //         method: 'post',
    //         data: fd,
    //         callback: res => {
    //             setExpenses(res.data.expenses);
    //             setErrorsReturned({});
    //             closeAddExpense(e);
    //         },
    //         catch: err => {
    //             if (err) {
    //                 setErrorsReturned(err);
    //             }
    //         }
    //     })
    // };

    //get transactions data
    useEffect(() => {
        if (slug === 'transactions') {
            setExpensesCall({
                url: 'get-client-wallet?page=' + transactionCurrentPage,
                method: 'post',
                data: {
                    finance_page: 1,
                    currency: costCurrency,
                    user_id: Number(user_id),
                    date_from: filterDateFrom ? formatDate(filterDateFrom) : formatDate(new Date()),
                    date_to: filterDateTo ? formatDate(filterDateTo) : formatDate(new Date()),
                    filter_type: currentFilterExpenses ? currentFilterExpenses?.value : '',
                    search_value: searchValue,
                    filter_user: filterByUser ? filterByUser?.value : '',
                    transaction_filter: filterTransactionStatus ? filterTransactionStatus?.value : '',
                    client_id: Number(user_id),
                },
                callback: res => {
                    setWallet(res.data.wallet);
                    setProfileClient(res.data.client_user);
                },
                catch: err => {
                }
            });
        }
    }, [filterTransactionStatus, slug, filterByUser, costCurrency, costType, transactionCurrentPage, changeWallet]);// eslint-disable-line react-hooks/exhaustive-deps

    //submit transaction data
    const submitTransactions = (e) => {
        e.preventDefault();
        e.stopPropagation();
        let fd = new FormData();
        if (currenEditTransaction?.id) {
            fd.append('expense_id', currenEditTransaction?.id);
        }

        fd.append('transaction_type_id', transactionType ? transactionType?.value : '');
        fd.append('currency_id', transactionCurrency ? transactionCurrency?.value : '');
        fd.append('receiver_id', transactionTo ? transactionTo?.value : '');
        fd.append('currency', costCurrency ? costCurrency : '');
        fd.append('user_id', Number(user_id));
        fd.append('amount', transactionAmount ? transactionAmount : '');
        fd.append('date', addTransactionDate ? formatDate(addTransactionDate) : '');
        fd.append('note', addTransactionNote ? addTransactionNote : '');
        fd.append('page', transactionCurrentPage);
        fd.append('date_from', filterDateFrom ? formatDate(filterDateFrom) : formatDate(new Date()));
        fd.append('date_to', filterDateTo ? formatDate(filterDateTo) : formatDate(new Date()));
        fd.append('filter_type', currentFilterExpenses ? currentFilterExpenses?.value : '');
        fd.append('finance_page', 1);
        fd.append('search_value', searchValue ? searchValue : '');
        fd.append('filter_user', filterByUser ? filterByUser?.value : '');
        fd.append('transaction_filter', filterTransactionStatus ? filterTransactionStatus?.value : '');
        fd.append('client_id', Number(user_id));

        setGeneralDataApiOptions({
            url: 'add-client-transaction',
            method: 'post',
            data: fd,
            callback: res => {
                setWallet(res.data.wallet);
                setErrorsReturned({});
                closeAddTransaction(e);
                setOpenEditTransaction(null);
            },
            catch: err => {
                if (err) {
                    setErrorsReturned(err);
                }
            }
        })
    };

    //select custom styles
    const customStylesUsersPopup = {
        placeholder: (defaultStyles) => {
            return {
                ...defaultStyles,
                display: "flex",
                fontFamily: 'Poppins',
                fontWeight: '400',
                textTransform: "capitalize",
                fontSize: '14px',
                color: '#07407C',
            }
        },
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                color: '#07407C',
                fontFamily: 'Poppins',
                fontWeight: '400',
                backgroundColor: 'white',
                textTransform: "capitalize",
                margin: "auto",
                paddingRight: "10px",
                maxWidth: "100%",
                paddingLeft: "10px",
                paddingBottom: "2px",
                paddingTop: "2px",
            };
        },
        singleValue: base => ({
            ...base,
            color: "#00366A",
            display: "flex",
            fontSize: "14px",
            fontFamily: "Poppins",
            fontWeight: "400",
            textTransform: "capitalize",
        }),
        control: provided => ({
            ...provided,
            height: "40px",
            border: "none",
            backgroundColor: 'white',
            outline: "none",
            '&:hover': { borderColor: 'transparent' },
            textTransform: 'uppercase',
            width: "100%",
            borderRadius: "7px",
            fontFamily: 'Poppins',
            fontWeight: '400',
            boxShadow: "0px 3px 6px rgb(0 0 0 / 16%)",
        }),
        menu: (provided, state) => ({
            ...provided,
            borderRadius: "7px",
            textTransform: 'uppercase',
            border: "1px solid transparent",
        }),
        dropdownIndicator: base => ({
            ...base,
            color: "#00366A !important",
            // marginTop: "-18px !important"
        }),
        input: (base, state) => ({
            ...base,
            color: "#393939",
            fontSize: "16px",
            height: "100%",
            fontFamily: 'Poppins',
            textTransform: 'uppercase',
            fontWeight: '400',
            marginTop: "14px",
            '& input': {
                font: 'inherit',
            },
        })
    };

    // custom styles for the select
    const customStylesPopup = {
        placeholder: (defaultStyles) => {
            return {
                ...defaultStyles,
                display: "flex",
                fontFamily: 'Poppins',
                fontWeight: '400',
                textTransform: "capitalize",
                fontSize: '14px',
                color: '#07407C',
            }
        },
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                color: '#07407C',
                fontFamily: 'Poppins',
                fontWeight: '400',
                backgroundColor: 'white',
                textTransform: "capitalize",
                margin: "auto",
                paddingRight: "10px",
                maxWidth: "100%",
                paddingLeft: "10px",
                paddingBottom: "0",
                paddingTop: "0",
            };
        },
        singleValue: base => ({
            ...base,
            color: "#00366A",
            display: "flex",
            fontSize: "14px",
            fontFamily: "Poppins",
            fontWeight: "400",
            textTransform: "capitalize",
        }),
        control: provided => ({
            ...provided,
            height: "40px",
            border: "none",
            backgroundColor: 'white',
            outline: "none",
            '&:hover': { borderColor: 'transparent' },
            textTransform: 'uppercase',
            width: "100%",
            borderRadius: "7px",
            fontFamily: 'Poppins',
            fontWeight: '400',
            boxShadow: "0px 3px 6px rgb(0 0 0 / 16%)",
        }),
        menu: (provided, state) => ({
            ...provided,
            borderRadius: "7px",
            textTransform: 'uppercase',
            border: "1px solid transparent",
        }),
        dropdownIndicator: base => ({
            ...base,
            color: "#00366A !important",
            // marginTop: "-18px !important"
        }),
        input: (base, state) => ({
            ...base,
            color: "#393939",
            fontSize: "16px",
            height: "100%",
            fontFamily: 'Poppins',
            textTransform: 'uppercase',
            fontWeight: '400',
            marginTop: "14px",
            '& input': {
                font: 'inherit',
            },
        })
    };

    //set cost currency
    useEffect(() => {
        if (filterCurrency) {
            setCostCurrency(filterCurrency?.slug);
        }
    }, [filterCurrency])

    //custom select styles
    const customStylesPopupCurr = {
        placeholder: (defaultStyles) => {
            return {
                ...defaultStyles,
                display: "flex",
                fontFamily: 'Poppins',
                fontWeight: '400',
                textTransform: "capitalize",
                fontSize: '14px',
                color: '#07407C',
            }
        },
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                color: '#07407C',
                fontFamily: 'Poppins',
                fontWeight: '400',
                backgroundColor: 'white',
                textTransform: "capitalize",
                margin: "auto",
                paddingRight: "10px",
                maxWidth: "100%",
                paddingLeft: "10px",
                paddingBottom: "0",
                paddingTop: "0",
            };
        },
        singleValue: base => ({
            ...base,
            color: "#00366A",
            display: "flex",
            fontSize: "14px",
            fontFamily: "Poppins",
            fontWeight: "400",
            textTransform: "capitalize",
        }),
        control: provided => ({
            ...provided,
            height: "40px",
            border: "none",
            backgroundColor: 'transparent !important',
            outline: "none",
            '&:hover': { borderColor: 'transparent' },
            textTransform: 'uppercase',
            width: "100%",
            borderRadius: "7px",
            fontFamily: 'Poppins',
            fontWeight: '400',
            boxShadow: "0px 3px 6px rgb(0 0 0 / 16%)",
        }),
        menu: (provided, state) => ({
            ...provided,
            borderRadius: "7px",
            textTransform: 'uppercase',
            border: "1px solid transparent",
        }),
        dropdownIndicator: base => ({
            ...base,
            color: "#00366A !important",
            marginTop: "-18px !important"
        }),
        input: (base, state) => ({
            ...base,
            color: "#393939",
            fontSize: "16px",
            height: "100%",
            fontFamily: 'Poppins',
            textTransform: 'uppercase',
            fontWeight: '400',
            marginTop: "14px",
            '& input': {
                font: 'inherit',
            },
        })
    };

    const handleOperationInputChange = (value, targetOperation, field) => {
        setOperations(prev => (
            {
                ...prev,
                data: [
                    ...prev.data.map(operation => (
                        Number(operation.id) === Number(targetOperation) ? { ...operation, [field]: value } : operation
                    )),
                ]
            }
        ))
        setTargetOperationUpdate({
            value: value,
            operation: targetOperation,
            field: field
        })
    }

    useEffect(() => {
        const submitData = setTimeout(() => {
            const fd = new FormData();
            fd.append('field', targetOperationUpdate.field);
            fd.append('value', targetOperationUpdate.value);
            fd.append('operation', targetOperationUpdate.operation);
            setGeneralDataApiOptions({
                url: 'update-operation',
                method: 'post',
                data: fd,
                showLoader: false,
                callback: res => {
                },
                catch: err => {
                    if (err) {
                        setErrorsReturned(err);
                    }
                }
            })
        }, 500)
        return () => clearTimeout(submitData);
    }, [targetOperationUpdate])

    useEffect(() => {
    }, [operations])

    return (
        <>
            <Layout activePage="contacts">
                {
                    authUser?.id ?
                        <>

                            <div className="row">
                                <div className="col-12">
                                    <div className="row justify-content-between">
                                        <div className="col-auto d-flex pe-0">
                                            <Link to={'/client/' + (user_id) + '/shareholders'} className="m-0 page-titles d-flex align-items-center">
                                                <BackIcon className='me-2 back-icon' /> {generalData?.shareholdersSectionSettings['date-report-title']}
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="col-12">
                                    <div className="row">

                                        <div className="col-12 col-md-8 col-xl-4 pb-2 pt-1">
                                            <input onKeyDown={(e) => (e.key === "Enter" ? slug === 'transactions' ? (setChangeWallet(changeWallet + 1), setTransactionCurrentPage(1)) : (setChangeExpenses(changeExpenses + 1), setExpensesCurrentPage(1)) : null)} name="search" className="table-search add-user-inputs" placeholder={generalData?.fixed_titles['search']} value={searchValue} onChange={(e) => setSearchValue(e.target.value)} />
                                        </div>
                                        <div className="col-12 col-xl-8 pb-2 pt-1">
                                            <div className="row w-100 justify-content-end">
                                                <div style={{ zIndex: "999" }} className="col-12 col-auto filter-picker-expenses postion-relative">
                                                    <Select
                                                        classNamePrefix="filters-date-select"
                                                        className="filters-date-select w-100"
                                                        styles={customStylesDateFilter}
                                                        options={allExpenseFilters()}
                                                        components={{
                                                            IndicatorSeparator: () => null
                                                        }}
                                                        isSearchable={false}
                                                        value={currentFilterExpenses?.value !== 8 ? currentFilterExpenses : (filterDateFrom && filterDateTo ? { value: 8, label: '' + formatDate(filterDateFrom) + ' / ' + formatDate(filterDateTo) + '' } : currentFilterExpenses)}
                                                        onChange={setCurrentFilterExpenses}
                                                    />
                                                </div>
                                                {
                                                    authUser?.user_role_id === 1 ?
                                                        <div style={{ zIndex: "998" }} className="col-auto filters-date-select postion-relative">
                                                            <Select
                                                                classNamePrefix="filter-picker-users"
                                                                className="filters-date-select filter-picker-users w-100"
                                                                styles={customStylesUsersFilter}
                                                                options={allUsers()}
                                                                placeholder={generalData?.fixed_titles['user']}
                                                                components={{
                                                                    IndicatorSeparator: () => null
                                                                }}
                                                                isSearchable={false}
                                                                value={filterByUser}
                                                                onChange={setFilterByUser}
                                                            />
                                                        </div>
                                                        :
                                                        null
                                                }
                                                <div style={{ zIndex: "997" }} className="col-auto  filter-status-expeneses  filters-date-select postion-relative">
                                                    <Select
                                                        classNamePrefix="filter-picker-users"
                                                        className="filter-status-expeneses w-100"
                                                        placeholder={generalData?.fixed_titles['status']}
                                                        styles={customStylesDateFilter}
                                                        options={ExpensesStatuses()}
                                                        components={{
                                                            IndicatorSeparator: () => null
                                                        }}
                                                        isSearchable={false}
                                                        value={filterExpensesStatus}
                                                        onChange={setFilterExpensesStatus}
                                                    />
                                                </div>
                                                <div style={{ zIndex: "995" }} className="col-auto filter-picker-expenses postion-relative">
                                                    <Select
                                                        classNamePrefix="filters-currencies-select"
                                                        className="filters-date-select filters-currencies-select w-100"
                                                        styles={customStylesDateFilter}
                                                        options={allCurrenciesFilters()}
                                                        components={{
                                                            IndicatorSeparator: () => null
                                                        }}
                                                        isSearchable={false}
                                                        value={filterCurrency}
                                                        onChange={setFilterCurrency}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                                <div className="col-12 pt-2 pb-3">
                                    <div className="row">
                                        <div className="col-12 pt-lg-0 height-100">
                                            <div className={'costs-box-styling bg-white'}>

                                                <div className="col-12 pt-1">
                                                    <div className="table-wrapper-settings">
                                                        <table className="users-table" width="100%">
                                                            <thead>
                                                                <tr>
                                                                    <td></td>
                                                                    <td className="table-sorting" style={{ minWidth: "300px" }}>{generalData?.shareholdersSectionSettings['shareholder-name-title']}</td>
                                                                    <td className="table-sorting" style={{ minWidth: "300px" }}>{generalData?.shareholdersSectionSettings['operation-type-title']}</td>
                                                                    <td className="table-sorting" style={{ minWidth: "300px" }}>{generalData?.shareholdersSectionSettings['par-value-title']}</td>
                                                                    <td className="table-sorting" style={{ minWidth: "300px" }}>{generalData?.shareholdersSectionSettings['shares-title']}</td>
                                                                    <td className="table-sorting" style={{ minWidth: "300px" }}>{generalData?.shareholdersSectionSettings['loan-title']}</td>
                                                                    <td className="table-sorting" style={{ minWidth: "300px" }}>{generalData?.shareholdersSectionSettings['note-title']}</td>
                                                                    <td className="table-sorting" style={{ minWidth: "300px" }}>{generalData?.shareholdersSectionSettings['amount-title']}</td>
                                                                    <td className="table-sorting" style={{ minWidth: "300px" }}>{generalData?.shareholdersSectionSettings['nominal-title']}</td>
                                                                    <td className="table-sorting" style={{ minWidth: "300px" }}>{generalData?.shareholdersSectionSettings['premium-title']}</td>
                                                                    <td className="table-sorting" style={{ minWidth: "300px" }}>{generalData?.shareholdersSectionSettings['premium-share-title']}</td>
                                                                    <td className="table-sorting" style={{ minWidth: "300px" }}>{generalData?.shareholdersSectionSettings['from-title']}</td>
                                                                    <td className="table-sorting" style={{ minWidth: "300px" }}>{generalData?.shareholdersSectionSettings['to-title']}</td>
                                                                    <td className="table-sorting" style={{ minWidth: "300px" }}>{generalData?.shareholdersSectionSettings['date-title']}</td>
                                                                    <td className="table-sorting" style={{ minWidth: "300px" }}>{generalData?.shareholdersSectionSettings['execution-date-title']}</td>
                                                                    <td className="table-sorting" style={{ minWidth: "300px" }}>{generalData?.shareholdersSectionSettings['ubo-title']}</td>
                                                                    <td className="table-sorting" style={{ minWidth: "300px" }}>{generalData?.shareholdersSectionSettings['ubo-shares-title']}</td>
                                                                    <td className="table-sorting" style={{ minWidth: "300px" }}>{generalData?.shareholdersSectionSettings['comment-title']}</td>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    operations?.data?.length > 0 &&
                                                                    operations.data?.map((operation, index) => (
                                                                        <tr key={index} className="my-3" >
                                                                            <td className="text-end p-0 pe-2 position-relative" >

                                                                            </td>
                                                                            <td>{operation?.id}</td>
                                                                            <td>{operation?.to.name}</td>
                                                                            <td>{operation?.share_operation.title}</td>
                                                                            <td>{profileClient?.share_values}</td>
                                                                            <td>{operation?.number_of_shares}</td>
                                                                            <td>{operation?.loan_type?.title}</td>
                                                                            <td>
                                                                                {
                                                                                    operation?.uploaded_files &&
                                                                                    <a rel="noreferrer" target='_blank' href={operation?.uploaded_files?.full_path_file} >icon</a>
                                                                                }
                                                                            </td>
                                                                            <td>{operation?.amount}</td>
                                                                            <td>
                                                                                {
                                                                                    operation?.share_operation.slug !== 'transfer-of-shares' && operation?.share_operation?.slug !== 'initial-subscription' &&
                                                                                    <div className="row">
                                                                                        <div className="col-6">
                                                                                            <div className="w-100 h-100 d-flex  justify-content-between">
                                                                                                <span>
                                                                                                    {profileClient.share_values}
                                                                                                </span>
                                                                                                <span>
                                                                                                    x
                                                                                                </span>
                                                                                                <span>
                                                                                                    {operation.number_of_shares}

                                                                                                </span>
                                                                                                <span>
                                                                                                    =
                                                                                                </span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-6">
                                                                                            <div className="row">
                                                                                                <div className="col">
                                                                                                    <p className="text-center mb-0">
                                                                                                        {operation?.nominal_total_value}
                                                                                                    </p>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="row">
                                                                                                <div className="col">
                                                                                                    <input onChange={({ target }) => { handleOperationInputChange(target.value, operation.id, 'editable_nominal_total_value') }} className="transaction-editable-input" value={operation?.editable_nominal_total_value} />
                                                                                                </div>
                                                                                            </div>

                                                                                        </div>

                                                                                    </div>
                                                                                }

                                                                            </td>
                                                                            <td>
                                                                                {
                                                                                    operation?.share_operation.slug !== 'transfer-of-shares' && operation?.share_operation?.slug !== 'initial-subscription' &&
                                                                                    <div className="row">
                                                                                        <div className="col-6">
                                                                                            <div className="w-100 h-100 d-flex justify-content-between">
                                                                                                <span>
                                                                                                    {operation?.amount}
                                                                                                </span>
                                                                                                <span>
                                                                                                    -
                                                                                                </span>
                                                                                                <span>
                                                                                                    {operation?.nominal_total_value}
                                                                                                </span>
                                                                                                <span>
                                                                                                    =
                                                                                                </span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-6">
                                                                                            <div className="row">
                                                                                                <div className="col">
                                                                                                    <p className="text-center mb-0">
                                                                                                        {operation?.premium_total_value}
                                                                                                    </p>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="row">
                                                                                                <div className="col">
                                                                                                    <input onChange={({ target }) => { handleOperationInputChange(target.value, operation.id, 'editable_premium_total_value') }} className="transaction-editable-input" value={operation?.editable_premium_total_value} />
                                                                                                </div>
                                                                                            </div>

                                                                                        </div>

                                                                                    </div>
                                                                                }
                                                                            </td>
                                                                            <td className="overflow-hidden">
                                                                                {
                                                                                    operation?.share_operation.slug !== 'transfer-of-shares' && operation?.share_operation?.slug !== 'initial-subscription' &&
                                                                                    <div className="row">
                                                                                        <div className="col-6">
                                                                                            <div className="w-100 h-100 d-flex justify-content-between">
                                                                                                <span>
                                                                                                    {operation?.premium_total_value}
                                                                                                </span>
                                                                                                <span>
                                                                                                    /
                                                                                                </span>
                                                                                                <span>
                                                                                                    {profileClient?.share_values}
                                                                                                </span>
                                                                                                <span>
                                                                                                    =
                                                                                                </span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-6">
                                                                                            <div className="row">
                                                                                                <div className="col">
                                                                                                    <p className="text-center mb-0">
                                                                                                        {operation?.premium_share}
                                                                                                    </p>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="row">
                                                                                                <div className="col">
                                                                                                    <input onChange={({ target }) => { handleOperationInputChange(target.value, operation.id, 'editable_premium_share') }} className="transaction-editable-input" value={operation?.editable_premium_share} />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                }
                                                                            </td>
                                                                            <td>{operation?.from?.name}</td>
                                                                            <td>{operation?.to?.name}</td>
                                                                            <td>{operation?.date}</td>
                                                                            <td>{operation?.execution_date}</td>
                                                                            <td>
                                                                                {
                                                                                    operation?.shareholder?.ubos.length > 0 &&
                                                                                    operation.shareholder.ubos.map((ubo) => (
                                                                                        <div className="my-2">{ubo.email}</div>
                                                                                    ))
                                                                                }
                                                                            </td>
                                                                            <td>
                                                                                {
                                                                                    operation?.shareholder?.ubos.length > 0 &&
                                                                                    operation.shareholder.ubos.map((ubo) => (
                                                                                        <div className="my-2">{ubo.shares}</div>
                                                                                    ))
                                                                                }
                                                                            </td>
                                                                            <td>{operation?.comment}</td>
                                                                        </tr>
                                                                    ))

                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                {
                                                    operationsLastPage > 1 ?
                                                        <div className="col-12 text-center py-2 d-flex justify-content-center">
                                                            <Pagination
                                                                className="pagination-bar"
                                                                currentPage={operationsCurrentPage}
                                                                totalCount={6 * operationsLastPage}
                                                                pageSize={6}
                                                                onPageChange={(e) => setOperationsCurrentPage(e)}
                                                            />
                                                        </div>
                                                        :
                                                        null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                        :
                        null
                }
                {
                    //custom dates popup
                    customDatesPopup ?
                        <div id="errors-popup" className=" error-popup-api d-flex align-items-center justify-content-center px-3 fade-in">
                            <div className="pop-up-api-box">
                                <CloseIcon className="cursor-pointer" onClick={() => { setCurrentFilterExpenses(allExpenseFilters()[2]); setCustomDatesPopup(false); setFilteDateFrom(new Date()); setFilteDateTo(new Date()) }} />
                                <div className="text-center ">
                                    <div className="containter-fluid">
                                        <div className="row">
                                            <div className="col-12">
                                                <h1 className="pop-up-title m-0 py-2 color-dark-blue">Custom Dates</h1>
                                            </div>
                                            <div className="col-12 pt-2 pb-4">
                                                <div className="row justify-content-center">
                                                    <div className="col-12 col-md-6 pt-2">
                                                        <div className="postion-relative text-start justify-content-start w-100">
                                                            <DatePicker
                                                                className="popup-date add-user-inputs"
                                                                placeholderText={'Date'}
                                                                showMonthDropdown
                                                                showYearDropdown
                                                                dropdownMode="select"
                                                                dateFormat="dd/MM/yyyy"
                                                                onChange={onChangeDate}
                                                                startDate={filterDateFrom}
                                                                endDate={filterDateTo}
                                                                selectsRange
                                                                minDate={addYears(new Date(), -100)}
                                                                maxDate={new Date()}
                                                                locale={localStorage.getItem("BlpLanguage")}
                                                            />
                                                        </div>
                                                        {
                                                            errorsReturned?.date ?
                                                                <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text">{errorsReturned?.date}</h5>
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 d-flex justify-content-center">
                                                <button type="button" onClick={() => { setCustomDatesPopup(false); slug === 'transactions' ? setChangeWallet(changeWallet + 1) : setChangeExpenses(changeExpenses + 1) }} className="popup-button my-auto">Filter</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        null
                }

                {
                    //expenses popup
                    addExpenses ?
                        <div id="errors-popup" className=" error-popup-api d-flex align-items-center justify-content-center px-3 fade-in">
                            <div className="pop-up-api-box">
                                <CloseIcon className="cursor-pointer" onClick={(e) => closeAddExpense(e)} />
                                <div className="text-center ">
                                    <div className="containter-fluid">
                                        <div className="row">
                                            <div className="col-12">
                                                <h1 className="pop-up-title m-0 py-2 color-dark-blue">{currenEditExpense ? generalData?.users_cost_settings['edit-expenses-title'] : generalData?.users_cost_settings['add-expenses-title']}</h1>
                                            </div>
                                            <form onSubmit={() => { }}>
                                                <div className="col-12 pt-2 pb-4">
                                                    <div className="row">
                                                        <div className="col-12 col-md-6 pt-2">
                                                            <div className="postion-relative text-start justify-content-start w-100">
                                                                <Select
                                                                    classNamePrefix="popup-select"
                                                                    className="popup-select w-100"
                                                                    styles={customStylesPopup}
                                                                    options={allExpensesTypes()}
                                                                    components={{
                                                                        IndicatorSeparator: () => null
                                                                    }}
                                                                    placeholder={generalData?.users_cost_settings['expenses-type']}
                                                                    isSearchable={false}
                                                                    value={addExpensesType ? addExpensesType : null}
                                                                    onChange={setAddExpensesType} />
                                                            </div>
                                                            {
                                                                errorsReturned?.expense_type_id ?
                                                                    <h5 className="pt-2 m-0 d-flex flex-start text-start pop-errors-text">{errorsReturned?.expense_type_id}</h5>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                        <div className="col-12 col-md-6 pt-2">
                                                            <div className="postion-relative text-start justify-content-start w-100">
                                                                <Select
                                                                    classNamePrefix="popup-select"
                                                                    className="popup-select w-100"
                                                                    styles={customStylesPopup}
                                                                    options={allCurrencies()}
                                                                    components={{
                                                                        IndicatorSeparator: () => null
                                                                    }}
                                                                    placeholder={generalData?.users_cost_settings['currency']}
                                                                    isSearchable={false}
                                                                    value={addExpensesCurrency ? addExpensesCurrency : null}
                                                                    onChange={setAddExpensesCurrency} />
                                                            </div>
                                                            {
                                                                errorsReturned?.currency_id ?
                                                                    <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text">{errorsReturned?.currency_id}</h5>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                        <div className="col-12 col-md-6 pt-2">
                                                            <input name="amount" value={addExpensesAmount} onChange={(e) => setAddExpensesAmount(e.target.value)} className="add-user-inputs" placeholder={generalData?.users_cost_settings['amount']} />
                                                            {
                                                                errorsReturned?.amount ?
                                                                    <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text">{errorsReturned?.amount}</h5>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                        <div className="col-12 col-md-6 pt-2">
                                                            <div className="postion-relative text-start justify-content-start w-100">
                                                                <DatePicker
                                                                    className="popup-date add-user-inputs"
                                                                    placeholderText={'Date'}
                                                                    value={addExpensesDate ? addExpensesDate : ''}
                                                                    showMonthDropdown
                                                                    showYearDropdown
                                                                    dropdownMode="select"
                                                                    selected={addExpensesDate}
                                                                    dateFormat="d MMMM yyyy"
                                                                    onChange={(date) => setAddExpensesDate(date)}
                                                                    minDate={addYears(new Date(), -21)}
                                                                    maxDate={new Date()}
                                                                    locale={localStorage.getItem("BlpLanguage")}
                                                                />
                                                            </div>
                                                            {
                                                                errorsReturned?.date ?
                                                                    <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text">{errorsReturned?.date}</h5>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                        <div className="col-12 col-md-6 pt-2 position-relative">
                                                            <input hidden id='upload-document-file' type="file" onChange={(e) => { documentHandler(e.target.files) }} />
                                                            <label className="pop-upload-file position-relative" htmlFor="upload-document-file">{documentLink ? generalData?.documentsSectionSettings['change-file-placeholder'] : generalData?.documentsSectionSettings['upload-file-placeholder']}</label>

                                                            <AttachIcon className="download-icon download-svg-popup" />
                                                            {
                                                                errorsReturned?.file ?
                                                                    <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text text-start">{errorsReturned?.file}</h5>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                        <div className="col-12 pt-2">
                                                            <textarea type="text-area" name="note" className="add-user-inputs" placeholder={generalData?.users_cost_settings['note']} value={addExpensesNote} onChange={(e) => setAddExpensesNote(e.target.value)}></textarea>
                                                        </div>
                                                        <div className="col-12 pt-2 d-flex my-auto">
                                                            <div onClick={() => addExpensesBillable === 0 ? setAddExpensesBillable(1) : setAddExpensesBillable(0)} className={'my-auto billable-div ' + (addExpensesBillable ? 'active' : '')}>
                                                            </div>
                                                            <div onClick={() => addExpensesBillable === 0 ? setAddExpensesBillable(1) : setAddExpensesBillable(0)} className="ms-2 billable-popup">{generalData?.users_cost_settings['billable']}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 d-flex justify-content-center">
                                                    <button type="submit" className="popup-button my-auto">{currenEditExpense ? generalData?.users_cost_settings['edit-expenses-button'] : generalData?.users_cost_settings['add-expenses-button']}</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        null
                }
                {
                    //delete popup
                    deletePopup ?
                        <div id="errors-popup" className=" error-popup-api d-flex align-items-center justify-content-center px-3 fade-in">
                            <div className="pop-up-api-box">
                                <CloseIcon className="cursor-pointer" onClick={() => { setDeletePopup(null); setDeleteType(null); setOpenEditTransaction(null); setEditExpense(null); }} />
                                <div className="text-center ">
                                    <div className="containter-fluid">
                                        <div className="row d-flex justify-content-center align-items-center">
                                            <div className="col-9 col-sm-12 d-flex justify-content-center">
                                                <h1 className="pop-up-title m-0 pt-3 color-dark-blue">{generalData?.settings_section_settings['delete-popup-title']}</h1>
                                            </div>
                                            <div className="col-12 d-flex justify-content-center pt-4 pb-2">
                                                {/* <button onClick={(e) => deleteAction(e)} type="button" className="popup-button my-auto mx-0 mx-md-2 w-auto px-3">{generalData?.settings_section_settings['delete-popup-first-button']}</button> */}
                                                <button onClick={() => { setDeletePopup(''); setDeleteType(''); setOpenEditTransaction(null); setEditExpense(null); }} type="button" className="auth-button border-dark-blue my-auto mx-2 background-transparent w-auto px-3">{generalData?.settings_section_settings['delete-popup-second-button']}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        null
                }

                {
                    //transactions popup
                    addTransaction ?
                        <div id="errors-popup" className=" error-popup-api d-flex align-items-center justify-content-center px-3 fade-in">
                            <div className="pop-up-api-box">
                                <CloseIcon className="cursor-pointer" onClick={(e) => closeAddTransaction(e)} />
                                <div className="text-center ">
                                    <div className="containter-fluid">
                                        <div className="row">
                                            <div className="col-12">
                                                <h1 className="pop-up-title m-0 py-2 color-dark-blue">{currenEditTransaction ? generalData?.users_cost_settings['edit-transaction-title'] : generalData?.users_cost_settings['add-transaction-title']}</h1>
                                            </div>
                                            <form onSubmit={submitTransactions}>
                                                <div className="col-12 pt-2 pb-4">
                                                    <div className="row">
                                                        <div className="col-12 col-md-6 pt-2">
                                                            <div className="postion-relative text-start justify-content-start w-100">
                                                                <Select
                                                                    classNamePrefix="popup-select"
                                                                    className="popup-select w-100"
                                                                    styles={customStylesPopup}
                                                                    options={allTransactionTypes()}
                                                                    components={{
                                                                        IndicatorSeparator: () => null
                                                                    }}
                                                                    placeholder={generalData?.users_cost_settings['transaction-type']}
                                                                    isSearchable={false}
                                                                    filterOption={(e) => filterTransactions(e)}
                                                                    value={transactionType}
                                                                    onChange={setTransactionType} />
                                                            </div>
                                                            {
                                                                errorsReturned?.transaction_type_id ?
                                                                    <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text">{errorsReturned?.transaction_type_id}</h5>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                        <div className="col-12 col-md-6 pt-2">
                                                            <div className="position-relative text-start justify-content-start w-100">
                                                                <input name="amount" value={transactionAmount} onChange={(e) => setTransactionAmount(e.target.value)} className="add-user-inputs" placeholder={generalData?.users_cost_settings['amount']} />
                                                                <Select
                                                                    classNamePrefix="curr-select"
                                                                    className="curr-select w-100"
                                                                    styles={customStylesPopupCurr}
                                                                    options={allCurrencies()}
                                                                    components={{
                                                                        IndicatorSeparator: () => null
                                                                    }}
                                                                    placeholder={generalData?.users_cost_settings['curr-transaction']}
                                                                    isSearchable={false}
                                                                    value={transactionCurrency ? transactionCurrency : null}
                                                                    onChange={setTransactionCurrency} />
                                                            </div>
                                                            {
                                                                errorsReturned?.currency_id ?
                                                                    <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text">{errorsReturned?.currency_id}</h5>
                                                                    :
                                                                    null
                                                            }
                                                            {
                                                                errorsReturned?.amount ?
                                                                    <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text">{errorsReturned?.amount}</h5>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                        <div className="col-12 col-md-6 pt-2">
                                                            <div className="postion-relative text-start justify-content-start w-100">
                                                                <DatePicker
                                                                    className="popup-date add-user-inputs"
                                                                    placeholderText={generalData?.users_cost_settings['date']}
                                                                    value={addTransactionDate ? addTransactionDate : ''}
                                                                    showMonthDropdown
                                                                    showYearDropdown
                                                                    dropdownMode="select"
                                                                    selected={addTransactionDate}
                                                                    dateFormat="d MMMM yyyy"
                                                                    onChange={(date) => setAddTransactionDate(date)}
                                                                    minDate={addYears(new Date(), -21)}
                                                                    maxDate={new Date()}
                                                                    locale={localStorage.getItem("BlpLanguage")}
                                                                />
                                                            </div>
                                                            {
                                                                errorsReturned?.date ?
                                                                    <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text">{errorsReturned?.date}</h5>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                        <div className="col-12 col-md-6 pt-2">
                                                            <div className="postion-relative text-start justify-content-start w-100">
                                                                <Select
                                                                    classNamePrefix="popup-select"
                                                                    className="popup-select w-100"
                                                                    styles={customStylesUsersPopup}
                                                                    options={allUsers()}
                                                                    components={{
                                                                        IndicatorSeparator: () => null
                                                                    }}
                                                                    placeholder={generalData?.users_cost_settings['transaction-to']}
                                                                    isSearchable={false}
                                                                    value={transactionTo ? transactionTo : null}
                                                                    onChange={setTransactionTo} />
                                                            </div>
                                                            {
                                                                errorsReturned?.receiver_id ?
                                                                    <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text">{errorsReturned?.receiver_id}</h5>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                        <div className="col-12 pt-2">
                                                            <textarea type="text-area" name="note" className="add-user-inputs" placeholder={generalData?.users_cost_settings['note']} value={addTransactionNote} onChange={(e) => setAddTransactionNote(e.target.value)}></textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 d-flex justify-content-center">
                                                    <button type="submit" className="popup-button my-auto">{currenEditTransaction ? generalData?.users_cost_settings['edit-transaction-button'] : generalData?.users_cost_settings['add-transaction-button']}</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        null
                }
                <Api options={usersApiOptions} />
                <Api options={generalDataApiOptions} />
                <Api options={expensesCall} />
            </Layout>
        </>
    )
}