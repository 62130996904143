import 'react-calendar/dist/Calendar.css';
import React, { useContext, useEffect, useRef, useState } from 'react';
import Select from 'react-select';
import GlobalState from '../contexts/GlobalState';
import { CompanyIcon, IndividualIcon, MattersIcon, NgoIcon, TasksIcon } from './Icons';

export default function RelationsSelect(props) {

    const { generalDataGlobal } = useContext(GlobalState);
    const [generalData,] = generalDataGlobal;
    const ref = useRef(null);
    const { excludeValue, isDisabled, excludeMultipleValues, excludeMatters, excludeTasks, className, setActiveSelect = () => { }, activeSelect = 0, count, editInput } = props;

    const [showCustomSelectMenu, setShowCustomSelectMenu] = useState(false);
    const [customRelationFilter, setCustomRelationFilter] = useState(!props?.selectedRelation && props.defaultSelected);


    //get all relations from general data
    const allRelations = () => {
        const relOptions = props.profile?.relation_names?.map(
            singleRel => ({ value: singleRel.slug + '-' + singleRel.id, label: singleRel?.title, slug: singleRel.slug, image: singleRel.image, client_type: singleRel.client_type }))
        return relOptions;
    }

    //get all individual relations
    const individualOnlyRelations = () => {
        const relOptions = props.profile?.individual_only?.filter(single => single.title !== excludeValue?.slug).filter(single => !excludeMultipleValues?.includes(single.title)).map(singleRel => {
            return {
                title: singleRel.title,
                type: singleRel.type,
                value: singleRel.slug + '-' + singleRel.id, label: singleRel?.title,
                slug: singleRel.slug,
                image: singleRel.image,
                client_type: singleRel.client_type
            }
        })
        return relOptions;
    }


    //get users with individual relations
    const individualRelations = () => {
        const relOptions = props.profile?.individual_relations?.filter(single => single.title !== excludeValue?.slug).filter(single => !excludeMultipleValues?.includes(single.title)).map(
            singleRel => ({ type: singleRel.type, value: singleRel.slug + '-' + singleRel.id, label: singleRel?.title, slug: singleRel.slug, image: singleRel.image, client_type: singleRel.client_type }))
        return relOptions;
    }

    // get all company relations
    const companyRelations = () => {
        const relOptions = props.profile?.company_relations?.filter(single => single.title !== excludeValue?.slug).filter(single => !excludeMultipleValues?.includes(single.title)).map(
            singleRel => (singleRel.title !== excludeValue?.label && { type: singleRel.type, value: singleRel.slug + '-' + singleRel.id, label: singleRel?.title, slug: singleRel.slug, image: singleRel.image, client_type: singleRel.client_type }))
        return relOptions;
    }

    
    

    //get all ngo relations
    const ngoRelations = () => {
        const relOptions = props.profile?.ngo_relations?.filter(single => single.title !== excludeValue?.slug).filter(single => !excludeMultipleValues?.includes(single.title)).map(
            singleRel => (singleRel.title !== excludeValue?.label && { type: singleRel.type, value: singleRel.slug + '-' + singleRel.id, label: singleRel?.title, slug: singleRel.slug, image: singleRel.image, client_type: singleRel.client_type }))
        return relOptions;
    }

    // get all client types
    const clientTypes = () => {
        const clientTy = generalData?.client_types?.map(
            singleType => ({ slug: singleType?.slug, value: singleType.id, label: singleType.title }))
        return clientTy;
    }

    const mattersRleations = () => {
        const relOptions =
            props.profile?.matter_relations?.filter(single => single.title !== excludeValue?.slug).filter(single => !excludeMultipleValues?.includes(single.title)).map(
                singleRel => ({ type: singleRel.type, value: singleRel.slug + '-' + singleRel.id, label: singleRel?.title, slug: singleRel.slug, image: singleRel.image, client_type: 4 }))
        return relOptions;
    }


    const tasksRelations = () => {
        const relOptions =
            props.profile?.tasks_relations?.filter(single => single.title !== excludeValue?.slug).filter(single => !excludeMultipleValues?.includes(single.title)).map(
                singleRel => ({ type: singleRel.type, value: singleRel.slug + '-' + singleRel.id, label: singleRel?.title, slug: singleRel.slug, image: singleRel.image, client_type: 5 }))
        return relOptions;
    }

    // custom select styles
    const customStylesPopupMulti = {
        placeholder: (defaultStyles) => {
            return {
                ...defaultStyles,
                display: "flex",
                fontFamily: 'Poppins',
                fontWeight: '400',
                textTransform: "capitalize",
                fontSize: '14px',
                color: '#07407C',
            }
        },
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                color: '#07407C',
                fontSize: "14px",
                fontFamily: 'Poppins',
                fontWeight: '400',
                backgroundColor: 'white',
                textTransform: "capitalize",
                margin: "auto",
                paddingRight: "10px",
                maxWidth: "100%",
                paddingLeft: "10px",
                paddingBottom: "0",
                paddingTop: "0",
            };
        },
        singleValue: base => ({
            ...base,
            color: "#00366A",
            display: "flex",
            fontSize: "14px",
            fontFamily: "Poppins",
            fontWeight: "400",
            textTransform: "capitalize",
        }),
        control: provided => ({
            ...provided,
            height: "40px",
            border: "none",
            backgroundColor: 'white',
            outline: "none",
            '&:hover': { borderColor: 'transparent' },
            textTransform: 'uppercase',
            width: "100%",
            fontSize: "14px",
            borderRadius: "7px",
            fontFamily: 'Poppins',
            fontWeight: '400',
            boxShadow: !editInput ? "0px 3px 6px rgb(0 0 0 / 16%)" : '',
        }),
        menu: (provided, state) => ({
            ...provided,
            borderRadius: "7px",
            textTransform: 'uppercase',
            border: "1px solid transparent",
        }),
        dropdownIndicator: base => ({
            ...base,
            color: "#00366A !important",
            marginTop: "-18px !important"
        }),
        input: (base, state) => ({
            ...base,
            color: "#00366A",
            fontSize: "16px",
            height: "100%",
            fontFamily: 'Poppins',
            textTransform: 'uppercase',
            fontWeight: '400',
            minWidth: '40px !important'
        })
    };

    /* handle outside click */
    const useOutsideClick = (ref, callback) => {
        const handleClick = e => {
            if (ref.current && !ref.current.contains(e.target)) {
                callback();
            }
        };

        useEffect(() => {
            window?.document?.addEventListener("click", handleClick);

            return () => {
                window?.document?.removeEventListener("click", handleClick);
            };
        });
    };

    /* handle outside click */
    useOutsideClick(ref, () => {
        setShowCustomSelectMenu(false);
    });

    //to set data when in edit mode
    useEffect(() => {
        if (props?.selectedRelation) {
            if (props?.selectedRelation?.slug === 'user' || props?.selectedRelation?.type === 'individual' || props?.selectedRelation?.client_type === 'individual') {
                setCustomRelationFilter('individual');
            }
            if (props?.selectedRelation?.slug === 'ngo' || props?.selectedRelation?.type === 'ngo' || props?.selectedRelation?.client_type === 'ngo') {
                setCustomRelationFilter('ngo');
            }
            if (props?.selectedRelation?.slug === 'company' || props?.selectedRelation?.type === 'company' || props?.selectedRelation?.client_type === 'company') {
                setCustomRelationFilter('company');
            }
            if (props?.selectedRelation?.slug === 'litigation' || props?.selectedRelation?.type === 'litigation' || props?.selectedRelation?.client_type === 'litigation') {
                setCustomRelationFilter('matters');
            }
            if (props?.selectedRelation?.slug === 'task' || props?.selectedRelation?.type === 'task' || props?.selectedRelation?.client_type === 'task') {
                setCustomRelationFilter('tasks');
            }
        }
    }, [props?.selectedRelation]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setShowCustomSelectMenu(activeSelect === count)
    }, [activeSelect]);// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <div className={`${isDisabled ? 'disabled' : ''} ${className ? className : ''} position-relative text-start justify-content-start w-100`}>
                <div ref={ref} onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (!isDisabled) {
                        setActiveSelect(count)
                        setShowCustomSelectMenu(!showCustomSelectMenu);
                    }
                }} className="select-drop-type" style={{ boxShadow : editInput ? 'none' : ''   }}>
                    {
                        customRelationFilter === 'individual' ?
                            <>
                                <IndividualIcon />
                            </>
                            :
                            customRelationFilter === 'ngo' ?

                                <NgoIcon />
                                :
                                customRelationFilter === 'matters'
                                    ?
                                    <MattersIcon className="active" />
                                    :
                                    customRelationFilter === 'tasks'
                                        ?
                                        <TasksIcon className="active" />
                                        :
                                        <CompanyIcon />

                    }
                    <div className={'menu-select-type ' + (showCustomSelectMenu ? ' d-block' : '')}>
                        {
                            clientTypes().map((singleType, typeIndex) => (
                                <p onClick={() => { if (!isDisabled) { setCustomRelationFilter(singleType?.slug); setShowCustomSelectMenu(false); } }} key={typeIndex} className="ps-2 mb-2">
                                    {
                                        singleType?.slug === 'individual' ?
                                            <>
                                                <IndividualIcon />
                                            </>
                                            :
                                            singleType?.slug === 'ngo' ?
                                                <NgoIcon />
                                                :
                                                <CompanyIcon />

                                    }
                                    {singleType?.label}
                                </p>
                            ))

                        }
                        {
                            !excludeMatters &&
                            <p onClick={() => { setCustomRelationFilter('matters') }} className="ps-2 mb-2">
                                <MattersIcon className="active" />
                                {generalData?.fixed_titles['matters']}
                            </p>
                        }
                        {
                            !excludeTasks &&
                            <p onClick={() => { setCustomRelationFilter('tasks') }} className="ps-2 mb-2">
                                <TasksIcon className="active" />
                                {generalData?.fixed_titles['tasks']}
                            </p>
                        }
                    </div>
                </div>
                <div className='w-100 h-100 position-relative'>

                    <Select
                        classNamePrefix="relations-select"
                        className="w-100 relations-select"
                        styles={customStylesPopupMulti}
                        options={
                            customRelationFilter === 'individual'
                                ?
                                (
                                    props?.withUsers === 1
                                        ?
                                        individualRelations()
                                        :
                                        individualOnlyRelations()
                                )
                                :
                                customRelationFilter === 'ngo'
                                    ?
                                    ngoRelations()
                                    :

                                    customRelationFilter === 'company'
                                        ?
                                        companyRelations()
                                        :
                                        customRelationFilter === 'matters' ?
                                            mattersRleations()
                                            :
                                            customRelationFilter === 'tasks' ?
                                                tasksRelations()
                                                :
                                                allRelations()
                        }
                        components={{
                            IndicatorSeparator: () => null
                        }}
                        placeholder={props.placeholder ? props.placeholder : generalData?.website_titles['name']}
                        isSearchable={true}
                        value={props.selectedRelation}
                        onChange={props.setSelectedRelation}
                        isDisabled={isDisabled}
                    />
                    {/* <input
                        type="text"
                        className='position-x-center'
                        style={{
                            opacity:0,
                            width:0,
                            height:0,
                        }}
                        value={props?.selectedRelation.label || ''}
                        required
                    /> */}
                </div>

            </div >
        </>
    )
}