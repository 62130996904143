import { useContext, useState, useEffect } from "react";
import GlobalState from '../contexts/GlobalState';
import { useParams } from "react-router";
import React from 'react';
import Api from "../components/Api";
import "react-datepicker/dist/react-datepicker.css";
import 'swiper/css';

import Layout from "../components/Layout";
import { Link } from "react-router-dom";

import { registerLocale } from "react-datepicker";
import ar from 'date-fns/locale/ar';
import TaskHistoryComponent from "../components/TaskHistoryComponent";
registerLocale('ar', ar)

export default function TaskHistory(props) {

    const { generalDataGlobal, authUserGlobal } = useContext(GlobalState);
    const [generalData] = generalDataGlobal;
    const [authUser,] = authUserGlobal;
    const router = useParams();
    const { task_id } = router;
    const [apiOptions, setApiOptions] = useState({});
    const [taskHistory, setTaskHistory] = useState({});

    useEffect(() => {
        if (authUser) {

            setApiOptions({
                url: 'get-task-data',
                method: 'post',
                data: {
                    id: Number(task_id),
                },
                callback: (res) => {
                    let activeTask = res?.data?.task;
                    let formattedHistory = []
                    activeTask?.history?.map(log => {
                        if (Object?.keys(JSON?.parse(log?.new_values)).length > 0 || log?.auditable_type === 'App/TaskAssignee') {
                            if (Object?.keys(JSON?.parse(log?.old_values)).length > 1 && log?.event !== 'created') {
                                Object?.keys(JSON?.parse(log?.old_values)).map((key, i) => {
                                    formattedHistory?.push({
                                        ...log,
                                        new_values: JSON.stringify({ [key]: Object?.values(JSON?.parse(log?.new_values))[i] }),
                                        old_values: JSON.stringify({ [key]: Object?.values(JSON?.parse(log?.old_values))[i] })

                                    })
                                    return null
                                })
                            } else {
                                formattedHistory?.push(log)
                            }
                        }
                        // Object?.values(JSON?.parse(log?.old_values))
                        // Object?.keys(JSON?.parse(log?.old_values))?.map((val, i) => {
                        //     logger[i][val] = Object?.values(JSON.parse(log?.old_values))[i]
                        // })
                        return null
                    })
                    setTaskHistory(formattedHistory)
                }, catch: err => {
                    if (err) {
                        // history('/not-found');
                    }
                }
            })
        }

    }, [task_id]);// eslint-disable-line react-hooks/exhaustive-deps


    return (
        <>
            <Layout activePage={'tasks'}>
                {
                    authUser?.id ?
                        <>

                            <div className="row py-2 flex-wrap">
                                <div className="col-12">
                                    <div className="row justify-content-between">
                                        <div className="col-auto d-flex pe-0">
                                            <Link to={`/tasks/${task_id}/general-info`} className="m-0 page-titles d-flex align-items-center">
                                                <svg className='me-2 back-icon' xmlns="http://www.w3.org/2000/svg" width="6.861" height="12" viewBox="0 0 6.861 12">
                                                    <path id="Icon_ionic-ios-arrow-down" data-name="Icon ionic-ios-arrow-down" d="M12.19,16.039,16.727,11.5a.854.854,0,0,1,1.211,0,.865.865,0,0,1,0,1.215L12.8,17.857a.856.856,0,0,1-1.183.025L6.438,12.717A.858.858,0,0,1,7.649,11.5Z" transform="translate(18.107 -6.188) rotate(90)" fill="#00366a" />
                                                </svg> {generalData?.tasksFixedTitles['tasks-history']}
                                            </Link>
                                        </div>
                                        {/* <div className="col-auto ps-0">
                                            <button className=" popup-button my-auto mx-0 mx-md-2 w-auto px-3" onClick={(e) => exportReport(e)}>
                                                <svg className="export-button-svg me-1" xmlns="http://www.w3.org/2000/svg" width="13.614" height="12.158" viewBox="0 0 13.614 12.158">
                                                    <path id="Icon_awesome-file-export" data-name="Icon awesome-file-export" d="M8.744,2.776a.545.545,0,0,0-.159-.385L6.355.159A.546.546,0,0,0,5.968,0H5.829V2.915H8.744ZM13,7.013l-2.179-2.2a.365.365,0,0,0-.624.257V6.558H4.35V8.015H10.2V9.5a.365.365,0,0,0,.624.257L13,7.56A.388.388,0,0,0,13,7.013Zm-8.63.638V6.922a.365.365,0,0,1,.364-.364H8.744V3.643h-3.1A.548.548,0,0,1,5.1,3.1V0H.546A.545.545,0,0,0,0,.546V11.112a.545.545,0,0,0,.546.546H8.2a.545.545,0,0,0,.546-.546v-3.1H4.736A.365.365,0,0,1,4.372,7.651Z" transform="translate(0.25 0.25)" fill="none" stroke="#fff" strokeWidth="0.5" />
                                                </svg> {generalData?.fixed_titles['export']}</button>
                                        </div> */}
                                    </div>
                                </div>
                                {
                                    taskHistory &&
                                    <TaskHistoryComponent taskHistory={taskHistory} />
                                }
                                {/* <div className="col-12 mb-4 mt-4">
                                    <div className="row g-0 p-0 py-4 costs-box-styling custom-shadow px-2">
                                        {
                                            returnedFiles.length > 0 ?
                                                returnedFiles.map((file, index) => (
                                                    <div key={index} className="col-12">
                                                        <div className="row pb-3 flex-wrap">
                                                            <div className="col-auto position-relative">
                                                                <div className="blue-dot-logs" />
                                                                {
                                                                    returnedFiles?.length !== index + 1 &&
                                                                    <div className="blue-dot-logs-lines" ></div>

                                                                }
                                                            </div>
                                                            <div className="col ps-0">
                                                                <div className="row flex-wrap">
                                                                    <div className="col">
                                                                        {
                                                                            file?.deleted_at ?
                                                                                <div className="d-flex align-items-md-center flex-md-row flex-column ">
                                                                                    <del>

                                                                                        {
                                                                                            file?.comment ?
                                                                                                <div className="d-flex align-items-md-center flex-md-row flex-column flex-wrap ">
                                                                                                    <p className={`fs-12 m-0 fs-smaller`}>
                                                                                                        {file?.comment}
                                                                                                    </p>

                                                                                                    <a target='_blank' href={file?.full_path_file} className={`fs-12 m-0 fs-smaller`}>
                                                                                                        <FileIcon className="p-1" />
                                                                                                        {file?.file_name}

                                                                                                    </a>

                                                                                                    <p className={`fs-12 ms-md-2 m-0 fs-smaller`}>
                                                                                                        {file?.file_date}
                                                                                                    </p>

                                                                                                </div>
                                                                                                :
                                                                                                null
                                                                                        }
                                                                                    </del>
                                                                                    <p className="ms-md-3 fs-12 m-0 fs-smaller">deleted by : {file?.delete_user?.name}</p>
                                                                                </div>
                                                                                :
                                                                                <div className="d-flex align-items-md-center flex-md-row flex-column flex-wrap">
                                                                                    {
                                                                                        file?.comment ?
                                                                                            <>
                                                                                                <p className={`fs-12 m-0 fs-smaller`}>

                                                                                                    {file?.comment}
                                                                                                </p>
                                                                                                <p className={`fs-12 m-0 fs-smaller ms-md-1`}>
                                                                                                    {file?.file_date}
                                                                                                </p>

                                                                                                <a target='_blank' className={`fs-12 m-0 fs-smaller`} href={file?.full_path_file}>
                                                                                                    <FileIcon className="p-1" />
                                                                                                    {file?.file_name}
                                                                                                </a>

                                                                                            </>
                                                                                            :
                                                                                            null
                                                                                    }
                                                                                </div>


                                                                        }


                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                                :
                                                <h5 className="py-3 locked-inputs w-100 m-0 p-0">{generalData?.website_titles['no-logs-yet']}</h5>
                                        }
                                    </div>
                                </div> */}
                            </div>
                        </>
                        : null

                }

                {/* <Api options={historyCall} /> */}
                <Api options={apiOptions} />
                {/* <Api options={expensesCall} /> */}
            </Layout>
        </>
    )
}