import React, { useRef, useState } from 'react'
import useOutsideClick from '../useOutsideClick';
import Api from './Api';
import { useEffect } from 'react';

export default function StatusComponent({returnSingleTask, api, statuses, selectedStatus, className, index=1, targetName, target, onChange=()=>{}, activeSelect, setActiveSelect = ()=>{}, dropdownPosition, editable=true, type='' }) {

    const [activeStatus, setActiveStatus] = useState();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [generalDataApiOptions, setGeneralDataApiOptions] = useState({});

    const handleStatusChange = (status) => {
        setActiveStatus(status)
        setIsMenuOpen(false)
        // send api call
        let fd = new FormData();
        fd.append((targetName || 'target_id'), target);
        fd.append('status', status.id)
        if(returnSingleTask && returnSingleTask ===1){
            fd.append('return_single_task',1)
        }
        setGeneralDataApiOptions({
            url: api,
            method: 'post',
            data: fd,
            callback: res => {
                onChange(res)
            },
            catch: err => {
                if (err) {
                    setActiveStatus()
                }
            }
        });
    }

    useEffect(() => {
        setActiveStatus(selectedStatus)
    }, [selectedStatus]);

    const dropdownRef = useRef();// eslint-disable-line react-hooks/exhaustive-deps

    useOutsideClick(dropdownRef, () => { setIsMenuOpen(false) })

    useEffect(() => {
        setIsMenuOpen(((Number(activeSelect?.index) === Number(index)) && (type === activeSelect?.type)));
    }, [activeSelect]);// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <Api options={generalDataApiOptions} />

            <div ref={dropdownRef} className={`position-relative cursor-pointer ${className ? className : ''}`}>
                <div onClick={(e) => {
                    setActiveSelect({index: index, type:type})
                    setIsMenuOpen(!isMenuOpen)
                }}
                    className="text-center currency-text text-white" style={{ backgroundColor: activeStatus?.background_color || activeStatus?.color }}>
                    {activeStatus?.title}
                </div>
                {
                    isMenuOpen && editable &&
                    <div className={`status-dropdown-menu mt-2 px-2 position-absolute top-100 w-100 ${dropdownPosition ? dropdownPosition : 'end-0'}`} style={{ zIndex: 8 - index }}>
                        {
                            statuses?.length > 0 ?
                                statuses?.map((status,statInd) => (
                                    <div key={statInd} onClick={() => { handleStatusChange(status) }} className='cursor-pointer row flex-nowrap gx-2 status-item align-items-center'>
                                        <div className='col-auto'>
                                            <div className='dot' style={{ backgroundColor: status?.background_color || status?.color }}></div>
                                        </div>
                                        <div className='col-auto'>
                                            <p className='m-0'>
                                                {status?.title}
                                            </p>
                                        </div>
                                    </div>
                                )) :
                                <div>No Options</div>
                        }
                    </div>
                }

            </div>
        </>
    )
}
