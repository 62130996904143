import { useContext, useEffect, useRef, useState } from "react";
import Layout from "../components/Layout";
import GlobalState from "../contexts/GlobalState";
import { useNavigate, useParams } from "react-router";
import Api from "../components/Api";
import Pagination from "../components/Pagination";
import { HearingsIcon, MattersIcon, LitigiationsIcon, AddIcon, Pencil, CloseIcon, ThreeDotsMenu } from "../components/Icons";
import UsersSelect from "../components/UsersSelect";
import StatusComponent from "../components/StatusComponent";
import HearingPopupComponent from "../components/HearingPopupComponent";
import HearingCommonPopup from "./HearingCommonPopup";
import LitigationPopupComponent from "../components/LitigationPopupComponent";

export default function Matters() {

    const {
        generalDataGlobal: [generalData],
        generalDataGlobal: [{ matterSectionsSettings }],
        authUserGlobal,
        currentClientGlobal,
        hearings, setHearings,
        litigations, setLitigations,
        setAllLitigations } = useContext(GlobalState);
    const { type } = useParams();

    const [activeMatterType, setActiveMatterType] = useState({ slug: type || 'litigations' });
    const [authUser,] = authUserGlobal;
    // const [{matter_settings}] = generalDataGlobal;
    const [generalDataApiOptions, setGeneralDataApiOptions] = useState({});
    const ref = useRef(null);
    const editMenuRef = useRef();
    const [currentClient,] = currentClientGlobal;
    const [openEditMenu, setOpenEditMenu] = useState(null);
    const [order, setOrder] = useState(null);
    const navigate = useNavigate();
    const [sortedDirection, setSortedDirection] = useState('ASC');
    const [addMore, setAddMore] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [updateSearch, setUpdateSearch] = useState(0);
    const [updateData, setUpdateData] = useState(1);

    const [totalLitigations, setTotalLitigations] = useState(0);
    const [totalHearings, setTotalHearings] = useState(0);
    const [totalMatters, setTotalMatters] = useState(0)

    const [addMatterPopup, setAddMatterPopup] = useState(false);
    const [activeEditUsers, setActiveEditUsers] = useState('');

    const [editLitigation, setEditLitigation] = useState();
    const [editHearing, setEditHearing] = useState();

    const [deleteLitigation, setDeleteLitigation] = useState();
    const [deleteHearing, setDeleteHearing] = useState();


    const [currentPage, setCurrentPage] = useState(1);
    const [commonPopup, setCommonPopup] = useState(false);
    const { statuses } = generalData;

    const [popupTitle, setPopupTitle] = useState('')
    const [popupContent, setPopupContent] = useState(null);

    const [activeSelect, setActiveSelect] = useState();

    /* handle click outside to close the custom dropdown */
    const useOutsideClick = (ref, callback) => {
        const handleClick = e => {
            if (ref.current && !ref.current.contains(e.target)) {
                callback();
            }
        };

        useEffect(() => {
            document.addEventListener("click", handleClick);

            return () => {
                document.removeEventListener("click", handleClick);
            };
        });
    };

    useOutsideClick(ref, () => {
        setAddMore(false);
    });

    useOutsideClick(editMenuRef, () => {
        setOpenEditMenu(false);
    })
    /* handle click outside to close the custom dropdown */

    useEffect(() => {

        setGeneralDataApiOptions({
            url: `matters?page=${currentPage}`,
            method: 'post',
            data: {
                direction: sortedDirection,
                sort: order,
                type: activeMatterType?.slug || 'litigations',
                search: searchValue
            },
            callback: ({ data }) => {
                setHearings(data?.hearings);
                setLitigations(data?.litigations);
                setTotalMatters(data?.total['hearings']?.length + data?.total['litigations']?.length)
                // setCurrentPage(data.litigations.current_page);
                setTotalLitigations(data?.total['litigations']?.length);
                setTotalHearings(data?.total['hearings'].length);
                setAllLitigations(data?.total['litigations']);
            }
        })
    }, [sortedDirection, order, currentPage, updateData, activeMatterType]);// eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        if (type && generalData) {
            setActiveMatterType(generalData?.matterTypes?.filter(matterType => matterType.slug === type)[0])
        } else {
            setActiveMatterType(generalData?.matterTypes[0])
        }
    }, [type, generalData]);// eslint-disable-line react-hooks/exhaustive-deps


    const handleAddMatterClick = (e, type) => {
        e.preventDefault();
        setAddMatterPopup({
            open: true,
            type: type.slug,
            returnIndex: 1
        });
        setPopupTitle(type?.title === "hearings" ? generalData?.mattersCommonTitles['hearing'] : generalData?.mattersCommonTitles['litigation']);
        setEditLitigation(null)
    }


    //delete litigation
    const submitDeleteLitigation = (litigation) => {
        setGeneralDataApiOptions({
            url: 'delete-litigation',
            method: 'post',
            data: {
                litigation: litigation,
                page: currentPage,
                sort: order,
                direction: sortedDirection,
                type: 'litigations',
            },
            callback: res => {
                setLitigations(res.data.litigations)
                setDeleteLitigation(null);
                setCurrentPage(currentPage <= res.data.litigations.last_page ? currentPage : res.data.litigations.last_page);
                setTotalMatters(res.data?.total['hearings']?.length + res.data?.total['litigations']?.length)
                // setCurrentPage(data.litigations.current_page);
                setTotalLitigations(res.data?.total['litigations']?.length);
                setTotalHearings(res.data?.total['hearings'].length);
            },
            catch: err => {
                if (err) {
                    setOpenEditMenu(null);
                }
            }
        })
    }

    //delete hearing
    const submitDeleteHearing = (hearing) => {
        setGeneralDataApiOptions({
            url: 'delete-hearing',
            method: 'post',
            data: {
                hearing: hearing,
                page: currentPage,
                sort: order,
                direction: sortedDirection
            },
            callback: res => {
                setHearings(res.data.hearings)
                setDeleteHearing(null);
                setTotalMatters(res?.data?.total['hearings']?.length + res?.data?.total['litigations']?.length)
                // setCurrentPage(data.litigations.current_page);
                setTotalLitigations(res?.data?.total['litigations']?.length);
                setTotalHearings(res?.data?.total['hearings'].length);
            },
            catch: err => {
                if (err) {
                    setOpenEditMenu(null);
                }
            }
        })
    }



    //reset open menu
    useEffect(() => {
        if (!currentClient?.id) {
            setOpenEditMenu(null);
        }
    }, [currentClient]);

    // // handle update client data
    // useEffect(() => {
    //     setLitigationsCurrentPage(1);
    //     setUpdateData(updateData + 1);
    // }, [clientType]);// eslint-disable-line react-hooks/exhaustive-deps

    //rest pagination current pages
    useEffect(() => {
        setCurrentPage(1);
        setUpdateData(updateData + 1);
    }, [updateSearch]);// eslint-disable-line react-hooks/exhaustive-deps


    const handleSortClick = (col) => {
        setOrder(col);
        setSortedDirection(
            sortedDirection === 'ASC' ?
                'DESC' : 'ASC'
        )
    }

    const handleTableEditSingleFieldClick = (record, fieldName, fieldTitle) => {
        setCommonPopup(true);
        setPopupTitle(generalData?.hearingFixedTitles[fieldTitle]);
        setPopupContent({
            record: record,
            fieldName: fieldName,
            returnIndex: 1
        })
    }

    const handleAssigneesChange = (data) => {
        setLitigations(data?.litigations)
        setHearings(data?.hearings)
    }

    const handleMatterTypeClick = (type) => {
        setActiveMatterType(type);
        setCurrentPage(1);
        handleSortClick('');
        setSearchValue('');
    }

    return (
        <>
            <Layout activePage='matters'>
                <>
                    {
                        authUser?.id ?
                            <>
                                <div className="row g-0">
                                    <div className="col-12 col-md-12 col-lg-11 col-xl-9">
                                        <div className="row g-0 w-100">
                                            <div className="col-12 col-md-4 pe-0 pe-md-5 finances-box bg-white seperating-line">
                                                <div className="row justify-content-center">
                                                    <div className="col-auto">
                                                        <div className="background-svg-finances">
                                                            <MattersIcon />
                                                        </div>
                                                    </div>
                                                    <div className="col ps-0 pe-0 d-flex flex-column my-auto">
                                                        <h1 className="big-numbers-finances m-0">{totalMatters}</h1>
                                                        <h4 className="big-titles-finances m-0">{matterSectionsSettings['total-matters']}</h4>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-8 finances-box bg-white seperated-div">
                                                <div className="row w-100 h-100 align-items-md-center justify-content-md-center">
                                                    <div className="col-6 col-sm-4 col-md-4 pb-2 pb-md-0 justify-content-center align-items-center">
                                                        <div className="row d-flex justify-content-center align-items-cente">
                                                            <div className="col-auto p-0 d-flex align-items-center">
                                                                <h1 className="big-numbers-finances">{totalLitigations}</h1>
                                                            </div>
                                                            <div className="col-auto d-flex align-items-center">
                                                                <LitigiationsIcon />
                                                            </div>
                                                            <div className="row d-flex justify-content-center alig-items-center">
                                                                <div className="col-auto">
                                                                    <h2 className="big-titles-finances m-0">{matterSectionsSettings['litigations']}</h2>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6 col-sm-4 col-md-4 pb-2 pb-md-0 justify-content-center align-items-center">
                                                        <div className="row d-flex justify-content-center align-items-cente">
                                                            <div className="col-auto p-0 d-flex align-items-center">
                                                                <h1 className="big-numbers-finances">{totalHearings}</h1>
                                                            </div>
                                                            <div className="col-auto d-flex align-items-center">
                                                                <HearingsIcon />

                                                            </div>
                                                        </div>
                                                        <div className="row d-flex justify-content-center alig-items-center">
                                                            <div className="col-auto">
                                                                <h2 className="big-titles-finances m-0">{matterSectionsSettings['hearings']}</h2>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row g-0 pt-4 pb-1 pb-md-4 justify-content-between">
                                    <div className="col-auto currency-box-padd my-auto d-flex flex-row font-dark-blue bg-white">
                                        <div className="row">
                                            {
                                                generalData?.matterTypes?.map((singleType, ind) => (
                                                    <div key={ind} className={'col-auto ' + (ind === 0 ? ' pe-0' : 'ps-0')}>
                                                        <div onClick={() => { handleMatterTypeClick(singleType); }} className={'smaller-font currency-text cursor-pointer d-flex align-items-center ' + (singleType?.slug === activeMatterType?.slug ? 'active ' : ' ') + (Number(ind + 1) === Number(generalData?.client_types?.length) ? ' ' : 'me-1')}>
                                                            {
                                                                ind === 0 ?
                                                                    <LitigiationsIcon className="me-2" />

                                                                    :
                                                                    ind === 1 ?
                                                                        <HearingsIcon className="me-2" />
                                                                        : ''

                                                            }
                                                            {singleType?.title}
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-7 col-xl-8">
                                        <div className="row justify-content-end">
                                            <div className="col-12 col-xl-5 my-3 my-md-0">
                                                <input
                                                    onKeyDown={(e) => (e.key === "Enter" ? setUpdateSearch(updateSearch + 1) : null)}
                                                    name="search" className="table-search add-user-inputs"
                                                    placeholder={generalData?.fixed_titles['search']}
                                                    value={searchValue}
                                                    onChange={(e) => setSearchValue(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {
                                    activeMatterType?.slug === 'litigations' ?
                                        <div className="row g-0">
                                            <div className="col-12 p-0 height-100 pb-0 w-100 costs-box-styling bg-white">
                                                <div className="table-wrapper-settings">

                                                    <>
                                                        <table className="users-table" width="100%">
                                                            <thead>
                                                                <tr>
                                                                    <td width="40px" style={{ minWidth: "20px", width: "20px", maxWidth: "20px" }}></td>
                                                                    <td className="table-sorting" style={{ minWidth: "250px", width: "250px", maxWidth: "250px" }}>
                                                                        {generalData?.litigationFixedTitles['litigation-number']}
                                                                    </td>

                                                                    <td className="table-sorting" style={{ minWidth: "300px", width: "350px", maxWidth: "350px" }}>
                                                                        {generalData?.litigationFixedTitles['litigation-title']}
                                                                    </td>

                                                                    <td className="table-sorting" style={{ minWidth: "200px", width: "200px", maxWidth: "200px" }}>
                                                                        {generalData?.litigationFixedTitles['work-area']}
                                                                    </td>

                                                                    <td className="table-sorting" onClick={() => { handleSortClick('stage_title') }} style={{ minWidth: "200px", width: "200px", maxWidth: "200px" }}>
                                                                        {generalData?.mattersCommonTitles['stage']}
                                                                        <img className={"ms-2" + (sortedDirection === 'DESC' && order === 'stage_title' ? ' active' : '')} src={'/assets/images/arrow-icon.svg'} alt="arrow" />
                                                                    </td>

                                                                    <td className="table-sorting" style={{ minWidth: "200px", width: "200px", maxWidth: "200px" }}>
                                                                        {generalData?.mattersCommonTitles['assignees']}
                                                                    </td>

                                                                    <td className="table-sorting" style={{ minWidth: "200px", width: "200px" }}>
                                                                        {generalData?.litigationFixedTitles['opponents-names']}
                                                                    </td>

                                                                    <td className="table-sorting" onClick={() => { handleSortClick('status_title') }} style={{ minWidth: "300px", width: "300px" }}>
                                                                        {generalData?.litigationFixedTitles['status']}
                                                                        <img className={"ms-2" + (sortedDirection === 'DESC' && order === 'status_title' ? ' active' : '')} src={'/assets/images/arrow-icon.svg'} alt="arrow" />
                                                                    </td>

                                                                    <td className="table-sorting" style={{ minWidth: "300px", width: "300px" }}>
                                                                        {generalData?.litigationFixedTitles['clients-names']}
                                                                    </td>

                                                                    <td className="table-sorting" style={{ minWidth: "300px", width: "300px" }}>
                                                                        {generalData?.litigationFixedTitles['litigation-subject']}
                                                                    </td>

                                                                    <td className="table-sorting" style={{ minWidth: "300px", width: "300px" }}>
                                                                        {generalData?.litigationFixedTitles['client-description']}
                                                                    </td>

                                                                    <td className="table-sorting" onClick={() => { handleSortClick('establish_date') }} style={{ minWidth: "300px", width: "300px" }}>
                                                                        {generalData?.litigationFixedTitles['establish-date']}
                                                                        <img className={"ms-2" + (sortedDirection === 'DESC' && order === 'establish_date' ? ' active' : '')} src={'/assets/images/arrow-icon.svg'} alt="arrow" />
                                                                    </td>

                                                                    <td className="table-sorting" onClick={() => { handleSortClick('appeal_date') }} style={{ minWidth: "300px", width: "300px" }}>
                                                                        {generalData?.litigationFixedTitles['appeal-date']}
                                                                        <img className={"ms-2" + (sortedDirection === 'DESC' && order === 'appeal_date' ? ' active' : '')} src={'/assets/images/arrow-icon.svg'} alt="arrow" />
                                                                    </td>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    litigations?.data?.length > 0 ?
                                                                        litigations?.data?.map((litigation, index) => (
                                                                            <tr key={index} onClick={() => navigate(`/matters/litigations/${litigation?.id}/general-info`)}>
                                                                                <td width="20px" className="text-end p-0 pe-2 position-relative" >
                                                                                    <ThreeDotsMenu onClick={(e) => { e.stopPropagation(); openEditMenu?.id === litigation?.id ? setOpenEditMenu(null) : setOpenEditMenu(litigation) }} />
                                                                                    <div style={{}} className={"settings-clickmenu first-td-buttons text-start " + (litigation?.id === openEditMenu?.id ? ' d-block' : '')}>
                                                                                        <p style={{ zIndex: "99" }} onClick={(e) => { e.stopPropagation(); e.preventDefault(); setEditLitigation(litigation); setAddMatterPopup({ open: true, type: 'litigations' }); setPopupTitle(generalData?.mattersCommonTitles['litigation']) }} className="p-0 py-1 m-0 ms-2">{generalData?.settings_section_settings['edit-title']}</p>
                                                                                        <p style={{ zIndex: "200" }} onClick={(e) => { e.stopPropagation(); e.preventDefault(); setDeleteLitigation(litigation?.id) }} className="p-0 py-1 m-0 ms-2">{generalData?.settings_section_settings['delete-title']}</p>
                                                                                    </div>
                                                                                </td>
                                                                                <td>{litigation.number}</td>
                                                                                <td>{litigation.title}</td>
                                                                                {/* {
                                                                                    clientType === 1 ?
                                                                                        <td>{litigation?.legal_type?.title}</td>
                                                                                        :
                                                                                        null
                                                                                } */}
                                                                                <td>{litigation?.work_area}</td>
                                                                                <td>
                                                                                    {
                                                                                        litigation?.stages?.length > 0 ?
                                                                                            litigation?.stages[0]?.stage?.title
                                                                                            :
                                                                                            '-'

                                                                                    }
                                                                                </td>
                                                                                <td onClick={(e) => { e.preventDefault(); e.stopPropagation() }}>
                                                                                    <div className="w-100" >
                                                                                        <UsersSelect
                                                                                            url={'/add-edit-assignees'}
                                                                                            activeEditUsers={activeEditUsers}
                                                                                            setActiveEditUsers={setActiveEditUsers}
                                                                                            data={litigation}
                                                                                            users={litigation?.assignees}
                                                                                            model={'Litigation'}
                                                                                            type={'litigations'}
                                                                                            clientId={''}
                                                                                            className="litigation-user-select m-0"
                                                                                            onChange={handleAssigneesChange}
                                                                                            page={currentPage}
                                                                                            sort={order}
                                                                                            direction={sortedDirection}
                                                                                            return={'index'}
                                                                                        />
                                                                                    </div>

                                                                                </td>
                                                                                <td>
                                                                                    {litigation?.opponents[0]?.client?.name || '-'}
                                                                                </td>
                                                                                <td className="" onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}>
                                                                                    <StatusComponent
                                                                                        api={'edit-litigation-status'}
                                                                                        selectedStatus={litigation?.status}
                                                                                        statuses={statuses}
                                                                                        index={index}
                                                                                        target={litigation.id}
                                                                                        targetName={'litigation_id'}
                                                                                        activeSelect={activeSelect}
                                                                                        setActiveSelect={setActiveSelect}

                                                                                    />
                                                                                    {/* <div className="text-center currency-text text-white" style={{ backgroundColor: litigation?.status?.background_color }}>
                                                                                        {litigation?.status?.title}
                                                                                    </div> */}
                                                                                </td>
                                                                                <td>
                                                                                    {litigation?.clients[0]?.client?.name || '-'}
                                                                                </td>
                                                                                <td>
                                                                                    {litigation?.subject}
                                                                                </td>
                                                                                <td>
                                                                                    {litigation?.clients[0]?.description || '-'}
                                                                                </td>
                                                                                <td>
                                                                                    {litigation?.establish_date}
                                                                                </td>
                                                                                <td>
                                                                                    {litigation?.appeal_date}
                                                                                </td>
                                                                            </tr>
                                                                        ))
                                                                        :
                                                                        null
                                                                }
                                                            </tbody>
                                                        </table>
                                                        {
                                                            litigations?.data?.length === 0 &&
                                                            <div className="text-center">
                                                                <p>{matterSectionsSettings['empty-records'].replace('%record%', activeMatterType.title)}</p>
                                                            </div>
                                                        }
                                                    </>


                                                </div>
                                                {
                                                    litigations?.last_page > 1 &&
                                                    <div className="col-12 text-center py-3 d-flex justify-content-center">
                                                        <Pagination
                                                            className="pagination-bar"
                                                            currentPage={currentPage}
                                                            totalCount={12 * litigations?.last_page}
                                                            pageSize={12}
                                                            onPageChange={(e) => setCurrentPage(e)}
                                                        />
                                                    </div>
                                                }
                                                <div className="col-12 pe-3 d-flex justify-content-end">
                                                    <div ref={ref} onClick={() => setAddMore(!addMore)} className={'add-button-fixed ' + (addMore ? ' active' : '')}>
                                                        <AddIcon />
                                                        <div className="menu-fixed-button">
                                                            {
                                                                generalData?.matterTypes?.map((singleType, ind) => (
                                                                    <p
                                                                        key={ind}
                                                                        className={Number(ind) === 0 ? 'pb-0' : ''}
                                                                        onClick={(e) => {
                                                                            e.preventDefault(); e.stopPropagation(); handleAddMatterClick(e, singleType)
                                                                        }}
                                                                    >
                                                                        {singleType?.title}
                                                                    </p>
                                                                ))
                                                            }
                                                            {/* <p onClick={(e) => { e.preventDefault(); e.stopPropagation(); handleAddMatterClick(e, 'hearings') }} className="pb-0">Hearing</p> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <>
                                            <div className="row g-0">
                                                <div className="col-12 p-0 height-100 pb-0 w-100 costs-box-styling bg-white">
                                                    <div className="table-wrapper-settings">

                                                        <>
                                                            <table className="users-table" width="100%">
                                                                <thead>
                                                                    <tr>
                                                                        <td width="40px" style={{ minWidth: "20px", width: "20px", maxWidth: "20px" }}></td>
                                                                        <td className="table-sorting" style={{ minWidth: "250px", width: "250px", maxWidth: "250px" }}>
                                                                            {generalData?.hearingFixedTitles['hearing-number']}
                                                                        </td>

                                                                        <td className="table-sorting" style={{ minWidth: "300px", width: "300px", maxWidth: "300px" }}>
                                                                            {generalData?.matterSectionsSettings['litigation-title']}
                                                                        </td>

                                                                        <td className="table-sorting" onClick={() => { handleSortClick('hearing_date') }} style={{ minWidth: "300px", width: "300px", maxWidth: "300px" }}>
                                                                            {generalData?.hearingFixedTitles['hearing-date-time']}
                                                                            <img className={"ms-2" + (sortedDirection === 'DESC' && order === 'hearing_date' ? ' active' : '')} src={'/assets/images/arrow-icon.svg'} alt="arrow" />
                                                                        </td>

                                                                        <td className="table-sorting" style={{ minWidth: "300px", width: "300px", maxWidth: "300px" }}>
                                                                            {generalData?.hearingFixedTitles['litigation-number-date']}
                                                                            {/* <img className={"ms-2" + (sortedDirection === 'DESC' && order === 'stage_title' ? ' active' : '')} src={'/assets/images/arrow-icon.svg'} alt="arrow" /> */}
                                                                        </td>

                                                                        <td className="table-sorting" onClick={() => { handleSortClick('stage_title') }} style={{ minWidth: "300px", width: "300px", maxWidth: "300px" }}>
                                                                            {generalData?.matterSectionsSettings['stage']}
                                                                            <img className={"ms-2" + (sortedDirection === 'DESC' && order === 'stage_title' ? ' active' : '')} src={'/assets/images/arrow-icon.svg'} alt="arrow" />
                                                                        </td>

                                                                        <td className="table-sorting" style={{ minWidth: "300px", width: "300px", maxWidth: "300px" }}>
                                                                            {generalData?.matterSectionsSettings['assignees']}
                                                                        </td>

                                                                        <td className="table-sorting" style={{ minWidth: "300px", width: "300px", maxWidth: "300px" }}>
                                                                            {generalData?.hearingFixedTitles['hearing-type']}
                                                                        </td>

                                                                        <td className="table-sorting" style={{ minWidth: "300px", width: "300px", maxWidth: "300px" }}>
                                                                            {generalData?.hearingFixedTitles['court-name']}
                                                                        </td>

                                                                        <td className="table-sorting" style={{ minWidth: "300px", width: "300px", maxWidth: "300px" }}>
                                                                            {generalData?.hearingFixedTitles['court-level']}
                                                                            {/* <img className={"ms-2" + (sortedDirection === 'DESC' && order === 'court_level' ? ' active' : '')} src={'/assets/images/arrow-icon.svg'} alt="arrow" /> */}
                                                                        </td>

                                                                        <td className="table-sorting" style={{ minWidth: "300px", width: "300px", maxWidth: "300px" }}>
                                                                            {generalData?.hearingFixedTitles['hearing-summary']}
                                                                        </td>

                                                                        <td className="table-sorting" style={{ minWidth: "300px", width: "300px", maxWidth: "300px" }}>
                                                                            {generalData?.hearingFixedTitles['client-summary']}
                                                                        </td>

                                                                        <td className="table-sorting" style={{ minWidth: "300px", width: "300px", maxWidth: "300px" }}>
                                                                            {generalData?.hearingFixedTitles['previous-hearing-date']}
                                                                            {/* <img className={"ms-2" + (sortedDirection === 'DESC' && order === 'appeal_date' ? ' active' : '')} src={'/assets/images/arrow-icon.svg'} alt="arrow" /> */}
                                                                        </td>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        hearings?.data?.length > 0 ?
                                                                            hearings?.data?.map((hearing, index) => (
                                                                                <tr key={index} onClick={() => { navigate(`/matters/litigations/${hearing?.litigation?.id}`) }}>
                                                                                    <td width="20px" className="text-end p-0 pe-2 position-relative" >
                                                                                        <ThreeDotsMenu onClick={(e) => { e.stopPropagation(); openEditMenu?.id === hearing?.id ? setOpenEditMenu(null) : setOpenEditMenu(hearing) }} />
                                                                                        <div style={{ width: 200 }} className={"settings-clickmenu first-td-buttons text-start " + (hearing?.id === openEditMenu?.id ? ' d-block' : '')}>
                                                                                            <p style={{ zIndex: "99" }} onClick={(e) => { e.stopPropagation(); e.preventDefault(); setEditHearing(hearing); setAddMatterPopup({ open: true, type: 'hearings', returnIndex: 1 }); setPopupTitle(generalData?.mattersCommonTitles['hearing']) }} className="p-0 py-1 m-0 ms-2">{generalData?.settings_section_settings['edit-title']}</p>
                                                                                            <p style={{ zIndex: "99" }} onClick={(e) => { e.stopPropagation(); e.preventDefault(); setDeleteHearing(hearing?.id) }} className="p-0 py-1 m-0 ms-2">{generalData?.settings_section_settings['delete-title']}</p>
                                                                                            <p style={{ zIndex: "99" }} onClick={(e) => { e.stopPropagation(); e.preventDefault(); setEditHearing(hearing); setAddMatterPopup({ open: true, type: 'hearings', action: 'copy', returnIndex: 1 }); setPopupTitle(generalData?.matterTypes[1]?.title) }} className="p-0 py-1 m-0 ms-2">{generalData?.hearingFixedTitles['copy']}</p>
                                                                                            <p style={{ zIndex: "99" }} onClick={(e) => { e.stopPropagation(); e.preventDefault(); handleTableEditSingleFieldClick(hearing, 'report', 'create-a-report') }} className="p-0 py-1 m-0 ms-2">{hearing?.report ? generalData?.hearingFixedTitles['view-report'] : generalData?.hearingFixedTitles['create-a-report']}</p>
                                                                                            <p style={{ zIndex: "99" }} onClick={(e) => { e.stopPropagation(); e.preventDefault(); setEditHearing(hearing); setAddMatterPopup({ open: true, type: 'hearings', showMore: true, returnIndex: 1 }); setPopupTitle(generalData?.matterTypes[1]?.title) }} className="p-0 py-1 m-0 ms-2">{hearing?.result_description ? generalData?.hearingFixedTitles['view-judgment'] : generalData?.hearingFixedTitles['determine-judgment']}</p>
                                                                                            <p style={{ zIndex: "99" }} onClick={(e) => { e.stopPropagation(); e.preventDefault(); navigate(`/matters/litigations/${hearing?.litigation?.id}/costs`) }} className="p-0 py-1 m-0 ms-2">{generalData?.website_titles['costs']}</p>
                                                                                            <p style={{ zIndex: "99" }} onClick={(e) => { e.stopPropagation(); e.preventDefault(); navigate(`/matters/litigations/${hearing?.litigation?.id}/reminder`) }} className="p-0 py-1 m-0 ms-2">{generalData?.website_titles['reminder']}</p>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>{hearing.number}</td>
                                                                                    <td>{hearing?.litigation?.title}</td>
                                                                                    <td>{hearing?.hearing_date}</td>
                                                                                    <td>{hearing?.litigation?.establish_date} / {hearing?.litigation?.number}</td>
                                                                                    <td>{hearing?.stage?.stage?.title}</td>
                                                                                    <td onClick={(e) => { e.stopPropagation(); e.preventDefault(); }}>
                                                                                        <div className="w-100">
                                                                                            <UsersSelect
                                                                                                url={'/add-edit-assignees'}
                                                                                                activeEditUsers={activeEditUsers}
                                                                                                setActiveEditUsers={setActiveEditUsers}
                                                                                                data={hearing}
                                                                                                users={hearing?.assignees}
                                                                                                model={'Hearing'}
                                                                                                clientId={''}
                                                                                                className="litigation-user-select m-0"
                                                                                                page={currentPage}
                                                                                                type={'hearings'}
                                                                                                sort={order}
                                                                                                direction={sortedDirection}
                                                                                                onChange={handleAssigneesChange}
                                                                                            />
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        {hearing?.hearing_type?.title}
                                                                                    </td>

                                                                                    <td className="" onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}>
                                                                                        {hearing?.stage?.court_name}
                                                                                    </td>
                                                                                    <td>
                                                                                        {hearing?.stage?.court_degree}
                                                                                    </td>
                                                                                    <td>
                                                                                        <div className="w-100 h-100 position-relative pe-2 cursor-pointer">
                                                                                            <div className="position-absolute top-0 end-0" style={{ zIndex: 2 }}>
                                                                                                <Pencil onClick={(e) => { e.preventDefault(); e.stopPropagation(); handleTableEditSingleFieldClick(hearing, 'hearing_summary', 'hearing-summary') }} />
                                                                                            </div>
                                                                                            {
                                                                                                hearing?.hearing_summary ?
                                                                                                    hearing?.hearing_summary?.length > 27 ?
                                                                                                        <>
                                                                                                            <div className="position-relative user-image-in-table">
                                                                                                                <span >
                                                                                                                    <>{`${hearing?.hearing_summary.substring(0, 27)}...`}</>
                                                                                                                </span>
                                                                                                                {

                                                                                                                    <>
                                                                                                                        <div className="triangle-down"></div>
                                                                                                                        <p className="hover-username" style={{ maxWidth: '100%', fontSize: '22px !important' }}>{hearing?.hearing_summary}</p>
                                                                                                                    </>

                                                                                                                }
                                                                                                            </div>
                                                                                                        </>
                                                                                                        :
                                                                                                        hearing?.hearing_summary
                                                                                                    :
                                                                                                    '-'
                                                                                            }
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        <div className="w-100 h-100 position-relative pe-2 cursor-pointer">
                                                                                            <div className="position-absolute top-0 end-0" style={{ zIndex: 2 }}>
                                                                                                <Pencil onClick={(e) => { e.preventDefault(); e.stopPropagation(); handleTableEditSingleFieldClick(hearing, 'client_summary', 'client-summary') }} />
                                                                                            </div>
                                                                                            {
                                                                                                hearing?.client_summary ?
                                                                                                    hearing?.client_summary?.length > 27 ?
                                                                                                        <>
                                                                                                            <div className="position-relative user-image-in-table">
                                                                                                                <span >
                                                                                                                    <>{`${hearing?.client_summary.substring(0, 27)}...`}</>
                                                                                                                </span>
                                                                                                                {

                                                                                                                    <>
                                                                                                                        <div className="triangle-down"></div>
                                                                                                                        <p className="hover-username" style={{ maxWidth: '100%', fontSize: '22px !important' }}>{hearing?.client_summary}</p>
                                                                                                                    </>

                                                                                                                }
                                                                                                            </div>
                                                                                                        </>
                                                                                                        :
                                                                                                        hearing?.client_summary
                                                                                                    :
                                                                                                    '-'
                                                                                            }
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>{hearing?.previous_hearing?.hearing_date}</td>
                                                                                </tr>
                                                                            ))
                                                                            :
                                                                            null
                                                                    }
                                                                </tbody>
                                                            </table>
                                                            {
                                                                hearings?.data?.length === 0 &&
                                                                <div className="text-center">
                                                                    <p>{matterSectionsSettings['empty-records'].replace('%record%', activeMatterType.title)}</p>
                                                                </div>
                                                            }
                                                        </>


                                                    </div>
                                                    {
                                                        hearings?.last_page > 1 &&
                                                        <div className="col-12 text-center py-3 d-flex justify-content-center">
                                                            <Pagination
                                                                className="pagination-bar"
                                                                currentPage={currentPage}
                                                                totalCount={12 * hearings?.last_page}
                                                                pageSize={12}
                                                                onPageChange={(e) => setCurrentPage(e)}
                                                            />
                                                        </div>
                                                    }
                                                    <div className="col-12 pe-3 d-flex justify-content-end">
                                                        <div ref={ref} onClick={() => setAddMore(!addMore)} className={'add-button-fixed ' + (addMore ? ' active' : '')}>
                                                            <AddIcon />
                                                            <div className="menu-fixed-button">
                                                                {
                                                                    generalData?.matterTypes?.map((singleType, ind) => (
                                                                        <p
                                                                            key={ind}
                                                                            className={Number(ind) === 0 ? 'pb-0' : ''}
                                                                            onClick={(e) => {
                                                                                e.preventDefault(); e.stopPropagation(); handleAddMatterClick(e, singleType)
                                                                            }}
                                                                        >
                                                                            {singleType?.title}
                                                                        </p>
                                                                    ))
                                                                }
                                                                {/* <p onClick={(e) => { e.preventDefault(); e.stopPropagation(); handleAddMatterClick(e, 'hearings') }} className="pb-0">Hearing</p> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                }
                            </>
                            :
                            null
                    }

                    {
                        //litigation popup
                        addMatterPopup.open && addMatterPopup?.type === 'litigations' &&
                        <LitigationPopupComponent search={searchValue} editLitigation={editLitigation} popupTitle={popupTitle} setAddMatterPopup={setAddMatterPopup} addMatterPopup={addMatterPopup} page={currentPage} sort={order} direction={sortedDirection} activePage={'matters'} setTotalMatters={setTotalMatters} setTotalLitigations={setTotalLitigations} setTotalHearings={setTotalHearings} />
                    }
                    {
                        // hearing popup 
                        addMatterPopup.open && addMatterPopup?.type === 'hearings' &&
                        <HearingPopupComponent
                            editHearing={editHearing}
                            setEditHearing={setEditHearing}
                            popupTitle={popupTitle}
                            setAddMatterPopup={setAddMatterPopup}
                            addMatterPopup={addMatterPopup}
                            page={currentPage}
                            sort={order}
                            direction={sortedDirection}
                            activePage={'matters'}
                            setTotalMatters={setTotalMatters}
                            setTotalLitigations={setTotalLitigations}
                            setTotalHearings={setTotalHearings}
                        />
                    }

                    {
                        commonPopup &&
                        <HearingCommonPopup
                            popupTitle={popupTitle}
                            commonPopup={commonPopup}
                            setCommonPopup={setCommonPopup}
                            popupContent={popupContent}
                            setPopupContent={setPopupContent}
                            page={currentPage}
                            sort={order}
                            direction={sortedDirection}
                        />
                    }
                    {
                        //delete litigation popup
                        deleteLitigation ?
                            <div id="errors-popup" className=" error-popup-api d-flex align-items-center justify-content-center px-3 fade-in">
                                <div className="pop-up-api-box">
                                    <CloseIcon className="cursor-pointer" onClick={() => { setDeleteLitigation(null); setOpenEditMenu(null) }} />
                                    <div className="text-center ">
                                        <div className="containter-fluid">
                                            <div className="row d-flex justify-content-center align-items-center">
                                                <div className="col-9 col-sm-12 d-flex justify-content-center">
                                                    <h1 className="pop-up-title m-0 pt-3 color-dark-blue">{generalData?.settings_section_settings['delete-popup-title']}</h1>
                                                </div>
                                                <div className="col-12 d-flex justify-content-center pt-4 pb-2">
                                                    <button onClick={() => submitDeleteLitigation(deleteLitigation ? deleteLitigation : null)} type="button" className="popup-button my-auto mx-2 w-auto px-3">{generalData?.settings_section_settings['delete-popup-first-button']}</button>
                                                    <button onClick={() => { setDeleteLitigation(null); setOpenEditMenu(null) }} type="button" className="auth-button border-dark-blue my-auto mx-2 background-transparent w-auto px-3">{generalData?.settings_section_settings['delete-popup-second-button']}</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            null
                    }
                    {
                        //delete Hearing popup
                        deleteHearing ?
                            <div id="errors-popup" className=" error-popup-api d-flex align-items-center justify-content-center px-3 fade-in">
                                <div className="pop-up-api-box">
                                    <CloseIcon className="cursor-pointer" onClick={() => { setDeleteHearing(null); setOpenEditMenu(null) }} />
                                    <div className="text-center ">
                                        <div className="containter-fluid">
                                            <div className="row d-flex justify-content-center align-items-center">
                                                <div className="col-9 col-sm-12 d-flex justify-content-center">
                                                    <h1 className="pop-up-title m-0 pt-3 color-dark-blue">{generalData?.settings_section_settings['delete-popup-title']}</h1>
                                                </div>
                                                <div className="col-12 d-flex justify-content-center pt-4 pb-2">
                                                    <button onClick={() => submitDeleteHearing(deleteHearing ? deleteHearing : null)} type="button" className="popup-button my-auto mx-2 w-auto px-3">{generalData?.settings_section_settings['delete-popup-first-button']}</button>
                                                    <button onClick={() => { setDeleteHearing(null); setOpenEditMenu(null) }} type="button" className="auth-button border-dark-blue my-auto mx-2 background-transparent w-auto px-3">{generalData?.settings_section_settings['delete-popup-second-button']}</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            null
                    }
                    <Api options={generalDataApiOptions} />
                </>
            </Layout>
        </>
    )
}