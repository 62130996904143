import { useContext, useState, useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router";
import GlobalState from '../contexts/GlobalState';
import React from 'react';
import Layout from "../components/Layout";
import Api from "../components/Api";
import Select from 'react-select';
import CmsTable from "../components/CmsTable";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { getIndex } from '../Helpers';
import { CloseIcon, ThreeDotsMenu } from "../components/Icons";
import EditInputFixedSettings from "../components/EditInputFixedSettings";
import getCroppedImg from '../components/CropImage';
import Cropper from 'react-easy-crop';
import EditPictureInput from "../components/EditPictureInput";

export default function Settings() {

    const { activeFixedInputValueGlobal, allCmsDataGlobal, generalDataGlobal, authUserGlobal, currentInputGlobal, currentInputValueGlobal } = useContext(GlobalState);
    const [currentInput, setCurrentInput] = currentInputGlobal;
    const [, setCurrentInputValue] = currentInputValueGlobal;
    const [generalData, setGeneralData] = generalDataGlobal;
    const [authUser] = authUserGlobal;
    const [usersApiOptions, setUsersApiOptions] = useState({});
    const [allCmsData, setAllCmsData] = allCmsDataGlobal;
    const [usersData, setUsersData] = useState({});
    const [addUserPopup, setAddUserPopup] = useState(false);
    const [generalDataApiOptions, setGeneralDataApiOptions] = useState({});
    const [errorsReturned, setErrorsReturned] = useState({});
    const [userRole, setUserRole] = useState();
    const [email, setEmail] = useState();
    const [jobTitle, setJobTitle] = useState();
    const [name, setName] = useState();
    const [openEditMenu, setOpenEditMenu] = useState(null);
    const [order, setOrder] = useState(null);
    const [sortedDirection, setSortedDirection] = useState('ASC');
    const [deletePopup, setDeletePopup] = useState(null);
    const [hoveredItem, setHoveredItem] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [activeFixedInput, setActiveFixedInput] = useState('');
    const [activeFixedInputValue, setActiveFixedInputValue] = activeFixedInputValueGlobal;
    const [targetFixedInputChanges, setTargetFixedInputChanges] = useState(0);
    const [tempPicture, setTempPicture] = useState('');

    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [rotation,] = useState(0);
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState('');
    const [croppedFinalImage, setCroppedFinalImage] = useState(null);
    const [uploadImagePopup, setUploadImagePopup] = useState('');
    const [, setProfilePicture] = useState();
    const [selectedEditPicture, setSelectedEditPicture] = useState('');

    const history = useNavigate();
    let { slug } = useParams();

    //handle redirection to firm-management if no slug detected
    useEffect(() => {
        if (!slug) {
            history('/settings/firm-management');
        }
    }, [slug]);// eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
        setActiveFixedInput('');
    }, [currentInput]);// eslint-disable-line react-hooks/exhaustive-deps
    //reset dynamic inputs on current page change
    useEffect(() => {
        setCurrentInput('');
        setCurrentInputValue('');
        setActiveFixedInput('');
    }, [currentPage]);// eslint-disable-line react-hooks/exhaustive-deps

    //get all user types
    const allUserTypes = () => {
        const typesOptions = generalData?.user_roles?.map(
            singleOption => ({ value: singleOption.id, label: singleOption.role_name }))
        return typesOptions;
    }

    //get all job titles
    const allJobTitles = () => {
        const jobOptions = generalData?.job_titles?.map(
            singleOption => ({ value: singleOption.id, label: singleOption.title }))
        return jobOptions;
    }

    // get all users data when slug is firm-management
    useEffect(() => {
        if (slug && slug === 'firm-management') {
            setUsersApiOptions({
                url: 'users-data',
                method: 'post',
                data: {
                    sort: order,
                    direction: sortedDirection,
                },
                callback: res => {
                    setUsersData(res.data);
                }
            });
        }
    }, [order, sortedDirection, slug]);

    //get cms tables data when slug is content management
    useEffect(() => {
        if (slug && slug === 'content-management') {
            setUsersApiOptions({
                url: 'cms-editable-data',
                method: 'get',
                callback: res => {
                    setAllCmsData(res.data);
                }
            });
        }
    }, [slug]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if ((activeFixedInput && activeFixedInputValue) && targetFixedInputChanges > 0) {
            setGeneralDataApiOptions({
                url: 'add-edit-cms',
                method: 'post',
                data: {
                    model: 'FixedSetting',
                    field: activeFixedInput,
                    value: activeFixedInputValue
                },
                callback: res => {
                    setAllCmsData(res.data.cms);
                    setGeneralData(res.data.general)
                    setCurrentInput('');
                    setCurrentInputValue('');
                    setActiveFixedInput('');
                    setActiveFixedInputValue('');
                    setErrorsReturned({});
                },
                catch: err => {
                    if (err) {
                        setErrorsReturned(err);
                    }
                }
            });
        }
    }, [targetFixedInputChanges]);// eslint-disable-line react-hooks/exhaustive-deps

    //custom select styles
    const customStyles = {
        placeholder: (defaultStyles) => {
            return {
                ...defaultStyles,
                display: "flex",
                fontFamily: 'Poppins',
                fontWeight: '400',
                textTransform: "capitalize",
                fontSize: '14px',
                color: '#07407C',
            }
        },
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                display: "flex",
                color: '#07407C',
                fontFamily: 'Poppins',
                fontWeight: '400',
                backgroundColor: 'white',
                textTransform: "capitalize",
                margin: "auto",
                paddingRight: "10px",
                paddingLeft: "10px",
                paddingBottom: "0",
                paddingTop: "0",
            };
        },
        singleValue: base => ({
            ...base,
            color: "#00366A",
            display: "flex",
            fontSize: "14px",
            fontFamily: "Poppins",
            fontWeight: "400",
            textTransform: "capitalize",
        }),
        control: provided => ({
            ...provided,
            border: "none",
            backgroundColor: 'white',
            outline: "none",
            '&:hover': { borderColor: 'transparent' },
            textTransform: 'uppercase',
            width: "100%",
            borderRadius: "7px",
            fontFamily: 'Poppins',
            fontWeight: '400',
            boxShadow: "0px 3px 6px rgb(0 0 0 / 16%)",
        }),
        menu: (provided, state) => ({
            ...provided,
            borderRadius: "7px",
            textTransform: 'uppercase',
            border: "1px solid transparent",
        }),
        dropdownIndicator: base => ({
            ...base,
            color: "#00366A !important",
            // marginTop: "-18px !important"
        }),
        input: (base, state) => ({
            ...base,
            color: "#393939",
            fontSize: "16px",
            height: "100%",
            fontFamily: 'Poppins',
            textTransform: 'uppercase',
            fontWeight: '400',
            marginTop: "14px",
            '& input': {
                font: 'inherit',
            },
        })
    };


    // submit add or edit user request
    const submitAddUser = (e) => {
        setErrorsReturned(null);
        e.preventDefault();
        setGeneralDataApiOptions({
            url: 'submit-new-user',
            method: 'post',
            data: {
                id: openEditMenu?.id ? openEditMenu?.id : null,
                email: email,
                name: name,
                user_role_id: userRole ? userRole.value : null,
                job_title_id: jobTitle ? jobTitle.value : null,
            },
            callback: res => {
                setAddUserPopup(false);
                setUsersData(res.data);
                setErrorsReturned(null);
                setName('');
                setEmail('');
                setUserRole('');
                setJobTitle('');
                setOpenEditMenu(null);
            },
            catch: err => {
                if (err) {
                    setErrorsReturned(err);
                    setOpenEditMenu(null);
                }
            }
        })
    }

    //set states with current editing user data
    function editClick(e, single) {
        e.preventDefault();
        if (single?.id) {
            setName(single?.name);
            setEmail(single?.email);
            setUserRole(allUserTypes()[getIndex(single?.user_role_id, allUserTypes(), 'value')]);
            setJobTitle(allJobTitles()[getIndex(single?.job_title_id, allJobTitles(), 'value')]);
            setAddUserPopup(true);
            setErrorsReturned(null);
        }
    }

    //reset states when popup is canceled or closed
    function closeUserPopup() {
        setName('');
        setEmail('');
        setUserRole('');
        setJobTitle('');
        setAddUserPopup(false);
        setErrorsReturned(null);
        setOpenEditMenu(null);
    }

    //deactivate user backend submit request
    const deactivateUser = (e, userId) => {
        e.preventDefault();
        setGeneralDataApiOptions({
            url: 'deactivate-user',
            method: 'post',
            data: {
                user_id: userId,
            },
            callback: res => {
                setUsersData(res.data);
                setOpenEditMenu(null);
            },
            catch: err => {
                if (err) {
                    setOpenEditMenu(null);
                }
            }
        })
    }

    //delete user request
    const deleteUserProcess = (e, userId) => {
        e.preventDefault();
        setDeletePopup(userId);
    }

    //reset delete states
    const cancelDelete = () => {
        setDeletePopup(null);
        setOpenEditMenu(null)
    }

    // delete user request backend
    const deleteUser = (userId) => {
        setGeneralDataApiOptions({
            url: 'delete-user',
            method: 'post',
            data: {
                user_id: userId,
            },
            callback: res => {
                setUsersData(res.data);
                setOpenEditMenu(null);
                setDeletePopup(null);
            },
            catch: err => {
                if (err) {
                    setOpenEditMenu(null);
                }
            }
        })
    }

    //handle changing profile picture(when picture is uploaded)
    const profilePictureHandler = (uploadedFiles) => {
        if (uploadedFiles.length > 0) {
            setProfilePicture(uploadedFiles[0]);
            setCurrentInput('picture');
            setCurrentInputValue(uploadedFiles[0]);
            let file = URL.createObjectURL(uploadedFiles[0]);
            setTempPicture(file);
        }
    }

    //on crop image handle
    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }, []);// eslint-disable-line react-hooks/exhaustive-deps



    //show cropped image
    const showCroppedImage = useCallback(async () => {
        let imageToCrop = (tempPicture ? tempPicture : (selectedEditPicture ? selectedEditPicture : null));
        try {
            const croppedImage = await getCroppedImg(
                imageToCrop,
                croppedAreaPixels,
                rotation
            )
            setCroppedFinalImage(croppedImage);
        } catch (e) {
        }
    }, [croppedAreaPixels, rotation, tempPicture]);// eslint-disable-line react-hooks/exhaustive-deps



    useEffect(() => {
        if (croppedFinalImage && uploadImagePopup) {
            let fd = new FormData();
            fd.append('model', 'FixedSetting');
            fd.append('field', uploadImagePopup);
            fd.append('value', croppedFinalImage);
            setGeneralDataApiOptions({
                url: 'add-edit-cms',
                method: 'post',
                data: fd,
                callback: res => {
                    setAllCmsData(res.data.cms);
                    setGeneralData(res.data.general)
                    setCurrentInput('');
                    setCurrentInputValue('');
                    setActiveFixedInput('');
                    setActiveFixedInputValue('');
                    setErrorsReturned({});
                    setUploadImagePopup('');
                    setTempPicture('');
                },
                catch: err => {
                    if (err) {
                        setErrorsReturned(err);
                    }
                }
            });
        }
    }, [croppedFinalImage]);// eslint-disable-line react-hooks/exhaustive-deps


    return (
        <>
            <Layout activePage="settings">
                <h1 className="page-titles color-dark-blue">{generalData?.settings_section_settings['settings-title']}</h1>
                <div className="row padding-top-table-settings" >
                    <div className="col-auto">
                        <h2 onClick={slug === 'firm-management' ? null : () => history('/settings/firm-management')} className={"settings-titles color-dark-blue cursor-pointer" + (slug === 'firm-management' ? " active" : "")}>{generalData?.settings_section_settings['settings-firm-title']}</h2>
                        {
                            slug === 'firm-management' ?
                                <div className="buttom-light-effect"></div>
                                :
                                null
                        }
                    </div>
                    <div className="col-auto">
                        <h2 onClick={slug === 'content-management' ? null : () => history('/settings/content-management')} className={"settings-titles color-dark-blue cursor-pointer" + (slug === 'content-management' ? " active" : "")}>{generalData?.settings_section_settings['settings-content-title']}</h2>
                        {
                            slug === 'content-management' ?
                                <div className="buttom-light-effect"></div>
                                :
                                null
                        }
                    </div>
                </div>
                <div className={'management-table small-neg-margin-top ' + (slug === 'content-management' ? ' pt-0' : null)} >
                    {
                        slug === 'firm-management' ?
                            <>
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-auto my-auto d-flex align-items-center mb-3">
                                            <h3 className="small-titles-table color-dark-blue ">{generalData?.settings_section_settings['settings-team-title']} </h3>
                                            <img style={{ height: "30px" }} className="cursor-pointer shadow ms-2" src={'/assets/images/plus.svg'} onClick={() => setAddUserPopup(true)} alt="add" />
                                        </div>
                                        <div className="col-12">
                                            <div className="table-wrapper-settings">
                                                <table className="users-table" width="100%">
                                                    <thead>
                                                        <tr>
                                                            <td className="table-sorting" onClick={() => { setOrder('name'); sortedDirection === 'ASC' ? setSortedDirection('DESC') : (sortedDirection === 'ASC' ? setSortedDirection('DESC') : setSortedDirection('ASC')) }} style={{ minWidth: "170px" }}>{generalData?.settings_section_settings['settings-name']}
                                                                <img className={"ms-2" + (sortedDirection === 'DESC' && order === 'name' ? ' active' : '')} src={'/assets/images/arrow-icon.svg'} alt="arrow" />
                                                            </td>
                                                            <td className="table-sorting" onClick={() => { setOrder('email'); sortedDirection === 'ASC' ? setSortedDirection('DESC') : (sortedDirection === 'ASC' ? setSortedDirection('DESC') : setSortedDirection('ASC')) }} style={{ minWidth: "200px" }}>{generalData?.settings_section_settings['settings-email-title']}
                                                                <img className={"ms-2" + (sortedDirection === 'DESC' && order === 'email' ? ' active' : '')} src={'/assets/images/arrow-icon.svg'} alt="arrow" />
                                                            </td>
                                                            <td className="table-sorting" onClick={() => { setOrder('job_title_id'); sortedDirection === 'ASC' ? setSortedDirection('DESC') : (sortedDirection === 'ASC' ? setSortedDirection('DESC') : setSortedDirection('ASC')) }} style={{ minWidth: "190px" }}>{generalData?.settings_section_settings['settings-job-title']}
                                                                <img className={"ms-2" + (sortedDirection === 'DESC' && order === 'job_title_id' ? ' active' : '')} src={'/assets/images/arrow-icon.svg'} alt="arrow" />
                                                            </td>
                                                            <td className="table-sorting" onClick={() => { setOrder('user_role_id'); sortedDirection === 'ASC' ? setSortedDirection('DESC') : (sortedDirection === 'ASC' ? setSortedDirection('DESC') : setSortedDirection('ASC')) }} style={{ minWidth: "200px", width: "200px", maxWidth: "200px" }}>{generalData?.settings_section_settings['settings-role-title']}
                                                                <img className={"ms-2" + (sortedDirection === 'DESC' && order === 'user_role_id' ? ' active' : '')} src={'/assets/images/arrow-icon.svg'} alt="arrow" />
                                                            </td>
                                                            <td className="table-sorting ps-5" onClick={() => { setOrder('status_user_id'); sortedDirection === 'ASC' ? setSortedDirection('DESC') : (sortedDirection === 'ASC' ? setSortedDirection('DESC') : setSortedDirection('ASC')) }} style={{ minWidth: "150px" }}>{generalData?.settings_section_settings['settings-status-title']}
                                                                <img className={"ms-2" + (sortedDirection === 'DESC' && order === 'status_user_id' ? ' active' : '')} src={'/assets/images/arrow-icon.svg'} alt="arrow" />
                                                            </td>
                                                            <td></td>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            usersData?.length > 0 ?
                                                                usersData?.map((singleUser, index) => (
                                                                    singleUser?.id !== authUser?.id ?
                                                                        <tr key={index} onClick={() => history('/profile/' + singleUser?.id + '/general-info')}>
                                                                            <td className={singleUser?.status_user_id === 2 ? ' disabled-user' : ''}>{singleUser.name}</td>
                                                                            <td className={singleUser?.status_user_id === 2 ? ' disabled-user' : ''}>{singleUser.email}</td>
                                                                            <td className={singleUser?.status_user_id === 2 ? ' disabled-user' : ''}>{singleUser?.job_title?.title}</td>
                                                                            <td className={singleUser?.status_user_id === 2 ? 'text-center disabled-user' : 'text-center'}><span className={"p-1 " + (singleUser?.user_role?.id === 1 ? ' admin' : ' user')}>{singleUser?.user_role?.role_name}</span></td>
                                                                            <td className={singleUser?.status_user_id === 2 ? 'ps-5 disabled-user' : 'ps-5'}>{singleUser?.status_user?.title}</td>
                                                                            <td className="text-end p-0 pe-2 position-relative" >
                                                                                <ThreeDotsMenu onClick={(e) => { e.stopPropagation(); openEditMenu?.id === singleUser?.id ? setOpenEditMenu(null) : setOpenEditMenu(singleUser) }} />
                                                                                <div className={"settings-clickmenu text-start " + (singleUser?.id === openEditMenu?.id ? ' d-block' : '')}>
                                                                                    <p style={{ zIndex: "99" }} onClick={(e) => { e.stopPropagation(); e.preventDefault(); editClick(e, singleUser) }} className="p-0 py-1 m-0 ms-2">{generalData?.settings_section_settings['edit-title']}</p>
                                                                                    <p style={{ zIndex: "200" }} onClick={(e) => { e.stopPropagation(); e.preventDefault(); deleteUserProcess(e, singleUser?.id) }} className="p-0 py-1 m-0 ms-2">{generalData?.settings_section_settings['delete-title']}</p>
                                                                                    <p style={{ zIndex: "101" }} onClick={(e) => { e.stopPropagation(); e.preventDefault(); deactivateUser(e, singleUser?.id) }} className="p-0 py-1 m-0 ms-2">{singleUser?.status_user_id === 1 ? generalData?.settings_section_settings['deactivate-title'] : generalData?.settings_section_settings['activate-title']}</p>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                        :
                                                                        null
                                                                ))
                                                                :
                                                                null
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {
                                    //add and edit user popup
                                    addUserPopup ?
                                        <div id="errors-popup" className=" error-popup-api d-flex align-items-center justify-content-center px-3 fade-in">
                                            <div className="pop-up-api-box">
                                                <CloseIcon className="cursor-pointer" onClick={() => closeUserPopup()} />
                                                <div className="text-center ">
                                                    <div className="containter-fluid">
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <h1 className="pop-up-title m-0 py-2 color-dark-blue">{generalData?.settings_section_settings['add-member-title']}</h1>
                                                            </div>
                                                            <form onSubmit={submitAddUser}>
                                                                <div className="col-12 pt-2 pb-4">
                                                                    <div className="row">
                                                                        <div className="col-12 col-md-6 pt-2">
                                                                            <input name="name" className="add-user-inputs" placeholder={generalData?.settings_section_settings['settings-name']} value={name} onChange={(e) => setName(e.target.value)} />
                                                                            {
                                                                                errorsReturned?.name ?
                                                                                    <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text">{errorsReturned?.name}</h5>
                                                                                    :
                                                                                    null
                                                                            }
                                                                        </div>
                                                                        <div className="col-12 col-md-6 pt-2">
                                                                            <input name="email" className="add-user-inputs" placeholder={generalData?.settings_section_settings['settings-email-title']} value={email} onChange={(e) => setEmail(e.target.value)} />
                                                                            {
                                                                                errorsReturned?.email ?
                                                                                    <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text">{errorsReturned?.email}</h5>
                                                                                    :
                                                                                    null
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className="row ">
                                                                        <div className="col-12 col-md-6 pt-2">
                                                                            <div className="postion-relative d-flex justify-content-start w-100">
                                                                                <Select
                                                                                    classNamePrefix="popup-select"
                                                                                    className="popup-select w-100"
                                                                                    styles={customStyles}
                                                                                    options={allJobTitles()}
                                                                                    components={{
                                                                                        IndicatorSeparator: () => null
                                                                                    }}
                                                                                    placeholder={generalData?.settings_section_settings['settings-job-title']}
                                                                                    isSearchable={false}
                                                                                    value={jobTitle ? jobTitle : null}
                                                                                    onChange={setJobTitle} />
                                                                            </div>
                                                                            {
                                                                                errorsReturned?.job_title_id ?
                                                                                    <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text">{errorsReturned?.job_title_id}</h5>
                                                                                    :
                                                                                    null
                                                                            }
                                                                        </div>
                                                                        <div className="col-12 col-md-6 pt-2">
                                                                            <div className="postion-relative d-flex justify-content-start w-100">
                                                                                <Select
                                                                                    classNamePrefix="popup-select"
                                                                                    className="popup-select w-100"
                                                                                    styles={customStyles}
                                                                                    options={allUserTypes()}
                                                                                    components={{
                                                                                        IndicatorSeparator: () => null
                                                                                    }}
                                                                                    placeholder={generalData?.settings_section_settings['settings-role-title']}
                                                                                    isSearchable={false}
                                                                                    value={userRole ? userRole : null}
                                                                                    onChange={setUserRole} />
                                                                            </div>
                                                                            {
                                                                                errorsReturned?.user_role_id ?
                                                                                    <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text">{errorsReturned?.user_role_id}</h5>
                                                                                    :
                                                                                    null
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 d-flex justify-content-center">
                                                                    <button type="submit" className="popup-button my-auto">{openEditMenu?.id ? generalData?.settings_section_settings['edit-member-button'] : generalData?.settings_section_settings['add-member-button']}</button>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        null
                                }
                            </>
                            :
                            null
                    }
                    {
                        slug === 'content-management' ?
                            <div className="content-management ">
                                <div className="row h-100 g-0">
                                    <div className="col-12 h-auto">
                                    </div>
                                    <div className="col-12 h-100 data-cms-content p-0">
                                        <div className="menu-content-management h-auto py-1 mb-4">
                                            <div className="row h-100 g-0">
                                                <div className="col-auto p-2">
                                                    <div onClick={() => setCurrentPage(1)} style={{ width: "auto", borderRadius: "30px 30px 30px 30px" }} onMouseLeave={() => setHoveredItem('')} onMouseEnter={() => setHoveredItem('general-settings')} className={'cursor-pointer links-div-users d-flex padd-icons-user-menu ' + (currentPage === 1 ? ' active' : '')}>
                                                        <div className="d-flex align-items-center">
                                                            {
                                                                currentPage === 1 || hoveredItem === 'general-settings' ?
                                                                    <img src="/assets/images/dashboard-hover.svg" alt="dashboard hover" />
                                                                    :
                                                                    <img src="/assets/images/dashboard.svg" alt="dashboard" />
                                                            }
                                                            <span style={{ width: "auto", textTransform: "none", border: "1px solid transparent", opacity: "1", display: "flex", height: "auto" }} className="ps-3 pe-3">{generalData?.fixed_titles['general-settings']}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-auto p-2">
                                                    <div onClick={() => setCurrentPage(2)} style={{ width: "auto", borderRadius: "30px 30px 30px 30px" }} onMouseLeave={() => setHoveredItem('')} onMouseEnter={() => setHoveredItem('contacts')} className={'cursor-pointer links-div-users d-flex padd-icons-user-menu ' + (currentPage === 2 ? ' active' : '')}>
                                                        <div className="d-flex align-items-center">
                                                            {
                                                                currentPage === 2 || hoveredItem === 'contacts' ?
                                                                    <img src="/assets/images/contacts-hover.svg" alt="contacts hover" />
                                                                    :
                                                                    <img src="/assets/images/contacts.svg" alt="contacts hover" />
                                                            }
                                                            <span style={{ width: "auto", textTransform: "none", border: "1px solid transparent", opacity: "1", display: "flex", height: "auto" }} className="ps-3 pe-3">{generalData?.fixed_titles['contacts']}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-auto p-2">
                                                    <div onClick={() => setCurrentPage(3)} style={{ width: "auto", borderRadius: "30px 30px 30px 30px" }} onMouseLeave={() => setHoveredItem('')} onMouseEnter={() => setHoveredItem('finances')} className={'cursor-pointer links-div-users d-flex padd-icons-user-menu ' + (currentPage === 3 ? ' active' : '')}>
                                                        <div className="d-flex align-items-center">
                                                            {
                                                                currentPage === 3 || hoveredItem === 'finances' ?
                                                                    <img src="/assets/images/finances-hover.svg" alt="finances hover" />
                                                                    :
                                                                    <img src="/assets/images/finances.svg" alt="finances hover" />
                                                            }
                                                            <span style={{ width: "auto", textTransform: "none", border: "1px solid transparent", opacity: "1", display: "flex", height: "auto" }} className="ps-3 pe-3">{generalData?.fixed_titles['finances']}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-auto p-2">
                                                    <div onClick={() => setCurrentPage(4)} style={{ width: "auto", borderRadius: "30px 30px 30px 30px" }} onMouseLeave={() => setHoveredItem('')} onMouseEnter={() => setHoveredItem('shareholders')} className={'cursor-pointer links-div-users d-flex padd-icons-user-menu ' + (currentPage === 4 ? ' active' : '')}>
                                                        <div className="d-flex align-items-center">
                                                            {/* {
                                                                currentPage === 4 || hoveredItem === 'shareholders' ?
                                                                    <img src="/assets/images/finances-hover.svg" alt="finances hover" />
                                                                    :
                                                                    <img src="/assets/images/finances.svg" alt="finances hover" />
                                                            } */}
                                                            <span style={{ width: "auto", textTransform: "none", border: "1px solid transparent", opacity: "1", display: "flex", height: "auto" }} className="ps-3 pe-3">{generalData?.fixed_titles['shareholders-settings']}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-auto p-2">
                                                    <div onClick={() => setCurrentPage(5)} style={{ width: "auto", borderRadius: "30px 30px 30px 30px" }} onMouseLeave={() => setHoveredItem('')} onMouseEnter={() => setHoveredItem('matters')} className={'cursor-pointer links-div-users d-flex padd-icons-user-menu ' + (currentPage === 5 ? ' active' : '')}>
                                                        <div className="d-flex align-items-center">
                                                            {
                                                                currentPage === 5 || hoveredItem === 'matters' ?
                                                                    <img src="/assets/images/matters-hover.svg" alt="matters hover" />
                                                                    :
                                                                    <img src="/assets/images/matters.svg" alt="matters hover" />
                                                            }
                                                            <span style={{ width: "auto", textTransform: "none", border: "1px solid transparent", opacity: "1", display: "flex", height: "auto" }} className="ps-3 pe-3">{generalData?.fixed_titles['matters']}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-auto p-2">
                                                    <div onClick={() => setCurrentPage(6)} style={{ width: "auto", borderRadius: "30px 30px 30px 30px" }} onMouseLeave={() => setHoveredItem('')} onMouseEnter={() => setHoveredItem('events')} className={'cursor-pointer links-div-users d-flex padd-icons-user-menu ' + (currentPage === 6 ? ' active' : '')}>
                                                        <div className="d-flex align-items-center">
                                                            {
                                                                currentPage === 6 || hoveredItem === 'events' ?
                                                                    <img src="/assets/images/calendar-hover.svg" alt="matters hover" />
                                                                    :
                                                                    <img src="/assets/images/calendar.svg" alt="matters hover" />
                                                            }
                                                            <span style={{ width: "auto", textTransform: "none", border: "1px solid transparent", opacity: "1", display: "flex", height: "auto" }} className="ps-3 pe-3">{generalData?.fixed_titles['events']}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-auto p-2">
                                                    <div onClick={() => setCurrentPage(7)} style={{ width: "auto", borderRadius: "30px 30px 30px 30px" }} onMouseLeave={() => setHoveredItem('')} onMouseEnter={() => setHoveredItem('tasks')} className={'cursor-pointer links-div-users d-flex padd-icons-user-menu ' + (currentPage === 7 ? ' active' : '')}>
                                                        <div className="d-flex align-items-center">
                                                            {
                                                                currentPage === 7 || hoveredItem === 'tasks' ?
                                                                    <img src={'/assets/images/tasks-hover.svg'} alt="tasks hover" />
                                                                    :
                                                                    <img src={'/assets/images/tasks.svg'} alt="tasks" />
                                                            }
                                                            <span style={{ width: "auto", textTransform: "none", border: "1px solid transparent", opacity: "1", display: "flex", height: "auto" }} className="ps-3 pe-3">{generalData?.fixed_titles['tasks']}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            currentPage === 1 ?
                                                <div className="row no-pad px-2">
                                                    <ResponsiveMasonry columnsCountBreakPoints={{ 250: 1, 1200: 2 }} margin={'40px'} >
                                                        <Masonry>
                                                            {
                                                                allCmsData['FixedSettings'] &&
                                                                <div className="col-12 pb-4 px-2">
                                                                    <div className="cms-table-content position-relative pb-5">
                                                                        <div className="row pb-2">
                                                                            <div className="col-12">
                                                                                <div className="gray-background">
                                                                                    <div className="row m-0 py-3">
                                                                                        <div className="col-6">
                                                                                            <h1 className="cms-data-table-title">Fixed Settings</h1>
                                                                                        </div>
                                                                                        <div className="col-6 text-end">
                                                                                            <h1 className="cms-data-table-title-ar">الإعدادات الثابتة</h1>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-12">
                                                                                <div className="row m-0 h-auto">
                                                                                    <div className="row">
                                                                                        <EditInputFixedSettings setErrorsReturned={setErrorsReturned} errorsReturned={errorsReturned} targetFixedInputChanges={targetFixedInputChanges} setTargetFixedInputChanges={setTargetFixedInputChanges} key={1} field={'lbp_to_usd'} value={allCmsData['FixedSettings']?.lbp_to_usd} title={generalData['website_titles']['lbp-to-usd']} setActiveFixedInput={setActiveFixedInput} activeFixedInput={activeFixedInput} />
                                                                                    </div>
                                                                                    <div className="row">
                                                                                        <EditInputFixedSettings setErrorsReturned={setErrorsReturned} errorsReturned={errorsReturned} targetFixedInputChanges={targetFixedInputChanges} setTargetFixedInputChanges={setTargetFixedInputChanges} key={2} field={'min_legal_type_amount'} value={allCmsData['FixedSettings']?.min_legal_type_amount} title={generalData['website_titles']['min-legal-type-amount']} setActiveFixedInput={setActiveFixedInput} activeFixedInput={activeFixedInput} />
                                                                                    </div>
                                                                                    <EditPictureInput key={1} setSelectedEditPicture={setSelectedEditPicture} field={'no_profile_picture'} setUploadImagePopup={setUploadImagePopup} image={allCmsData['FixedSettings']?.full_path_no_profile_picture} title={generalData['website_titles']['no-profile-picture']}/>
                                                                                    <EditPictureInput key={2} setSelectedEditPicture={setSelectedEditPicture} field={'no_company_picture'} setUploadImagePopup={setUploadImagePopup} image={allCmsData['FixedSettings']?.full_path_no_company_picture} title={generalData['website_titles']['no-company-picture']}/>
                                                                                    <EditPictureInput key={3} setSelectedEditPicture={setSelectedEditPicture} field={'no_ngo_picture'} setUploadImagePopup={setUploadImagePopup} image={allCmsData['FixedSettings']?.full_path_no_ngo_picture} title={generalData['website_titles']['no-ngo-picture']}/>
                                                                                    <EditPictureInput key={4} setSelectedEditPicture={setSelectedEditPicture} field={'no_individual_picture'} setUploadImagePopup={setUploadImagePopup} image={allCmsData['FixedSettings']?.full_path_no_individual_picture} title={generalData['website_titles']['no-individual-picture']}/>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }
                                                            <div className="col-12 pb-4 px-2">
                                                                {/* 
                                                                    -placeholderEn is the english placeholder should be added in front_cms_titles
                                                                    -placeholderAr is the arabic placeholder should be added in front_cms_titles
                                                                    -addNew should be 1 if user is allowed to add new records, else 0
                                                                    -delete should be 1 if user is allowed to delete records, else 0 
                                                                    -title_en is the english table title should be added in front_cms_titles
                                                                    -title_ar is the arabic table title should be added in front_cms_titles
                                                                    -model is the model name exp: "Gender"
                                                                    -data is the data fetched from backend for this table exp: allCmsData['Gender']
                                                                */}
                                                                <CmsTable placeholderEn={generalData?.front_cms_titles['gender-placeholder']['title_en']} placeholderAr={generalData?.front_cms_titles['gender-placeholder']['title_ar']} addNew={1} delete={1} title_en={generalData?.front_cms_titles['gender']['title_en']} title_ar={generalData?.front_cms_titles['gender']['title_ar']} model={'Gender'} data={allCmsData['Gender']} />
                                                            </div>
                                                            <div className="col-12 pb-4 px-2">
                                                                <CmsTable placeholderEn={generalData?.front_cms_titles['comment-types-placeholder']['title_en']} placeholderAr={generalData?.front_cms_titles['comment-types-placeholder']['title_ar']} addNew={0} delete={0} title_en={generalData?.front_cms_titles['comment-types']['title_en']} title_ar={generalData?.front_cms_titles['comment-types']['title_ar']} model={'CommentType'} data={allCmsData['CommentTypes']} />
                                                            </div>
                                                            <div className="col-12 pb-4 px-2">
                                                                <CmsTable placeholderEn={generalData?.front_cms_titles['fixed-titles-placeholder']['title_en']} placeholderAr={generalData?.front_cms_titles['fixed-titles-placeholder']['title_ar']} addNew={0} delete={0} title_en={generalData?.front_cms_titles['fixed-titles']['title_en']} title_ar={generalData?.front_cms_titles['fixed-titles']['title_ar']} model={'FixedTitle'} data={allCmsData['FixedTitles']} />
                                                            </div>
                                                            <div className="col-12 pb-4 px-2">
                                                                <CmsTable placeholderEn={generalData?.front_cms_titles['excel-report-headings-placeholder']['title_en']} placeholderAr={generalData?.front_cms_titles['excel-report-headings-placeholder']['title_ar']} addNew={0} delete={0} title_en={generalData?.front_cms_titles['excel-report-headings']['title_en']} title_ar={generalData?.front_cms_titles['excel-report-headings']['title_ar']} model={'ExcelReportHeading'} data={allCmsData['ExcelReportHeadings']} />
                                                            </div>
                                                            <div className="col-12 pb-4 px-2">
                                                                <CmsTable placeholderEn={generalData?.front_cms_titles['settings-section-settings-placeholder']['title_en']} placeholderAr={generalData?.front_cms_titles['settings-section-settings-placeholder']['title_ar']} addNew={0} delete={0} title_en={generalData?.front_cms_titles['settings-section-settings']['title_en']} title_ar={generalData?.front_cms_titles['settings-section-settings']['title_ar']} model={'SettingsSectionSetting'} data={allCmsData['SettingsSectionSettings']} withSlugs={1} />
                                                            </div>
                                                            <div className="col-12 pb-4 px-2">
                                                                <CmsTable placeholderEn={generalData?.front_cms_titles['files-type-placeholder']['title_en']} placeholderAr={generalData?.front_cms_titles['files-type-placeholder']['title_ar']} addNew={1} delete={1} title_en={generalData?.front_cms_titles['files-type']['title_en']} title_ar={generalData?.front_cms_titles['files-type']['title_ar']} model={'FilesType'} data={allCmsData['FilesType']} />
                                                            </div>
                                                            <div className="col-12 pb-4 px-2">
                                                                <CmsTable placeholderEn={generalData?.front_cms_titles['authentication-pages-settings-placeholder']['title_en']} placeholderAr={generalData?.front_cms_titles['authentication-pages-settings-placeholder']['title_ar']} addNew={0} delete={0} title_en={generalData?.front_cms_titles['authentication-pages-settings']['title_en']} title_ar={generalData?.front_cms_titles['authentication-pages-settings']['title_ar']} model={'AuthenticationPagesSetting'} data={allCmsData['AuthenticationPagesSettings']} withSlugs={1} />
                                                            </div>
                                                            <div className="col-12 pb-4 px-2">
                                                                <CmsTable placeholderEn={generalData?.front_cms_titles['user-status-placeholder']['title_en']} placeholderAr={generalData?.front_cms_titles['user-status-placeholder']['title_ar']} addNew={0} delete={0} title_en={generalData?.front_cms_titles['user-status']['title_en']} title_ar={generalData?.front_cms_titles['user-status']['title_ar']} model={'StatusUser'} data={allCmsData['StatusUser']} />
                                                            </div>
                                                            <div className="col-12 pb-4 px-2">
                                                                <CmsTable placeholderEn={generalData?.front_cms_titles['job-title-placeholder']['title_en']} placeholderAr={generalData?.front_cms_titles['job-title-placeholder']['title_ar']} addNew={1} delete={1} title_en={generalData?.front_cms_titles['job-title']['title_en']} title_ar={generalData?.front_cms_titles['job-title']['title_ar']} model={'JobTitle'} data={allCmsData['JobTitle']} />
                                                            </div>
                                                            <div className="col-12 pb-4 px-2">
                                                                <CmsTable placeholderEn={generalData?.front_cms_titles['nationalities-placeholder']['title_en']} placeholderAr={generalData?.front_cms_titles['nationalities-placeholder']['title_ar']} addNew={1} delete={1} title_en={generalData?.front_cms_titles['nationalities']['title_en']} title_ar={generalData?.front_cms_titles['nationalities']['title_ar']} model={'Nationality'} data={allCmsData['Nationalities']} />
                                                            </div>
                                                            <div className="col-12 pb-4 px-2">
                                                                <CmsTable placeholderEn={generalData?.front_cms_titles['documents-section-settings-placeholder']['title_en']} placeholderAr={generalData?.front_cms_titles['documents-section-settings-placeholder']['title_ar']} addNew={0} delete={0} title_en={generalData?.front_cms_titles['documents-section-settings']['title_en']} title_ar={generalData?.front_cms_titles['documents-section-settings']['title_ar']} model={'DocumentsSectionSetting'} data={allCmsData['DocumentsSectionSettings']} />
                                                            </div>
                                                            <div className="col-12 pb-4 px-2">
                                                                <CmsTable placeholderEn={generalData?.front_cms_titles['website-titles-placeholder']['title_en']} placeholderAr={generalData?.front_cms_titles['website-titles-placeholder']['title_ar']} addNew={0} delete={0} title_en={generalData?.front_cms_titles['website-titles']['title_en']} title_ar={generalData?.front_cms_titles['website-titles']['title_ar']} model={'WebsiteTitle'} data={allCmsData['WebsiteTitles']} />
                                                            </div>
                                                            <div className="col-12 pb-4 px-2">
                                                                <CmsTable placeholderEn={generalData?.front_cms_titles['documents-report-settings-placeholder']['title_en']} placeholderAr={generalData?.front_cms_titles['documents-report-settings-placeholder']['title_ar']} addNew={0} delete={0} title_en={generalData?.front_cms_titles['documents-report-settings']['title_en']} title_ar={generalData?.front_cms_titles['documents-report-settings']['title_ar']} model={'DocumentReportSetting'} data={allCmsData['DocumentReportSettings']} />
                                                            </div>
                                                            <div className="col-12 pb-4 px-2">
                                                                <CmsTable placeholderEn={generalData?.front_cms_titles['validation-message-placeholder']['title_en']} placeholderAr={generalData?.front_cms_titles['validation-message-placeholder']['title_ar']} addNew={0} delete={0} title_en={generalData?.front_cms_titles['validation-message']['title_en']} title_ar={generalData?.front_cms_titles['validation-message']['title_ar']} model={'ValidationMessage'} data={allCmsData['ValidationMessages']} />
                                                            </div>
                                                            <div className="col-12 pb-4 px-2">
                                                                <CmsTable placeholderEn={generalData?.front_cms_titles['validation-attributes-placeholder']['title_en']} placeholderAr={generalData?.front_cms_titles['validation-attributes-placeholder']['title_ar']} addNew={0} delete={0} title_en={generalData?.front_cms_titles['validation-attributes']['title_en']} title_ar={generalData?.front_cms_titles['validation-attributes']['title_ar']} model={'AttributesValidation'} data={allCmsData['AttributesValidations']} />
                                                            </div>
                                                            <div className="col-12 pb-4 px-2">
                                                                <CmsTable placeholderEn={generalData?.front_cms_titles['licenses-types-placeholder']['title_en']} placeholderAr={generalData?.front_cms_titles['licenses-types-placeholder']['title_ar']} addNew={1} delete={0} title_en={generalData?.front_cms_titles['licenses-types']['title_en']} title_ar={generalData?.front_cms_titles['licenses-types']['title_ar']} model={'LicenseWaiverType'} data={allCmsData['LicensesTypes']} />
                                                            </div>
                                                        </Masonry>
                                                    </ResponsiveMasonry>
                                                </div>
                                                :
                                                null
                                        }
                                        {
                                            currentPage === 2 ?
                                                <div className="row no-pad px-2">
                                                    <ResponsiveMasonry columnsCountBreakPoints={{ 250: 1, 1200: 2 }} margin={'40px'} >
                                                        <Masonry>
                                                            <div className="col-12 pb-4 px-2">
                                                                <CmsTable placeholderEn={generalData?.front_cms_titles['client-role-placeholder']['title_en']} placeholderAr={generalData?.front_cms_titles['client-role-placeholder']['title_ar']} addNew={1} delete={1} title_en={generalData?.front_cms_titles['client-role']['title_en']} title_ar={generalData?.front_cms_titles['client-role']['title_ar']} model={'ClientRole'} data={allCmsData['ClientRoles']}/>
                                                            </div>
                                                            <div className="col-12 pb-4 px-2">
                                                                <CmsTable placeholderEn={generalData?.front_cms_titles['legal-type-placeholder']['title_en']} placeholderAr={generalData?.front_cms_titles['legal-type-placeholder']['title_ar']} addNew={1} delete={1} title_en={generalData?.front_cms_titles['legal-type']['title_en']} title_ar={generalData?.front_cms_titles['legal-type']['title_ar']} model={'LegalType'} data={allCmsData['LegalType']} additionalField={{ type: 'text', name: 'min_balance', placeholder: 'Min Balance LBP' }} />
                                                            </div>
                                                            <div className="col-12 pb-4 px-2">
                                                                <CmsTable placeholderEn={generalData?.front_cms_titles['clients-statuses-placeholder']['title_en']} placeholderAr={generalData?.front_cms_titles['clients-statuses-placeholder']['title_ar']} addNew={0} delete={0} title_en={generalData?.front_cms_titles['clients-statuses']['title_en']} title_ar={generalData?.front_cms_titles['clients-statuses']['title_ar']} model={'ClientStat'} data={allCmsData['ClientStats']} />
                                                            </div>
                                                            <div className="col-12 pb-4 px-2">
                                                                <CmsTable placeholderEn={generalData?.front_cms_titles['shareholders-fixed-titles-placeholder']['title_en']} placeholderAr={generalData?.front_cms_titles['shareholders-fixed-titles-placeholder']['title_ar']} addNew={0} delete={0} title_en={generalData?.front_cms_titles['shareholders-fixed-titles']['title_en']} title_ar={generalData?.front_cms_titles['shareholders-fixed-titles']['title_ar']} model={'ShareholdersFixedTitle'} data={allCmsData['ShareholdersFixedTitles']} />
                                                            </div>
                                                            <div className="col-12 pb-4 px-2">
                                                                <CmsTable placeholderEn={generalData?.front_cms_titles['signature-types-placeholder']['title_en']} placeholderAr={generalData?.front_cms_titles['signature-types-placeholder']['title_ar']} title_en={generalData?.front_cms_titles['signature-types']['title_en']} title_ar={generalData?.front_cms_titles['signature-types']['title_ar']} model={'SignatureType'} data={allCmsData['SignatureTypes']} />
                                                            </div>
                                                            <div className="col-12 pb-4 px-2">
                                                                <CmsTable placeholderEn={generalData?.front_cms_titles['bank-currencies-placeholder']['title_en']} placeholderAr={generalData?.front_cms_titles['bank-currencies-placeholder']['title_ar']} title_en={generalData?.front_cms_titles['bank-currencies']['title_en']} title_ar={generalData?.front_cms_titles['bank-currencies']['title_ar']} model={'BankCurrency'} data={allCmsData['BankCurrencies']} addNew={1} delete={1} />
                                                            </div>
                                                            <div className="col-12 pb-4 px-2">
                                                                <CmsTable placeholderEn={generalData?.front_cms_titles['entity-contact-types-placeholder']['title_en']} placeholderAr={generalData?.front_cms_titles['entity-contact-types-placeholder']['title_ar']} title_en={generalData?.front_cms_titles['entity-contact-types']['title_en']} title_ar={generalData?.front_cms_titles['entity-contact-types']['title_ar']} model={'EntityContactsType'} data={allCmsData['EntityContactsTypes']} addNew={1} delete={1} />
                                                            </div>
                                                            <div className="col-12 pb-4 px-2">
                                                                <CmsTable placeholderEn={generalData?.front_cms_titles['permanent-represent-type-placeholder']['title_en']} placeholderAr={generalData?.front_cms_titles['permanent-represent-type-placeholder']['title_ar']} title_en={generalData?.front_cms_titles['permanent-represent-type']['title_en']} title_ar={generalData?.front_cms_titles['permanent-represent-type']['title_ar']} model={'PermanentRepresentType'} data={allCmsData['PermanentRepresentTypes']} addNew={1} delete={1} />
                                                            </div>
                                                            <div className="col-12 pb-4 px-2">
                                                                <CmsTable placeholderEn={generalData?.front_cms_titles['contacts-settings-placeholder']['title_en']} placeholderAr={generalData?.front_cms_titles['contacts-settings-placeholder']['title_ar']} title_en={generalData?.front_cms_titles['contacts-settings']['title_en']} title_ar={generalData?.front_cms_titles['contacts-settings']['title_ar']} model={'ContactsSetting'} data={allCmsData['ContactsSettings']} addNew={0} delete={0} />
                                                            </div>
                                                        </Masonry>
                                                    </ResponsiveMasonry>
                                                </div>
                                                :
                                                null
                                        }
                                        {
                                            currentPage === 3 ?
                                                <div className="row no-pad px-2">
                                                    <ResponsiveMasonry columnsCountBreakPoints={{ 250: 1, 1200: 2 }} margin={'40px'} >
                                                        <Masonry>
                                                            <div className="col-12 pb-4 px-2">
                                                                <CmsTable placeholderEn={generalData?.front_cms_titles['expense-type-placeholder']['title_en']} placeholderAr={generalData?.front_cms_titles['expense-type-placeholder']['title_ar']} addNew={1} delete={0} title_en={generalData?.front_cms_titles['expense-type']['title_en']} title_ar={generalData?.front_cms_titles['expense-type']['title_ar']} model={'ExpenseType'} data={allCmsData['ExpenseType']} />
                                                            </div>
                                                            <div className="col-12 pb-4 px-2">
                                                                <CmsTable placeholderEn={generalData?.front_cms_titles['expenses-filters-placeholder']['title_en']} placeholderAr={generalData?.front_cms_titles['expenses-filters-placeholder']['title_ar']} addNew={0} delete={0} title_en={generalData?.front_cms_titles['expenses-filters']['title_en']} title_ar={generalData?.front_cms_titles['expenses-filters']['title_ar']} model={'ExpenseFilter'} data={allCmsData['ExpenseFilters']} />
                                                            </div>
                                                            <div className="col-12 pb-4 px-2">
                                                                <CmsTable placeholderEn={generalData?.front_cms_titles['currency-placeholder']['title_en']} placeholderAr={generalData?.front_cms_titles['currency-placeholder']['title_ar']} addNew={0} delete={0} title_en={generalData?.front_cms_titles['currency']['title_en']} title_ar={generalData?.front_cms_titles['currency']['title_ar']} model={'Currency'} data={allCmsData['Currency']} />
                                                            </div>
                                                            <div className="col-12 pb-4 px-2">
                                                                <CmsTable placeholderEn={generalData?.front_cms_titles['transaction-type-placeholder']['title_en']} placeholderAr={generalData?.front_cms_titles['transaction-type-placeholder']['title_ar']} addNew={0} delete={0} title_en={generalData?.front_cms_titles['transaction-type']['title_en']} title_ar={generalData?.front_cms_titles['transaction-type']['title_ar']} model={'TransactionType'} data={allCmsData['TransactionType']} />
                                                            </div>
                                                            <div className="col-12 pb-4 px-2">
                                                                <CmsTable placeholderEn={generalData?.front_cms_titles['transaction-status-placeholder']['title_en']} placeholderAr={generalData?.front_cms_titles['transaction-status-placeholder']['title_ar']} addNew={0} delete={0} title_en={generalData?.front_cms_titles['transaction-status']['title_en']} title_ar={generalData?.front_cms_titles['transaction-status']['title_ar']} model={'TransactionStat'} data={allCmsData['TransactionStat']} />
                                                            </div>
                                                            <div className="col-12 pb-4 px-2">
                                                                <CmsTable placeholderEn={generalData?.front_cms_titles['cost-settings-placeholder']['title_en']} placeholderAr={generalData?.front_cms_titles['cost-settings-placeholder']['title_ar']} addNew={0} delete={0} title_en={generalData?.front_cms_titles['cost-settings']['title_en']} title_ar={generalData?.front_cms_titles['cost-settings']['title_ar']} model={'UsersCostSetting'} data={allCmsData['CostSettings']} />
                                                            </div>
                                                        </Masonry>
                                                    </ResponsiveMasonry>
                                                </div>
                                                :
                                                null
                                        }
                                        {
                                            Number(currentPage) === 4 &&
                                            <div className="row no-pad px-2">
                                                <ResponsiveMasonry columnsCountBreakPoints={{ 250: 1, 1200: 2 }} margin={'40px'} >
                                                    <Masonry>
                                                        <div className="col-12 pb-4 px-2">
                                                            <CmsTable placeholderEn={generalData?.front_cms_titles['share-types-placeholder']['title_en']} placeholderAr={generalData?.front_cms_titles['share-types-placeholder']['title_ar']} addNew={1} delete={1} title_en={generalData?.front_cms_titles['share-types']['title_en']} title_ar={generalData?.front_cms_titles['share-types']['title_ar']} model={'ShareType'} data={allCmsData['ShareType']} />
                                                        </div>
                                                        <div className="col-12 pb-4 px-2">
                                                            <CmsTable placeholderEn={generalData?.front_cms_titles['loan-types-placeholder']['title_en']} placeholderAr={generalData?.front_cms_titles['loan-types-placeholder']['title_ar']} addNew={0} delete={0} title_en={generalData?.front_cms_titles['loan-types']['title_en']} title_ar={generalData?.front_cms_titles['loan-types']['title_ar']} model={'LoanType'} data={allCmsData['LoanTypes']} />
                                                        </div>
                                                        <div className="col-12 pb-4 px-2">
                                                            <CmsTable placeholderEn={generalData?.front_cms_titles['share-class-placeholder']['title_en']} placeholderAr={generalData?.front_cms_titles['share-class-placeholder']['title_ar']} addNew={1} delete={1} title_en={generalData?.front_cms_titles['share-classes']['title_en']} title_ar={generalData?.front_cms_titles['share-classes']['title_ar']} model={'SharesClass'} data={allCmsData['SharesClass']} />
                                                        </div>
                                                        <div className="col-12 pb-4 px-2">
                                                            <CmsTable placeholderEn={generalData?.front_cms_titles['share-operations-placeholder']['title_en']} placeholderAr={generalData?.front_cms_titles['share-operations-placeholder']['title_ar']} addNew={0} delete={0} title_en={generalData?.front_cms_titles['shares-operation']['title_en']} title_ar={generalData?.front_cms_titles['shares-operation']['title_ar']} model={'SharesOperationType'} data={allCmsData['SharesOperations']} />
                                                        </div>
                                                        <div className="col-12 pb-4 px-2">
                                                            <CmsTable placeholderEn={generalData?.front_cms_titles['shareholders-section-settings-placeholder']['title_en']} placeholderAr={generalData?.front_cms_titles['shareholders-section-settings-placeholder']['title_ar']} addNew={0} delete={0} title_en={generalData?.front_cms_titles['shareholders-section-settings']['title_en']} title_ar={generalData?.front_cms_titles['shareholders-section-settings']['title_ar']} model={'ShareholdersSectionSetting'} data={allCmsData['shareholdersSectionSettings']} />
                                                        </div>
                                                    </Masonry>
                                                </ResponsiveMasonry>
                                            </div>
                                        }
                                        {
                                            Number(currentPage) === 5 &&
                                            <div className="row no-pad px-2">
                                                <ResponsiveMasonry columnsCountBreakPoints={{ 250: 1, 1200: 2 }} margin={'40px'} >
                                                    <Masonry>
                                                        <div className="col-12 pb-4 px-2">
                                                            <CmsTable placeholderEn={generalData?.front_cms_titles['matter-section-settings-placeholder']['title_en']} placeholderAr={generalData?.front_cms_titles['matter-section-settings-placeholder']['title_ar']} addNew={0} delete={0} title_en={generalData?.front_cms_titles['matter-section-settings']['title_en']} title_ar={generalData?.front_cms_titles['matter-section-settings']['title_ar']} model={'MatterSectionSetting'} data={allCmsData['MatterSectionSettings']} />
                                                        </div>
                                                        <div className="col-12 pb-4 px-2">
                                                            <CmsTable placeholderEn={generalData?.front_cms_titles['litigation-statuses-placeholder']['title_en']} placeholderAr={generalData?.front_cms_titles['litigation-statuses-placeholder']['title_ar']} addNew={1} delete={1} title_en={generalData?.front_cms_titles['litigation-statuses']['title_en']} title_ar={generalData?.front_cms_titles['litigation-statuses']['title_ar']} model={'Status'} data={allCmsData['Statuses']} additionalField={{ type: 'color', name: 'background_color', placeholder: 'Color', value: '#000000' }} />
                                                        </div>
                                                        <div className="col-12 pb-4 px-2">
                                                            <CmsTable placeholderEn={generalData?.front_cms_titles['litigation-priorities-placeholder']['title_en']} placeholderAr={generalData?.front_cms_titles['litigation-priorities-placeholder']['title_ar']} addNew={1} delete={1} title_en={generalData?.front_cms_titles['litigation-priorities']['title_en']} title_ar={generalData?.front_cms_titles['litigation-priorities']['title_ar']} model={'Priority'} data={allCmsData['Priorities']} additionalField={{ type: 'color', name: 'color', placeholder: 'Color', value: '#000000' }} />
                                                        </div>
                                                        <div className="col-12 pb-4 px-2">
                                                            <CmsTable placeholderEn={generalData?.front_cms_titles['litigation-stage-placeholder']['title_en']} placeholderAr={generalData?.front_cms_titles['litigation-stage-placeholder']['title_ar']} addNew={1} delete={1} title_en={generalData?.front_cms_titles['litigation-stage']['title_en']} title_ar={generalData?.front_cms_titles['litigation-stage']['title_ar']} model={'LitigationStage'} data={allCmsData['LitigationStages']} />
                                                        </div>
                                                        <div className="col-12 pb-4 px-2">
                                                            <CmsTable placeholderEn={generalData?.front_cms_titles['litigation-type-placeholder']['title_en']} placeholderAr={generalData?.front_cms_titles['litigation-type-placeholder']['title_ar']} addNew={1} delete={1} title_en={generalData?.front_cms_titles['litigation-type']['title_en']} title_ar={generalData?.front_cms_titles['litigation-type']['title_ar']} model={'LitigationType'} data={allCmsData['LitigationTypes']} />
                                                        </div>
                                                        <div className="col-12 pb-4 px-2">
                                                            <CmsTable placeholderEn={generalData?.front_cms_titles['hearing-type-placeholder']['title_en']} placeholderAr={generalData?.front_cms_titles['hearing-type-placeholder']['title_ar']} addNew={1} delete={1} title_en={generalData?.front_cms_titles['hearing-type']['title_en']} title_ar={generalData?.front_cms_titles['hearing-type']['title_ar']} model={'HearingType'} data={allCmsData['HearingTypes']} />
                                                        </div>
                                                        <div className="col-12 pb-4 px-2">
                                                            <CmsTable placeholderEn={generalData?.front_cms_titles['hearing-result-placeholder']['title_en']} placeholderAr={generalData?.front_cms_titles['hearing-result-placeholder']['title_ar']} addNew={1} delete={1} title_en={generalData?.front_cms_titles['hearing-result']['title_en']} title_ar={generalData?.front_cms_titles['hearing-result']['title_ar']} model={'HearingResult'} data={allCmsData['HearingResults']} />
                                                        </div>
                                                        <div className="col-12 pb-4 px-2">
                                                            <CmsTable placeholderEn={generalData?.front_cms_titles['court-type-placeholder']['title_en']} placeholderAr={generalData?.front_cms_titles['court-type-placeholder']['title_ar']} addNew={1} delete={1} title_en={generalData?.front_cms_titles['court-type']['title_en']} title_ar={generalData?.front_cms_titles['court-type']['title_ar']} model={'CourtType'} data={allCmsData['CourtTypes']} />
                                                        </div>
                                                        <div className="col-12 pb-4 px-2">
                                                            <CmsTable placeholderEn={generalData?.front_cms_titles['hearing-fixed-title-placeholder']['title_en']} placeholderAr={generalData?.front_cms_titles['hearing-fixed-title-placeholder']['title_ar']} addNew={0} delete={0} title_en={generalData?.front_cms_titles['hearing-fixed-titles']['title_en']} title_ar={generalData?.front_cms_titles['hearing-fixed-titles']['title_ar']} model={'HearingFixedTitle'} data={allCmsData['HearingFixedTitles']} />
                                                        </div>
                                                        <div className="col-12 pb-4 px-2">
                                                            <CmsTable placeholderEn={generalData?.front_cms_titles['litigation-fixed-title-placeholder']['title_en']} placeholderAr={generalData?.front_cms_titles['litigation-fixed-title-placeholder']['title_ar']} addNew={0} delete={0} title_en={generalData?.front_cms_titles['litigation-fixed-title']['title_en']} title_ar={generalData?.front_cms_titles['litigation-fixed-title']['title_ar']} model={'LitigationFixedTitle'} data={allCmsData['LitigationFixedTitles']} />
                                                        </div>
                                                        <div className="col-12 pb-4 px-2">
                                                            <CmsTable placeholderEn={generalData?.front_cms_titles['matters-common-titles-placeholder']['title_en']} placeholderAr={generalData?.front_cms_titles['matters-common-titles-placeholder']['title_ar']} addNew={0} delete={0} title_en={generalData?.front_cms_titles['matters-common-titles']['title_en']} title_ar={generalData?.front_cms_titles['matters-common-titles']['title_ar']} model={'MattersCommonTitle'} data={allCmsData['MattersCommonTitles']} />
                                                        </div>
                                                        <div className="col-12 pb-4 px-2">
                                                            <CmsTable placeholderEn={generalData?.front_cms_titles['stages-fixed-titles-placeholder']['title_en']} placeholderAr={generalData?.front_cms_titles['stages-fixed-titles-placeholder']['title_ar']} addNew={0} delete={0} title_en={generalData?.front_cms_titles['stages-fixed-titles']['title_en']} title_ar={generalData?.front_cms_titles['stages-fixed-titles']['title_ar']} model={'StagesFixedTitle'} data={allCmsData['StagesFixedTitles']} />
                                                        </div>
                                                        <div className="col-12 pb-4 px-2">
                                                            <CmsTable placeholderEn={generalData?.front_cms_titles['stages-conditions-placeholder']['title_en']} placeholderAr={generalData?.front_cms_titles['stages-conditions-placeholder']['title_ar']} addNew={0} delete={0} title_en={generalData?.front_cms_titles['stages-conditions']['title_en']} title_ar={generalData?.front_cms_titles['stages-conditions']['title_ar']} model={'StagesCondition'} data={allCmsData['StagesConditions']} />
                                                        </div>
                                                        <div className="col-12 pb-4 px-2">
                                                            <CmsTable placeholderEn={generalData?.front_cms_titles['hearing-stage-conditions-placeholder']['title_en']} placeholderAr={generalData?.front_cms_titles['hearing-stage-conditions-placeholder']['title_ar']} addNew={0} delete={0} title_en={generalData?.front_cms_titles['hearing-stage-conditions']['title_en']} title_ar={generalData?.front_cms_titles['hearing-stage-conditions']['title_ar']} model={'HearingStageCondition'} data={allCmsData['HearingStageConditions']} />
                                                        </div>
                                                        <div className="col-12 pb-4 px-2">
                                                            <CmsTable placeholderEn={generalData?.front_cms_titles['litigation-work-areas-placeholder']['title_en']} placeholderAr={generalData?.front_cms_titles['litigation-work-areas-placeholder']['title_ar']} addNew={1} delete={1} title_en={generalData?.front_cms_titles['litigation-work-areas']['title_en']} title_ar={generalData?.front_cms_titles['litigation-work-areas']['title_ar']} model={'LitigationWorkArea'} data={allCmsData['LitigationWorkAreas']} />
                                                        </div>
                                                    </Masonry>
                                                </ResponsiveMasonry>
                                            </div>
                                        }
                                        {
                                            Number(currentPage) === 6 &&
                                            <div className="row no-pad px-2">
                                                <ResponsiveMasonry columnsCountBreakPoints={{ 250: 1, 1200: 2 }} margin={'40px'} >
                                                    <Masonry>
                                                        <div className="col-12 pb-4 px-2">
                                                            <CmsTable placeholderEn={generalData?.front_cms_titles['events-fixed-titles-placeholder']['title_en']} placeholderAr={generalData?.front_cms_titles['events-fixed-titles-placeholder']['title_ar']} addNew={0} delete={0} title_en={generalData?.front_cms_titles['events-fixed-titles']['title_en']} title_ar={generalData?.front_cms_titles['events-fixed-titles']['title_ar']} model={'EventsFixedTitle'} data={allCmsData['EventsFixedTitles']} />
                                                        </div>
                                                        <div className="col-12 pb-4 px-2">
                                                            <CmsTable placeholderEn={generalData?.front_cms_titles['notify-before-types-placeholder']['title_en']} placeholderAr={generalData?.front_cms_titles['notify-before-types-placeholder']['title_ar']} addNew={0} delete={0} title_en={generalData?.front_cms_titles['notify-before-types']['title_en']} title_ar={generalData?.front_cms_titles['notify-before-types']['title_ar']} model={'NotifyBeforeType'} data={allCmsData['NotifyBeforeTypes']} />
                                                        </div>
                                                        <div className="col-12 pb-4 px-2">
                                                            <CmsTable placeholderEn={generalData?.front_cms_titles['events-types-color-placeholder']['title_en']} placeholderAr={generalData?.front_cms_titles['events-types-color-placeholder']['title_ar']} addNew={1} delete={0} title_en={generalData?.front_cms_titles['events-types-color']['title_en']} title_ar={generalData?.front_cms_titles['events-types-color']['title_ar']} model={'EventsTypesColor'} data={allCmsData['EventsTypesColor']} additionalField={{ type: 'color', name: 'color', placeholder: 'Color', value: '#000000' }} />
                                                        </div>
                                                        <div className="col-12 pb-4 px-2">
                                                            <CmsTable placeholderEn={generalData?.front_cms_titles['meeting-types-placeholder']['title_en']} placeholderAr={generalData?.front_cms_titles['meeting-types-placeholder']['title_ar']} addNew={1} delete={1} title_en={generalData?.front_cms_titles['meeting-types']['title_en']} title_ar={generalData?.front_cms_titles['meeting-types']['title_ar']} model={'MeetingsType'} data={allCmsData['MeetingsTypes']} />
                                                        </div>
                                                        <div className="col-12 pb-4 px-2">
                                                            <CmsTable placeholderEn={generalData?.front_cms_titles['meetings-fixed-titles-placeholder']['title_en']} placeholderAr={generalData?.front_cms_titles['meetings-fixed-titles-placeholder']['title_ar']} addNew={1} delete={1} title_en={generalData?.front_cms_titles['meetings-fixed-titles']['title_en']} title_ar={generalData?.front_cms_titles['meetings-fixed-titles']['title_ar']} model={'MeetingFixedTitle'} data={allCmsData['MeetingFixedTitles']} />
                                                        </div>
                                                        <div className="col-12 pb-4 px-2">
                                                            <CmsTable placeholderEn={generalData?.front_cms_titles['reminder-type-placeholder']['title_en']} placeholderAr={generalData?.front_cms_titles['reminder-type-placeholder']['title_ar']} addNew={1} delete={0} title_en={generalData?.front_cms_titles['reminder-type']['title_en']} title_ar={generalData?.front_cms_titles['reminder-type']['title_ar']} model={'ReminderType'} data={allCmsData['ReminderTypes']} />
                                                        </div>
                                                        <div className="col-12 pb-4 px-2">
                                                            <CmsTable placeholderEn={generalData?.front_cms_titles['snooze-types-placeholder']['title_en']} placeholderAr={generalData?.front_cms_titles['snooze-types-placeholder']['title_ar']} addNew={0} delete={0} title_en={generalData?.front_cms_titles['snooze-types']['title_en']} title_ar={generalData?.front_cms_titles['snooze-types']['title_ar']} model={'SnoozeType'} data={allCmsData['SnoozeTypes']} />
                                                        </div>
                                                    </Masonry>
                                                </ResponsiveMasonry>
                                            </div>
                                        }
                                        {
                                            Number(currentPage) === 7 &&
                                            <div className="row no-pad px-2">
                                                <ResponsiveMasonry columnsCountBreakPoints={{ 250: 1, 1200: 2 }} margin={'40px'} >
                                                    <Masonry>
                                                        <div className="col-12 pb-4 px-2">
                                                            <CmsTable placeholderEn={generalData?.front_cms_titles['tasks-fixed-titles-placeholder']['title_en']} placeholderAr={generalData?.front_cms_titles['tasks-fixed-titles-placeholder']['title_ar']} addNew={0} delete={0} title_en={generalData?.front_cms_titles['tasks-fixed-titles']['title_en']} title_ar={generalData?.front_cms_titles['tasks-fixed-titles']['title_ar']} model={'TasksFixedTitle'} data={allCmsData['TasksFixedTitles']} />
                                                        </div>
                                                        <div className="col-12 pb-4 px-2">
                                                            <CmsTable placeholderEn={generalData?.front_cms_titles['tasks-types-placeholder']['title_en']} placeholderAr={generalData?.front_cms_titles['tasks-types-placeholder']['title_ar']} addNew={1} delete={0} title_en={generalData?.front_cms_titles['tasks-types']['title_en']} title_ar={generalData?.front_cms_titles['tasks-types']['title_ar']} model={'TasksType'} data={allCmsData['TasksTypes']} />
                                                        </div>
                                                        <div className="col-12 pb-4 px-2">
                                                            <CmsTable placeholderEn={generalData?.front_cms_titles['tasks-priorities-placeholder']['title_en']} placeholderAr={generalData?.front_cms_titles['tasks-priorities-placeholder']['title_ar']} addNew={1} delete={0} title_en={generalData?.front_cms_titles['tasks-priorities']['title_en']} title_ar={generalData?.front_cms_titles['tasks-priorities']['title_ar']} model={'TasksPriority'} data={allCmsData['TasksPriorities']} additionalField={{ type: 'color', name: 'color', placeholder: 'Color', value: '#000000' }} />
                                                        </div>
                                                        <div className="col-12 pb-4 px-2">
                                                            <CmsTable placeholderEn={generalData?.front_cms_titles['task-history-titles-placeholder']['title_en']} placeholderAr={generalData?.front_cms_titles['task-history-titles-placeholder']['title_ar']} addNew={0} delete={0} title_en={generalData?.front_cms_titles['task-history-titles']['title_en']} title_ar={generalData?.front_cms_titles['task-history-titles']['title_ar']} model={'TaskHistoryTitle'} data={allCmsData['TaskHistoryTitles']} />
                                                        </div>
                                                        <div className="col-12 pb-4 px-2">
                                                            <CmsTable placeholderEn={generalData?.front_cms_titles['tasks-statuses-placeholder']['title_en']} placeholderAr={generalData?.front_cms_titles['tasks-statuses-placeholder']['title_ar']} addNew={1} delete={1} title_en={generalData?.front_cms_titles['tasks-statuses']['title_en']} title_ar={generalData?.front_cms_titles['tasks-statuses']['title_ar']} model={'TasksStatus'} data={allCmsData['TaskStatus']} additionalField={{ type: 'color', name: 'color', placeholder: 'Color', value: '#000000' }} />
                                                        </div>
                                                    </Masonry>
                                                </ResponsiveMasonry>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            :
                            null
                    }
                </div>
                {
                    //delete popup
                    deletePopup ?
                        <div id="errors-popup" className=" error-popup-api d-flex align-items-center justify-content-center px-3 fade-in">
                            <div className="pop-up-api-box">
                                <CloseIcon className="cursor-pointer" onClick={() => { setDeletePopup(null); setOpenEditMenu(null) }} />
                                <div className="text-center ">
                                    <div className="containter-fluid">
                                        <div className="row d-flex justify-content-center align-items-center">
                                            <div className="col-9 col-sm-12 d-flex justify-content-center">
                                                <h1 className="pop-up-title m-0 pt-3 color-dark-blue">{generalData?.settings_section_settings['delete-popup-title']}</h1>
                                            </div>
                                            <div className="col-12 d-flex justify-content-center pt-4 pb-2">
                                                <button onClick={() => deleteUser(openEditMenu ? openEditMenu?.id : null)} type="button" className="popup-button my-auto mx-2 w-auto px-3">{generalData?.settings_section_settings['delete-popup-first-button']}</button>
                                                <button onClick={() => cancelDelete()} type="button" className="auth-button border-dark-blue my-auto mx-2 background-transparent w-auto px-3">{generalData?.settings_section_settings['delete-popup-second-button']}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        null
                }
                {
                    //upload image popup
                    uploadImagePopup ?
                        <div id="errors-popup" className=" error-popup-api d-flex align-items-center justify-content-center px-3 fade-in">
                            <div className="pop-up-api-box">
                                <CloseIcon className="cursor-pointer" onClick={(e) => { e.preventDefault(); setUploadImagePopup(''); setTempPicture(''); setProfilePicture(''); setZoom(1); }} />
                                <div className="text-center ">
                                    <div className="containter-fluid">
                                        <div className="row">
                                            <div className="col-12">
                                                <h1 className="pop-up-title m-0 pt-2 color-dark-blue">Change Picture</h1>
                                            </div>
                                            <div className="col-12 d-flex justify-content-center py-3">
                                                {
                                                    (selectedEditPicture || tempPicture) && tempPicture ?
                                                        <div className="image-crop-custom-containter">
                                                            <Cropper
                                                                image={tempPicture ? tempPicture : (selectedEditPicture ? selectedEditPicture : null)}
                                                                crop={crop}
                                                                accept={'jpg,jpeg'}
                                                                zoom={zoom}
                                                                maxZoom={10}
                                                                cropShape={'round'}
                                                                aspect={1 / 1}
                                                                onCropChange={(e) => setCrop(e)}
                                                                onCropComplete={onCropComplete}
                                                                onZoomChange={setZoom}
                                                                showGrid={true}
                                                            />
                                                        </div>
                                                        :
                                                        <img className="popup-profile-image" src={selectedEditPicture ? selectedEditPicture : null} alt="profile" />
                                                }

                                            </div>

                                            <form>
                                                <div className="col-12 d-flex justify-content-center">
                                                    <input hidden accept="image/*" className="upload-comment-files" id='upload-comment-files' type="file" onChange={(e) => { e.preventDefault(); e.stopPropagation(); profilePictureHandler(e.target.files) }} />
                                                    <label htmlFor="upload-comment-files" >
                                                        <p className="popup-button my-auto mx-2">{selectedEditPicture ? 'Edit' : 'Add'}</p>
                                                    </label>
                                                    {
                                                        tempPicture ?
                                                            <button onClick={(e) => showCroppedImage()} type="button" className="popup-button my-auto mx-2">Save</button>
                                                            :
                                                            null
                                                    }
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        null
                }
                <Api options={generalDataApiOptions} />
                <Api options={usersApiOptions} />
            </Layout>
        </>
    )
}