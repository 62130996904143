import React from 'react'
import { CompanyIcon, IndividualIcon, NgoIcon, ThreeDotsMenu } from './Icons'
import { Link, useNavigate } from 'react-router-dom'
import { useContext } from 'react';
import GlobalState from '../contexts/GlobalState';
import UsersSelect from './UsersSelect';
import { useState } from 'react';
import { isValidUrl } from '../Helpers';
import { useRef } from 'react';
import { useEffect } from 'react';

export default function EventCalendarDropdown({ className, event, returnUser, editFunction, deleteFunction, positionX, positionY }) {
    const {
        generalDataGlobal: [generalData],
        authUserGlobal:[authUser],
    } = useContext(GlobalState);

    const [openMenu, setOpenMenu] = useState(false);
    const [activeEditUsers, setActiveEditUsers] = useState('');
    const [target, setTarget] = useState();

    const menuRef = useRef();
    const navigate = useNavigate();

    const triggerEditMenu = (e, event) => {
        e.preventDefault()
        e.stopPropagation()
        setTarget(Number(target?.id) === Number(event?.id) ? null : event)
    }

    useEffect(() => {
        setOpenMenu(Number(target?.id) === Number(event?.id));
    }, [target]);// eslint-disable-line react-hooks/exhaustive-deps

    let permissions = authUser?.user_role?.slug !== 'admin' ?
        authUser?.user_role?.role_permissions?.filter(permission => permission?.user_pages?.slug === "events")[0] :
        {
            edit: 1,
            delete: 1,
            edit_others: 1,
            delete_others: 1,
        };
    return (
        <div
            className={`${className ? className : ''} event-dropdown shadow ${positionX} ${positionY}`}
            style={{
                // opacity: activeEvent === event?.id ? 1 : 0,
                // pointerEvents: activeEvent === event?.id ? 'all' : 'none',
            }}
        >
            <div className='row'>
                <div className='col-12 mb-2'>
                    {

                        ((permissions?.edit_others || permissions?.delete_others) || (Number(event?.user_id) === Number(authUser?.id) && (permissions?.edit || permissions?.delete))) &&

                        <ThreeDotsMenu
                            className="position-absolute top-0 end-0 mt-3 cursor-pointer"
                            ref={menuRef}
                            onClick={(e) => { triggerEditMenu(e, event) }}
                        />
                    }


                    <p onClick={() => { event?.event_type === 'hearing' ? navigate(`/matters/litigations/${event?.litigation_id}/general-info`) : event?.event_type === 'task' && navigate(`/tasks/${event?.id}/general-info`)  }} className="cursor-pointer accordion-title d-flex align-items-center text-primary">
                        {event.name}
                    </p>
                </div>
                <div ref={menuRef} style={{ width: 150 }} className={`settings-clickmenu text-start ${openMenu ? 'd-block' : ''} `}>
                    {
                        editFunction &&
                        (
                            (permissions?.edit_others || ((Number(event?.user_id) === Number(authUser?.id)) && permissions?.edit)) &&

                            <p style={{ zIndex: "99" }} onClick={editFunction} className="p-0 py-1 m-0 ms-2 cursor-pointer">{generalData?.settings_section_settings['edit-title']}</p>
                        )

                    }
                    {
                        deleteFunction &&
                        (
                            (permissions?.delete_others || (Number(event?.user_id) === Number(authUser?.id) && permissions?.delete)) &&
                            <p style={{ zIndex: "200" }} onClick={deleteFunction} className="p-0 py-1 m-0 ms-2 cursor-pointer">{generalData?.settings_section_settings['delete-title']}</p>
                        )
                    }

                </div>
                <div className='col-12'>

                    <div className='row flex-wrap mb-2'>
                        <div className='col-xl-4 col-lg-12 col-md-4 col-sm-3'>
                            <p className='fw-normal text-primary'>{generalData?.website_titles['date-time']}</p>
                        </div>
                        <div className='col-8'>
                            <p className='fw-normal text-gray fs-14'>{event.date}</p>
                        </div>
                    </div>

                    <div className='row flex-wrap mb-2'>
                        <div className='col-4'>
                            <p className='fw-normal text-primary'>{generalData?.mattersCommonTitles['assignees']}</p>
                        </div>
                        <div className='col-8'>
                            <UsersSelect
                                editable={false}
                                returnUser={returnUser}
                                activeEditUsers={activeEditUsers}
                                setActiveEditUsers={setActiveEditUsers}
                                data={event}
                                users={event.assignees || event?.remind_users}
                                addPadding
                            // clientId={props.clientId}
                            // userId={props.userId}
                            />

                        </div>
                    </div>

                    <div className='row flex-wrap mb-2'>
                        <div className='col-4'>
                            <p className='fw-normal text-primary'>{generalData?.website_titles['document']}</p>
                        </div>
                        <div className='col-8'>
                            <p className='fw-normal text-gray fs-14'>
                                {
                                    event?.uploaded_files ?
                                        <a href={event?.uploaded_files?.full_path_file} target='_blank' rel="noreferrer" >{event?.uploaded_files?.file_name || '-'}</a>
                                        :
                                        '-'
                                }
                            </p>
                        </div>
                    </div>
                    {
                        event?.event_type === 'meeting' &&

                        <div className='row flex-wrap mb-2'>
                            <div className='col-4'>
                                <p className='fw-normal text-primary'>{generalData?.meetingFixedTitles['location']}</p>
                            </div>
                            <div className='col-8'>
                                {
                                    event?.location ?
                                        (
                                            isValidUrl(event?.location) ?
                                                <a className='fw-normal text-gray fs-14' href={event?.location} target='_blank' rel="noreferrer" >{event?.location}</a>
                                                :
                                                <p className='fw-normal text-gray fs-14'>{event?.location || '-'}</p>

                                        )
                                        :
                                        <p className='fw-normal text-gray fs-14'>{event?.location || '-'}</p>
                                }
                            </div>
                        </div>
                    }

                    <div className='row flex-wrap mb-2'>
                        <div className='col-4'>
                            <p className='fw-normal text-primary'>{generalData?.website_titles['relation-name']}</p>
                        </div>
                        <div className='col-8'>
                            {
                                event?.client ?
                                    <>
                                        <Link to={`/client/${event?.client?.id}/general-info`}>
                                            <div className='d-flex align-items-center'>

                                                {
                                                    Number(event?.client?.client_type_id) === 1 ?
                                                        <CompanyIcon />
                                                        :
                                                        Number(event?.client?.client_type_id) === 2 ?
                                                            <IndividualIcon />
                                                            :
                                                            <NgoIcon />
                                                }

                                                <p className='fw-normal text-gray fs-14 ms-2'>{event?.client?.name || '-'}</p>
                                            </div>
                                        </Link>
                                    </>
                                    : <p className='fw-normal text-gray fs-14'> - </p>
                            }
                        </div>
                    </div>
                    <div className='row flex-wrap mb-2'>
                        <div className='col-4'>
                            <p className='fw-normal text-primary'>{generalData?.meetingFixedTitles['note']}</p>
                        </div>
                        <div className='col-8'>
                            <p className='fw-normal text-gray fs-14 word-break'>{event?.note || '-'}</p>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}
