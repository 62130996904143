export default function showCommaSeperator(to_seperate) {
    let x = String(to_seperate).replace(/[^0-9^.]/g, '');
    let seperated;
    var nf = new Intl.NumberFormat();
    if (x.length > 0) {
        seperated = nf.format(x);
    } else {
        seperated = '';
    }
    return seperated;
}