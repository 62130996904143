import React, { useState, useRef, useEffect, useContext } from 'react';
import GlobalState from '../contexts/GlobalState';
import { CompanyIcon, IndividualIcon, NgoIcon, TasksIcon, ThreeDotsMenu } from './Icons';
import useOutsideClick from '../useOutsideClick';
import UsersSelect from './UsersSelect';
import { Link, useNavigate } from 'react-router-dom';
import { isValidUrl } from '../Helpers';

export default function EventCardComponent(props) {
    const {
        generalDataGlobal: [generalData],
        profileClientGlobal
    } = useContext(GlobalState);

    const [height, setHeight] = useState(0);
    const elementRef = useRef(null);
    const [profileClient,] = profileClientGlobal;
    const [openCardId, ] = useState();
    const [cardHeight, ] = useState(0);
    const [addedHeight, ] = useState(0);
    const [initialSize, setInitialSize] = useState(0);
    const [width, setWidth] = useState(window.innerWidth);
    const { className, onClick, updateOnChange, openId, index, menuClick, editFunction, deleteFunction, target, setTarget, data, authUser } = props


    const { returnUser } = props
    const [activeEditUsers, setActiveEditUsers] = useState('');

    const menuRef = useRef();

    const [openMenu, setOpenMenu] = useState(false);

    let permissions = authUser?.user_role?.slug !== 'admin' ?
        authUser?.user_role?.role_permissions?.filter(permission => permission?.user_pages?.slug === "events")[0] :
        {
            edit: 1,
            delete: 1,
            edit_others: 1,
            delete_others: 1,
        }
        ;

    const navigate = useNavigate();

    useEffect(() => {
        setOpenMenu(target?.id === data?.id);
    }, [target]);// eslint-disable-line react-hooks/exhaustive-deps

    useOutsideClick(menuRef, () => {
        setOpenMenu(false);
        setTarget(null)
    })

    //update inner width end to calculate div initial height when width changes
    const updateDimensions = () => {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setInitialSize(elementRef?.current?.clientHeight);
    }, [width, profileClient]);// eslint-disable-line react-hooks/exhaustive-deps
    //update inner width end

    // reset active menu when card is changed
    // useEffect(() => {
    //     setActiveMenu({});
    // }, [openCardId]);

    //change height
    useEffect(() => {
        if (!openCardId) {
            setHeight(initialSize);
        } else {
            setHeight(initialSize + cardHeight);
        }
    }, [cardHeight, addedHeight, openCardId, profileClient, initialSize]);// eslint-disable-line react-hooks/exhaustive-deps
    
    useEffect(() => {
        setHeight(elementRef?.current?.clientHeight);
    }, updateOnChange);// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <div className={`faqs position-relative ${className ? className : ''} ${openId === index ? ' active ' : ''}`} >
                <div className={`accordion-title`} >
                    {/* header  */}
                    <div
                        className="row justify-content-between position-relative"
                        onClick={(e) => {
                            setHeight(elementRef.current.clientHeight);
                            onClick(e);
                        }}
                    >
                        <div className="col-12">

                            <div className='position-relative bg-white shadow p-3 border-left border-thick rounded-right-3' style={{ borderColor: generalData?.eventsColor[data?.event_type] }}>
                                {
                                    ((permissions?.edit_others || permissions?.delete_others) || (data?.user_id === authUser?.id && (permissions?.edit || permissions?.delete))) &&

                                    <ThreeDotsMenu className="position-absolute top-0 end-0 mt-3 cursor-pointer" ref={menuRef} onClick={menuClick} />
                                }

                                <p className="cursor-pointer accordion-title color-dark-blue d-flex align-items-center fw-normal text-primary">
                                    {data.name}
                                </p>
                                <p className="cursor-pointer accordion-title color-dark-blue d-flex align-items-center fw-normal text-gray">
                                    {data?.date}
                                </p>
                                <p className="cursor-pointer accordion-title color-dark-blue d-flex align-items-center fw-normal" style={{ color: generalData?.eventsColor[data?.event_type] }}>
                                    {generalData?.eventsTypes[data?.event_type]}
                                </p>
                            </div>
                        </div>
                        <div ref={menuRef} style={{ width: 150 }} className={`settings-clickmenu text-start ${openMenu ? 'd-block' : ''} `}>
                            {
                                editFunction &&
                                (
                                    (permissions?.edit_others || (data?.user_id === authUser?.id && permissions?.edit)) &&
                                    <p style={{ zIndex: "99" }} onClick={editFunction} className="p-0 py-1 m-0 ms-2 cursor-pointer">{generalData?.settings_section_settings['edit-title']}</p>
                                )

                            }
                            {
                                deleteFunction &&
                                (permissions?.delete_others || (data?.user_id === authUser?.id && permissions?.delete)) &&
                                <p style={{ zIndex: "200" }} onClick={deleteFunction} className="p-0 py-1 m-0 ms-2 cursor-pointer">{generalData?.settings_section_settings['delete-title']}</p>
                            }

                        </div>
                    </div>
                    {/* body  */}
                    <div className="col-12">
                        <div
                            style={{
                                height: openId === index ? height : 0,
                                overflow: openId !== index ? 'hidden' : ''
                            }}
                            className={` ${openId === index ? 'accordion-content open' : 'accordion-content '}`}
                        >
                            <div ref={elementRef} className="bg-white shadow mt-2 p-3 rounded-3">
                                <div className='row'>
                                    <div className='col-12 mb-2'>

                                        <p onClick={() => { data?.event_type === 'hearing' ? navigate(`/matters/litigations/${data?.litigation_id}/general-info`) : data?.event_type === 'task' && navigate(`/tasks/${data?.id}/general-info`) }} className="cursor-pointer accordion-title d-flex align-items-center text-primary">
                                            {data.name}
                                        </p>
                                    </div>
                                    <div className='col-12'>

                                        <div className='row flex-wrap mb-2'>
                                            <div className='col-xl-4 col-lg-12 col-md-4 col-sm-3'>
                                                <p className='fw-normal text-primary'>{generalData?.website_titles['date-time']}</p>
                                            </div>
                                            <div className='col-8'>
                                                <p className='fw-normal text-gray fs-14'>{data.date}</p>
                                            </div>
                                        </div>

                                        <div className='row flex-wrap mb-2'>
                                            <div className='col-4'>
                                                <p className='fw-normal text-primary'>{generalData?.mattersCommonTitles['assignees']}</p>
                                            </div>
                                            <div className='col-8'>
                                                <UsersSelect
                                                    editable={false}
                                                    returnUser={returnUser}
                                                    activeEditUsers={activeEditUsers}
                                                    setActiveEditUsers={setActiveEditUsers}
                                                    data={data}
                                                    users={data.assignees || data?.remind_users}
                                                    addPadding
                                                // clientId={props.clientId}
                                                // userId={props.userId}
                                                />

                                            </div>
                                        </div>

                                        <div className='row flex-wrap mb-2'>
                                            <div className='col-4'>
                                                <p className='fw-normal text-primary'>{generalData?.website_titles['document']}</p>
                                            </div>
                                            <div className='col-8'>
                                                <p className='fw-normal text-gray fs-14'>
                                                    {
                                                        data?.uploaded_files ?
                                                            <a href={data?.uploaded_files?.full_path_file} target='_blank' rel="noreferrer" >{data?.uploaded_files?.file_name || '-'}</a>
                                                            :
                                                            '-'
                                                    }
                                                </p>
                                            </div>
                                        </div>
                                        {
                                            data?.event_type === 'meeting' &&

                                            <div className='row flex-wrap mb-2'>
                                                <div className='col-4'>
                                                    <p className='fw-normal text-primary'>{generalData?.meetingFixedTitles['location']}</p>
                                                </div>
                                                <div className='col-8'>
                                                    {
                                                        data?.location ?
                                                            (
                                                                isValidUrl(data?.location) ?
                                                                    <a className='fw-normal text-gray fs-14' href={data?.location} target='_blank' rel="noreferrer" >{data?.location}</a>
                                                                    :
                                                                    <p className='fw-normal text-gray fs-14'>{data?.location || '-'}</p>

                                                            )
                                                            :
                                                            <p className='fw-normal text-gray fs-14'>{data?.location || '-'}</p>
                                                    }
                                                </div>
                                            </div>
                                        }

                                        <div className='row flex-wrap mb-2'>
                                            <div className='col-4'>
                                                <p className='fw-normal text-primary'>{generalData?.website_titles['relation-name']}</p>
                                            </div>
                                            <div className='col-8'>
                                                {
                                                    data?.client ?
                                                        <>
                                                            <Link to={`/client/${data?.client?.id}/general-info`}>
                                                                <div className='d-flex align-items-center'>

                                                                    {
                                                                        data?.client?.client_type_id === 1 ?
                                                                            <CompanyIcon />
                                                                            :
                                                                            data?.client?.client_type_id === 2 ?
                                                                                <IndividualIcon />
                                                                                :
                                                                                <NgoIcon />
                                                                    }

                                                                    <p className='fw-normal text-gray fs-14 ms-2'>{data?.client?.name || '-'}</p>
                                                                </div>
                                                            </Link>
                                                        </>
                                                        :
                                                        data?.task ?
                                                            <Link to={`/tasks/${data?.task?.id}/general-info`}>
                                                                <div className='d-flex align-items-center'>


                                                                    <TasksIcon />

                                                                    <p className='fw-normal text-gray fs-14 ms-2'>{data?.task?.name || '-'}</p>
                                                                </div>
                                                            </Link>
                                                            : <p className='fw-normal text-gray fs-14'> - </p>
                                                }
                                            </div>
                                        </div>
                                        <div className='row flex-wrap mb-2'>
                                            <div className='col-4'>
                                                <p className='fw-normal text-primary'>{generalData?.meetingFixedTitles['note']}</p>
                                            </div>
                                            <div className='col-8'>
                                                <p className='fw-normal text-gray fs-14 word-break'>{data?.note || '-'}</p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}