import React, { useContext, useState } from 'react';
import GlobalState from '../contexts/GlobalState';
import Select from 'react-select';
import Api from './Api';
import { getIndex } from '../Helpers';
import { CommentArrow, LockClose, LockOpen, ThreeDotsMenu, XIcon } from './Icons';

export default function ClientComments(props) {

    const { profileClientGlobal, generalDataGlobal, authUserGlobal } = useContext(GlobalState);
    const [profileClient, setProfileClient] = profileClientGlobal;
    const [authUser,] = authUserGlobal;
    const [generalData,] = generalDataGlobal;
    const [errorsReturned, setErrorsReturned] = useState({});
    const [generalDataApiOptions, setGeneralDataApiOptions] = useState({});
    const { commentsData, setState, deleteApi} = props
    //get comment types
    const commentTypes = () => {
        const types = generalData?.comment_types?.map(
            type => ({ value: type.id, label: type.title }))
        return types;
    }

    /* Comment Start */
    const [clientComment, setClientComment] = useState('');
    const [commentType, setCommentType] = useState(2);
    const [editCommentMenu, setEditCommentMenu] = useState(null);
    const [currentComment, setCurrentComment] = useState(null);
    const [editComment, setEditComment] = useState('');
    const [editCommentType, setEditCommentType] = useState('');
    const [deleteComment, setDeleteComment] = useState(null);
    /* Comment End */

    //edit comment we set the states with comment data
    const editCommentClick = (e, data) => {
        setCurrentComment(data);
        setEditComment(data?.comment);
        setEditCommentType(commentTypes()[getIndex(data?.comment_type_id, commentTypes(), 'value')]);
    }

    //to reset states when comment is canceled
    const cancelCommentClick = (e, data) => {
        setEditComment(null);
        setEditCommentType('');
        setEditCommentMenu(null);
        setCurrentComment(null);
        setDeleteComment(null);
    }


    //delete comment 
    const deleteCommentProcess = (e, data) => {
        e.preventDefault();
        e.stopPropagation();

        let fd = new FormData();

        fd.append('comment_id', data ? data?.id : '');
        if (props?.relation) {
            fd.append(props?.relation, props.relation_id)
        } else {
            fd.append('client_id', props.clientId);
        }
        fd.append('user_id', props.clientId);
        fd.append('section', props.section);

        setGeneralDataApiOptions({
            url: deleteApi || 'delete-comment',
            method: 'post',
            data: fd,
            callback: res => {
                setErrorsReturned({});
                setState ? setState(res.data) : setProfileClient(res.data);
                setEditCommentType('');
                setEditCommentMenu(null);
                setEditComment(null);
                setCurrentComment('');
                setClientComment('');
                setDeleteComment(null);
                setErrorsReturned({});
            },
            catch: err => {
                if (err) {
                    setErrorsReturned(err);
                }
            }
        })
    }

    //submit client comment
    const submitClientComment = (e) => {
        e.preventDefault();
        e.stopPropagation();
        let fd = new FormData();

        if (currentComment?.id) {
            fd.append('comment_id', currentComment ? currentComment?.id : '');
            fd.append('comment', editComment ? editComment : '');
            if (props?.relation) {
                fd.append(props?.relation, props.relation_id)
            } else {
                fd.append('client_id', props.clientId);
            }
            fd.append('user_id', props.clientId);
            fd.append('comment_type_id', editCommentType ? editCommentType.value : 2);
            fd.append('section', currentComment.section);
        } else {
            fd.append('comment', clientComment ? clientComment : '');
            if (props?.relation) {
                fd.append(props?.relation, props.relation_id)
            } else {
                fd.append('client_id', props.clientId);
            }
            fd.append('user_id', props.clientId);
            fd.append('comment_type_id', commentType ? commentType : 2);
            fd.append('section', props.section);
        }

        setGeneralDataApiOptions({
            url: props?.api || 'add-edit-comment',
            method: 'post',
            data: fd,
            callback: res => {
                setErrorsReturned({});
                setState ? setState(res.data) : setProfileClient(res.data);
                setEditCommentType('');
                setEditCommentMenu(null);
                setEditComment(null);
                setCurrentComment('');
                setClientComment('');
                setErrorsReturned({});
            },
            catch: err => {
                if (err) {
                    setErrorsReturned(err);
                }
            }
        })
    }

    // custom styles for the select
    const customStylesPopup = {
        placeholder: (defaultStyles) => {
            return {
                ...defaultStyles,
                display: "flex",
                fontFamily: 'Poppins',
                fontWeight: '400',
                textTransform: "capitalize",
                fontSize: '14px',
                color: '#07407C',
            }
        },
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                color: '#07407C',
                fontFamily: 'Poppins',
                fontWeight: '400',
                backgroundColor: 'white',
                textTransform: "capitalize",
                margin: "auto",
                paddingRight: "10px",
                maxWidth: "100%",
                paddingLeft: "10px",
                paddingBottom: "0",
                paddingTop: "0",
            };
        },
        singleValue: base => ({
            ...base,
            color: "#00366A",
            display: "flex",
            fontSize: "14px",
            fontFamily: "Poppins",
            fontWeight: "400",
            textTransform: "capitalize",
        }),
        control: provided => ({
            ...provided,
            height: "40px",
            border: "none",
            backgroundColor: 'white',
            outline: "none",
            '&:hover': { borderColor: 'transparent' },
            textTransform: 'uppercase',
            width: "100%",
            borderRadius: "7px",
            fontFamily: 'Poppins',
            fontWeight: '400',
            boxShadow: "0px 3px 6px rgb(0 0 0 / 16%)",
        }),
        menu: (provided, state) => ({
            ...provided,
            borderRadius: "7px",
            textTransform: 'uppercase',
            border: "1px solid transparent",
        }),
        dropdownIndicator: base => ({
            ...base,
            color: "#00366A !important",
            // marginTop: "-18px !important"
        }),
        input: (base, state) => ({
            ...base,
            color: "#393939",
            fontSize: "16px",
            height: "100%",
            fontFamily: 'Poppins',
            textTransform: 'uppercase',
            fontWeight: '400',
            marginTop: "14px",
            '& input': {
                font: 'inherit',
            },
        })
    };

    return (
        <>
            <div className="row py-2">
                <h1 className="profile-big-title">
                    {generalData?.website_titles['comments-title']}
                </h1>

                <div className="col-12 pt-2">
                    <div className="row g-0 p-0 costs-box-styling custom-shadow bg-dark-gray">
                        {
                            commentsData ?
                                (

                                    commentsData?.length > 0 ?
                                        (

                                            commentsData.map((singleComment, indxx) => (
                                                (singleComment?.comment_type_id === 1 && authUser?.id === singleComment?.user_id) || singleComment?.comment_type_id === 2 ?
                                                    <div key={indxx} className="col-12 px-3 py-3">
                                                        <div className="row">
                                                            <div className="col-auto">
                                                                <img className="image-user-chat" src={singleComment?.user?.picture ? singleComment?.user?.full_path_picture : generalData?.fixed_settings?.full_path_no_profile_picture} alt="chat user" />
                                                            </div>
                                                            <div className="col ps-0">
                                                                <div className="row justify-content-between">
                                                                    <div className="col-auto">
                                                                        <p className="m-0 font-semi-bold">{singleComment?.user?.name} {singleComment?.comment_type_id === 1 ?
                                                                            <LockClose style={{ width: '20px', height: '18px', display: 'inline-block' }} />
                                                                            :
                                                                            null
                                                                        }</p>
                                                                    </div>
                                                                    <div className="col text-end my-auto">
                                                                        <h4 className="time-for-human-chat">{singleComment?.human_date}</h4>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-12">
                                                                        <h5 className="comments-text-chat">{singleComment?.comment}</h5>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {
                                                                authUser?.id === singleComment?.user_id ?
                                                                    <div className="col-auto pe-0 d-flex align-items-center">
                                                                        <div className="text-end p-0 position-relative" >
                                                                            <XIcon onClick={(e) => { e.stopPropagation(); editCommentMenu?.id === singleComment?.id ? setEditCommentMenu(null) : setEditCommentMenu(singleComment) }} />
                                                                            <div style={{ top: '0px', zIndex: '999999' }} className={"settings-clickmenu text-start " + (singleComment?.id === editCommentMenu?.id ? ' d-block' : '')}>
                                                                                <p style={{ zIndex: "99" }} onClick={(e) => { e.stopPropagation(); e.preventDefault(); editCommentClick(e, singleComment) }} className="p-0 py-1 m-0 ms-2">{generalData?.settings_section_settings['edit-title']}</p>
                                                                                <p style={{ zIndex: "200" }} onClick={(e) => { e.stopPropagation(); e.preventDefault(); setDeleteComment(singleComment) }} className="p-0 py-1 m-0 ms-2">{generalData?.settings_section_settings['delete-title']}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                    </div>
                                                    :
                                                    null
                                            ))
                                        )
                                        :
                                        <h5 className="py-4 px-2 locked-inputs w-100 m-0 p-0">{generalData?.website_titles['no-comments-yet']}</h5>
                                )

                                :
                                profileClient?.comments?.length > 0 ?
                                    profileClient?.comments?.map((singleComment, indxx) => (
                                        (singleComment?.comment_type_id === 1 && authUser?.id === singleComment?.user_id) || singleComment?.comment_type_id === 2 ?
                                            <div key={indxx} className="col-12 px-3 py-3">
                                                <div className="row">
                                                    <div className="col-auto">
                                                        <img className="image-user-chat" src={singleComment?.user?.picture ? singleComment?.user?.full_path_picture : generalData?.fixed_settings?.full_path_no_profile_picture} alt="chat user" />
                                                    </div>
                                                    <div className="col ps-0">
                                                        <div className="row justify-content-between">
                                                            <div className="col-auto">
                                                                <p className="m-0 font-semi-bold">{singleComment?.user?.name} {singleComment?.comment_type_id === 1 ?
                                                                    <LockClose style={{ width: '20px', height: '18px', display: 'inline-block' }} />
                                                                    :
                                                                    null
                                                                }</p>
                                                            </div>
                                                            <div className="col text-end my-auto">
                                                                <h4 className="time-for-human-chat">{singleComment?.human_date}</h4>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <h5 className="comments-text-chat">{singleComment?.comment}</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {
                                                        authUser?.id === singleComment?.user_id ?
                                                            <div className="col-auto pe-0 d-flex align-items-center">
                                                                <div className="text-end p-0 position-relative" >
                                                                    <ThreeDotsMenu onClick={(e) => { e.stopPropagation(); editCommentMenu?.id === singleComment?.id ? setEditCommentMenu(null) : setEditCommentMenu(singleComment) }} />
                                                                    <div style={{ top: '0px', zIndex: '999999' }} className={"settings-clickmenu text-start " + (singleComment?.id === editCommentMenu?.id ? ' d-block' : '')}>
                                                                        <p style={{ zIndex: "99" }} onClick={(e) => { e.stopPropagation(); e.preventDefault(); editCommentClick(e, singleComment) }} className="p-0 py-1 m-0 ms-2">{generalData?.settings_section_settings['edit-title']}</p>
                                                                        <p style={{ zIndex: "200" }} onClick={(e) => { e.stopPropagation(); e.preventDefault(); setDeleteComment(singleComment) }} className="p-0 py-1 m-0 ms-2">{generalData?.settings_section_settings['delete-title']}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            :
                                                            null
                                                    }
                                                </div>
                                            </div>
                                            :
                                            null
                                    ))
                                    :
                                    <h5 className="py-4 px-2 locked-inputs w-100 m-0 p-0">{generalData?.website_titles['no-comments-yet']}</h5>
                        }
                        <div className="col-12 position-relative">
                            <input onKeyDown={(e) => (e.key === "Enter") ? submitClientComment(e) : 1} className="chat-input w-100" type="text" value={clientComment} onChange={(e) => setClientComment(e.target.value)} placeholder={generalData?.website_titles['add-comment-here']} />
                            <div onClick={(e) => submitClientComment(e)} className="chat-input-arrow">
                                <CommentArrow />
                            </div>
                            <div onClick={(e) => commentType === 1 ? setCommentType(2) : setCommentType(1)} className="chat-input-comment-type">
                                <div className="position-relative">
                                    <div className="triangle-down-expl"></div>
                                    <h5 className="commet-type-expl">{commentType === 2 ? generalData?.website_titles['make-private'] : generalData?.website_titles['make-public']}</h5>
                                </div>
                                {
                                    commentType === 2 ?
                                        <LockOpen />
                                        :
                                        <LockClose />
                                }
                            </div>
                        </div>
                        {
                            errorsReturned?.comment ?
                                <h5 className="pb-2 pt-2 m-0 d-flex flex-start pop-errors-text text-start">{errorsReturned?.comment}</h5>
                                :
                                null
                        }
                    </div>
                    {
                        //delete popup
                        deleteComment && deleteComment?.id ?
                            <div id="errors-popup" className=" error-popup-api d-flex align-items-center justify-content-center px-3 fade-in">
                                <div className="pop-up-api-box">
                                    <XIcon onClick={(e) => cancelCommentClick(e)} />
                                    <div className="text-center ">
                                        <div className="containter-fluid">
                                            <div className="row d-flex justify-content-center align-items-center">
                                                <div className="col-9 col-sm-12 d-flex justify-content-center">
                                                    <h1 className="pop-up-title m-0 pt-3 color-dark-blue">{generalData?.settings_section_settings['delete-popup-title']}</h1>
                                                </div>
                                                <div className="col-12 d-flex justify-content-center pt-4 pb-2">
                                                    <button onClick={(e) => { e.preventDefault(); e.stopPropagation(); deleteCommentProcess(e, deleteComment) }} type="button" className="popup-button my-auto mx-2 w-auto px-3">{generalData?.settings_section_settings['delete-popup-first-button']}</button>
                                                    <button onClick={(e) => cancelCommentClick(e)} type="button" className="auth-button border-dark-blue my-auto mx-2 background-transparent w-auto px-3">{generalData?.settings_section_settings['delete-popup-second-button']}</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            null
                    }

                    {
                        //comment popup
                        currentComment && currentComment?.id ?
                            <div id="errors-popup" className=" error-popup-api d-flex align-items-center justify-content-center px-3 fade-in">
                                <div className="pop-up-api-box">
                                    <XIcon onClick={(e) => cancelCommentClick(e)} />
                                    <div className="text-center ">
                                        <div className="containter-fluid">
                                            <div className="row">
                                                <div className="col-12">
                                                    <h1 className="pop-up-title m-0 py-2 color-dark-blue">{generalData?.website_titles['edit-comment']}</h1>
                                                </div>
                                                <form onSubmit={submitClientComment}>
                                                    <div className="col-12 pt-2 pb-4">
                                                        <div className="row justify-content-start">
                                                            <div className="col-12 pt-2">
                                                                <textarea style={{ minHeight:200 }} type="text-area" name="comment" className="add-user-inputs resizable-vertical"  placeholder={generalData?.website_titles['enter-comment']} value={editComment} onChange={(e) => setEditComment(e.target.value)}  ></textarea>
                                                                {
                                                                    errorsReturned?.comment ?
                                                                        <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text text-start">{errorsReturned?.comment}</h5>
                                                                        :
                                                                        null
                                                                }
                                                            </div>
                                                            <div className="col-12 pt-2">
                                                                <div className="postion-relative text-start justify-content-start w-100">
                                                                    <Select
                                                                        classNamePrefix="popup-select"
                                                                        className="popup-select w-100"
                                                                        styles={customStylesPopup}
                                                                        options={commentTypes()}
                                                                        components={{
                                                                            IndicatorSeparator: () => null
                                                                        }}
                                                                        placeholder='Comment Type'
                                                                        isSearchable={false}
                                                                        value={editCommentType}
                                                                        onChange={setEditCommentType} />
                                                                </div>
                                                                {
                                                                    errorsReturned?.comment_type_id ?
                                                                        <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text">{errorsReturned?.comment_type_id}</h5>
                                                                        :
                                                                        null
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 d-flex justify-content-center">
                                                        <button type="submit" className="popup-button my-auto">{generalData?.website_titles['edit-proxy-button']}</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            null
                    }
                </div>
            </div>
            <Api options={generalDataApiOptions} />
        </>
    )
}