import React, { useEffect } from 'react'
import { useContext } from 'react';
import GlobalState from '../contexts/GlobalState';
import { Link, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import ReminderNotificationComponent from './ReminderNotificationComponent';
import NotificationComponent from './NotificationComponent';
import axios from 'axios';

export default function NotificationsMenuComponent({ setNotificationsMenu, notificationsMenu, customSnoozePopup, setCustomSnoozePopup }) {
    const {
        generalDataGlobal: [generalData],
        authUserGlobal: [authUser],
        unreadRemindersGlobal,
        unreadNotificationsGlobal,
        notificationsGlobal,
        remindersGlobal,
    } = useContext(GlobalState);
    const [notificationType, setNotificationType] = useState('notifications');
    const [activeReminder, setActiveReminder] = useState({});
    const [snoozeDate, setSnoozeDate] = useState('');
    const [snoozeType, setSnoozeType] = useState('');
    const [reminders, setReminders] = remindersGlobal;
    const [targetReminders, setTargetReminders] = useState(0);
    const [targetNotifications, setTargetNotifications] = useState(0);
    const [notifications, setNotifications] = notificationsGlobal;
    const [currentNotification, setCurrentNotification] = useState({});
    const [, setUnreadReminders] = unreadRemindersGlobal;
    const [, setUnreadNotifications] = unreadNotificationsGlobal;
    const history = useNavigate();

    useEffect(() => {
        axios.get('/get-user-reminders?reset=1').then((res) => {
            setReminders(res?.data);
        })
    }, [targetReminders]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        axios.get('/get-user-notifications?reset=1').then((res) => {
            setNotifications(res?.data?.data);
        })
    }, [targetNotifications]);// eslint-disable-line react-hooks/exhaustive-deps

    const handleNotificationTypeSwich = (type) => {
        if (type === 'reminders') {
            setTargetReminders(Number(targetReminders) + 1);
            setUnreadReminders(0);
        }
        if (type === 'notifications') {
            setTargetNotifications(Number(targetNotifications) + 1);
            setUnreadNotifications(0);
        }
        setNotificationType(type)
    }

    const handleViewAllReminders = (e) => {
        e.preventDefault();
        setNotificationsMenu(false);
        history(`/profile/${authUser?.id}/reminder`);
    }

    return (
        <div className="notification-quick-div">
            <div className='w-100 p-2'>

                <div className='row my-3'>

                    <div className="col-auto">
                        <h4 onClick={() => handleNotificationTypeSwich('notifications')} className={`finance-menu-titles pb-2  ${notificationType === 'notifications' ? 'active' : ''}`}>{generalData?.website_titles['notifications']}</h4>
                        {
                            notificationType === 'notifications' &&
                            <div className="buttom-light-effect"></div>
                        }
                    </div>

                    <div className="col-auto">
                        <h4 onClick={() => handleNotificationTypeSwich('reminders')} className={`finance-menu-titles pb-2 ${notificationType === 'reminders' ? 'active' : ''}`}>{generalData?.website_titles['reminders']}</h4>
                        {
                            notificationType === 'reminders' &&
                            <div className="buttom-light-effect"></div>
                        }
                    </div>
                </div>

                {
                    notificationType === 'reminders' ?
                        <>
                            {

                                reminders?.length > 0 ?
                                    reminders?.map((reminder, i) => (
                                        <ReminderNotificationComponent
                                            key={i}
                                            reminder={reminder}
                                            reminders={reminders}
                                            setReminders={setReminders}
                                            index={i}
                                            className={i + 1 !== reminders?.length && (reminders?.length > 5 && i + 1 !== 5) ? 'border-bottom pb-2 mb-2' : ''}
                                            activeReminder={activeReminder}
                                            setActiveReminder={setActiveReminder}
                                            snoozeDate={snoozeDate}
                                            setSnoozeDate={setSnoozeDate}
                                            snoozeType={snoozeType}
                                            setSnoozeType={setSnoozeType}
                                            customSnoozePopup={customSnoozePopup}
                                            setCustomSnoozePopup={setCustomSnoozePopup}
                                        />
                                    ))
                                    :
                                    <div>{generalData?.website_titles['no-notifications']}</div>
                            }
                            {
                                reminders?.length > 0 &&
                                <div onClick={(e) => handleViewAllReminders(e)} className='popup-button mx-auto my-3 cursor-pointer'>{generalData?.website_titles['view-all']}</div>
                            }

                        </>


                        :
                        (
                            <>
                                {


                                    notifications?.length > 0 ?
                                        <>
                                            {

                                                notifications?.map((notification, i) => (
                                                    i < 5 &&
                                                    <NotificationComponent
                                                        key={i}
                                                        className={i + 1 !== notifications?.length && (notifications?.length > 5 && i + 1 !== 5) ? ' border-bottom pb-2 mb-2' : ''}
                                                        notification={notification}
                                                        setNotifications={setNotifications}
                                                        currentNotification={currentNotification}
                                                        setCurrentNotification={setCurrentNotification}
                                                        index={i}
                                                    />
                                                ))
                                            }
                                        </>
                                        :
                                        <div>{generalData?.website_titles['no-notifications']}</div>


                                }

                                {
                                    notifications?.length > 0 &&
                                    <Link to="/notifications" className='popup-button mx-auto my-3'>{generalData?.website_titles['view-all']}</Link>
                                }
                            </>

                        )

                }
            </div>

        </div>
    )
}
