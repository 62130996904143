import { useEffect, useRef, useState, useContext } from "react";
import GlobalState from '../contexts/GlobalState';

export default function GoogleMaps(props) {
    const { updateMapGlobal } = useContext(GlobalState);
    const [updateMap, setUpdateMap] = updateMapGlobal;

    const center = props.center;
    let zoom = props.zoom;
    const ref = useRef(null);
    const [map, setMap] = useState();
    useEffect(() => {
        if ((ref.current && updateMap === 1) || (ref.current && !map) ) {
            let map = new window.google.maps.Map(ref.current, { center, zoom });
            new window.google.maps.Marker({
                position: center,
                map,
            });
            setMap(map);
            setUpdateMap(0);
        }
    }, [ref, updateMap]);// eslint-disable-line react-hooks/exhaustive-deps

    return (

        <div ref={ref} style={{ height: 400 }} />
    )

}