import React, { useRef } from 'react'
import UsersSelect from './UsersSelect'
import { useState } from 'react';
import PriorityComponent from './PriorityComponent';
import { useContext } from 'react';
import GlobalState from '../contexts/GlobalState';
import useOutsideClick from '../useOutsideClick';

export default function TaskBoxComponent({ task, editFunction, deleteFunction, copyFunction, index, setCols, handleAssigneesChange = () => { }, activeTasksType, dueBox = false, hideDate = false, dueTodayTasks, setDueTodayTasks }) {
    const {
        generalDataGlobal: [generalData],
        authUserGlobal: [authUser],
    } = useContext(GlobalState);

    const [activeEditUsers, setActiveEditUsers] = useState('');
    const { tasksPriorities } = generalData;
    const menuRef = useRef();
    const [openMenu, setOpenMenu] = useState()

    const editMenuClick = (e, id) => {
        e.stopPropagation();
        e.preventDefault();
        setOpenMenu(Number(openMenu) === Number(id) ? null : id);
    }

    useOutsideClick(menuRef, () => {
        setOpenMenu(null);
    })


    return (
        <div
            className={`mb-3 shadow-primary task-box p-3 d-flex flex-column`}
            style={{ minHeight: dueBox ? 100 : 150 }}
        >
            {/* edit menu on click */}
            <div className='row justify-content-between'>
                <div className='col-9'>
                    {
                        dueBox ?
                            <div className='d-flex flex-1 align-items-center'>
                                <h6 className='fw-700 me-3 my-0'>{task?.name}</h6>

                                <PriorityComponent
                                    className={`p-0 mx-n3`}
                                    selectedPriority={task?.priority}
                                    priorities={tasksPriorities}
                                    api={'edit-task-priority'}
                                    target={task.id}
                                    targetName={'task_id'}
                                    showFlagOnly={1}
                                    hideShadow={1}
                                    onChange={(res) => {
                                        setCols(res?.data?.cols);
                                        setDueTodayTasks(res?.data?.dueTodayTasks)

                                    }}
                                    activeTasksType={activeTasksType}
                                    editable={Number(authUser?.id) === Number(task?.user_id) || authUser?.user_role_id === 1}
                                    showToolTip={true}
                                />
                            </div>

                            :
                            <h6 className='fw-700'>{task?.name}</h6>
                    }
                </div>
                <div className='col-2 ms-auto px-0'>

                    {
                        (Number(authUser?.id) === Number(task?.user_id) || authUser?.user_role_id === 1) &&
                        <div onClick={e => { e.stopPropagation(); e.preventDefault() }} className=" pe-0 d-flex justify-content-end align-items-center cursor-pointer">
                            <div onClick={e => { e.stopPropagation(); e.preventDefault() }} className="text-end p-0 position-relative" >
                                <svg ref={menuRef} onClick={(e) => { editMenuClick(e, task?.id) }} id="Component_54_3" data-name="Component 54 – 3" xmlns="http://www.w3.org/2000/svg" width="30" height="19" viewBox="0 0 5 22">
                                    <circle id="Ellipse_36" data-name="Ellipse 36" cx="2.5" cy="2.5" r="2.5" fill="#00366a" />
                                    <circle id="Ellipse_37" data-name="Ellipse 37" cx="2.5" cy="2.5" r="2.5" transform="translate(0 7)" fill="#00366a" />
                                    <circle id="Ellipse_38" data-name="Ellipse 38" cx="2.5" cy="2.5" r="2.5" transform="translate(0 14)" fill="#00366a" />
                                </svg>
                                <div style={{ width: 150 }} className={`settings-clickmenu text-start ${Number(openMenu) === Number(task?.id) ? 'd-block' : ''} `}>
                                    {
                                        editFunction &&
                                        <p style={{ zIndex: "99" }} onClick={(e) => { e.preventDefault(); e.stopPropagation(); editFunction(task) }} className="p-0 py-1 m-0 ms-2">{generalData?.settings_section_settings['edit-title']}</p>
                                    }
                                    {
                                        deleteFunction &&
                                        <p style={{ zIndex: "200" }} onClick={(e) => { e.preventDefault(); e.stopPropagation(); deleteFunction(task) }} className="p-0 py-1 m-0 ms-2">{generalData?.settings_section_settings['delete-title']}</p>
                                    }
                                    {
                                        copyFunction &&
                                        <p style={{ zIndex: "99" }} onClick={copyFunction} className="p-0 py-1 m-0 ms-2">{generalData?.hearingFixedTitles['copy']}</p>
                                    }
                                </div>
                            </div>
                        </div>
                    }
                </div>

            </div>
            {
                !dueBox &&
                <p className='text-primary'>TA{task?.id}</p>
            }
            {
                !hideDate &&
                <p className='text-gray'>{task?.date}</p>
            }
            <div className={`w-100 d-flex justify-content-between mt-2`}>
                {
                    dueBox ?
                        <p className='text-primary'>TA{task?.id}</p>

                        :
                        <PriorityComponent
                            className={`p-0 mx-n3`}
                            selectedPriority={task?.priority}
                            priorities={tasksPriorities}
                            api={'edit-task-priority'}
                            target={task.id}
                            targetName={'task_id'}
                            showFlagOnly={1}
                            hideShadow={1}
                            activeTasksType={activeTasksType}
                            onChange={(res) => {
                                setCols(res?.data?.cols);
                                setDueTodayTasks(res?.data?.dueTodayTasks)

                            }}
                            editable={Number(authUser?.id) === Number(task?.user_id) || authUser?.user_role_id === 1}
                            showToolTip={true}
                        />
                }


                <UsersSelect
                    url={'/add-edit-task-assignees'}
                    activeEditUsers={activeEditUsers}
                    setActiveEditUsers={setActiveEditUsers}
                    data={task}
                    users={task?.assignees}
                    model={'Task'}
                    clientId={''}
                    className="m-0"
                    editable={Number(authUser?.id) === Number(task?.user_id) || authUser?.user_role_id === 1}
                    onChange={handleAssigneesChange}
                    return={'index'}
                />
            </div>
        </div>
    )
}
