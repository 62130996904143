import React, { useContext, useEffect, useRef, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import useOutsideClick from '../useOutsideClick';
import EventCalendarDropdown from './EventCalendarDropdown';
import GlobalState from '../contexts/GlobalState';
import { ArrowLeft, ArrowRight } from './Icons';

const localizer = momentLocalizer(moment);

const MyCalendar = ({ activeDay, events, onNavigate=()=>{}, editFunction, deleteFunction, meetingCheckbox, setMeetingCheckbox, hearingCheckbox, setHearingCheckbox, tasksCheckbox, setTasksCheckbox, remindersCheckbox, setRemindersCheckbox, defaultView, defaultDate, disableHeader=false, customTopSpacing }) => {
    const formattedEvents = events?.map(event => (
        {
            ...event,
            id: event?.id,
            name: event?.name,
            start: new Date(event?.start.replace(/-/g, "/")),
            end: new Date(event?.end.replace(/-/g, "/")),
        }
    ))

    const {
        generalDataGlobal: [generalData],
    } = useContext(GlobalState);

    const [activeEvent, setActiveEvent] = useState(null);
    // const [currentMonth, setCurrentMonth] = useState(moment().startOf('month'));
    const [currentDate, setCurrentDate] = useState(moment().startOf('day'));
    const [currentMonth, setCurrentMonth] = useState(moment().startOf('month'));
    const [activeView, setActiveView] = useState('month');
    const [eventTarget, setEventTarget] = useState();

    const [showFilterMenu, setShowFilterMenu] = useState(false);


    const calendarRef = useRef();

    const weeklyEventRef = useRef();

    const timeGutterFormat = (date) => moment(date).format('h a');

    const onEventClick = (e, event) => {
        e.stopPropagation();
        setActiveEvent(Number(activeEvent?.id) === Number(event?.id) ? null : event);
        setEventTarget(e);
        setShowFilterMenu(false)
    }

    useEffect(() => {
        setCurrentDate(activeDay);
    },[activeDay])

    const CustomEventWrapper = (props) => {
        const { style, event } = props;
        const eventStyle = {
            position: 'absolute',
            top: `${style?.top}%`,
            left: `${style?.xOffset}%`,
            width: `${style?.width}%`,
            height: `${style?.height}%`,
        };

        return (
            <div onClick={(e) => onEventClick(e, event)} style={{ ...eventStyle, display: 'flex', justifyContent: 'space-between', borderColor: generalData?.eventsColor[event?.event_type] }} className='event-wrapper' >
                <div className='event-title'>

                    <div>{event?.name}</div>
                    {/* <p style={{ margin: 0, whiteSpace: 'nowrap' }}>{props?.label}</p> */}
                </div>
                {/* <div style={{ color: event?.type == 'hearing' ? 'red' : event?.type == 'meeting' ? 'green' : 'blue' }}>{event?.type}</div> */}
                {
                    Number(activeEvent?.id) === Number(event?.id) &&
                    <EventCalendarDropdown
                        ref={weeklyEventRef}
                        editFunction={(e) => { editFunction(e, event) }}
                        deleteFunction={(e) => { deleteFunction(e, event) }}
                        activeEvent={activeEvent}
                        event={event}
                        positionX={eventTarget?.clientX + eventTarget?.view?.pageXOffset + 400 > window?.innerWidth ? 'end-0' : 'start-0'}
                        positionY={eventTarget?.clientY + eventTarget?.view?.pageYOffset + (customTopSpacing || 250) > window?.innerHeight ? 'bottom-0 translate-y-100' : 'bottom-0 translate-y-100'}
                    />
                }

            </div>
        );
    };

    const CustomMonthlyEvent = (props) => {
        const {  event } = props;

        return (
            <div
                style={{ display: 'flex', justifyContent: 'space-between', borderColor: generalData?.eventsColor[event?.event_type] }}
                className='event-wrapper rounded-right-3 event'
            >
                <div
                    onClick={(e) => onEventClick(e, event)}
                    className='event-title w-100'
                >
                    <div >{event?.name}</div>
                </div >
                {
                    ((Number(activeEvent?.id) === Number(event?.id)) && (activeEvent?.event_type === event?.event_type)) &&

                    <EventCalendarDropdown
                        editFunction={(e) => { editFunction(e, event) }}
                        deleteFunction={(e) => { deleteFunction(e, event) }}
                        activeEvent={activeEvent}
                        setActiveEvent={setActiveEvent}
                        event={event}
                        positionX={eventTarget?.clientX + eventTarget?.view?.pageXOffset + 350 > window?.innerWidth ? 'end-0' : 'start-0'}
                        positionY={eventTarget?.clientY + eventTarget?.view?.pageYOffset + 100 > window?.innerHeight ? 'bottom-0 translate-y-100' : 'bottom-0 translate-y-100'}
                    />

                }

            </div>
        );
    };

    const handleNavigate = (date) => {
        console.log('111111111111',date);
        setCurrentMonth(moment(date).startOf('month'));
        setCurrentDate(moment(date).startOf('day'))
        setActiveEvent(null);
        onNavigate(date);
    };

    const handleOnView = (view) => {
        setActiveView(view);
        setCurrentMonth(moment(currentDate).startOf('month'));
        setCurrentDate(moment(currentDate).startOf('day'))
    }

    const handleFilterClick = (e) => {
        e.stopPropagation()
        setActiveEvent(null);
        setShowFilterMenu(!showFilterMenu);
    }

    const CustomToolbar = (toolbar) => {
        const { onNavigate } = toolbar;

        const filterMenuRef = useRef();

        useOutsideClick(filterMenuRef, () => { setShowFilterMenu(false) })

        const handlePrev = (e) => {
            setActiveEvent(null);
            onNavigate('PREV');
        };

        const handleNext = (e) => {
            setActiveEvent(null);
            onNavigate('NEXT');
        };

        const handleViewChange = (view) => {
            toolbar.onView(view);
            setActiveView(view);
            setCurrentMonth(moment(currentDate).startOf('month'));
            setCurrentDate(moment(currentDate).startOf('day'))
            setActiveEvent(null);

        };



        return (
            disableHeader ? 
            <> 
            </>
            :
            <div className="custom-toolbar w-100 position-absolute top-0 start-0 p-4 pb-0 pb-2">
                <div className='row mb-2'>
                    <div className='col-12 mb-2'>
                        <div className='d-flex justify-content-between'>
                            <div>
                                <button className={`btn onhover-active ${activeView === 'day' ? 'active' : ''}`} onClick={() => handleViewChange('day')}>{generalData?.website_titles['day']}</button>
                                <button className={`btn onhover-active ${activeView === 'week' ? 'active' : ''}`} onClick={() => handleViewChange('week')}>{generalData?.website_titles['week']}</button>
                                <button className={`btn onhover-active ${activeView === 'month' ? 'active' : ''}`} onClick={() => handleViewChange('month')}>{generalData?.website_titles['month']}</button>

                            </div>
                            <div className='position-relative'>
                                <button ref={filterMenuRef} onClick={handleFilterClick} className='filter-btn btn m-0'>{generalData?.website_titles['filter']}</button>
                                {
                                    showFilterMenu &&
                                    <div className='mt-2 position-absolute top-100 end-0 filter-menu bg-white w-auto d-flex flex-nowrap px-3 pt-3 shadow dropdown-menu'>
                                        <div className='d-flex flex-column flex-grow-1'>
                                            <div className='w-100 d-flex mb-3 align-items-center'>
                                                <div onClick={(e) => { e.stopPropagation(); setMeetingCheckbox(!meetingCheckbox) }} className={`m-0 billable-div ${meetingCheckbox ? 'active' : ''}`}>
                                                </div>
                                                <div onClick={(e) => { e.stopPropagation(); setMeetingCheckbox(!meetingCheckbox) }} className="ms-2 billable-popup white-space-nowrap">{generalData?.website_titles['meetings']}</div>
                                            </div>
                                            <div className='w-100 d-flex mb-3 align-items-center'>
                                                <div onClick={(e) => { e.stopPropagation(); setHearingCheckbox(!hearingCheckbox) }} className={`m-0 billable-div ${hearingCheckbox ? 'active' : ''}`}>
                                                </div>
                                                <div onClick={(e) => { e.stopPropagation(); setHearingCheckbox(!hearingCheckbox) }} className="ms-2 billable-popup white-space-nowrap">{generalData?.website_titles['hearings']}</div>
                                            </div>
                                            <div className='w-100 d-flex mb-3 align-items-center'>
                                                <div onClick={(e) => { e.stopPropagation(); setTasksCheckbox(!tasksCheckbox) }} className={`m-0 billable-div ${tasksCheckbox ? 'active' : ''}`}>
                                                </div>
                                                <div onClick={(e) => { e.stopPropagation(); setTasksCheckbox(!tasksCheckbox) }} className="ms-2 billable-popup white-space-nowrap">{generalData?.website_titles['tasks']}</div>
                                            </div>
                                            <div className='w-100 d-flex mb-3 align-items-center'>
                                                <div onClick={(e) => { e.stopPropagation(); setRemindersCheckbox(!remindersCheckbox) }} className={`m-0 billable-div ${remindersCheckbox ? 'active' : ''}`}>
                                                </div>
                                                <div onClick={(e) => { e.stopPropagation(); setRemindersCheckbox(!remindersCheckbox) }} className="ms-2 billable-popup white-space-nowrap">{generalData?.website_titles['reminders']}</div>
                                            </div>
                                        </div>
                                    </div>
                                }

                            </div>
                        </div>

                    </div>
                    <div className='col-12'>
                        <div className='d-flex justify-content-between'>
                            <p className='fw-700'>
                                {
                                    activeView === 'day' ?
                                        currentDate?.format('dddd, DD MMMM YYYY')
                                        :
                                        currentMonth?.format('MMMM YYYY')
                                }
                            </p>

                            <div className='d-flex'>
                                <button className='btn circle d-flex align-items-center justify-content-center onhover-revercolor' onClick={(e) => handlePrev(e)}><ArrowLeft /></button>
                                {/* <span>{label}</span> */}
                                <button className='btn circle d-flex align-items-center justify-content-center onhover-revercolor' onClick={(e) => handleNext(e)}><ArrowRight /></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const CustomHeader = ({ label }) => {
        const day = label.split(' ')[1];
        const date = label.split(' ')[0];
        return (
            <div className='custom-days-header' >
                <p className='day-label' style={{ color: 'gray' }}>{day}</p>
                <p className='date-label'>{date}</p>
                {/* Add any additional custom header elements or styling */}
            </div>
        );
    };

    useOutsideClick(calendarRef, () => {
        setActiveEvent(null)
    })

    // useEffect(()=>{
    //     // if(defaultDate){
    //     //     handleNavigate(defaultDate)
    //     // }

    // },[defaultDate])

    return (

        <>
            <div ref={calendarRef} className='w-100 h-100' onClick={() => { setActiveEvent(null) }}>
                <Calendar
                    localizer={localizer}
                    events={formattedEvents}
                    onNavigate={handleNavigate}
                    onView={handleOnView}
                    showMultiDayTimes={5}
                    components={{
                        day: { eventWrapper: CustomEventWrapper },
                        week: { eventWrapper: CustomEventWrapper, header: CustomHeader },
                        month: { eventWrapper: CustomMonthlyEvent },
                        toolbar: CustomToolbar,
                        // eventContainerWrapper : CustomEventContainer,
                    }}
                    
                    date={currentDate || null}
                    defaultView={defaultView || 'month'}
                    defaultDate={defaultDate || '' }
                    formats={{
                        timeGutterFormat: timeGutterFormat
                    }}
                />
            </div>

        </>
    );
};

export default MyCalendar;