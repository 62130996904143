import React from 'react'
import { FileIcon } from './Icons'

export default function LogsComponent({ generalData, returnedFiles, showCount }) {

    return (
        <div className="col-12 pt-2">
            <div className="row g-0 p-0 costs-box-styling custom-shadow">
                {
                    returnedFiles?.length > 0 ?
                        <div className="height-100 ">
                            <div className="col-12">
                                <div className="table-wrapper-settings h-auto">
                                    <table id="custom-grey-colors" className="users-table custom-grey-colors" width="100%">
                                        <thead>
                                            <tr>
                                                <td className="table-sorting" style={{ minWidth: "170px" }}>{generalData?.website_titles['user']}</td>
                                                <td className="table-sorting" style={{ minWidth: "170px" }}>{generalData?.website_titles['document']}</td>
                                                <td className="table-sorting" style={{ minWidth: "200px" }}>{generalData?.website_titles['comment']}</td>
                                                <td className="table-sorting" style={{ minWidth: "200px" }}>{generalData?.website_titles['date']}</td>
                                            </tr>
                                        </thead>
                                        {

                                            returnedFiles?.map((file, index) => (
                                                (index < showCount) &&
                                                <tbody key={index}>
                                                    {
                                                        file?.deleted_at ?

                                                            file?.comment &&
                                                            <>
                                                                <tr>
                                                                    <td>
                                                                        <del>
                                                                            <img width="10px" height="10px" className="image-user-chat-smaller me-2" src={file?.delete_user?.picture ? file?.delete_user?.full_path_picture : generalData?.fixed_settings?.full_path_no_profile_picture} alt="chat user" />
                                                                            {file?.delete_user?.name}
                                                                        </del>
                                                                    </td>
                                                                    <td>
                                                                        <del>
                                                                            <a target='_blank' rel="noreferrer" className={`fs-12 m-0 fs-smaller`} href={file?.full_path_file}>
                                                                                <FileIcon className="p-1" />
                                                                                {file?.file_name}
                                                                            </a>
                                                                        </del>
                                                                    </td>
                                                                    <td>
                                                                        <div style={{ display: "-webkit-box" }}>
                                                                            <div className="user-image-in-table custom-comment-popup position-relative">
                                                                                <del>
                                                                                    <div className="showing-comment">
                                                                                        {file?.comment?.length > 200 ? file?.comment?.slice(0, 200) + '...' : file?.comment}
                                                                                    </div>
                                                                                </del>
                                                                                <div className={index <= 1 ? 'triangle-up' : 'triangle-down'}></div>
                                                                                <div style={{ width: '350px',maxHeight: '150px',overflow: 'auto' }} className={index <= 1 ? 'hover-username-fliped' : 'hover-username' }>
                                                                                    <p>{file?.comment}</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td><del>{file?.deleted_at}</del></td>
                                                                </tr>
                                                            </>
                                                            :
                                                            file?.comment &&
                                                            <>
                                                                <tr>
                                                                    <td>
                                                                        <img width="10px" height="10px" className="image-user-chat-smaller me-2" src={file?.create_user?.picture ? file?.create_user?.full_path_picture : generalData?.fixed_settings?.full_path_no_profile_picture} alt="chat user" />
                                                                        {file?.create_user?.name}
                                                                    </td>
                                                                    <td>
                                                                        <a target='_blank' rel="noreferrer" className={`fs-12 m-0 fs-smaller`} href={file?.full_path_file}>
                                                                            <FileIcon className="p-1" />
                                                                            {file?.file_name}
                                                                        </a>
                                                                    </td>
                                                                    <td>
                                                                        <div style={{ display: "-webkit-box" }}>
                                                                            <div className="user-image-in-table custom-comment-popup position-relative">
                                                                                <div className="showing-comment">
                                                                                    {file?.comment?.length > 200 ? file?.comment?.slice(0, 200) + '...' : file?.comment}
                                                                                </div>
                                                                                <div className={index <= 1 ? 'triangle-up' : 'triangle-down'}></div>
                                                                                <div style={{ width: '350px',maxHeight: '150px',overflow: 'auto' }} className={index <= 1 ? 'hover-username-fliped' : 'hover-username' }>
                                                                                    <p>{file?.comment}</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td>{file?.formatted_date}</td>
                                                                </tr>
                                                            </>
                                                    }
                                                </tbody>
                                            ))
                                        }
                                    </table>
                                </div>
                            </div>
                        </div>
                        :
                        <h5 className="py-3 locked-inputs w-100 m-0 p-0 ps-2">{generalData?.website_titles['no-logs-yet']}</h5>
                }
            </div >
        </div >
    )
}
