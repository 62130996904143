import { useEffect, useState } from "react";
import axios from "axios";
export default function Api(props) {
    const { showLoader = true } = props.options
    const [validationMessages, setValidationMessages] = useState([]);
    const [loading, setLoading] = useState(false);
    const checkValidationErros = (errors) => {
        let newValidationMessages = [];
        for (const key in errors) {
            for (let i = 0; i < errors[key].length; i++) {
                newValidationMessages.push(errors[key][i]);
            }
        }
        setValidationMessages(newValidationMessages);
    }
    useEffect(() => {
        if (props.options.url) {
            if (loading) return;
            setLoading(true);
            setValidationMessages([]);
            axios({
                url: props.options.url,
                method: props.options.method,
                params: props.options.params,
                data: props.options.data,
                headers: props.options.headers ? props.options.header : {
                    Accept: 'application/json',
                },
            })
                .then(props.options.callback)
                .catch(r => {
                    if (r.response?.status === 422) {
                        if (props.options.catch) props.options.catch(r.response.data.errors);
                        checkValidationErros(r.response.data.errors);
                    } else if (r.response?.status === 401) {
                        localStorage.removeItem('auth_token');
                        // navigate('/login');
                    } else if (r.response?.status === 404) {
                        // history.push('/404');
                    } else {
                        //alert(r.message)
                        props?.options?.catch(r);

                    }
                })
                .finally(() => {
                    setLoading(false);
                    if (props.options.finally) props.options.finally();
                });
        }
    }, [props.options]);// eslint-disable-line react-hooks/exhaustive-deps 
    return (
        <>
            {
                (((showLoader && loading && props.options.url !== 'edit-license-users' && props.options.url !== 'user-data' && props.options.url !== 'edit-users-reminder') || (loading && props.options?.showBigLoader === 1)) && !props?.options?.hideLoaderInPage) && (
                    <div className="loader-forms d-flex align-items-center justify-content-center">
                        <div className="lds-ring">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </div>
                )
            }
            {
                validationMessages.length > 0 && props.options.url === 'save-form-1' && (
                    <div id="errors-popup" className="error-popup-api d-flex align-items-center justify-content-center px-3 fade-in flex-column">
                        <div className="pop-up-api-box d-flex align-items-center jsutify-content-center">
                            <svg onClick={() => setValidationMessages([])} style={{ cursor: "pointer" }} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                <circle id="Ellipse_9" data-name="Ellipse 9" cx="10" cy="10" r="10" fill="#158e30" />
                                <path id="close" d="M6.757,5.846,11.2,1.4A.74.74,0,0,0,10.158.353L5.71,4.8,1.263.353A.74.74,0,0,0,.217,1.4L4.664,5.846.217,10.293A.74.74,0,1,0,1.263,11.34L5.71,6.892l4.447,4.447A.74.74,0,0,0,11.2,10.293Zm0,0" transform="translate(4.29 4.154)" fill="#fff" />
                            </svg>

                            <div className="content w-100 background-light text-center px-md-5 py-md-5 px-2 py-2">
                                {
                                    validationMessages.map((message, i) => (
                                        <p className="my-2" key={i}>{message}</p>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                )
            }
        </>
    )
}