import { useContext, useEffect, useState } from "react";
import Select from 'react-select';
import Api from "./Api";
import GlobalState from '../contexts/GlobalState';
import "react-datepicker/dist/react-datepicker.css";
import 'react-phone-number-input/style.css';
import DatePicker from 'react-datepicker';


import { registerLocale } from "react-datepicker";
import ar from 'date-fns/locale/ar';
import { addYears, formatDate, getOptions,getIndex } from "../Helpers";
import RelationsSelect from "./RelationsSelect";
import { AddIconCircle, CloseIcon } from "./Icons";
registerLocale('ar', ar)


export default function AddStagePopupComponent(props) {
    const {
        authUserGlobal: [authUser],
        apiSuccessDataGlobal,
        generalDataGlobal,
        activeMatter,
        setActiveMatter
    } = useContext(GlobalState);

    const { editLitigation, setAddStagePopup } = props;

    const [excludeValues, setExcludeValues] = useState([]);

    const [generalDataApiOptions, setGeneralDataApiOptions] = useState({});
    const [generalData] = generalDataGlobal;


    const [, setApiSuccessData] = apiSuccessDataGlobal;

    const [activeSelect, setActiveSelect] = useState(null)

    const [ , setTitle] = useState('');



    const [selectedClient, setSelectedClient] = useState([]);

    const [appealDate, setAppealDate] = useState();
    const [appealNumber, setAppealNumber] = useState()
    // formdata states

    const [formData, setFormData] = useState({});

    const [ , setSelectedLitigationType] = useState();
    const [selectedLitigationStage, setSelectedLitigationStage] = useState();
    const [selectedStageCondition, setSelectedStageCondition] = useState();

    const [selectedCourtType, setSelectedCourtType] = useState();
    const [stageCourtName, setStageCourtName] = useState();
    const [stageCourtArea, setStageCourtArea] = useState();
    const [verdictDate, setVerdictDate] = useState();

    const [errorsReturned, setErrorsReturned] = useState({});

    //get individual realtions
    const individualRelations = () => {
        const relOptions = authUser?.individual_only?.map(
            singleRel => ({ type: singleRel.type, value: singleRel.slug + '-' + singleRel.id, label: singleRel?.title, slug: singleRel.slug, image: singleRel.image, client_type: singleRel.client_type }))
        return relOptions;
    }

    //get company relations
    const companyRelations = () => {
        const relOptions = authUser?.company_relations?.map(
            singleRel => ({ type: singleRel.type, value: singleRel.slug + '-' + singleRel.id, label: singleRel?.title, slug: singleRel.slug, image: singleRel.image, client_type: singleRel.client_type }))
        return relOptions;
    }

    //get ngro relations
    const ngoRelations = () => {
        const relOptions = authUser?.ngo_relations?.map(
            singleRel => ({ type: singleRel.type, value: singleRel.slug + '-' + singleRel.id, label: singleRel?.title, slug: singleRel.slug, image: singleRel.image, client_type: singleRel.client_type }))
        return relOptions;
    }

    const initClientOpponent = (keys) => {
        let emptyObject = {};
        keys?.map(key => (

            emptyObject[key] = [
                { client: '', description: '' }
            ]
        ))
        setFormData({ ...formData, ...emptyObject })
    }

    useEffect(() => {
        let keys = ['clients', 'opponents']
        initClientOpponent(keys)
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    //set states when current client changes
    useEffect(() => {
        if (editLitigation && editLitigation?.id) {
            setTitle(editLitigation?.title);
            setSelectedLitigationType(getOptions(generalData?.litigationTypes)[getIndex(editLitigation?.type_id, getOptions(generalData?.litigationTypes), 'value')])
            if (editLitigation?.client?.client_type_id === 1) {
                let text = 'client-' + editLitigation?.client?.id;

                setTimeout(() => {
                    setSelectedClient([...selectedClient, companyRelations()[getIndex(text, companyRelations(), 'value')]]);
                }, 300)
            }
            if (editLitigation?.client?.client_type_id === 2) {
                let text = 'client-' + editLitigation?.client?.id;
                setTimeout(() => {
                    setSelectedClient([...selectedClient, individualRelations()[getIndex(text, individualRelations(), 'value')]]);
                }, 300);
            }
            if (editLitigation?.client?.client_type_id === 3) {
                let text = 'client-' + editLitigation?.client?.id;
                setTimeout(() => {
                    setSelectedClient([...selectedClient, ngoRelations()[getIndex(text, ngoRelations(), 'value')]]);
                }, 300);
            }
            let emptyObject = [];
            emptyObject['clients'] = editLitigation?.clients?.length > 0
                ?
                editLitigation.clients.map((client, index) => (
                    {
                        id: client.client.id,
                        selectedClient:
                            client?.client?.client_type_id === 1 ?
                                companyRelations()[getIndex('client-' + client?.client?.id, companyRelations(), 'value')]
                                :
                                client?.client?.client_type_id === 2 ?
                                    individualRelations()[getIndex('client-' + client?.client?.id, individualRelations(), 'value')]
                                    :
                                    client?.client?.client_type_id === 3 ?
                                        ngoRelations()[getIndex('client-' + client?.client?.id, ngoRelations(), 'value')]
                                        :
                                        ''
                        ,
                        description: client.description
                    }
                ))
                :
                [{ client: '', description: '' }];

            emptyObject['opponents'] = editLitigation?.opponents?.length > 0
                ?
                editLitigation.opponents.map((opponent) => (
                    {
                        id: opponent?.client?.id,
                        selectedOpponent:
                            opponent?.client?.client_type_id === 1 ?
                                companyRelations()[getIndex('client-' + opponent?.client?.id, companyRelations(), 'value')]
                                :
                                opponent?.client?.client_type_id === 2 ?
                                    individualRelations()[getIndex('client-' + opponent?.client?.id, individualRelations(), 'value')]
                                    :
                                    opponent?.client?.client_type_id === 3 ?
                                        ngoRelations()[getIndex('client-' + opponent?.client?.id, ngoRelations(), 'value')]
                                        :
                                        ''
                        ,
                        description: opponent.description
                    }
                )) : [{ client: '', description: '' }];

            setFormData(emptyObject)
        }

    }, [editLitigation]);// eslint-disable-line react-hooks/exhaustive-deps


    // custom styles for the select
    const customStylesPopup = {
        placeholder: (defaultStyles) => {
            return {
                ...defaultStyles,
                display: "flex",
                fontFamily: 'Poppins',
                fontWeight: '400',
                textTransform: "capitalize",
                fontSize: '14px',
                color: '#07407C',
            }
        },
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                color: '#07407C',
                fontFamily: 'Poppins',
                fontWeight: '400',
                backgroundColor: 'white',
                textTransform: "capitalize",
                margin: "auto",
                paddingRight: "10px",
                maxWidth: "100%",
                paddingLeft: "10px",
                paddingBottom: "0",
                paddingTop: "0",
            };
        },
        singleValue: base => ({
            ...base,
            color: "#00366A",
            display: "flex",
            fontSize: "14px",
            fontFamily: "Poppins",
            fontWeight: "400",
            textTransform: "capitalize",
        }),
        control: provided => ({
            ...provided,
            height: "40px",
            border: "none",
            backgroundColor: 'white',
            outline: "none",
            '&:hover': { borderColor: 'transparent' },
            textTransform: 'uppercase',
            width: "100%",
            borderRadius: "7px",
            fontFamily: 'Poppins',
            fontWeight: '400',
            boxShadow: "0px 3px 6px rgb(0 0 0 / 16%)",
        }),
        menu: (provided, state) => ({
            ...provided,
            borderRadius: "7px",
            textTransform: 'uppercase',
            border: "1px solid transparent",
        }),
        dropdownIndicator: base => ({
            ...base,
            color: "#00366A !important",
            // marginTop: "-18px !important"
        }),
        input: (base, state) => ({
            ...base,
            color: "#393939",
            fontSize: "16px",
            height: "100%",
            fontFamily: 'Poppins',
            textTransform: 'uppercase',
            fontWeight: '400',
            marginTop: "14px",
            '& input': {
                font: 'inherit',
            },
        })
    };

    //close add client and reset states
    const resetForm = (e) => {

    }


    //submit client to backend
    const submitLitigationStage = (e) => {
        e.preventDefault();

        setGeneralDataApiOptions({
            url: 'add-edit-litigation_stage',
            method: 'post',
            data: {
                litigation_id: activeMatter?.id,
                stage_id: selectedLitigationStage?.value || '',
                condition_id: selectedStageCondition?.value || '',
                verdict_date: verdictDate ? formatDate(verdictDate) : '',
                appeal_date: appealDate ? formatDate(appealDate) : '',
                appeal_number: appealNumber || '',
                court_type_id: selectedCourtType?.value || '',
                court_name: stageCourtName,
                court_area: stageCourtArea,
                clients: formData?.clients.length > 0 && formData.clients[0].client !== '' ? JSON.stringify(formData?.clients) : '',
                opponents: formData?.opponents.length > 0 && formData.opponents[0].client !== '' ? JSON.stringify(formData?.opponents) : '',
            },
            callback: res => {
                resetForm();
                setActiveMatter(res.data);
                setAddStagePopup(false);
                setApiSuccessData(res.data);
            },
            catch: err => {
                if (err) {
                    setErrorsReturned(err);
                }
            }
        });
    }

    //add new empty field to formdata by key
    const addToFormData = (key) => {
        let emptyObject = {};
        emptyObject = { client: '', description: '' }
        let newFormData = { ...formData };
        newFormData[key].push(emptyObject);
        setFormData(newFormData);
    }

    const removeFromFormData = (dataField, index, id = null) => {
        let newFd = { ...formData };
        newFd[dataField].splice(index, 1);
        setFormData(newFd);
    }

    const handleSelectClientChange = (e, index) => {
        const newFormData = { ...formData }
        newFormData.clients[index] =
        {

            selectedClient:
                e?.client_type === 1 ?
                    companyRelations()[getIndex(e?.value, companyRelations(), 'value')]
                    :
                    e?.client_type === 2 ?
                        individualRelations()[getIndex(e?.value, individualRelations(), 'value')]
                        :
                        e?.client_type === 3 ?
                            ngoRelations()[getIndex(e?.value, ngoRelations(), 'value')]
                            :
                            ''
            ,
        }
        setFormData(newFormData)
    }
    const handleSelectedOpponentChange = (e, index) => {
        const newFormData = { ...formData }
        newFormData.opponents[index] =
        {

            selectedOpponent:
                e?.client_type === 1 ?
                    companyRelations()[getIndex(e?.value, companyRelations(), 'value')]
                    :
                    e?.client_type === 2 ?
                        individualRelations()[getIndex(e?.value, individualRelations(), 'value')]
                        :
                        e?.client_type === 3 ?
                            ngoRelations()[getIndex(e?.value, ngoRelations(), 'value')]
                            :
                            ''
            ,
        }
        setFormData(newFormData)
    }

    const handleClientDescriptionChange = ({ target }, index) => {
        let newFormData = { ...formData }
        newFormData['clients'][index].description = target.value;
        setFormData(newFormData);
    }

    const handleOpponentDescriptionChange = ({ target }, index) => {
        let newFormData = { ...formData };
        newFormData['opponents'][index].description = target?.value;
        setFormData(newFormData);
    }


    useEffect(() => {
        setExcludeValues([]);
    }, [formData])

    useEffect(() => {
        if (excludeValues?.length === 0) {
            if (formData?.clients) {
                formData?.clients?.map((client) => {
                    if (!excludeValues.includes(client?.selectedClient)) {
                        if (client?.id !== 0) {
                            setExcludeValues(prev => ([...prev, client?.selectedClient?.label]))
                        }
                    }
                    return null; // Return a value for each iteration
                })

            }
            if (formData?.opponents) {
                formData?.opponents?.map((opponent) => {
                    if (!excludeValues.includes(opponent?.selectedOpponent)) {
                        if (opponent?.id !== 0) {
                            setExcludeValues(prev => ([...prev, opponent?.selectedOpponent?.label]))
                        }
                    }
                    return null; // Return a value for each iteration
                })
            }
        }
    }, [excludeValues]);// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <div id="errors-popup" className=" error-popup-api d-flex align-items-center justify-content-center px-3 fade-in" >
                <div className="pop-up-api-box" style={{ maxHeight: 540, overflow: 'auto' }}>
                    <div className="d-flex">
                        <CloseIcon className="cursor-pointer" onClick={(e) => { setAddStagePopup({ open: false }); resetForm() }} />
                    </div>
                    <div className="text-center ">
                        <div className="containter-fluid">
                            <div className="row">
                                <div className="col-12">
                                    {

                                        <h1 className="pop-up-title m-0 py-2 color-dark-blue">{generalData?.stagesFixedTitles['change-litigation-stage']}</h1>
                                    }
                                </div>
                                <form onSubmit={submitLitigationStage}>
                                    <div className="col-12 pt-2 pb-4">

                                        <>
                                            <div className="row">
                                                <>
                                                    <div className="col-12 pt-2">
                                                        <div className="postion-relative text-start justify-content-start w-100">
                                                            <Select
                                                                classNamePrefix="popup-select"
                                                                className="popup-select w-100"
                                                                styles={customStylesPopup}
                                                                options={getOptions(generalData?.litigationStages)}
                                                                components={{
                                                                    IndicatorSeparator: () => null
                                                                }}
                                                                placeholder={generalData?.stagesFixedTitles['stage']}
                                                                isSearchable={false}
                                                                value={selectedLitigationStage}
                                                                onClick={() => { setActiveSelect(null) }}

                                                                onChange={setSelectedLitigationStage} />
                                                        </div>
                                                        {
                                                            errorsReturned?.stage_id ?
                                                                <h5 className="pt-2 m-0 d-flex flex-start  text-start pop-errors-text">{errorsReturned?.stage_id}</h5>
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                </>
                                                <>
                                                    <div className="col-12 col-md-6 pt-2">
                                                        <div className="postion-relative text-start justify-content-start w-100">
                                                            <Select
                                                                classNamePrefix="popup-select"
                                                                className="popup-select w-100"
                                                                styles={customStylesPopup}
                                                                options={getOptions(generalData?.stageConditions)}
                                                                components={{
                                                                    IndicatorSeparator: () => null
                                                                }}
                                                                placeholder={generalData?.stagesFixedTitles['stage-condition']}
                                                                isSearchable={false}
                                                                value={selectedStageCondition}
                                                                onClick={() => { setActiveSelect(null) }}
                                                                onChange={setSelectedStageCondition} />

                                                        </div>
                                                        {
                                                            errorsReturned?.condition_id ?
                                                                <h5 className="pt-2 m-0 d-flex flex-start  text-start pop-errors-text">{errorsReturned?.condition_id}</h5>
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                </>
                                                <>
                                                    <div className="col-12 col-md-6 pt-2">
                                                        <div className="postion-relative text-start justify-content-start w-100">
                                                            <DatePicker
                                                                className="popup-date add-user-inputs"
                                                                placeholderText={generalData?.stagesFixedTitles['verdict-date']}
                                                                showMonthDropdown
                                                                showYearDropdown
                                                                dropdownMode="select"
                                                                dateFormat="dd/MM/yyyy"
                                                                minDate={addYears(new Date(), -21)}
                                                                maxDate={addYears(new Date(), 2)}
                                                                isClearable={true}
                                                                selected={verdictDate}
                                                                value={verdictDate}
                                                                onChange={setVerdictDate}
                                                                locale={localStorage.getItem("BlpLanguage")}
                                                            />
                                                        </div>
                                                        {
                                                            errorsReturned?.verdict_date ?
                                                                <h5 className="pt-2 m-0 d-flex flex-start  text-start pop-errors-text">{errorsReturned?.verdict_date}</h5>
                                                                :
                                                                null
                                                        }
                                                    </div>

                                                    <div className="col-12 col-md-6 pt-2 position-relative" >

                                                    </div>
                                                </>
                                                <div className="col-12 text-start mt-3">
                                                    <h6 className="m-0">{generalData?.litigationFixedTitles['clients-names']}</h6>
                                                </div>
                                                {
                                                    formData?.clients?.length > 0 &&
                                                    formData?.clients?.map((client, index) => {
                                                        return (

                                                            <>
                                                                <div className="col-12 col-md-6 mt-2 position-relative" style={{ zIndex: 100 - index }}>
                                                                    <div className='position-absolute top-0 start-n1 h-100 d-flex align-items-center'>
                                                                        {
                                                                            formData.clients?.length > 1 && index !== formData.clients?.length - 1 ?
                                                                                <button className="btn border-circle-sm border-circle p-0" type='button' onClick={() => removeFromFormData('clients', index, client?.id)}>-</button>
                                                                                :
                                                                                <button className="btn border-circle-sm border-circle p-0" type='button' onClick={() => addToFormData('clients')}><AddIconCircle className="start-0 nostyling" /></button>
                                                                        }
                                                                    </div>
                                                                    <div className="postion-relative text-start justify-content-start w-100" >
                                                                        <RelationsSelect
                                                                            selectedRelation={client?.selectedClient ? client?.selectedClient : ''}
                                                                            setSelectedRelation={(e) => handleSelectClientChange(e, index)}
                                                                            profile={authUser}
                                                                            defaultSelected={'individual'}
                                                                            key={index}
                                                                            excludeMultipleValues={excludeValues}
                                                                            excludeMatters
                                                                            // onChange={(e) => setTitle(e.target.value)}
                                                                            className="add-user-inputs"
                                                                            placeholder={generalData?.litigationsFixedTitles['client-name']}
                                                                            activeSelect={activeSelect}
                                                                            setActiveSelect={setActiveSelect}
                                                                            count={index}
                                                                        />
                                                                    </div>
                                                                    {
                                                                        errorsReturned?.legal_type_id ?
                                                                            <h5 className="pt-2 m-0 d-flex flex-start  text-start pop-errors-text">{errorsReturned?.legal_type_id}</h5>
                                                                            :
                                                                            null
                                                                    }
                                                                </div>
                                                                <div className="col-12 col-md-6 pt-2">
                                                                    <div className="postion-relative text-start justify-content-start w-100">
                                                                        <input
                                                                            name="title"
                                                                            value={client?.description || ''}
                                                                            onChange={(e) => handleClientDescriptionChange(e, index)}
                                                                            className="add-user-inputs"
                                                                            placeholder={generalData?.litigationsFixedTitles['client-description']}
                                                                        />
                                                                    </div>
                                                                    {
                                                                        errorsReturned?.legal_type_id ?
                                                                            <h5 className="pt-2 m-0 d-flex flex-start  text-start pop-errors-text">{errorsReturned?.legal_type_id}</h5>
                                                                            :
                                                                            null
                                                                    }
                                                                </div>
                                                            </>
                                                        )
                                                    }
                                                    )

                                                }
                                                <div className="col-12 text-start mt-3">
                                                    <h6 className="m-0">{generalData?.litigationFixedTitles['opponents-names']}</h6>
                                                </div>
                                                {
                                                    formData?.opponents?.length > 0 &&
                                                    formData?.opponents?.map((opponent, index) => (
                                                        <>

                                                            <div className="col-12 col-md-6 mt-2 position-relative" style={{ zIndex: 99 - formData?.clients?.length - index }}>
                                                                <div className='position-absolute top-0 start-n1 h-100 d-flex align-items-center'>
                                                                    {
                                                                        formData.opponents.length > 1 && index !== formData.opponents.length - 1 ?
                                                                            <button className="btn border-circle-sm border-circle p-0" type='button' onClick={() => removeFromFormData('opponents', index, opponent?.id)}>-</button>
                                                                            :
                                                                            <button className="btn border-circle-sm border-circle p-0" type='button' onClick={() => addToFormData('opponents')}><AddIconCircle className="start-0 nostyling" /></button>
                                                                    }
                                                                </div>
                                                                <div className="postion-relative text-start justify-content-start w-100">
                                                                    <RelationsSelect
                                                                        selectedRelation={opponent?.selectedOpponent ? opponent?.selectedOpponent : ''}
                                                                        setSelectedRelation={(e) => handleSelectedOpponentChange(e, index)}
                                                                        profile={authUser}
                                                                        defaultSelected={'individual'}
                                                                        excludeMultipleValues={excludeValues}
                                                                        excludeMatters
                                                                        className="add-user-inputs"
                                                                        placeholder={generalData?.litigationsFixedTitles['opponent-name']}
                                                                        key={index}
                                                                        activeSelect={activeSelect}
                                                                        setActiveSelect={setActiveSelect}
                                                                        count={formData.clients + index}

                                                                    />
                                                                </div>
                                                                {
                                                                    errorsReturned?.legal_type_id ?
                                                                        <h5 className="pt-2 m-0 d-flex flex-start  text-start pop-errors-text">{errorsReturned?.legal_type_id}</h5>
                                                                        :
                                                                        null
                                                                }
                                                            </div>
                                                            <div className="col-12 col-md-6 pt-2">
                                                                <div className="postion-relative text-start justify-content-start w-100">
                                                                    <input
                                                                        name="title"
                                                                        value={opponent?.description}
                                                                        onChange={(e) => handleOpponentDescriptionChange(e, index)}
                                                                        className="add-user-inputs"
                                                                        placeholder={generalData?.litigationsFixedTitles['opponent-description']}
                                                                    />
                                                                </div>
                                                                {
                                                                    errorsReturned?.legal_type_id ?
                                                                        <h5 className="pt-2 m-0 d-flex flex-start  text-start pop-errors-text">{errorsReturned?.legal_type_id}</h5>
                                                                        :
                                                                        null
                                                                }
                                                            </div>
                                                        </>
                                                    ))

                                                }
                                                {
                                                    selectedLitigationStage && selectedLitigationStage?.value === 2 &&
                                                    <>
                                                        <div className="col-12 col-md-6 pt-2">
                                                            <div className="postion-relative text-start justify-content-start w-100">
                                                                <DatePicker
                                                                    className="popup-date add-user-inputs"
                                                                    placeholderText={generalData?.litigationsFixedTitles['appeal-date']}
                                                                    showMonthDropdown
                                                                    showYearDropdown
                                                                    dropdownMode="select"
                                                                    dateFormat="dd/MM/yyyy"
                                                                    minDate={addYears(new Date(), -21)}
                                                                    maxDate={addYears(new Date(), 1)}
                                                                    isClearable={true}
                                                                    value={appealDate}
                                                                    selected={appealDate}
                                                                    onChange={setAppealDate}
                                                                    locale={localStorage.getItem("BlpLanguage")}
                                                                />
                                                            </div>
                                                            {
                                                                errorsReturned?.legal_type_id ?
                                                                    <h5 className="pt-2 m-0 d-flex flex-start  text-start pop-errors-text">{errorsReturned?.legal_type_id}</h5>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                        <div className="col-12 col-md-6 pt-2">
                                                            <div className="postion-relative text-start justify-content-start w-100">
                                                                <input
                                                                    name="title"
                                                                    value={appealNumber}
                                                                    onChange={(e) => setAppealNumber(e.target.value)}
                                                                    className="add-user-inputs"
                                                                    placeholder={generalData?.litigationsFixedTitles['appeal-number']}
                                                                />
                                                            </div>
                                                            {
                                                                errorsReturned?.legal_type_id ?
                                                                    <h5 className="pt-2 m-0 d-flex flex-start  text-start pop-errors-text">{errorsReturned?.legal_type_id}</h5>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                    </>
                                                }
                                                {
                                                    selectedLitigationStage && selectedLitigationStage.value !== 1 &&
                                                    <>
                                                        <div className="col-12 pt-2">
                                                            <div className="postion-relative text-start justify-content-start w-100">
                                                                <Select
                                                                    classNamePrefix="popup-select"
                                                                    className="popup-select w-100"
                                                                    styles={customStylesPopup}
                                                                    options={getOptions(generalData?.courtTypes)}
                                                                    components={{
                                                                        IndicatorSeparator: () => null
                                                                    }}
                                                                    placeholder={generalData?.stagesFixedTitles['court-type']}
                                                                    isSearchable={false}
                                                                    value={selectedCourtType}
                                                                    onChange={setSelectedCourtType} />
                                                            </div>
                                                            {
                                                                errorsReturned?.court_type_id ?
                                                                    <h5 className="pt-2 m-0 d-flex flex-start  text-start pop-errors-text">{errorsReturned?.court_type_id}</h5>
                                                                    :
                                                                    null
                                                            }
                                                        </div>

                                                        <div className="col-12 col-md-6 pt-2">
                                                            <div className="postion-relative text-start justify-content-start w-100">
                                                                <input
                                                                    name="subject"
                                                                    value={stageCourtName}
                                                                    onChange={(e) => setStageCourtName(e.target.value)}
                                                                    className="add-user-inputs"
                                                                    placeholder={generalData?.stagesFixedTitles['court-name']}
                                                                />
                                                            </div>
                                                            {
                                                                errorsReturned?.court_name ?
                                                                    <h5 className="pt-2 m-0 d-flex flex-start  text-start pop-errors-text">{errorsReturned?.court_name}</h5>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                        <div className="col-12 col-md-6 pt-2">
                                                            <div className="postion-relative text-start justify-content-start w-100">
                                                                <input
                                                                    name="subject"
                                                                    value={stageCourtArea}
                                                                    onChange={(e) => setStageCourtArea(e.target.value)}
                                                                    className="add-user-inputs"
                                                                    placeholder={generalData?.stagesFixedTitles['court-area']}
                                                                />
                                                            </div>
                                                            {
                                                                errorsReturned?.court_area ?
                                                                    <h5 className="pt-2 m-0 d-flex flex-start  text-start pop-errors-text">{errorsReturned?.court_area}</h5>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                    </>

                                                }


                                                <div className="col-12 col-md-6 pt-2">
                                                </div>
                                            </div>
                                        </>

                                    </div>
                                    <div className="col-12 d-flex justify-content-center">
                                        <button type="submit" className="popup-button my-auto">{generalData?.website_titles?.save}</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Api options={generalDataApiOptions} />
        </>
    )
}