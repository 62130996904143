import 'react-calendar/dist/Calendar.css';
import React, { useContext, useEffect, useState } from 'react';
import Select from 'react-select';
import GlobalState from '../contexts/GlobalState';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Api from './Api';
import { addYears, getIndex, formatDate } from '../Helpers';
import { registerLocale } from "react-datepicker";
import ar from 'date-fns/locale/ar';
import RelationsSelect from "./RelationsSelect";
import { CloseIcon } from './Icons';

registerLocale('ar', ar);

export default function AdministrativeBody(props) {

    const { profileClientGlobal, generalDataGlobal, entityContactPopupGlobal } = useContext(GlobalState);
    const [generalDataApiOptions, setGeneralDataApiOptions] = useState({});
    const [profileClient, setProfileClient] = profileClientGlobal;
    const [generalData,] = generalDataGlobal;
    const [errorsReturned, setErrorsReturned] = useState({});
    const [comment, setComment] = useState('');
    const [entityContactPopup, setEntityContactPopup] = entityContactPopupGlobal;

    const [designationDate, setDesignationDate] = useState('');
    const [entityContactStatus, setEntityContactStatus] = useState('');


    //get all client statuses from backend
    const clientStatuses = () => {
        const clientStat = generalData?.client_statuses?.map(
            stat => ({ slug: stat?.slug, value: stat.id, label: stat.title }))
        return clientStat;
    }

    // custom styles for the select
    const customStylesPopup = {
        placeholder: (defaultStyles) => {
            return {
                ...defaultStyles,
                display: "flex",
                fontFamily: 'Poppins',
                fontWeight: '400',
                textTransform: "capitalize",
                fontSize: '14px',
                color: '#07407C',
            }
        },
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                color: '#07407C',
                fontFamily: 'Poppins',
                fontWeight: '400',
                backgroundColor: 'white',
                textTransform: "capitalize",
                margin: "auto",
                paddingRight: "10px",
                maxWidth: "100%",
                paddingLeft: "10px",
                paddingBottom: "0",
                paddingTop: "0",
            };
        },
        singleValue: base => ({
            ...base,
            color: "#00366A",
            display: "flex",
            fontSize: "14px",
            fontFamily: "Poppins",
            fontWeight: "400",
            textTransform: "capitalize",
        }),
        control: provided => ({
            ...provided,
            height: "40px",
            border: "none",
            backgroundColor: 'white',
            outline: "none",
            '&:hover': { borderColor: 'transparent' },
            textTransform: 'uppercase',
            width: "100%",
            borderRadius: "7px",
            fontFamily: 'Poppins',
            fontWeight: '400',
            boxShadow: "0px 3px 6px rgb(0 0 0 / 16%)",
        }),
        menu: (provided, state) => ({
            ...provided,
            borderRadius: "7px",
            textTransform: 'uppercase',
            border: "1px solid transparent",
        }),
        dropdownIndicator: base => ({
            ...base,
            color: "#00366A !important",
        }),
        input: (base, state) => ({
            ...base,
            color: "#00366A",
            fontSize: "16px",
            height: "100%",
            fontFamily: 'Poppins',
            textTransform: 'uppercase',
            fontWeight: '400',
            minWidth: '40px !important'
        })
    };

    // reset states when add entity is closed
    const closeAddEntityContact = (e) => {
        setEntityContactPopup({});
        setErrorsReturned({});
        setEntityContactStatus('');
        setComment('');
        setSelectedRelation('');
        setDesignationDate('');
    }

    //set entity states values once editing entity
    useEffect(() => {
        if (entityContactPopup?.comment) {
            setComment(entityContactPopup?.comment ? entityContactPopup?.comment : '');
        }
        if (entityContactPopup?.contact_user) {
            let text = 'user-' + entityContactPopup?.contact_user_id;
            setTimeout(() => {
                setSelectedRelation(allIndividuals()[getIndex(text, allIndividuals(), 'value')]);
            }, 100);
        }

        if (entityContactPopup?.contact?.client_type_id === 1) {
            let text = 'client-' + entityContactPopup?.contact_id;
            setTimeout(() => {
                setSelectedRelation(companyRelations()[getIndex(text, companyRelations(), 'value')]);
            }, 100);
        }
        if (entityContactPopup?.contact?.client_type_id === 2) {
            let text = 'client-' + entityContactPopup?.contact_id;
            setTimeout(() => {
                setSelectedRelation(individualRelations()[getIndex(text, individualRelations(), 'value')]);
            }, 100);
        }
        if (entityContactPopup?.contact?.client_type_id === 3) {
            let text = 'client-' + entityContactPopup?.contact_id;
            setTimeout(() => {
                setSelectedRelation(ngoRelations()[getIndex(text, ngoRelations(), 'value')]);
            }, 100);
        }
        if (entityContactPopup?.designation_date) {
            setDesignationDate(entityContactPopup?.designation_date ? new Date(entityContactPopup?.designation_date) : '');
        }
        if (entityContactPopup?.client_stat_id) {
            setEntityContactStatus(clientStatuses()[getIndex(entityContactPopup?.client_stat_id, clientStatuses(), 'value')]);
        }
    }, [entityContactPopup]);// eslint-disable-line react-hooks/exhaustive-deps

    //submit entity
    const submitEntityContact = (e) => {
        e.preventDefault();
        e.stopPropagation();
        let fd = new FormData();

        fd.append('contact_id', selectedRelation ? selectedRelation?.value : '');
        fd.append('user_id', entityContactPopup?.client_id);
        fd.append('client_id', entityContactPopup?.client_id);
        fd.append('comment', comment ? comment : '');
        fd.append('designation_date', designationDate ? formatDate(designationDate) : '');
        fd.append('client_stat_id', entityContactStatus ? entityContactStatus?.value : '');
        fd.append('entity_contacts_types_id', entityContactPopup?.entity_contacts_types_id);

        if (entityContactPopup && entityContactPopup?.id > 0) {
            fd.append('entity_contact_id', entityContactPopup?.id);
        }

        setGeneralDataApiOptions({
            url: 'add-edit-entity-contact',
            method: 'post',
            data: fd,
            callback: res => {
                closeAddEntityContact(e);
                setProfileClient(res.data);
            },
            catch: err => {
                setErrorsReturned(err);
            }
        });
    }

    //get individual realtions
    const individualRelations = () => {
        const relOptions = profileClient?.individual_only?.map(
            singleRel => ({ type: singleRel.type, value: singleRel.slug + '-' + singleRel.id, label: singleRel?.title, slug: singleRel.slug, image: singleRel.image, client_type: singleRel.client_type }))
        return relOptions;
    }


    //get all individuals with users
    const allIndividuals = () => {
        const relOptions = profileClient?.individual_relations?.map(
            singleRel => ({ type: singleRel.type, value: singleRel.slug + '-' + singleRel.id, label: singleRel?.title, slug: singleRel.slug, image: singleRel.image, client_type: singleRel.client_type }))
        return relOptions;
    }

    //get company relations
    const companyRelations = () => {
        const relOptions = profileClient?.company_relations?.map(
            singleRel => ({ type: singleRel.type, value: singleRel.slug + '-' + singleRel.id, label: singleRel?.title, slug: singleRel.slug, image: singleRel.image, client_type: singleRel.client_type }))
        return relOptions;
    }

    //get ngro relations
    const ngoRelations = () => {
        const relOptions = profileClient?.ngo_relations?.map(
            singleRel => ({ type: singleRel.type, value: singleRel.slug + '-' + singleRel.id, label: singleRel?.title, slug: singleRel.slug, image: singleRel.image, client_type: singleRel.client_type }))
        return relOptions;
    }

    /* close custom dropdown on click outside*/
    const [selectedRelation, setSelectedRelation] = useState('');

    return (
        <>
            <div id="errors-popup" className=" error-popup-api d-flex align-items-center justify-content-center px-3 fade-in">
                <div className="pop-up-api-box">
                    <CloseIcon className="cursor-pointer" onClick={(e) => closeAddEntityContact(e)} />
                    <div className="text-center ">
                        <div className="containter-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <h1 className="pop-up-title m-0 py-2 color-dark-blue">{entityContactPopup?.id > 0 ? generalData?.website_titles['edit'] + ' ' + entityContactPopup?.entity_contacts_types?.title : generalData?.website_titles['add'] + ' ' + entityContactPopup?.entity_contacts_types?.title}</h1>
                                </div>
                                <form onSubmit={submitEntityContact}>
                                    <div className="col-12 pt-2 pb-4">
                                        <div className="row">
                                      
                                            <div className="col-12 col-md-6 pt-2">
                                                <RelationsSelect
                                                    selectedRelation={selectedRelation}
                                                    setSelectedRelation={setSelectedRelation}
                                                    profile={profileClient}
                                                    defaultSelected={'individual'}
                                                    excludeMatters
                                                    excludeTasks
                                                    withUsers={1}
                                                    // onChange={(e) => setTitle(e.target.value)}
                                                    className="relations-select"
                                                    placeholder={generalData?.website_titles['name']}
                                                />
                                                <input
                                                    type="text"
                                                    className='position-x-center'
                                                    style={{
                                                        opacity: 0,
                                                        width: 0,
                                                        height: 0,
                                                    }}
                                                    value={selectedRelation?.label || ''}
                                                    required
                                                />

                                                {
                                                    errorsReturned?.contact_id ?
                                                        <h5 className="pt-2 m-0 d-flex flex-start text-start pop-errors-text">{errorsReturned?.contact_id}</h5>
                                                        :
                                                        null
                                                }
                                            </div>
                                            <div className="col-12 col-md-6 pt-2">
                                                <div className="postion-relative text-start justify-content-start w-100">
                                                    <DatePicker
                                                        className="popup-date add-user-inputs"
                                                        placeholderText={generalData?.website_titles['designation-date']}
                                                        value={designationDate ? designationDate : ''}
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        dropdownMode="select"
                                                        selected={designationDate}
                                                        dateFormat="d MMMM yyyy"
                                                        required
                                                        onChange={(date) => setDesignationDate(date)}
                                                        minDate={addYears(new Date(), -40)}
                                                        maxDate={addYears(new Date(), +40)}
                                                        locale={localStorage.getItem("BlpLanguage")}
                                                    />
                                                </div>
                                                {
                                                    errorsReturned?.designation_date ?
                                                        <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text">{errorsReturned?.designation_date}</h5>
                                                        :
                                                        null
                                                }
                                            </div>
                                            <div className="col-12 col-md-6 pt-2">
                                                <div className="position-relative text-start justify-content-start w-100">
                                                    <Select
                                                        classNamePrefix="popup-select"
                                                        className="popup-select w-100"
                                                        styles={customStylesPopup}
                                                        options={clientStatuses()}
                                                        components={{
                                                            IndicatorSeparator: () => null
                                                        }}
                                                        placeholder={generalData?.website_titles['status']}
                                                        isSearchable={false}
                                                        value={entityContactStatus ? entityContactStatus : null}
                                                        onChange={setEntityContactStatus}
                                                    />
                                                    <input
                                                        type="text"
                                                        className='position-x-center'
                                                        style={{
                                                            opacity: 0,
                                                            width: 0,
                                                            height: 0,
                                                        }}
                                                        value={entityContactStatus || ''}
                                                        required
                                                    />

                                                </div>
                                                {
                                                    errorsReturned?.client_stat_id ?
                                                        <h5 className="pt-2 m-0 d-flex flex-start text-start pop-errors-text">{errorsReturned?.client_stat_id}</h5>
                                                        :
                                                        null
                                                }
                                            </div>
                                            <div className="col-12 pt-2">
                                                <textarea type="text-area" name="description" className="add-user-inputs" placeholder={generalData?.website_titles['comment']} value={comment} onChange={(e) => setComment(e.target.value)}></textarea>
                                                {
                                                    errorsReturned?.comment ?
                                                        <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text">{errorsReturned?.comment}</h5>
                                                        :
                                                        null
                                                }
                                                {
                                                    errorsReturned?.board_exists ?
                                                        <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text">{errorsReturned?.board_exists}</h5>
                                                        :
                                                        null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 d-flex justify-content-center">
                                        <button type="submit" className="popup-button my-auto">{entityContactPopup?.id > 0 ? generalData?.website_titles['edit'] : generalData?.website_titles['add']}</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Api options={generalDataApiOptions} />
        </>
    )
}