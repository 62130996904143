import { useContext, useState, useEffect } from "react";
import GlobalState from '../contexts/GlobalState';
import { useNavigate, useParams } from "react-router";
import React, { useCallback } from 'react';
import Api from "../components/Api";
import EditInput from "../components/EditInput";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
import 'swiper/css';
import getCroppedImg from '../components/CropImage';
import Cropper from 'react-easy-crop';
import DocumentsSmallTab from "./DocumentsSmallTab";
import ClientComments from "./ClientComments";
import { getIndex, formatDate } from "../Helpers";

import { registerLocale } from "react-datepicker";
import ar from 'date-fns/locale/ar';
import LogsComponent from "./LogsComponent";
import { AddIconCircle, AddIconLt, AttachIcon, CloseIcon, DocumentPencil, EditPencil, RemoveFd, SmallPencil, ThreeDotsMenu, TrueSign, XSign } from "./Icons";
registerLocale('ar', ar)

export default function IndividualGeneralInfo(props) {

    const {
        generalDataGlobal,
        changeInputGlobal,
        currentInputGlobal,
        currentInputValueGlobal,
        profileClientGlobal,
        returnedFiles,
        setReturnedFiles,
    } = useContext(GlobalState);
    const [generalDataApiOptions, setGeneralDataApiOptions] = useState({});
    let { slug, user_id } = useParams();
    const [generalData] = generalDataGlobal;
    const [usersApiOptions,] = useState({});
    const [profileClient, setProfileClient] = profileClientGlobal;
    const [currentInput, setCurrentInput] = currentInputGlobal;
    const [currentInputValue, setCurrentInputValue] = currentInputValueGlobal;
    const history = useNavigate();
    const [errorsReturned, setErrorsReturned] = useState({});
    const [profilePicture, setProfilePicture] = useState();
    const [changeInput, setChangeInput] = changeInputGlobal;
    const [uploadImagePopup, setUploadImagePopup] = useState(false);
    const [formData, setFormData] = useState({});
    const [birthDate, setBirthDate] = useState('');
    const [documentDate, setDocumentDate] = useState('');
    const [jobTitle, setJobTitle] = useState();
    const [userNationality, setUserNationality] = useState();
    const [userGender, setUserGender] = useState();
    const [deletePopup, setDeletePopup] = useState(null);
    const [tempPicture, setTempPicture] = useState('');

    const [tempIdDocumentName, setTempIdDocumentName] = useState('');
    const [, setTempIdDocument] = useState('');
    const [croppedFinalImage, setCroppedFinalImage] = useState(null);
    const [changeIdDocument, setChangeIdDocument] = useState(false);
    const [idDocument, setIdDocument] = useState('');



    /*proxies*/
    const [addProxy, setAddProxy] = useState(false);
    const [poa, setPoa] = useState('');
    const [publicNotary, setPublicNotary] = useState('');
    const [registrationNumber, setRegistrationNubmer] = useState('');
    const [proxyDate, setProxyDate] = useState('');
    const [proxyDocument, setProxyDocument] = useState('');
    const [proxyDocumentLink, setProxyDocumentLink] = useState();
    const [openEditMenu, setOpenEditMenu] = useState(null);
    const [deleteProxy, setDeleteProxy] = useState(false);
    /*proxies*/


    //reset proxy states on cancel or close
    const closeProxyPopup = (e) => {
        e.preventDefault();
        setProxyDate('');
        setProxyDocument('');
        setProxyDocumentLink('');
        setPoa('');
        setPublicNotary('');
        setRegistrationNubmer('');
        setAddProxy(false);
        setOpenEditMenu(null);
        setDeletePopup(false);
        setDeleteProxy(null);
        setErrorsReturned({});
    }

    // set states values from current edit proxy
    const editClick = (e, data) => {
        setProxyDate(data?.date ? new Date(data?.date) : '');
        setProxyDocument('');
        setProxyDocumentLink(data?.file ? data?.full_path_file : '');
        setPoa(data?.poa ? data?.poa : '');
        setPublicNotary(data?.public_notary ? data?.public_notary : '');
        setRegistrationNubmer(data?.registration_number ? data?.registration_number : '');
        setAddProxy(true);
    }


    //delete proxy menu clicked
    const deleteProxyProcess = (e, data) => {
        e.preventDefault();
        e.stopPropagation();
        submitDeleteProxyProcess(e, data);
    }

    //submit proxy delete request to backend
    const submitDeleteProxyProcess = (e, data) => {
        e.preventDefault();
        e.stopPropagation();
        let fd = new FormData();

        if (openEditMenu && openEditMenu?.id) {
            fd.append('proxy_id', openEditMenu?.id);
        }
        fd.append('client_id', user_id);
        fd.append('user_id', user_id);

        setGeneralDataApiOptions({
            url: 'delete-proxy',
            method: 'post',
            data: fd,
            callback: res => {
                setErrorsReturned({});
                setFormData(res?.data);
                setProfileClient(res.data);
                closeProxyPopup(e);
                setErrorsReturned({});
            },
            catch: err => {
                if (err) {
                    setErrorsReturned(err);
                }
            }
        })
    }


    //submit add/edit proxy request to backend
    const submitProxy = (e) => {
        e.preventDefault();
        e.stopPropagation();
        let fd = new FormData();

        if (proxyDocument) {
            fd.append('file', proxyDocument[0]);
        }

        if (openEditMenu && openEditMenu?.id) {
            fd.append('proxy_id', openEditMenu?.id);
        }

        fd.append('poa', poa ? poa : '');
        fd.append('client_id', user_id);
        fd.append('user_id', user_id);
        fd.append('public_notary', publicNotary ? publicNotary : '');
        fd.append('registration_number', registrationNumber ? registrationNumber : '');
        fd.append('date', proxyDate ? formatDate(proxyDate) : '');
        fd.append('force', 1);
        fd.append('file_id', openEditMenu?.uploaded_files_id ? openEditMenu?.uploaded_files_id : '');

        fd.append('parent_folder_id', '');
        fd.append('files_type_id', 5);
        fd.append('main_directory', 2);
        fd.append('file_name', poa ? poa : '');
        fd.append('user_id', profileClient?.id);
        fd.append('folder_name', 'Proxies');

        setGeneralDataApiOptions({
            url: 'add-proxy',
            method: 'post',
            data: fd,
            callback: res => {
                setErrorsReturned({});
                setFormData(res?.data);
                setProfileClient(res.data);
                closeProxyPopup(e);
                setErrorsReturned({});
            },
            catch: err => {
                if (err) {
                    setErrorsReturned(err);
                }
            }
        })
    };


    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [rotation,] = useState(0);
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState('');

    //on crop image handle
    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    //show cropped image
    const showCroppedImage = useCallback(async () => {
        let imageToCrop = (tempPicture ? tempPicture : (profileClient?.picture ? profileClient?.full_path_picture : null));
        try {
            const croppedImage = await getCroppedImg(
                imageToCrop,
                croppedAreaPixels,
                rotation
            )
            setCroppedFinalImage(croppedImage);
        } catch (e) {
        }
    }, [croppedAreaPixels, rotation, tempPicture]);// eslint-disable-line react-hooks/exhaustive-deps

    //set states when cropped image
    useEffect(() => {
        if (croppedFinalImage) {
            setProfilePicture(croppedFinalImage);
            setCurrentInputValue(croppedFinalImage);

            setTimeout(() => {
                setChangeInput(1);
            }, 100)
        }
    }, [croppedFinalImage]);// eslint-disable-line react-hooks/exhaustive-deps


    //get all jobs
    const allJobTitles = () => {
        const jobOptions = generalData?.job_titles?.map(
            singleOption => ({ value: singleOption.id, label: singleOption.title }))
        return jobOptions;
    }

    //function to add years to a date
    function addYears(date, years) {
        date.setFullYear(date.getFullYear() + years);
        return date;
    }

    //get all nationalities
    const allNationalities = () => {
        const natOptions = generalData?.nationalities?.map(
            singleNat => ({ value: singleNat.id, label: singleNat.title }))
        return natOptions;
    }

    //get all genders
    const allGenders = () => {
        const genders = generalData?.genders?.map(
            singleGender => ({ value: singleGender.id, label: singleGender.title }))
        return genders;
    }

    useEffect(() => {
        if (slug) {
            history('/client/' + user_id + '/' + slug);
        }
    }, [slug]);// eslint-disable-line react-hooks/exhaustive-deps

    //custom select styles
    const customStyles = {
        placeholder: (defaultStyles) => {
            return {
                ...defaultStyles,
                display: "flex",
                fontFamily: 'Poppins',
                fontWeight: '400',
                fontSize: '16px',
                color: '#707070 !important',
                border: 'none !important',
                minHeight: "26px",
                maxHeight: "26px",
            }
        },
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                display: "flex",
                color: '#00366A',
                fontFamily: 'Poppins',
                fontWeight: '400',
                backgroundColor: 'white',
                textTransform: "capitalize",
                margin: "auto",
                paddingBottom: "0",
                paddingTop: "0",
            };
        },
        singleValue: base => ({
            ...base,
            color: "#707070",
            display: "flex",
            fontSize: "14px",
            fontFamily: "Poppins",
            fontWeight: "400",
            minHeight: "26px",
            maxHeight: "26px",
            textTransform: "capitalize",
        }),
        control: (base, provided) => ({
            ...base,
            border: "0 !important",
            boxShadow: 'none !important',
            minHeight: '26px !important',
            height: '26px !important',
            '&:hover': { borderColor: 'transparent', border: 'none' },
            backgroundColor: 'white',
            outline: "none",
            width: "fit-content",
            borderRadius: "7px",
            fontFamily: 'Poppins',
            fontWeight: '400',
            fontSize: '16px',
        }),
        menu: (provided, state) => ({
            ...provided,
            borderRadius: "7px",
            textTransform: 'uppercase',
            border: "1px solid transparent",
            maxWidth: '250px',
            marginTop: '0px',
            boxShadow: '0px 3px 6px rgb(0 0 0 / 16%)',
        }),
        dropdownIndicator: base => ({
            ...base,
            color: "#00366A !important",
            padding: "4px",
            paddingTop: "1px",
        }),
        container: (base) => ({
            ...base,
            height: "26px",
            minHeight: "26px",
            maxHeight: "26px",
        }),
        valueContainer: (base) => ({
            ...base,
            height: "26px",
            minHeight: "26px",
            maxHeight: "26px",
        }),
        input: (base, state) => ({
            ...base,
            backgroundColor: "transparent !important",
            width: 'auto',
            textTransform: "none !important",
            border: "none !important",
            color: "#707070",
            fontSize: "16px",
            height: "23px",
            fontFamily: 'Poppins',
            fontWeight: '400',
            marginTop: "14px",
            '& input': {
                font: 'inherit',
            },
        })
    };

    //handle changing profile picture(when picture is uploaded)
    const profilePictureHandler = (uploadedFiles) => {
        if (uploadedFiles.length > 0) {
            setProfilePicture(uploadedFiles[0]);
            setCurrentInput('picture');
            setCurrentInputValue(uploadedFiles[0]);
            let file = URL.createObjectURL(uploadedFiles[0]);
            setTempPicture(file);
        }
    }

    //handle id/passport document upload
    const idDocumentHandler = (uploadedFiles) => {
        if (uploadedFiles.length > 0) {
            setIdDocument(uploadedFiles[0]);
            setCurrentInput('document');
            setTempIdDocumentName(uploadedFiles[0].name);
            setCurrentInputValue(uploadedFiles[0]);
            let file = URL.createObjectURL(uploadedFiles[0]);
            setTempIdDocument(file);
        }
    }

    //document handler
    const documentHandler = (files) => {
        if (files.length > 0) {
            setProxyDocument(files);
            let file = URL.createObjectURL(files[0])
            // setTempFile(file);
            setProxyDocumentLink(file);
        }
    }

    //remove picture process
    const removePicture = (e) => {
        setProfilePicture(null);
        setCurrentInput('picture');
        setCurrentInputValue('');
        setChangeInput(1);
        setUploadImagePopup(false);
    }

    //trigger backend post change document date
    useEffect(() => {
        if (documentDate) {
            setCurrentInput('document_date');
            setCurrentInputValue(documentDate);
        }
    }, [documentDate]);// eslint-disable-line react-hooks/exhaustive-deps

    //trigger birthday change post to backend
    useEffect(() => {
        if (birthDate) {
            setCurrentInput('dob');
            setCurrentInputValue(birthDate);
        }
    }, [birthDate]);// eslint-disable-line react-hooks/exhaustive-deps

    //trigger job title change post to backend   
    useEffect(() => {
        if (jobTitle) {
            setCurrentInput('job_title_id');
            setCurrentInputValue(jobTitle);
        }
    }, [jobTitle]);// eslint-disable-line react-hooks/exhaustive-deps

    //trigger nationality change post to backend
    useEffect(() => {
        if (userNationality) {
            setCurrentInput('nationality_id');
            setCurrentInputValue(userNationality);
        }
    }, [userNationality]);// eslint-disable-line react-hooks/exhaustive-deps

    //trigger gender change post to backend
    useEffect(() => {
        if (userGender) {
            setCurrentInput('gender_id');
            setCurrentInputValue(userGender);
        }
    }, [userGender]);// eslint-disable-line react-hooks/exhaustive-deps

    //set page states with curren profile data when profile client changes or user_id
    useEffect(() => {
        setFormData(profileClient);
        setTempIdDocumentName(profileClient?.document ? profileClient.document.replace('clients/', '') : generalData?.website_titles['upload-document']);
        setBirthDate(profileClient?.dob ? new Date(profileClient?.dob) : '');
        setDocumentDate(profileClient?.document_date ? new Date(profileClient?.document_date) : '');
        setJobTitle(allJobTitles()[getIndex(profileClient?.job_title_id, allJobTitles(), 'value')]);
        setUserGender(allGenders()[getIndex(profileClient?.gender_id, allGenders(), 'value')]);
        setUserNationality(allNationalities()[getIndex(profileClient?.nationality_id, allNationalities(), 'value')]);
    }, [profileClient, user_id]);// eslint-disable-line react-hooks/exhaustive-deps

    //trigger post to backend once inputs changes
    useEffect(() => {
        if (changeInput > 0 && slug === 'general-info') {
            let fd = new FormData();
            fd.append('current_user', user_id);
            fd.append('current_field', currentInput);
            if (currentInput === 'emails' || currentInput === 'phones' || currentInput === 'addresses') {
                fd.append('current_field_value', [currentInputValue['id'], currentInputValue['value']]);
            } else {
                fd.append('current_field_value', currentInputValue);
            }
            fd.append('name', formData?.name ? formData?.name : '');
            fd.append('mother_name', formData?.mother_name ? formData?.mother_name : '');
            fd.append('foreign_name', formData?.foreign_name ? formData?.foreign_name : '');
            fd.append('picture', profilePicture ? profilePicture : '');
            fd.append('picture', idDocument ? idDocument : '');
            fd.append('internal_ref', formData?.internal_ref ? formData?.internal_ref : '');
            fd.append('document_number', formData?.document_number ? formData?.document_number : '');
            fd.append('dob', birthDate ? formatDate(birthDate) : '');
            fd.append('document_date', documentDate ? formatDate(documentDate) : '');
            fd.append('job_title_id', jobTitle ? jobTitle.value : null);
            fd.append('gender_id', userGender ? userGender.value : null);
            fd.append('nationality_id', userNationality ? userNationality.value : null);
            fd.append('user_id', user_id);


            fd.append('force', 1);
            fd.append('file_id', formData?.uploaded_files_id ? formData?.uploaded_files_id : '');

            fd.append('parent_folder_id', '');
            fd.append('files_type_id', 5);
            fd.append('main_directory', 2);
            fd.append('file_name', 'ID/Passport');
            fd.append('folder_name', 'Personal Documents');
            fd.append('client_id', user_id);
            if (currentInput === 'document') {
                fd.append('file', currentInputValue);
                fd.append('from_home', 1);
            }
            setGeneralDataApiOptions({
                url: 'edit-client-data',
                method: 'post',
                data: fd,
                callback: res => {
                    setErrorsReturned({});
                    setFormData(res?.data);
                    setProfileClient(res.data);
                    setCurrentInputValue('');
                    setCurrentInput('');
                    setErrorsReturned({});
                    setDeletePopup();
                    setChangeInput(0);
                    setChangeIdDocument(false);
                    setUploadImagePopup(false);
                    setTempPicture('');
                    setZoom(1);
                },
                catch: err => {
                    if (err) {
                        if (currentInput === 'nationalities') {
                            setFormData(profileClient);
                        }
                        setErrorsReturned(err);
                        setChangeInput(0);
                    }
                }
            })
        }
    }, [changeInput]);// eslint-disable-line react-hooks/exhaustive-deps

    //remove from formdata for dynamic inputs
    const removeFormData = (single, index, key) => {
        setErrorsReturned({});
        if (key === 'nationalities') {
            if (!formData?.nationalities[index]?.id) {
                let newFd = { ...formData };
                newFd[key].splice(index, 1);
                setFormData(newFd);
            }
            else {
                setDeletePopup({ single, index, key });
            }
        }
        if (key === 'emails') {
            if (!formData?.emails[index]?.id) {
                let newFd = { ...formData };
                newFd[key].splice(index, 1);
                setFormData(newFd);
            }
            else {
                setDeletePopup({ single, index, key });
            }
        }
        if (key === 'phones') {
            if (!formData?.phones[index]?.id) {
                let newFd = { ...formData };
                newFd[key].splice(index, 1);
                setFormData(newFd);
            }
            else {
                setDeletePopup({ single, index, key });
            }
        }
        if (key === 'addresses') {
            if (!formData?.addresses[index]?.id) {
                let newFd = { ...formData };
                newFd[key].splice(index, 1);
                setFormData(newFd);
            }
            else {
                setDeletePopup({ single, index, key });
            }
        }
    }

    //delete element trigger backend call once record is already in DB
    const deleteElement = () => {
        if (deletePopup) {
            if (deletePopup['key'] === 'nationalities') {
                setCurrentInput('delete_nationalities');
                setCurrentInputValue(deletePopup['single'].id ? deletePopup['single'].id : 0);
                setChangeInput(1);
            }
            if (deletePopup['key'] === 'emails') {
                setCurrentInput('delete_emails');
                setCurrentInputValue(deletePopup['single'].id ? deletePopup['single'].id : 0);
                setChangeInput(1);
            }
            if (deletePopup['key'] === 'phones') {
                setCurrentInput('delete_phones');
                setCurrentInputValue(deletePopup['single'].id ? deletePopup['single'].id : 0);
                setChangeInput(1);
            }
            if (deletePopup['key'] === 'addresses') {
                setCurrentInput('delete_addresses');
                setCurrentInputValue(deletePopup['single'].id ? deletePopup['single'].id : 0);
                setChangeInput(1);
            }
        }
    }

    //add new empty field to formdata by key
    const addToFormData = (key) => {
        let emptyObject = {};
        let added = 1;
        if (key === 'nationalities') {
            if (!profileClient?.nationality_id) {
                added = 0;
            }
            if (formData?.nationalities?.length > 0) {
                formData?.nationalities?.forEach(single => {
                    if (single?.nationality_id === null || single?.nationality_id === '') {
                        added = 0;
                    }
                });
            }
            if (added === 1) {
                emptyObject = { id: null, nationality_id: null };
                let newFormData = { ...formData };
                newFormData[key].push(emptyObject);
                setFormData(newFormData);
            }
        }


        if (key === 'emails') {
            if (!profileClient?.email) {
                added = 0;
            }
            if (formData?.emails?.length > 0) {
                formData?.emails?.forEach(single => {
                    if (single?.email === null || single?.email === '') {
                        added = 0;
                    }
                });
            }
            if (added === 1) {
                emptyObject = { id: 0, email: '' };
                let newFormData = { ...formData };
                newFormData[key].push(emptyObject);
                setFormData(newFormData);
            }
        }

        if (key === 'phones') {
            if (!profileClient?.main_phone_number) {
                added = 0;
            }
            if (formData?.phones?.length > 0) {
                formData?.phones?.forEach(single => {
                    if (single?.phone === null || single?.phone === '') {
                        added = 0;
                    }
                });
            }
            if (added === 1) {
                emptyObject = { id: 0, phone: '' };
                let newFormData = { ...formData };
                newFormData[key].push(emptyObject);
                setFormData(newFormData);
            }
        }

        if (key === 'addresses') {
            if (!profileClient?.main_address) {
                added = 0;
            }
            if (formData?.addresses?.length > 0) {
                formData?.addresses?.forEach(single => {
                    if (single?.address === null || single?.address === '') {
                        added = 0;
                    }
                });
            }
            if (added === 1) {
                emptyObject = { id: 0, address: '' };
                let newFormData = { ...formData };
                newFormData[key].push(emptyObject);
                setFormData(newFormData);
            }
        }
    }

    //handle dynamic nationalities changes
    const handleDynamicInputChange = (e, single, index, key) => {
        let formDt = { ...formData };
        if (key === 'nationalities') {
            formData.nationalities[index].nationality_id = e.value;
        }
        setFormData(formDt);
        setCurrentInput('nationalities');
        setCurrentInputValue([
            single.id ? single?.id : 0,
            e.value
        ]);
        setChangeInput(1);
    }

    //cancel delete popup
    const cancelDeletePopup = () => {
        setDeletePopup();
    }

    useEffect(() => {
        setGeneralDataApiOptions({
            url: 'directories-list',
            method: 'post',
            data: {
                user_id: user_id,
                all: true,
                noEmptyComment: true,
                orderByDate: true,
                main_directory: 2,
                showLoader: false,
            },
            callback: res => {
                setReturnedFiles(res.data?.files);
            },
            catch: err => {
                if (err) {
                }
            }
        })
    }, [profileClient]);// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <div className="container-fluid">
                <div className="row client-profile-height">
                    <div className="col-12 col-lg-4 pt-4 drop-shadow-div scrollable-client-profile-height">
                        <div className="row">
                            <div className="col-12 d-flex justify-content-center pb-3">
                                <div onClick={() => setUploadImagePopup(true)} className="position-relative">
                                    <img className="profile-image-general" src={formData.picture ? formData.full_path_picture : generalData?.fixed_settings?.full_path_no_individual_picture} alt="profile" />
                                    <div className="edit-prof-back">
                                        <EditPencil />
                                    </div>
                                </div>
                            </div>

                            {
                                errorsReturned && errorsReturned?.current_field_value && currentInput === 'picture' ?
                                    <div className="col-12 my-auto align-items-center pb-4">
                                        <h5 className="pop-errors-text">{errorsReturned?.current_field_value}</h5>
                                    </div>
                                    :
                                    null
                            }
                            <div className="col-12 my-auto align-items-center pb-4">
                                <div className="row">
                                    <div className="col-12 pt-3">
                                        <div className="row">
                                            <div className="col-12 d-flex">
                                                <img className="profile-general-svg me-2" src="/assets/images/name-tag.svg" alt="name" />
                                                <p className="profile-general-input-title">{generalData?.website_titles['name']}</p>
                                            </div>
                                            <div className="col-12 col-sm-8 col-lg-10 col-xl-9 pt-1 w-100">
                                                <EditInput withPadd={1} inputType={'text'} postUrl="edit-client-data" data={profileClient} value={formData?.name ? formData?.name : ''} inputName={"name"} userId={user_id} />
                                            </div>
                                            {
                                                errorsReturned && errorsReturned?.name ?
                                                    <div className="col-12 col-sm-8 col-lg-10 col-xl-9 pt-1 w-100">
                                                        <h5 className=" ps-4 pop-errors-text">{errorsReturned?.name}</h5>
                                                    </div>
                                                    :
                                                    null
                                            }
                                        </div>
                                    </div>

                                    <div className="col-12 pt-3">
                                        <div className="row">
                                            <div className="col-12 d-flex">
                                                <img style={{ opacity: '0' }} className="profile-general-svg me-2" src="/assets/images/name-tag.svg" alt="mother_name" />
                                                <p className="profile-general-input-title">{generalData?.website_titles['mother-name']}</p>
                                            </div>
                                            <div className="col-12 col-sm-8 col-lg-10 col-xl-9 pt-1 w-100">
                                                <EditInput placeholder={generalData?.website_titles['mother-name']} withPadd={1} inputType={'text'} postUrl="edit-client-data" data={profileClient} value={formData?.mother_name ? formData?.mother_name : ''} inputName={"mother_name"} userId={user_id} />
                                            </div>
                                            {
                                                errorsReturned && errorsReturned?.mother_name ?
                                                    <div className="col-12 col-sm-8 col-lg-10 col-xl-9 pt-1 w-100">
                                                        <h5 className=" ps-4 pop-errors-text">{errorsReturned?.mother_name}</h5>
                                                    </div>
                                                    :
                                                    null
                                            }
                                        </div>
                                    </div>

                                    <div className="col-12 pt-3">
                                        <div className="row">
                                            <div className="col-12 d-flex">
                                                <img style={{ opacity: '0' }} className="profile-general-svg me-2" src="/assets/images/name-tag.svg" alt="foreign_name" />
                                                <p className="profile-general-input-title">{generalData?.website_titles['foreign-name']}</p>
                                            </div>
                                            <div className="col-12 col-sm-8 col-lg-10 col-xl-9 pt-1 w-100">
                                                <EditInput placeholder={generalData?.website_titles['foreign-name']} withPadd={1} inputType={'text'} postUrl="edit-client-data" data={profileClient} value={formData?.foreign_name ? formData?.foreign_name : ''} inputName={"foreign_name"} userId={user_id} />
                                            </div>
                                            {
                                                errorsReturned && errorsReturned?.foreign_name ?
                                                    <div className="col-12 col-sm-8 col-lg-10 col-xl-9 pt-1 w-100">
                                                        <h5 className=" ps-4 pop-errors-text">{errorsReturned?.foreign_name}</h5>
                                                    </div>
                                                    :
                                                    null
                                            }
                                        </div>
                                    </div>

                                    <div className="col-12 col-sm-6 col-lg-12 pt-3">
                                        <div className="row">
                                            <div className="col-12 d-flex">
                                                <img className="profile-general-svg me-2" src="/assets/images/nationality.svg" alt="date" />
                                                <p className="profile-general-input-title">{generalData?.website_titles['nationality']}</p>
                                            </div>
                                            <div className="col-12 col-sm-8 col-lg-10 col-xl-8 pt-1">
                                                <div className="row">
                                                    <div style={{ zIndex: "999" }} className="col-auto" onClick={(e) => addToFormData('nationalities')}>
                                                        <AddIconLt />
                                                    </div>
                                                    <div style={{ marginLeft: "-4px", marginRight: "-4px" }} className="col-10 ps-0">
                                                        <Select
                                                            isSearchable={true}
                                                            classNamePrefix="profile-select"
                                                            className="profile-select w-100 my-auto"
                                                            styles={customStyles}
                                                            options={allNationalities()}
                                                            components={{
                                                                IndicatorSeparator: () => null
                                                            }}
                                                            placeholder={generalData?.website_titles['nationality']}
                                                            value={userNationality ? userNationality : null}
                                                            onChange={(e) => { setUserNationality(e); setTimeout(() => { setChangeInput(1) }, 100) }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                formData?.nationalities?.length > 0 ?
                                                    formData?.nationalities?.map((singleNat, index) => (
                                                        <div className="col-12 col-sm-8 col-lg-10 col-xl-8 pt-1" key={index}>
                                                            <div className="row">
                                                                <div style={{ zIndex: "999" }} className="col-auto" onClick={(e) => removeFormData(singleNat, index, 'nationalities')}>
                                                                    <RemoveFd />
                                                                </div>
                                                                <div style={{ marginLeft: "-4px", marginRight: "-4px" }} className="col-10 ps-0">
                                                                    <Select
                                                                        classNamePrefix="profile-select"
                                                                        className="profile-select w-100 my-auto"
                                                                        styles={customStyles}
                                                                        options={allNationalities()}
                                                                        components={{
                                                                            IndicatorSeparator: () => null
                                                                        }}
                                                                        placeholder={generalData?.website_titles['nationality']}
                                                                        isSearchable={true}
                                                                        value={singleNat ? allNationalities()[getIndex(singleNat?.nationality_id, allNationalities(), 'value')] : null}
                                                                        onChange={(e) => handleDynamicInputChange(e, singleNat, index, 'nationalities')} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))
                                                    :
                                                    null
                                            }
                                            {
                                                (errorsReturned && errorsReturned?.nationalities) || (errorsReturned && errorsReturned?.nationality_id) ?
                                                    <>
                                                        <p className="m-0 ps-4 errors-auth-profile">{errorsReturned?.nationalities}</p>
                                                        <p className="m-0 ps-4 errors-auth-profile">{errorsReturned?.nationality_id}</p>
                                                    </>
                                                    :
                                                    null
                                            }
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6 col-lg-12 pt-3">
                                        <div className="row">
                                            <div className="col-12 d-flex">
                                                <img className="profile-general-svg me-2" src="/assets/images/emails.svg" alt="date" />
                                                <p className="profile-general-input-title">{generalData?.website_titles['email']}</p>
                                            </div>
                                            <div className="col-12 pt-1">
                                                <div className="row">
                                                    <div style={{ zIndex: "9999" }} className="col-auto" onClick={(e) => addToFormData('emails')}>
                                                        <AddIconLt />
                                                    </div>
                                                    <div style={{ marginRight: "-4px" }} className="col ps-0">
                                                        <EditInput placeholder={generalData?.website_titles['enter-email']} inputType={'text'} postUrl="edit-client-data" data={profileClient} value={formData?.email ? formData?.email : ''} inputName={"email"} userId={user_id} />
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                formData?.emails?.length > 0 ?
                                                    formData?.emails?.map((singleEmail, index) => (
                                                        <div className="col-12 pt-1" key={index}>
                                                            <div className="row">
                                                                <div style={{ zIndex: "999" }} className="col-auto" onClick={(e) => removeFormData(singleEmail, index, 'emails')}>
                                                                    <RemoveFd />
                                                                </div>
                                                                <div style={{ marginRight: "-4px" }} className="col ps-0">
                                                                    <EditInput placeholder={generalData?.website_titles['enter-email']} className="w-100" singleRec={singleEmail} withPadd={0} inputType={'text'} data={formData} value={singleEmail?.email ? singleEmail?.email : ''} inputName={"emails"} userId={user_id} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))
                                                    :
                                                    null
                                            }

                                            {
                                                errorsReturned && errorsReturned?.email ?
                                                    <p className="m-0 ps-4 errors-auth-profile">{errorsReturned?.email}</p>
                                                    :
                                                    null
                                            }
                                            {
                                                errorsReturned && errorsReturned?.emails ?
                                                    <p className="m-0 ps-4 errors-auth-profile">{errorsReturned?.emails}</p>
                                                    :
                                                    null
                                            }
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6 col-lg-12 pt-3">
                                        <div className="row">
                                            <div className="col-12 d-flex">
                                                <img className="profile-general-svg me-2" src="/assets/images/phone.svg" alt="date" />
                                                <p className="profile-general-input-title">{generalData?.website_titles['phone']}</p>
                                            </div>
                                            <div className="col-12 pt-1">
                                                <div className="row">
                                                    <div style={{ zIndex: "9999" }} className="col-auto" onClick={(e) => addToFormData('phones')}>
                                                        <AddIconLt style={{ marginLeft: "2px !important" }} />
                                                    </div>
                                                    <div style={{ marginRight: "-4px" }} className="col ps-0">
                                                        <EditInput placeholder={generalData?.website_titles['enter-phone']} inputType={'phones'} postUrl="edit-client-data" data={profileClient} value={formData?.main_phone_number ? formData?.main_phone_number : ''} inputName={"main_phone_number"} userId={user_id} />
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                formData?.phones?.length > 0 ?
                                                    formData?.phones?.map((singlePhone, index) => (
                                                        <div className="col-12 pt-1" key={index}>
                                                            <div className="row">
                                                                <div style={{ zIndex: "9999" }} className="col-auto" onClick={(e) => removeFormData(singlePhone, index, 'phones')}>
                                                                    <RemoveFd style={{ marginTop: "-6px", marginLeft: "2px !important" }} />
                                                                </div>
                                                                <div style={{ marginRight: "-4px" }} className="col ps-0">
                                                                    <EditInput placeholder={generalData?.website_titles['enter-phone']} className="w-100" singleRec={singlePhone} withPadd={0} inputType={'phones'} data={formData} value={singlePhone?.phone ? singlePhone?.phone : ''} inputName={"phones"} userId={user_id} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))
                                                    :
                                                    null
                                            }
                                            {
                                                errorsReturned && errorsReturned?.phones ?
                                                    <p className="m-0 ps-4 errors-auth-profile">{errorsReturned?.phones}</p>
                                                    :
                                                    null
                                            }
                                        </div>
                                    </div>
                                    <div className="col-12 pt-3">
                                        <div className="row">
                                            <div className="col-12 d-flex">
                                                <img className="profile-general-svg me-2" src="/assets/images/location.svg" alt="date" />
                                                <p className="profile-general-input-title">{generalData?.website_titles['address']}</p>
                                            </div>
                                            <div className="col-12 pt-1">
                                                <div className="row">
                                                    <div style={{ zIndex: "9999" }} className="col-auto" onClick={(e) => addToFormData('addresses')}>
                                                        <AddIconLt style={{ marginLeft: "2px !important" }} />
                                                    </div>
                                                    <div style={{ marginRight: "-4px" }} className="col ps-0 pe-4">
                                                        <EditInput placeholder={generalData?.website_titles['enter-address']} inputType={'text'} postUrl="edit-client-data" data={profileClient} value={formData?.main_address ? formData?.main_address : ''} inputName={"main_address"} userId={user_id} />
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                formData?.addresses?.length > 0 ?
                                                    formData?.addresses?.map((singleAdd, index) => (
                                                        <div className="col-12 pt-1" key={index}>
                                                            <div className="row">
                                                                <div style={{ zIndex: "9999" }} className="col-auto" onClick={(e) => removeFormData(singleAdd, index, 'addresses')}>
                                                                    <RemoveFd style={{ marginTop: "-6px", marginLeft: "2px !important" }} />
                                                                </div>
                                                                <div style={{ marginRight: "-4px" }} className="col ps-0">
                                                                    <EditInput placeholder={generalData?.website_titles['enter-address']} className="w-100" singleRec={singleAdd} withPadd={0} inputType={'text'} data={formData} value={singleAdd?.address ? singleAdd?.address : ''} inputName={"addresses"} userId={user_id} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))
                                                    :
                                                    null
                                            }
                                            {
                                                errorsReturned && (errorsReturned?.addresses || errorsReturned?.main_address) ?
                                                    <>
                                                        <p className="m-0 ps-4 errors-auth-profile">{errorsReturned?.addresses}</p>
                                                        <p className="m-0 ps-4 errors-auth-profile">{errorsReturned?.main_address}</p>
                                                    </>
                                                    :
                                                    null
                                            }
                                        </div>
                                    </div>
                                    <div className="col-12 pt-3">
                                        <div className="row">
                                            <div className="col-12 d-flex">
                                                <img className="profile-general-svg me-2" src="/assets/images/date-icon.svg" alt="date" />
                                                <p className="profile-general-input-title">{generalData?.website_titles['date-of-birth']}</p>
                                            </div>
                                            <div style={{ zIndex: '99999999' }} className="col-12 col-sm-8 col-lg-10 col-xl-8 pt-1">
                                                <DatePicker
                                                    className="birthdate-profile"
                                                    placeholderText={generalData?.website_titles['select-date-of-birth']}
                                                    value={birthDate ? birthDate : ''}
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                    selected={birthDate}
                                                    dateFormat="d MMMM yyyy"
                                                    onChange={(date) => { setBirthDate(date); setTimeout(() => { setChangeInput(1) }, 100) }}
                                                    maxDate={addYears(new Date(), -21)}
                                                    minDate={addYears(new Date(), -100)}
                                                    locale={localStorage.getItem("BlpLanguage")}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="col-12 col-lg-8 pt-4 px-lg-4 pb-5 scrollable-client-profile-height">

                        <div className="row">
                            <div className="col-auto">
                                <h1 className="profile-big-title">{generalData?.website_titles['additional-info']}</h1>
                            </div>
                        </div>
                        <div className="row pt-2">
                            <div className="col-12 col-md-6 pb-2">
                                <p className="profile-general-input-title pb-1">{generalData?.website_titles['job-title']}</p>
                                <Select
                                    classNamePrefix="profile-select"
                                    className="profile-select w-100 my-auto"
                                    styles={customStyles}
                                    options={allJobTitles()}
                                    components={{
                                        IndicatorSeparator: () => null
                                    }}
                                    placeholder={generalData?.settings_section_settings['settings-job-title']}
                                    isSearchable={false}
                                    value={jobTitle ? jobTitle : null}
                                    onChange={(e) => { setJobTitle(e); setTimeout(() => { setChangeInput(1) }, 100) }}
                                />
                            </div>
                            <div className="col-12 col-md-6 pb-2">
                                <p className="profile-general-input-title pb-1">{generalData?.website_titles['internal-ref-title']}</p>
                                <div className="row">
                                    <div className="col-12 col-lg-10 col-xl-8">
                                        <EditInput placeholder={generalData?.website_titles['internal-ref-title']} inputType={'text'} postUrl="edit-client-data" data={profileClient} value={profileClient?.internal_ref ? profileClient?.internal_ref : ''} inputName={"internal_ref"} userId={user_id} />
                                        {
                                            errorsReturned && (errorsReturned?.internal_ref) ?
                                                <>
                                                    <p className="m-0 errors-auth-profile">{errorsReturned?.internal_ref}</p>
                                                </>
                                                :
                                                null
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row pt-2">
                            <div className="col-12 col-md-6 pb-2">
                                <p className="profile-general-input-title pb-1">{generalData?.website_titles['gender']}</p>
                                <Select
                                    classNamePrefix="profile-select"
                                    className="profile-select w-100 my-auto"
                                    styles={customStyles}
                                    options={allGenders()}
                                    components={{
                                        IndicatorSeparator: () => null
                                    }}
                                    placeholder={generalData?.website_titles['gender']}
                                    isSearchable={false}
                                    value={userGender ? userGender : null}
                                    onChange={(e) => { setUserGender(e); setTimeout(() => { setChangeInput(1) }, 100) }} />
                            </div>
                        </div>
                        <div className="row py-2">
                            <h1 className="profile-big-title">{generalData?.website_titles['passport_id_title']}</h1>
                            <div className="col-12 col-md-6 pt-2 pb-2">
                                <p className="profile-general-input-title pb-1">{generalData?.website_titles['number']}</p>
                                <div className="row">
                                    <div className="col-12 col-lg-10 col-xl-8">
                                        <EditInput placeholder={generalData?.website_titles['number']} inputType={'text'} postUrl="edit-client-data" data={profileClient} value={profileClient?.document_number ? profileClient?.document_number : ''} inputName={"document_number"} userId={user_id} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 pt-2 pb-2">
                                <p className="profile-general-input-title pb-1">{generalData?.website_titles['document']}</p>
                                <div className="row w-100">
                                    <div className="col-12">
                                        {
                                            !changeIdDocument ?
                                                <>
                                                    <h5 className="locked-inputs w-100 ">
                                                        {
                                                            formData?.document ?
                                                                <a className="cursor-pointer" href={formData?.full_path_document} target="_blank" rel="noreferrer" download={tempIdDocumentName} >
                                                                    {tempIdDocumentName?.length > 15 ? tempIdDocumentName.substring(0, 5) + '...' + tempIdDocumentName.substring(tempIdDocumentName.length - 11, tempIdDocumentName.length) : tempIdDocumentName}
                                                                    {/* {tempIdDocumentName} */}
                                                                </a>
                                                                :
                                                                <p className="m-0 gray-color-dark" onClick={(e) => setChangeIdDocument(true)}>{tempIdDocumentName}</p>
                                                        }
                                                        {
                                                            formData?.document ?
                                                                <DocumentPencil className="ms-2 cursor-pointer" onClick={(e) => setChangeIdDocument(true)} style={{ width: '16px', height: 'auto' }} />
                                                                :
                                                                null
                                                        }
                                                    </h5>
                                                </>
                                                :
                                                <>
                                                    <input hidden className="upload-comment-files" id='upload-comment-files' type="file" onChange={(e) => { e.preventDefault(); e.stopPropagation(); idDocumentHandler(e.target.files) }} />
                                                    <div className="row">
                                                        <div className="col pe-0">
                                                            <label className="w-100 position-relative" htmlFor="upload-comment-files">
                                                                <input className="input-text-reversed pointer-events-none profile-general-input ms-0 w-100" onChange={(e) => 1} value={tempIdDocumentName?.length > 15 ? tempIdDocumentName.substring(0, 5) + '...' + tempIdDocumentName.substring(tempIdDocumentName.length - 11, tempIdDocumentName.length) : tempIdDocumentName} readOnly />
                                                                <SmallPencil className="svg-upload-profile" />
                                                            </label>
                                                        </div>
                                                        <div className="col-auto ps-1">
                                                            <div className="d-flex">
                                                                <div className="input-profile-buttons ms-1" onClick={(e) => setChangeInput(changeInput + 1)}>
                                                                    <TrueSign />
                                                                </div>
                                                                <div className="input-profile-buttons ms-1" onClick={(e) => { setChangeIdDocument(false); setIdDocument(''); setCurrentInput(''); setErrorsReturned({}); setCurrentInputValue(''); setTempIdDocumentName(formData?.document ? formData.document.replace('clients/', '') : generalData?.website_titles['upload-document']) }}>
                                                                    <XSign />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                        }
                                        {
                                            errorsReturned && errorsReturned?.current_field_value && currentInput === 'document' ?
                                                <div className="col-12 my-auto align-items-center pt-1 pb-2">
                                                    <h5 className="pop-errors-text">{errorsReturned?.current_field_value}</h5>
                                                </div>
                                                :
                                                null
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 py-2">
                                <p className="profile-general-input-title pb-1">{generalData?.website_titles['expiry-date']}</p>
                                <div style={{ zIndex: '99999999' }} className="col-12 col-sm-8 col-lg-10 col-xl-8 pt-1">
                                    <DatePicker
                                        className="birthdate-profile ps-0"
                                        placeholderText={generalData?.website_titles['expiry-date']}
                                        value={documentDate ? documentDate : ''}
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        selected={documentDate}
                                        dateFormat="d MMMM yyyy"
                                        onChange={(date) => { setDocumentDate(date); setTimeout(() => { setChangeInput(1) }, 100) }}
                                        maxDate={addYears(new Date(), +50)}
                                        minDate={addYears(new Date(), -50)}
                                        locale={localStorage.getItem("BlpLanguage")}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row py-2">
                            <h1 className="profile-big-title">
                                {generalData?.website_titles['proxy-title']}
                                <AddIconCircle onClick={(e) => setAddProxy(true)} style={{ width: "15px", height: 'auto' }} className="ms-2 cursor-pointer" />
                            </h1>
                            {
                                formData?.proxies?.length > 0 ?
                                    formData?.proxies?.map((singleProxy, indexx) => (
                                        <div key={indexx} className="col-12 col-xl-6 pb-3 pb-xl-0">
                                            <div className="row g-0 costs-box-styling custom-shadow position-relative">
                                                <div className="proxy-menu">
                                                    <div className="position-relative" >
                                                        <ThreeDotsMenu onClick={(e) => { e.stopPropagation(); openEditMenu?.id === singleProxy?.id ? setOpenEditMenu(null) : setOpenEditMenu(singleProxy) }} />
                                                        <div className={"settings-clickmenu text-start " + (singleProxy?.id === openEditMenu?.id ? ' d-block' : '')}>
                                                            <p style={{ zIndex: "99" }} onClick={(e) => { e.stopPropagation(); e.preventDefault(); editClick(e, singleProxy) }} className="p-0 py-1 m-0 ms-2">{generalData?.settings_section_settings['edit-title']}</p>
                                                            <p style={{ zIndex: "200" }} onClick={(e) => { e.stopPropagation(); e.preventDefault(); setDeleteProxy(singleProxy) }} className="p-0 py-1 m-0 ms-2">{generalData?.settings_section_settings['delete-title']}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6 pt-2 pb-2 pe-md-2">
                                                    <p className="profile-general-input-title pb-1">{generalData?.website_titles['poa-title']}</p>
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <h5 className="locked-inputs w-100 m-0 p-0">{singleProxy?.poa ? singleProxy?.poa : ''}</h5>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6 pt-2 pb-2">
                                                    <p className="profile-general-input-title pb-1">{generalData?.website_titles['public-notary-title']}</p>
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <h5 className="locked-inputs w-100 m-0 p-0">{singleProxy?.public_notary ? singleProxy?.public_notary : ''}</h5>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6 pt-2 pe-md-2">
                                                    <p className="profile-general-input-title pb-1">{generalData?.website_titles['date-title']}</p>
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <h5 className="locked-inputs w-100 m-0 p-0">{singleProxy?.date ? singleProxy?.date_formatted : ''}</h5>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6 pt-2 pb-2">
                                                    <p className="profile-general-input-title pb-1">{generalData?.website_titles['client-file-title']}</p>
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <a href={singleProxy?.file ? singleProxy.full_path_file : null} target="_blank" rel="noreferrer" className="locked-inputs w-100 m-0 p-0">{singleProxy?.file ? generalData?.website_titles['download-file'] : ''}</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 pt-2 pb-2 ">
                                                    <p className="profile-general-input-title pb-1">{generalData?.website_titles['registration-number-title']}</p>
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <h5 className="locked-inputs w-100 m-0 p-0">{singleProxy?.registration_number ? singleProxy?.registration_number : ''}</h5>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                    :
                                    <div className="col-12">
                                        <h5 className="locked-inputs w-100 m-0 p-0">{generalData?.website_titles['no-proxies']}</h5>
                                    </div>
                            }
                        </div>

                        <DocumentsSmallTab userId={user_id} files={profileClient?.home_files} />

                        <ClientComments clientId={user_id} section={'general-info'} />

                        <div className="row py-2">
                            <div className="d-flex justify-content-between">
                                <h1 className="profile-big-title">
                                    {generalData?.documentsSectionSettings['view-history']}
                                </h1>
                                {
                                    returnedFiles?.length > 0 &&
                                    <a href={`/client/${user_id}/documents/history`}>{generalData?.fixed_titles['view-all']}</a>
                                }
                            </div>
                            <LogsComponent generalData={generalData} showCount={5} returnedFiles={returnedFiles} />
                            {/* <div className="col-12 mb-4 mt-4">
                                <div className="row g-0 p-0 py-4 costs-box-styling custom-shadow px-2">
                                    {
                                        returnedFiles.length > 0 ?
                                            returnedFiles.map((file, index) => (
                                                index < 5 &&
                                                <div key={index} className="col-12">
                                                    <div className="row pb-3 flex-wrap">
                                                        <div className="col-auto position-relative">
                                                            <div className="blue-dot-logs" />
                                                            {
                                                                returnedFiles?.length !== index + 1 && index + 1 < 5 &&
                                                                <div className="blue-dot-logs-lines" ></div>

                                                            }
                                                        </div>
                                                        <div className="col ps-0">
                                                            <div className="row flex-wrap">
                                                                <div className="col">
                                                                    {
                                                                        file?.deleted_at ?
                                                                            <div className="d-flex align-items-md-center flex-md-row flex-column ">
                                                                                <del>

                                                                                    {
                                                                                        file?.comment ?
                                                                                            <div className="d-flex align-items-md-center flex-md-row flex-column flex-wrap ">
                                                                                                <p className={`fs-12 m-0 fs-smaller`}>
                                                                                                    {file?.comment}
                                                                                                </p>

                                                                                                <a target='_blank' href={file?.full_path_file} className={`fs-12 m-0 fs-smaller`}>
                                                                                                    <FileIcon className="p-1" />
                                                                                                    {file?.file_name}

                                                                                                </a>

                                                                                                <p className={`fs-12 ms-md-2 m-0 fs-smaller`}>
                                                                                                    {file?.file_date}
                                                                                                </p>

                                                                                            </div>
                                                                                            :
                                                                                            null
                                                                                    }
                                                                                </del>
                                                                                <p className="ms-md-3 fs-12 m-0 fs-smaller">deleted by : {file?.delete_user?.name}</p>
                                                                            </div>
                                                                            :
                                                                            <div className="d-flex align-items-md-center flex-md-row flex-column flex-wrap">
                                                                                {
                                                                                    file?.comment ?
                                                                                        <>
                                                                                            <p className={`fs-12 m-0 fs-smaller`}>

                                                                                                {file?.comment}
                                                                                            </p>
                                                                                            <p className={`fs-12 m-0 fs-smaller ms-md-1`}>
                                                                                                {file?.file_date}
                                                                                            </p>

                                                                                            <a target='_blank' className={`fs-12 m-0 fs-smaller`} href={file?.full_path_file}>
                                                                                                <FileIcon className="p-1" />
                                                                                                {file?.file_name}
                                                                                            </a>

                                                                                        </>
                                                                                        :
                                                                                        null
                                                                                }
                                                                            </div>


                                                                    }


                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                            :
                                            <h5 className="py-3 locked-inputs w-100 m-0 p-0">{generalData?.website_titles['no-logs-yet']}</h5>
                                    }
                                </div>
                            </div> */}

                            {/* <div className="col-12">
                                <div className="row g-0 p-0 py-4 costs-box-styling custom-shadow px-2">
                                    {
                                        returnedFiles?.length > 0 ?
                                            returnedFiles?.map((file, index) => (
                                                index < 5 &&
                                                <div key={index} className="col-12">
                                                    <div className="row pb-3">
                                                        <div className="col-auto position-relative">
                                                            <div className="blue-dot-logs" />
                                                            {
                                                                returnedFiles?.length !== index + 1 && index + 1 < 5 &&
                                                                <div className="blue-dot-logs-lines" ></div>

                                                            }
                                                        </div>
                                                        <div className="col ps-0">
                                                            <div className="row">
                                                                <div className="col">
                                                                    {
                                                                        file?.deleted_at ?
                                                                            <div className="d-flex align-items-center">
                                                                                <del>

                                                                                    {
                                                                                        file?.comment ?
                                                                                            <div className="d-flex align-items-center">
                                                                                                <p className={`fs-12 m-0 fs-smaller`}>
                                                                                                    {file?.comment}
                                                                                                </p>
                                                                                                &nbsp;

                                                                                                <a target='_blank' href={file?.full_path_file} className={`fs-12 m-0 fs-smaller`}>
                                                                                                    <FileIcon className="p-1" />
                                                                                                    {file?.file_name}

                                                                                                </a>
                                                                                                &nbsp;

                                                                                                <p className={`fs-12 m-0 fs-smaller`}>
                                                                                                    {file?.file_date}
                                                                                                </p>

                                                                                            </div>
                                                                                            :
                                                                                            null
                                                                                    }
                                                                                </del>
                                                                                <p className="ms-3 fs-12 m-0 fs-smaller">deleted by : {file?.delete_user?.name}</p>
                                                                            </div>
                                                                            :
                                                                            <div className="d-flex align-items-center">
                                                                                {
                                                                                    file?.comment ?
                                                                                        <>
                                                                                            <p className={`fs-12 m-0 fs-smaller`}>

                                                                                                {file?.comment}
                                                                                            </p>
                                                                                            &nbsp;
                                                                                            <p className={`fs-12 m-0 fs-smaller`}>
                                                                                                {file?.file_date}
                                                                                            </p>
                                                                                            &nbsp;

                                                                                            <a target='_blank' className={`fs-12 m-0 fs-smaller`} href={file?.full_path_file}>
                                                                                                <FileIcon className="p-1" />
                                                                                                {file?.file_name}
                                                                                            </a>

                                                                                        </>
                                                                                        :
                                                                                        null
                                                                                }
                                                                            </div>


                                                                    }


                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                            :
                                            <h5 className="py-3 locked-inputs w-100 m-0 p-0">{generalData?.website_titles['no-logs-yet']}</h5>
                                    }
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
                {
                    //delete popup
                    deletePopup ?
                        <div id="errors-popup" className=" error-popup-api d-flex align-items-center justify-content-center px-3 fade-in">
                            <div className="pop-up-api-box">
                                <CloseIcon className="cursor-pointer" onClick={() => cancelDeletePopup()} />
                                <div className="text-center ">
                                    <div className="containter-fluid">
                                        <div className="row d-flex justify-content-center align-items-center">
                                            <div className="col-9 col-sm-12 d-flex justify-content-center">
                                                <h1 className="pop-up-title m-0 pt-3 color-dark-blue">{generalData?.settings_section_settings['delete-popup-title']}</h1>
                                            </div>
                                            <div className="col-12 d-flex justify-content-center pt-4 pb-2">
                                                <button onClick={() => deleteElement()} type="button" className="popup-button my-auto mx-2 w-auto px-3">{generalData?.settings_section_settings['delete-popup-first-button']}</button>
                                                <button onClick={() => cancelDeletePopup()} type="button" className="auth-button border-dark-blue my-auto mx-2 background-transparent w-auto px-3">{generalData?.settings_section_settings['delete-popup-second-button']}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        null
                }

                {
                    //delete proxy popup
                    deleteProxy ?
                        <div id="errors-popup" className=" error-popup-api d-flex align-items-center justify-content-center px-3 fade-in">
                            <div className="pop-up-api-box">
                                <CloseIcon className="cursor-pointer" onClick={(e) => closeProxyPopup(e)} />
                                <div className="text-center ">
                                    <div className="containter-fluid">
                                        <div className="row d-flex justify-content-center align-items-center">
                                            <div className="col-9 col-sm-12 d-flex justify-content-center">
                                                <h1 className="pop-up-title m-0 pt-3 color-dark-blue">{generalData?.settings_section_settings['delete-popup-title']}</h1>
                                            </div>
                                            <div className="col-12 d-flex justify-content-center pt-4 pb-2">
                                                <button onClick={(e) => { e.preventDefault(); e.stopPropagation(); deleteProxyProcess(e, openEditMenu) }} type="button" className="popup-button my-auto mx-2 w-auto px-3">{generalData?.settings_section_settings['delete-popup-first-button']}</button>
                                                <button onClick={(e) => closeProxyPopup(e)} type="button" className="auth-button border-dark-blue my-auto mx-2 background-transparent w-auto px-3">{generalData?.settings_section_settings['delete-popup-second-button']}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        null
                }

                {
                    //upload image popup
                    uploadImagePopup ?
                        <div id="errors-popup" className=" error-popup-api d-flex align-items-center justify-content-center px-3 fade-in">
                            <div className="pop-up-api-box">
                                <CloseIcon className="cursor-pointer" onClick={(e) => { e.preventDefault(); setUploadImagePopup(false); setTempPicture(''); setProfilePicture(''); setZoom(1); }} />
                                <div className="text-center ">
                                    <div className="containter-fluid">
                                        <div className="row">
                                            <div className="col-12">
                                                <h1 className="pop-up-title m-0 pt-2 color-dark-blue">Profile Picture</h1>
                                            </div>
                                            <div className="col-12 d-flex justify-content-center py-3">
                                                {
                                                    (formData?.picture || tempPicture) && tempPicture ?
                                                        <div className="image-crop-custom-containter">
                                                            <Cropper
                                                                image={tempPicture ? tempPicture : (profileClient?.picture ? profileClient?.full_path_picture : null)}
                                                                crop={crop}
                                                                accept={'jpg,jpeg'}
                                                                zoom={zoom}
                                                                maxZoom={10}
                                                                cropShape={'round'}
                                                                aspect={1 / 1}
                                                                onCropChange={(e) => setCrop(e)}
                                                                onCropComplete={onCropComplete}
                                                                onZoomChange={setZoom}
                                                                showGrid={true}
                                                            />
                                                        </div>
                                                        :
                                                        <img className="popup-profile-image" src={formData?.picture ? formData?.full_path_picture : generalData?.fixed_settings?.full_path_no_individual_picture} alt="profile" />
                                                }

                                            </div>

                                            <form>
                                                <div className="col-12 d-flex justify-content-center">
                                                    <input hidden accept="image/*" className="upload-comment-files" id='upload-comment-files' type="file" onChange={(e) => { e.preventDefault(); e.stopPropagation(); profilePictureHandler(e.target.files) }} />
                                                    <label htmlFor="upload-comment-files" >
                                                        <p className="popup-button my-auto mx-2">{profilePicture || profileClient?.picture ? 'Edit' : 'Add'}</p>
                                                    </label>
                                                    {
                                                        formData?.picture ?
                                                            <button onClick={(e) => removePicture(e)} type="button" className="popup-button my-auto mx-2">Remove</button>
                                                            :
                                                            null
                                                    }
                                                    {
                                                        tempPicture ?
                                                            <button onClick={(e) => showCroppedImage()} type="button" className="popup-button my-auto mx-2">Save</button>
                                                            :
                                                            null
                                                    }
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        null
                }

                {
                    //proxy popup
                    addProxy ?
                        <div id="errors-popup" className=" error-popup-api d-flex align-items-center justify-content-center px-3 fade-in">
                            <div className="pop-up-api-box">
                                <CloseIcon className="cursor-pointer" onClick={(e) => closeProxyPopup(e)} />
                                <div className="text-center ">
                                    <div className="containter-fluid">
                                        <div className="row">
                                            <div className="col-12">
                                                <h1 className="pop-up-title m-0 py-2 color-dark-blue">{openEditMenu?.id ? generalData?.website_titles['edit-proxy'] : generalData?.website_titles['add-proxy-title']}</h1>
                                            </div>
                                            <form onSubmit={submitProxy}>
                                                <div className="col-12 pt-2 pb-4">
                                                    <div className="row justify-content-start">
                                                        <div className="col-12 col-md-6 pt-2">
                                                            <input name="poa" className="add-user-inputs" placeholder={generalData?.website_titles['poa-title']} value={poa} onChange={(e) => setPoa(e.target.value)} />
                                                            {
                                                                errorsReturned?.poa ?
                                                                    <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text text-start">{errorsReturned?.poa}</h5>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                        <div className="col-12 col-md-6 pt-2">
                                                            <input name="public_notary" className="add-user-inputs" placeholder={generalData?.website_titles['public-notary-title']} value={publicNotary} onChange={(e) => setPublicNotary(e.target.value)} />
                                                            {
                                                                errorsReturned?.public_notary ?
                                                                    <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text text-start">{errorsReturned?.public_notary}</h5>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                        <div className="col-12 col-md-6 pt-2">
                                                            <input name="registration_number" className="add-user-inputs" placeholder={generalData?.website_titles['registration-number-title']} value={registrationNumber} onChange={(e) => setRegistrationNubmer(e.target.value)} />
                                                            {
                                                                errorsReturned?.registration_number ?
                                                                    <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text text-start">{errorsReturned?.registration_number}</h5>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                        <div className="col-12 col-md-6 pt-2">
                                                            <div className="postion-relative text-start justify-content-start w-100">
                                                                <DatePicker
                                                                    className="popup-date add-user-inputs"
                                                                    placeholderText={generalData?.website_titles['date-title']}
                                                                    value={proxyDate ? proxyDate : ''}
                                                                    showMonthDropdown
                                                                    showYearDropdown
                                                                    dropdownMode="select"
                                                                    selected={proxyDate}
                                                                    dateFormat="d MMMM yyyy"
                                                                    onChange={(date) => setProxyDate(date)}
                                                                    minDate={addYears(new Date(), -21)}
                                                                    maxDate={new Date()}
                                                                    locale={localStorage.getItem("BlpLanguage")}
                                                                />
                                                            </div>
                                                            {
                                                                errorsReturned?.date ?
                                                                    <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text">{errorsReturned?.date}</h5>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                        <div className="col-12 col-md-6 pt-2 position-relative">
                                                            <input hidden id='upload-document-file' type="file" onChange={(e) => { documentHandler(e.target.files) }} />
                                                            <label className="pop-upload-file position-relative" htmlFor="upload-document-file">{proxyDocumentLink ? generalData?.documentsSectionSettings['change-file-placeholder'] : generalData?.documentsSectionSettings['upload-file-placeholder']}</label>

                                                            <AttachIcon className="download-icon download-icon-nopadd" />
                                                            {
                                                                errorsReturned?.file ?
                                                                    <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text text-start">{errorsReturned?.file}</h5>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 d-flex justify-content-center">
                                                    <button type="submit" className="popup-button my-auto">{openEditMenu?.id ? generalData?.website_titles['edit-proxy-button'] : generalData?.website_titles['add-proxy-button']}</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        null
                }

            </div>
            <Api options={usersApiOptions} />
            <Api options={generalDataApiOptions} />
        </>
    )
}