import GlobalState from "../contexts/GlobalState";
import { useNavigate } from 'react-router-dom';
import { useState, useContext } from "react";
import { useParams } from 'react-router';
import { Link } from "react-router-dom";
import PinInput from 'react-pin-input';
import Api from "../components/Api";
import axios from "axios";
import { SignInWeb } from "../components/Icons";

export default function ConfirmEmail() {

    let { email } = useParams();

    const [generalDataApiOptions, setGeneralDataApiOptions] = useState({});
    const { generalDataGlobal, authUserGlobal } = useContext(GlobalState);
    const [errorsReturned, setErrorsReturned] = useState();
    const [resendSuccess, setResendSuccess] = useState(false);
    const [pinCode, setPinCode] = useState();
    const [generalData] = generalDataGlobal;
    const [, setAuthUser] = authUserGlobal;
    const history = useNavigate();


    //submit confirm email
    const submitConfirm = (e) => {
        setResendSuccess(false);
        e.preventDefault();

        setGeneralDataApiOptions({
            url: 'confirm-email',
            method: 'post',
            data: {
                email: email,
                code: pinCode,
            },
            callback: res => {
                if (res?.data?.link) {
                    localStorage.setItem('auth_token', '');
                    history(res?.data?.link);
                }
                if (res?.data?.user) {
                    localStorage.setItem('auth_token', res.data.token);
                    axios.defaults.headers.common['Authorization'] = res.data.token;
                    setAuthUser(res.data.user);
                    setTimeout(() => {
                        history('/');
                    }, 400);
                }
            },
            catch: err => {
                if (err) {
                    setErrorsReturned(err);
                }
            }
        })
    }

    //handle resend code request
    const resendCode = () => {
        setErrorsReturned();
        setResendSuccess(false);
        setGeneralDataApiOptions({
            url: 'send-email-code',
            method: 'post',
            data: {
                email: email,
            },
            callback: res => {
                setResendSuccess(true);
            }
        })
    }

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 col-md-6 full-screen-min light-bg-color d-flex align-items-center justify-content-center d-none d-md-flex">
                        <SignInWeb />
                    </div>
                    <div className="col-12 col-md-6 full-screen-min dark-blue-bg d-flex align-items-center justify-content-center py-4 py-md-0">
                        <div className="row w-100 justify-content-center">
                            <div className="col-12 d-flex d-md-none small-screens-auth-logo py-4 pt-md-0">
                                <SignInWeb />
                            </div>
                            <div className="col-10 col-lg-8 col-xl-7 text-center">
                                <h1 className="login-title white-text pb-4">{generalData?.authentication_pages_settings['verify-email-title']}</h1>
                                <h1 className="login-subtitle white-text pb-4">{generalData?.authentication_pages_settings['verify-email-subtitle']}</h1>
                                <form className="w-100" onSubmit={submitConfirm}>
                                    <div className="row w-100 m-0">
                                        <div className="col-12 pb-3 justify-content-center">
                                            <PinInput
                                                length={4}
                                                initialValue=""
                                                secret
                                                onChange={(value, index) => { }}
                                                type="numeric"
                                                inputMode="number"
                                                style={{ padding: '10px' }}
                                                inputStyle={{ borderColor: 'transparent' }}
                                                inputFocusStyle={{ borderColor: '#30D325' }}
                                                onComplete={(value, index) => { setPinCode(value) }}
                                                autoSelect={true}
                                                regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                                            />
                                            {
                                                errorsReturned?.code ?
                                                    <p className="m-0 errors-auth text-center">{errorsReturned?.code}</p>
                                                    :
                                                    null
                                            }
                                            {
                                                errorsReturned && !errorsReturned?.code ?
                                                    <p className="m-0 errors-auth text-center">{generalData?.authentication_pages_settings['verify-email-error']}</p>
                                                    :
                                                    null
                                            }
                                            {
                                                resendSuccess ?
                                                    <p className="m-0 success-auth text-center">{generalData?.authentication_pages_settings['verify-email-resend-success']}</p>
                                                    :
                                                    null
                                            }
                                        </div>
                                        <div>
                                        </div>
                                        <div className="col-12 d-flex justify-content-center">
                                            <p onClick={() => resendCode()} className="forgot-pass-text">{generalData?.authentication_pages_settings['verify-email-resend']}</p>
                                        </div>
                                        <div className="col-12 pt-4 d-flex justify-content-center">
                                            <button className="auth-button">{generalData?.authentication_pages_settings['verify-email-button']}</button>
                                        </div>
                                        <div className="col-12 pt-4 d-flex justify-content-center">
                                            <Link to="/sign-in" className="forgot-pass-text">{generalData?.authentication_pages_settings['verify-email-back']}</Link>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Api options={generalDataApiOptions} />
        </>

    )
}