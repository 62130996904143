import { useState, useContext } from "react";
import { Link } from "react-router-dom";
import GlobalState from "../contexts/GlobalState";
import {SignInMobile, SignInWeb} from "../components/Icons"
import Api from "../components/Api";

export default function ForgotPassword() {

    const [generalDataApiOptions, setGeneralDataApiOptions] = useState({});
    const { generalDataGlobal } = useContext(GlobalState);
    const [errorsReturned, setErrorsReturned] = useState({});
    const [generalData] = generalDataGlobal;
    const [email, setEmail] = useState('');
    const [success, setSuccess] = useState(false);

    //submit login request
    const submitLogin = (e) => {
        e.preventDefault();
        setErrorsReturned({});
        setSuccess(false);

        setGeneralDataApiOptions({
            url: 'forgot-password',
            method: 'post',
            data: {
                email: email,
            },
            callback: res => {
                setErrorsReturned({});
                setSuccess(true);
                setEmail('');
            },
            catch: err => {
                if (err) {
                    setErrorsReturned(err);
                }
            }
        })
    }

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 col-md-6 full-screen-min light-bg-color d-flex align-items-center justify-content-center d-none d-md-flex">
                        <SignInWeb />
                    </div>
                    <div className="col-12 col-md-6 full-screen-min dark-blue-bg d-flex align-items-center justify-content-center py-4 py-md-0">
                        <div className="row w-100 justify-content-center">
                            <div className="col-12 d-flex d-md-none small-screens-auth-logo py-4 pt-md-0">
                                <SignInMobile />
                            </div>
                            <div className="col-12 col-md-10 col-lg-9 col-xl-5 text-center">
                                <h1 className="login-title white-text pb-4">{generalData?.authentication_pages_settings['forgot-password-page-title']}</h1>
                                <h1 className="login-subtitle white-text pb-4">{generalData?.authentication_pages_settings['forgot-password-page-text']}</h1>
                                <form className="w-100" onSubmit={submitLogin}>
                                    <div className="row w-100 m-0">
                                        <div className="col-12 pb-3">
                                            <input name="email" className={errorsReturned?.email ? 'auth-input w-100 border-red' : 'auth-input w-100'} placeholder={generalData?.authentication_pages_settings['email-placeholder']} onChange={e => setEmail(e.target.value)} value={email} />
                                            {
                                                errorsReturned?.email ?
                                                    <p className="m-0 errors-auth">{errorsReturned.email}</p>
                                                    :
                                                    null
                                            }
                                            {
                                                errorsReturned && !errorsReturned?.email ?
                                                    <p className="m-0 errors-auth">{errorsReturned[0]}</p>
                                                    :
                                                    null
                                            }
                                            {
                                                success ?
                                                    <p className="m-0 success-auth">{generalData?.authentication_pages_settings['forgot-password-success']}</p>
                                                    :
                                                    null
                                            }
                                        </div>
                                        <div className="col-12 pt-4 d-flex justify-content-center">
                                            <button className="auth-button">{generalData?.authentication_pages_settings['forgot-password-button']}</button>
                                        </div>
                                        <div className="col-12 pt-3 d-flex justify-content-center">
                                            <Link to="/sign-in" className="forgot-pass-text">{generalData?.authentication_pages_settings['forgot-password-back-login']}</Link>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Api options={generalDataApiOptions} />
        </>

    )
}