import React, { useContext, useEffect, useState } from 'react'
import GlobalState from '../contexts/GlobalState'
import Layout from '../components/Layout';
import NotificationComponent from '../components/NotificationComponent';
import Api from '../components/Api';

export default function Notifications() {
    const {
        generalDataGlobal: [generalData]
    } = useContext(GlobalState);

    const [notifications, setNotifications] = useState([]);

    const [apiOptions, setApiOptions] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [lastPage, setLastPage] = useState(0);

    useEffect(() => {
        setApiOptions({
            url: '/get-user-notifications?page=' + currentPage,
            method: 'get',
            callback: (res) => {
                setNotifications(prevNotifications => [
                    ...prevNotifications,
                    ...res?.data?.data
                ]);
                setLastPage(res?.data?.last_page);
            }
        })
    }, [currentPage]);

    return (
        <Layout>

            <div className="container-fluid">
                <div className="row client-profile-height">
                    <div className='col'>
                        <h1 className='page-titles color-dark-blue pb-3 mb-0'>{generalData?.website_titles['notifications']}</h1>
                    </div>
                    <div className='col-lg-12  bg-white shadow scrollable-profile-height py-3' style={{ minHeight: `calc(100vh-220px) !important` }}>
                        <div className='w-100 position-relative'>
                            {
                                notifications?.length > 0 ?
                                    (
                                        notifications?.map((notification, i) => (
                                            <NotificationComponent
                                                key={i}
                                                className={(i + 1 !== notifications?.length) ? ' border-bottom pb-2 mb-2' : ''}
                                                notification={notification}
                                                notifications={notifications}
                                                setNotifications={setNotifications}
                                                index={i}
                                            />

                                        ))
                                    )

                                    : <div>{generalData?.website_titles['no-notifications']}</div>
                            }
                        </div>
                        {
                            currentPage < lastPage &&
                            <button onClick={() => setCurrentPage(currentPage + 1)} className='popup-button mx-auto my-3 w-auto h-auto px-3 py-2'>{generalData?.website_titles['load-more']}</button>
                        }
                    </div>
                </div>
            </div>
            <Api options={apiOptions} />
        </Layout>

    )
}
