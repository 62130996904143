import { useContext, useEffect, useState } from "react";
import Select from 'react-select';
import Api from "../components/Api";
import GlobalState from '../contexts/GlobalState';
import "react-datepicker/dist/react-datepicker.css";
import PhoneInput from "react-phone-number-input";
import 'react-phone-number-input/style.css';
import { Countries } from "../AvailableCountries";


import { registerLocale } from "react-datepicker";
import ar from 'date-fns/locale/ar';
import { getIndex } from '../Helpers';
import { XIcon } from "./Icons";
registerLocale('ar', ar)

export default function ClientPopup(props) {

    //get all client categories
    const clientCategories = () => {
        const types = generalData?.client_categories?.map(
            type => ({ value: type.id, label: type.title }))
        return types;
    }

    //get all legal types
    const legalTypes = () => {
        const types = generalData?.legal_types?.map(
            type => ({ value: type.id, label: type.title }))
        return types;
    }

    const { apiSuccessDataGlobal, addClientGlobal, currentClientGlobal, generalDataGlobal } = useContext(GlobalState);
    const [generalDataApiOptions, setGeneralDataApiOptions] = useState({});
    const [generalData] = generalDataGlobal;
    const [, setAddClient] = addClientGlobal;
    const [currentClient, setCurrentClient] = currentClientGlobal;
    const [, setApiSuccessData] = apiSuccessDataGlobal;


    const [name, setName] = useState('');
    const [category, setCategory] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');
    const [legalType, setLegalType] = useState('');

    const [errorsReturned, setErrorsReturned] = useState({});

    const { setAddPopup = () => { }, onSuccess, returnRecord=0 } = props

    //set states when current client changes
    useEffect(() => {
        if (currentClient && currentClient?.id) {
            setName(currentClient?.name);
            setEmail(currentClient?.email);
            if (currentClient?.legal_type_id) {
                setLegalType(legalTypes()[getIndex(currentClient?.legal_type_id, legalTypes(), 'value')]);
            }
            setPhoneNumber(currentClient?.main_phone_number);
            setCategory(currentClient?.client_category_id ? clientCategories()[getIndex(currentClient?.client_category_id, clientCategories(), 'value')] : '');
        }
    }, [currentClient]);// eslint-disable-line react-hooks/exhaustive-deps


    // custom styles for the select
    const customStylesPopup = {
        placeholder: (defaultStyles) => {
            return {
                ...defaultStyles,
                display: "flex",
                fontFamily: 'Poppins',
                fontWeight: '400',
                textTransform: "capitalize",
                fontSize: '14px',
                color: '#07407C',
            }
        },
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                color: '#07407C',
                fontFamily: 'Poppins',
                fontWeight: '400',
                backgroundColor: 'white',
                textTransform: "capitalize",
                margin: "auto",
                paddingRight: "10px",
                maxWidth: "100%",
                paddingLeft: "10px",
                paddingBottom: "0",
                paddingTop: "0",
            };
        },
        singleValue: base => ({
            ...base,
            color: "#00366A",
            display: "flex",
            fontSize: "14px",
            fontFamily: "Poppins",
            fontWeight: "400",
            textTransform: "capitalize",
        }),
        control: provided => ({
            ...provided,
            height: "40px",
            border: "none",
            backgroundColor: 'white',
            outline: "none",
            '&:hover': { borderColor: 'transparent' },
            textTransform: 'uppercase',
            width: "100%",
            borderRadius: "7px",
            fontFamily: 'Poppins',
            fontWeight: '400',
            boxShadow: "0px 3px 6px rgb(0 0 0 / 16%)",
        }),
        menu: (provided, state) => ({
            ...provided,
            borderRadius: "7px",
            textTransform: 'uppercase',
            border: "1px solid transparent",
        }),
        dropdownIndicator: base => ({
            ...base,
            color: "#00366A !important",
            // marginTop: "-18px !important"
        }),
        input: (base, state) => ({
            ...base,
            color: "#393939",
            fontSize: "16px",
            height: "100%",
            fontFamily: 'Poppins',
            textTransform: 'uppercase',
            fontWeight: '400',
            marginTop: "14px",
            '& input': {
                font: 'inherit',
            },
        })
    };

    //close add client and reset states
    const closeAddClient = (e) => {
        setAddClient(false);
        setCurrentClient({});
        setErrorsReturned({});
        setName('');
        setPhoneNumber('');
        setCategory('');
        setLegalType('');
        setEmail('');
        setAddPopup({});
    }


    //submit client to backend
    const submitClient = (e) => {
        e.preventDefault();

        setGeneralDataApiOptions({
            url: 'add-edit-client',
            method: 'post',
            data: {
                client_type_id: currentClient?.client_type_id,
                name: name ? name : '',
                email: email ? email : '',
                main_phone_number: phoneNumber ? phoneNumber : '',
                legal_type_id: legalType ? legalType?.value : '',
                client_category_id: category ? category?.value : '',
                client_id: currentClient?.id ? currentClient?.id : '',
                sort: props.sort ? props.sort : '',
                direction: props.sortDirection ? props.sortDirection : '',
                page: props.page,
                return_dashboard: (props?.returnDashboard && props?.returnDashboard === 1) ? 1 : 0,
                client_type: props.clientType,
                returnRecord: returnRecord,
                current_client: props?.currentClient ? props.currentClient : '',
                tasksType: props?.tasksType ?? '',
                full_url: window.location.href,
                return_client: (props?.returnClients && props?.returnClients === 1) ? 1 : 0,
            },
            callback: res => {
                if (onSuccess) {
                    onSuccess(res)
                } else {
                    setApiSuccessData(res.data);
                }
                closeAddClient();
                setAddPopup({});

            },
            catch: err => {
                if (err) {
                    setErrorsReturned(err);
                }
            }
        });
    }

    return (
        <>
            <div id="errors-popup" className=" error-popup-api d-flex align-items-center justify-content-center px-3 fade-in">
                <div className="pop-up-api-box">
                    <XIcon onClick={(e) => closeAddClient(e)} />
                    <div className="text-center ">
                        <div className="containter-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <h1 className="pop-up-title m-0 py-2 color-dark-blue">{currentClient?.id ? generalData?.contacts_settings['edit-client-title'].replace('client', props?.popupTitle) : generalData?.contacts_settings['add-client-title']?.replace('client', props?.popupTitle)}</h1>
                                </div>
                                <form onSubmit={submitClient}>
                                    <div className="col-12 pt-2 pb-4">
                                        <div className="row">
                                            <div className="col-12 col-md-6 pt-2">
                                                <input required name="name" value={name} onChange={(e) => setName(e.target.value)} className="add-user-inputs" placeholder={generalData?.contacts_settings['name-title']} />
                                                {
                                                    errorsReturned?.name ?
                                                        <h5 className="pt-2 m-0 d-flex flex-start text-start pop-errors-text">{errorsReturned?.name}</h5>
                                                        :
                                                        null
                                                }
                                            </div>
                                            <div className="col-12 col-md-6 pt-2">
                                                <div className="postion-relative text-start justify-content-start w-100">
                                                    <Select
                                                        classNamePrefix="popup-select"
                                                        className="popup-select w-100"
                                                        styles={customStylesPopup}
                                                        options={clientCategories()}
                                                        components={{
                                                            IndicatorSeparator: () => null
                                                        }}
                                                        required
                                                        placeholder={generalData?.contacts_settings['category-title']}
                                                        isSearchable={false}
                                                        value={category}
                                                        onChange={setCategory} />
                                                </div>
                                                {
                                                    errorsReturned?.client_category_id ?
                                                        <h5 className="pt-2 m-0 d-flex flex-start  text-start pop-errors-text">{errorsReturned?.client_category_id}</h5>
                                                        :
                                                        null
                                                }
                                            </div>
                                            {
                                                currentClient?.client_type_id && currentClient?.client_type_id === 1 ?
                                                    <div className="col-12 col-md-6 pt-2">
                                                        <div className="postion-relative text-start justify-content-start w-100">
                                                            <Select
                                                                classNamePrefix="popup-select"
                                                                className="popup-select w-100"
                                                                styles={customStylesPopup}
                                                                options={legalTypes()}
                                                                components={{
                                                                    IndicatorSeparator: () => null
                                                                }}
                                                                required
                                                                placeholder={generalData?.contacts_settings['legal-title']}
                                                                isSearchable={false}
                                                                value={legalType}
                                                                onChange={setLegalType} />
                                                        </div>
                                                        {
                                                            errorsReturned?.legal_type_id ?
                                                                <h5 className="pt-2 m-0 d-flex flex-start  text-start pop-errors-text">{errorsReturned?.legal_type_id}</h5>
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                    :
                                                    null
                                            }
                                            <div className="col-12 col-md-6 pt-2">
                                                <input name="email" value={email} onChange={(e) => setEmail(e.target.value)} className="add-user-inputs" placeholder={generalData?.contacts_settings['email-title']} />
                                                {
                                                    errorsReturned?.email ?
                                                        <h5 className="pt-2 m-0 d-flex flex-start  text-start pop-errors-text">{errorsReturned?.email}</h5>
                                                        :
                                                        null
                                                }
                                            </div>
                                            <div className="col-12 col-md-6 pt-2">
                                                <PhoneInput
                                                    countries={Countries}
                                                    name="main_phone_number"
                                                    value={phoneNumber}
                                                    onChange={setPhoneNumber}
                                                    className={`add-user-inputs`}
                                                    placeholder={generalData?.contacts_settings['phone-title']}
                                                    style={{ zIndex: 0 }}
                                                />
                                                {
                                                    errorsReturned?.main_phone_number ?
                                                        <h5 className="pt-2 m-0 d-flex flex-start text-start pop-errors-text">{errorsReturned?.main_phone_number}</h5>
                                                        :
                                                        null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 d-flex justify-content-center">
                                        <button type="submit" className="popup-button my-auto">{currentClient?.id ? 'Edit' : 'Add'}</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Api options={generalDataApiOptions} />
        </>
    )
}