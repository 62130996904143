import { useContext } from "react";
import GlobalState from "../contexts/GlobalState";

export default function RelationCard(props) {

    const { generalDataGlobal } = useContext(GlobalState);
    const [generalData,] = generalDataGlobal;

    return (
        <>
            <div className="row px-3 py-3">
                {
                    props?.user === 1 && props?.userData?.job_title_id ?
                        <div className="col-12 col-md-6 py-2">
                            <p className="profile-general-input-title">{generalData?.website_titles['work']}</p>
                            <h5 className="locked-inputs w-100 fs-14">
                                {props?.userData?.job_title?.title}
                            </h5>
                        </div>
                        :
                        (props?.client === 1 || props?.realtionTo === 1) && props?.userData?.job_title_id ?
                            <div className="col-12 col-md-6 py-2">
                                <p className="profile-general-input-title">{generalData?.website_titles['work']}</p>
                                <h5 className="locked-inputs w-100 fs-14">
                                    {props?.userData?.job_title?.title}
                                </h5>
                            </div>
                            :
                            null
                }
                {
                    props?.user === 1 && props?.userData?.mof_number ?
                        <div className="col-12 col-md-6 py-2">
                            <p className="profile-general-input-title">{generalData?.website_titles['registration-number-title']}</p>
                            <h5 className="locked-inputs w-100 fs-14">
                                {props?.userData?.mof_number}
                            </h5>
                        </div>
                        :
                        (props?.client === 1 || props.relationTo === 1) && props?.userData?.internal_ref ?
                            <div className="col-12 col-md-6 py-2">
                                <p className="profile-general-input-title">{generalData?.website_titles['internal-ref-title']}</p>
                                <h5 className="locked-inputs w-100 fs-14">
                                    {props?.userData?.internal_ref}
                                </h5>
                            </div>
                            :
                            null
                }
                {
                    (props?.user === 1 && props.userData?.main_phone_number) || (props?.client === 1 && props?.userData?.main_phone_number)
                        || (props?.realtionTo === 1 && props?.userData?.main_phone_number) ?
                        <div className="col-12 col-md-6 py-2">
                            <p className="profile-general-input-title">{generalData?.website_titles['phone-number']}</p>
                            <h5 className="locked-inputs w-100 fs-14">
                                {props?.userData?.main_phone_number}
                            </h5>
                        </div>
                        :
                        null
                }
                {
                    props.userData?.email ?
                        <div className="col-12 col-md-6 py-2">
                            <p className="profile-general-input-title">{generalData?.website_titles['email']}</p>
                            <h5 className="locked-inputs w-100 fs-14">
                                {props?.userData?.email}
                            </h5>
                        </div>
                        :
                        null
                }

                {
                    props?.litigationNumber &&
                    <div className="col-12 py-2">
                        <p className="profile-general-input-title">{generalData?.litigationFixedTitles['litigation-number']}</p>
                        <h5 className="locked-inputs w-100 fs-14">
                            {props?.litigationNumber}
                        </h5>
                    </div>

                }

                {
                    props?.litigationStage && 
                    <div className="col-12 py-2">
                        <p className="profile-general-input-title">{generalData?.litigationFixedTitles['stage']}</p>
                        <h5 className="locked-inputs w-100 fs-14">
                            {props?.litigationStage}
                        </h5>
                    </div>
                }

                {
                    props.description ?
                        <div className="col-12 py-2">
                            <p className="profile-general-input-title">{generalData?.website_titles['description']}</p>
                            <h5 className="locked-inputs w-100 fs-14">
                                {props?.description}
                            </h5>
                        </div>
                        :
                        null
                }

            </div>
        </>
    )
}