import { useContext, useEffect, useState } from 'react';
import EditInputTranslatable from '../components/EditInputTranslatable';
import GlobalState from '../contexts/GlobalState';

export default function CmsTable(props) {

    const { trigDeleteGlobal, currentInputGlobal, currentInputValueGlobal } = useContext(GlobalState);
    const [trigDelete,] = trigDeleteGlobal;
    const [currentInput, setCurrentInput] = currentInputGlobal;
    const [setCurrentInputValue] = currentInputValueGlobal;
    const [addedData, setAddedData] = useState([]);

    //add data once add more clicked
    const addToFormData = () => {
        let emptyObject = {};
        if (addedData.length === 0) {
            emptyObject = {
                id: 0,
                translations: [
                    { title: "" },
                    { title: "" }
                ]
            };
            setAddedData([emptyObject]);
        }
    }

    useEffect(() => {
        setAddedData([]);
    }, [props?.data]);// eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        if (trigDelete > 1 && currentInput === props.model) {
            setAddedData([]);
            setCurrentInput('');
        }
    }, [trigDelete]);// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <div className="cms-table-content position-relative pb-5">
                <div className="row pb-2">
                    <div className="col-12">
                        <div className="gray-background">
                            <div className="row m-0 py-3">
                                <div className="col-6">
                                    <h1 className="cms-data-table-title">{props.title_en}</h1>
                                </div>
                                <div className="col-6 text-end">
                                    <h1 className="cms-data-table-title-ar">{props.title_ar}</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row h-auto">
                    <div className="col-12">
                        {
                            props?.data?.length > 0 &&
                            props?.data?.map((singleData, index) => (
                                <EditInputTranslatable
                                    withSlugs={props?.withSlugs && props?.withSlugs === 1 ? 1 : 0}
                                    placeholderAr={props.placeholderAr}
                                    placeholderEn={props.placeholderEn}
                                    delete={props.delete}
                                    model={props?.model}
                                    data={singleData}
                                    key={index}
                                    additionalField={props?.additionalField || null}
                                />
                            ))

                        }
                        {
                            addedData?.length > 0 ?
                                addedData?.map((singleNew, index) => (
                                    <EditInputTranslatable
                                        withSlugs={props?.withSlugs && props?.withSlugs === 1 ? 1 : 0}
                                        placeholderAr={props.placeholderAr}
                                        placeholderEn={props.placeholderEn}
                                        delete={props.delete}
                                        model={props?.model}
                                        data={singleNew}
                                        key={index}
                                        additionalField={props?.additionalField || null}
                                    />
                                ))
                                :
                                null
                        }
                    </div>
                </div>
                {
                    props?.addNew === 1 && addedData?.length === 0 && !currentInput ?
                        <div className="row mb-3 pt-1 add-mew-cms-data">
                            <div className="col-12">
                                <div className="">
                                    <div className="row m-0">
                                        <div className="col-6">
                                            <div onClick={(e) => {
                                                addToFormData(e); setCurrentInput(props?.model); setCurrentInputValue({
                                                    id: 0,
                                                    translations: [
                                                        { title: "" },
                                                        { title: "" }
                                                    ]
                                                });
                                            }} className="cms-data-add-title">Add New</div>
                                        </div>
                                        <div className="col-6 text-end">
                                            <div onClick={(e) => {
                                                addToFormData(e); setCurrentInput(props?.model); setCurrentInputValue({
                                                    id: 0,
                                                    translations: [
                                                        { title: "" },
                                                        { title: "" }
                                                    ]
                                                });
                                            }} className="cms-data-add-title-ar">اضف</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        null
                }
            </div>
        </>
    )
}