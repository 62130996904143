import React from 'react'
import { useContext } from 'react'
import GlobalState from '../contexts/GlobalState'

export default function TaskHistoryComponent({ taskHistory, showCount = -1 }) {
    const {
        authUserGlobal: [authUser],
        generalDataGlobal: [generalData],
    } = useContext(GlobalState)
    return (
        <>
            <div className="col-12 pt-2">
                <div className="row g-0 p-0 py-4 costs-box-styling custom-shadow px-2">
                    {
                        taskHistory.length > 0 ?
                            taskHistory?.map((log, index) => (
                                ((showCount > -1 && index < showCount) || showCount === -1) &&
                                // (index < 5) &&
                                <div key={index} className="col-12">
                                    <div className="row pb-3 flex-wrap">
                                        <div className="col-auto position-relative">
                                            <div className="blue-dot-logs" />
                                            {
                                                taskHistory.length !== index + 1 && ((showCount > -1 && index + 1 < showCount) || showCount === -1) &&
                                                // index + 1 < 5 &&
                                                <div className="blue-dot-logs-lines" ></div>

                                            }
                                        </div>
                                        <div className="col ps-0">
                                            <div className="row flex-wrap">
                                                <div className="col">
                                                    {
                                                        <div className="d-flex align-items-md-center flex-md-row flex-column flex-wrap">
                                                            {
                                                                log?.event === 'created' ?
                                                                    <p className={`fs-12 m-0 fs-smaller me-md-1`}>
                                                                        {log?.user?.id === authUser?.id ? generalData?.taskHistoryTitles['you'] : log?.user?.name} {generalData?.taskHistoryTitles[log?.event]} {generalData?.taskHistoryTitles['this-task']} <span className='ms-3'>{log?.date_human}</span>
                                                                    </p>
                                                                    :
                                                                    log?.auditable_type === 'App/TaskAssignee'
                                                                        ?
                                                                        <p className={`fs-12 m-0 fs-smaller me-md-1`}>
                                                                            {`

                                                                                        ${log?.user?.id === authUser?.id ? generalData?.taskHistoryTitles['you'] : log?.user?.name}
                                                                                        ${generalData?.taskHistoryTitles[log?.event]} 
                                                                                        ${Object?.values(JSON?.parse(log?.new_values))}
                                                                                        
                                                                                    `}
                                                                            <span className='ms-3'>{log?.date_human}</span>
                                                                        </p>
                                                                        :

                                                                        <p className={`fs-12 m-0 fs-smaller me-md-1`}>
                                                                            {`
                                                                                        ${log?.user?.id === authUser?.id ? generalData?.taskHistoryTitles['you'] : log?.user?.name} 
                                                                                        ${generalData?.taskHistoryTitles[log?.event]} 
                                                                                        ${Object?.keys(JSON?.parse(log?.old_values))} 
                                                                                        ${generalData?.taskHistoryTitles['from']} 
                                                                                    `} {

                                                                                Object?.values(JSON?.parse(log?.old_values))[0]?.length > 20 ?
                                                                                    <>
                                                                                        <span className="position-relative user-image-in-table">
                                                                                            <span >
                                                                                                <>{`${Object?.values(JSON?.parse(log?.old_values))[0].substring(0, 17)}....`}</>
                                                                                            </span>
                                                                                            {

                                                                                                <>
                                                                                                    <div className="triangle-down"></div>
                                                                                                    <p className="hover-username" style={{ maxWidth: '100%', fontSize: '22px !important' }}>{Object?.values(JSON?.parse(log?.old_values))}</p>
                                                                                                </>

                                                                                            }
                                                                                        </span>
                                                                                    </>
                                                                                    :
                                                                                    Object?.values(JSON?.parse(log?.old_values))

                                                                            } {generalData?.taskHistoryTitles['to']}  {

                                                                                Object?.values(JSON?.parse(log?.new_values))[0]?.length > 20 ?
                                                                                    <>
                                                                                        <span className="position-relative user-image-in-table">
                                                                                            <span >
                                                                                                <>{`${Object?.values(JSON?.parse(log?.new_values))[0].substring(0, 17)}....`}</>
                                                                                            </span>
                                                                                            {

                                                                                                <>
                                                                                                    <div className="triangle-down"></div>
                                                                                                    <p className="hover-username" style={{ maxWidth: '100%', fontSize: '22px !important' }}>{Object?.values(JSON?.parse(log?.old_values))}</p>
                                                                                                </>

                                                                                            }
                                                                                        </span>
                                                                                    </>
                                                                                    :
                                                                                    Object?.values(JSON?.parse(log?.new_values))

                                                                            }
                                                                            <span className='ms-3'>{log?.date_human}</span>
                                                                            {/* {Object?.values(JSON?.parse(log?.new_values))} */}
                                                                        </p>
                                                            }
                                                        </div>


                                                    }


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                            :
                            <h5 className="py-3 locked-inputs w-100 m-0 p-0">{generalData?.website_titles['no-logs-yet']}</h5>
                    }
                </div>
            </div>
        </>
    )
}
