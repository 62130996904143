import { useContext, useState, useEffect } from "react";
import GlobalState from '../contexts/GlobalState';
import { useParams } from "react-router";
import React from 'react';
import Api from "../components/Api";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
import 'swiper/css';
import Pagination from "../components/Pagination";
import Layout from "../components/Layout";
import { Link } from "react-router-dom";
import { registerLocale } from "react-datepicker";
import ar from 'date-fns/locale/ar';
import { AttachIcon, BackIcon, CloseIcon, ExportIcon, GreenArrowLeft, MattersIcon, RedArrowRight, ThreeDotsMenu } from "../components/Icons";
import { getIndex, formatDate } from '../Helpers';

registerLocale('ar', ar);

export default function AllLitigationsFinances(props) {

    const {
        generalDataGlobal,
        authUserGlobal,
        profileClientGlobal,
        activeMatter
    } = useContext(GlobalState);
    const [generalDataApiOptions, setGeneralDataApiOptions] = useState({});
    const [expensesCall, setExpensesCall] = useState({});
    const [generalData] = generalDataGlobal;
    const [authUser,] = authUserGlobal;
    const [usersApiOptions,] = useState({});
    const [, setProfileClient] = profileClientGlobal;
    const { user_id, slug, matter_id } = useParams();
    const [errorsReturned, setErrorsReturned] = useState({});
    const [costType, setCostType] = useState(2);
    const [costCurrency, setCostCurrency] = useState('usd');

    //get all currencies
    const allCurrenciesFilters = () => {
        let curren = generalData?.currencies?.map(
            singleOption => ({ value: singleOption.id, label: singleOption.title, slug: singleOption.slug }))
        curren = [...curren, { value: 0, label: localStorage.getItem("BlpLanguage") === "en" ? 'ALL' : 'الكل', slug: 'all' }];
        return curren;
    }
    const [filterCurrency, setFilterCurrency] = useState(allCurrenciesFilters()[0]);
    const [expenses, setExpenses] = useState({
        'total': 'x',
    });

    const [wallet, setWallet] = useState({
        "total": 'x',
        "salary": 0,
        "other": 0,
        "bonuses": 0,
    });
    /* expenses start */
    const [addExpenses, setAddExpenses] = useState(false);
    const [editExpense, setEditExpense] = useState(null);
    const [currenEditExpense, setCurrenEditExpense] = useState('');
    const [addExpensesType, setAddExpensesType] = useState('');
    const [addExpensesAmount, setAddExpensesAmount] = useState('');
    const [addExpensesCurrency, setAddExpensesCurrency] = useState('');
    const [addExpensesDate, setAddExpensesDate] = useState(new Date());
    const [addExpensesNote, setAddExpensesNote] = useState('');
    const [addExpensesBillable, setAddExpensesBillable] = useState(0);
    const [document, setDocument] = useState();
    const [documentLink, setDocumentLink] = useState('');
    const [, setExpensesLinks] = useState([]);
    const [expensesCurrentPage, setExpensesCurrentPage] = useState(1);
    const [expensesLastPage, setExpensesLastPage] = useState(1);
    const [filterDateFrom, setFilteDateFrom] = useState();
    const [filterDateTo, setFilteDateTo] = useState();

    //get all expenses filters
    const allExpenseFilters = () => {
        const expFilter = generalData?.expense_filters?.map(
            singleOption => ({ value: singleOption.id, label: singleOption.title }))
        return expFilter;
    }

    const [currentFilterExpenses, setCurrentFilterExpenses] = useState(allExpenseFilters()[2]);
    const [changeExpenses, setChangeExpenses] = useState(0);
    const [changeWallet, setChangeWallet] = useState(0);
    const [customDatesPopup, setCustomDatesPopup] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    /* expenses end */

    //get all transactions types
    const allTransactionTypes = () => {
        const transactionType = generalData?.transaction_types?.map(
            singleOption => ({ value: singleOption.id, label: singleOption.title }))
        return transactionType;
    }

    /* transactions start */
    const [addTransaction, setAddTransaction] = useState(false);
    const [openEditTransaction, setOpenEditTransaction] = useState(null);
    const [transactionType, setTransactionType] = useState(allTransactionTypes()[getIndex(1, allTransactionTypes(), 'value')]);
    const [transactionCurrency, setTransactionCurrency] = useState('');
    const [transactionTo, setTransactionTo] = useState('');
    const [currenEditTransaction, setCurrenEditTransaction] = useState('');
    const [transactionAmount, setTransactionAmount] = useState('');
    const [addTransactionDate, setAddTransactionDate] = useState(new Date());
    const [addTransactionNote, setAddTransactionNote] = useState('');
    const [, setTransactionLinks] = useState([]);
    const [transactionCurrentPage, setTransactionCurrentPage] = useState(1);
    const [transactionLastPage, setTransactionLastPage] = useState(1);

    const [deletePopup, setDeletePopup] = useState(null);
    const [deleteType, setDeleteType] = useState(null);
    const [filterByUser, setFilterByUser] = useState(null);


    const [filterTransactionStatus, setFilterTransactionStatus] = useState('');
    const [filterExpensesStatus, setFilterExpensesStatus] = useState('');
    /* transactions start */

    //reset states
    useEffect(() => {
        setExpenses({
            'total': 'x',
        });
        setWallet({
            'total': 'x',
        });
        setExpensesCurrentPage(1);
        setCostType(2);
        setCurrentFilterExpenses(allExpenseFilters()[2]);
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    //handle date change
    const onChangeDate = (dates) => {
        const [start, end] = dates;
        setFilteDateFrom(start);
        setFilteDateTo(end);
    };

    //reset current pagingation pages
    useEffect(() => {
        setExpensesCurrentPage(1);
        setTransactionCurrentPage(1);
    }, [costType]);

    //handle filtering transacion types
    const filterTransactions = (e) => {
        if (e.value !== 4) {
            return true;
        }
    }

    //handle date filters
    useEffect(() => {
        if ((currentFilterExpenses?.value >= 1) && (currentFilterExpenses?.value <= 7)) {
            if (currentFilterExpenses?.value === 1) {
                setFilteDateFrom(new Date());
                setFilteDateTo(new Date());
            }
            setExpensesCurrentPage(1);
            setTransactionCurrentPage(1);
            if (slug === 'expenses') {
                setChangeExpenses(changeExpenses + 1);
            } else {
                setChangeWallet(changeWallet + 1);
            }
        }
        if (currentFilterExpenses?.value === 8) {
            setCustomDatesPopup(true);
        }
    }, [currentFilterExpenses]);// eslint-disable-line react-hooks/exhaustive-deps

    // document handler
    const documentHandler = (files) => {
        if (files.length > 0) {
            setDocument(files);
            let file = URL.createObjectURL(files[0])
            // setTempFile(file);
            setDocumentLink(file);
        }
    }

    //handle edit by setting states with related values
    function editClick(e, single) {
        e.preventDefault();
        e.stopPropagation();
        setAddTransaction(true);
        setCurrenEditTransaction(single);
        setTransactionType(allTransactionTypes()[getIndex(1, allTransactionTypes(), 'value')]);
        setTransactionAmount(single?.amount);
        setTransactionCurrency(single?.currency_id ? allCurrencies()[getIndex(single?.currency_id, allCurrencies(), 'value')] : '');
        setAddTransactionDate(single?.date ? new Date(single.date) : new Date());
        setAddTransactionNote(single?.note ? single?.note : '');
        setTransactionTo(single?.receiver_id ? allUsers()[getIndex(single?.receiver_id, allCurrencies(), 'value')] : '');
    }

    //export expenses excel
    const exportExpensesExcel = (e) => {
        e.preventDefault();
        setGeneralDataApiOptions({
            url: 'download-excel-expenses-client',
            method: 'post',
            data: {
                finance_page: 1,
                user_id: Number(user_id),
                currency: costCurrency,
                date_from: filterDateFrom ? formatDate(filterDateFrom) : formatDate(new Date()),
                date_to: filterDateTo ? formatDate(filterDateTo) : formatDate(new Date()),
                filter_type: currentFilterExpenses ? currentFilterExpenses?.value : '',
                search_value: searchValue,
                filter_user: filterByUser ? filterByUser?.value : '',
                expenses_status: filterExpensesStatus ? filterExpensesStatus.value : '',
            },
            callback: res => {
                clickUrl(e, res.data);
            },
        });
    };

    //export transactions to excel
    const exportExcel = (e) => {
        e.preventDefault();
        setGeneralDataApiOptions({
            url: 'download-excel-transactions-client',
            method: 'post',
            data: {
                finance_page: 1,
                user_id: Number(user_id),
                currency: costCurrency,
                date_from: filterDateFrom ? formatDate(filterDateFrom) : formatDate(new Date()),
                date_to: filterDateTo ? formatDate(filterDateTo) : formatDate(new Date()),
                filter_type: currentFilterExpenses ? currentFilterExpenses?.value : '',
                search_value: searchValue,
                filter_user: filterByUser ? filterByUser?.value : '',
                excel: 1,
            },
            callback: res => {
                clickUrl(e, res.data);
            },
        });
    };

    //open url _blank
    const clickUrl = (e, url) => {
        e.preventDefault();
        window.open(url, "_blank");
    };

    //delete handle both transactions and expenses
    const deleteAction = (e) => {
        e.preventDefault();
        if (deleteType === 'transaction') {
            deleteTransaction(e, deletePopup);
        }
        if (deleteType === 'expense') {
            deleteExpense(e, deletePopup);
        }
    }

    //delete transaction submit
    const deleteTransaction = (e, single) => {
        e.preventDefault();
        setGeneralDataApiOptions({
            url: 'delete-litigation-transaction',
            method: 'post',
            data: {
                finance_page: 1,
                transaction_id: single?.id,
                currency: transactionCurrency,
                user_id: Number(user_id),
                page: transactionCurrentPage,
                date_from: filterDateFrom ? formatDate(filterDateFrom) : formatDate(new Date()),
                date_to: filterDateTo ? formatDate(filterDateTo) : formatDate(new Date()),
                filter_type: currentFilterExpenses ? currentFilterExpenses?.value : '',
                search_value: searchValue,
                id: matter_id,
                filter_user: filterByUser ? filterByUser?.value : '',
                transaction_filter: filterTransactionStatus ? filterTransactionStatus?.value : '',
            },
            callback: res => {
                setWallet(res.data.wallet);
                setErrorsReturned({});
                closeAddTransaction(e);
                setOpenEditTransaction(null);
                setDeletePopup(null);
                setDeleteType(null);
            },
            catch: err => {
            }
        })
    }

    //submit delete expense
    const deleteExpense = (e, single) => {
        e.preventDefault();
        setGeneralDataApiOptions({
            url: 'delete-litigation-expense',
            method: 'post',
            data: {
                finance_page: 1,
                page: expensesCurrentPage ? expensesCurrentPage : 1,
                expense_id: single?.id,
                user_id: Number(user_id),
                currency: costCurrency,
                date_from: filterDateFrom ? formatDate(filterDateFrom) : formatDate(new Date()),
                date_to: filterDateTo ? formatDate(filterDateTo) : formatDate(new Date()),
                filter_type: currentFilterExpenses ? currentFilterExpenses?.value : '',
                search_value: searchValue,
                filter_user: filterByUser ? filterByUser?.value : '',
                expenses_status: filterExpensesStatus ? filterExpensesStatus.value : '',
                id: matter_id,
            },
            callback: res => {
                setExpenses(res.data.expenses);
                setErrorsReturned({});
                closeAddExpense(e);
                setDeletePopup(null);
                setDeleteType(null);
            },
            catch: err => {
            }
        })
    }

    //handle edit expense and set states with values
    const editExpenses = (e, single) => {
        e.preventDefault();
        e.stopPropagation();
        setAddExpenses(true);

        setCurrenEditExpense(single);
        setAddExpensesType(single?.expense_type_id ? allExpensesTypes()[getIndex(single?.expense_type_id, allExpensesTypes(), 'value')] : '');
        setAddExpensesAmount(single?.amount);
        setAddExpensesCurrency(single?.currency_id ? allCurrencies()[getIndex(single?.currency_id, allCurrencies(), 'value')] : '');
        setAddExpensesDate(single?.date ? new Date(single.date) : new Date());
        setAddExpensesNote(single?.note ? single?.note : '');
        setAddExpensesBillable(single?.billable === 1 ? 1 : 0);
        setDocumentLink(single?.file ? single?.full_path_file : '');
    }

    //close expenses popup and reset states
    function closeAddExpense(e) {
        e.preventDefault();
        setAddExpenses(false);
        setCurrenEditExpense('');
        setAddExpensesType('');
        setAddExpensesAmount('');
        setAddExpensesCurrency('');
        setAddExpensesDate(new Date());
        setEditExpense(null);
        setAddExpensesNote('');
        setAddExpensesBillable('');
        setDocument();
        setDocumentLink('');
        setErrorsReturned({});
    }

    //close transactions popup and reset states
    function closeAddTransaction(e) {
        e.preventDefault();
        setAddTransaction(false);
        setTransactionAmount('');
        setTransactionCurrency('');
        setAddTransactionDate(new Date());
        setCurrenEditTransaction(null);
        setAddTransactionNote('');
        setOpenEditTransaction(null);
        setErrorsReturned({});
    }

    //get all transaction statuses
    const transactionStatuses = () => {
        const transactions = generalData?.transaction_statuses?.map(
            single => ({ value: single.id, label: single.title }))
        return transactions;
    }

    //get all expenses statuses
    const ExpensesStatuses = () => {
        let expenses = [];
        expenses.push({ value: 'billable', label: generalData?.users_cost_settings['billable'] });
        expenses.push({ value: 'not_billable', label: generalData?.users_cost_settings['not-billable'] });
        return expenses;
    }

    // get all expenses types
    const allExpensesTypes = () => {
        const expensesTypes = generalData?.expense_types?.map(
            singleOption => ({ value: singleOption.id, label: singleOption.title }))
        return expensesTypes;
    }

    //get all currencies
    const allCurrencies = () => {
        const curren = generalData?.currencies?.map(
            singleOption => ({ value: singleOption.id, label: singleOption.title, slug: singleOption.slug }))
        return curren;
    }

    //get all users
    const allUsers = () => {
        const curren = generalData?.users?.map(
            singleOption => ({ value: singleOption.id, label: <div><img className="select-image me-1" src={singleOption?.picture ? singleOption?.full_path_picture : generalData?.fixed_settings?.full_path_no_profile_picture} alt={singleOption?.name} />{singleOption?.name}</div> }))
        return curren;
    }

    //function to add years to a date
    function addYears(date, years) {
        date.setFullYear(date.getFullYear() + years);
        return date;
    }

    // get expenses data
    useEffect(() => {
        if (slug === 'expenses') {
            setExpensesCall({
                url: 'get-litigation-expenses?page=' + expensesCurrentPage,
                method: 'post',
                data: {
                    finance_page: 1,
                    user_id: Number(user_id),
                    currency: costCurrency,
                    date_from: filterDateFrom ? formatDate(filterDateFrom) : formatDate(new Date()),
                    date_to: filterDateTo ? formatDate(filterDateTo) : formatDate(new Date()),
                    filter_type: currentFilterExpenses ? currentFilterExpenses?.value : '',
                    search_value: searchValue,
                    filter_user: filterByUser ? filterByUser?.value : '',
                    expenses_status: filterExpensesStatus ? filterExpensesStatus.value : '',
                    id: matter_id,
                },
                callback: res => {
                    setExpenses(res.data.expenses);
                    setProfileClient(res.data.client_user);
                },
            });
        }
    }, [filterExpensesStatus, slug, filterByUser, costCurrency, costType, generalData, expensesCurrentPage, changeExpenses]);// eslint-disable-line react-hooks/exhaustive-deps

    //set expenses pagination data
    useEffect(() => {
        if (expenses?.data?.links?.length > 0) {
            let links = [];
            expenses?.data?.links?.forEach((element, index) => {
                links.push(index);
            });
            setExpensesLinks(links);
            setExpensesCurrentPage(expenses?.data?.current_page);
            setExpensesLastPage(expenses?.data?.last_page);
        }
    }, [expenses]);

    //set wallet pagination data
    useEffect(() => {
        if (wallet?.data?.links?.length > 0) {
            let links = [];
            wallet?.data?.links?.forEach((element, index) => {
                links.push(index);
            });
            setTransactionLinks(links);
            setTransactionCurrentPage(wallet?.data?.current_page);
            setTransactionLastPage(wallet?.data?.last_page);
        }
    }, [wallet]);

    //submit expenses
    const submitExpenses = (e) => {
        e.preventDefault();
        e.stopPropagation();
        let fd = new FormData();
        if (currenEditExpense?.id) {
            fd.append('expense_id', currenEditExpense?.id);
        }

        fd.append('expense_type_id', addExpensesType ? addExpensesType?.value : '');
        fd.append('currency_id', addExpensesCurrency ? addExpensesCurrency?.value : '');
        fd.append('currency', costCurrency ? costCurrency : '');
        fd.append('user_id', Number(user_id));
        fd.append('amount', addExpensesAmount ? addExpensesAmount : '');
        fd.append('date', addExpensesDate ? formatDate(addExpensesDate) : '');
        fd.append('note', addExpensesNote ? addExpensesNote : '');
        fd.append('billable', addExpensesBillable ? addExpensesBillable : 0);
        fd.append('date_from', filterDateFrom ? formatDate(filterDateFrom) : formatDate(new Date()));
        fd.append('date_to', filterDateTo ? formatDate(filterDateTo) : formatDate(new Date()));
        fd.append('filter_type', currentFilterExpenses ? currentFilterExpenses?.value : '');
        fd.append('page', expensesCurrentPage ? expensesCurrentPage : 1);
        fd.append('finance_page', 1);
        fd.append('search_value', searchValue ? searchValue : '');

        if (document) {
            fd.append('file', document[0]);
        }
        fd.append('id', matter_id);

        setGeneralDataApiOptions({
            url: 'add-litigation-expense',
            method: 'post',
            data: fd,
            callback: res => {
                setExpenses(res.data.expenses);
                setErrorsReturned({});
                closeAddExpense(e);
            },
            catch: err => {
                if (err) {
                    setErrorsReturned(err);
                }
            }
        })
    };

    //get transactions data
    useEffect(() => {
        if (slug === 'transactions') {
            setExpensesCall({
                url: 'get-litigation-wallet?page=' + transactionCurrentPage,
                method: 'post',
                data: {
                    finance_page: 1,
                    currency: costCurrency,
                    user_id: Number(user_id),
                    date_from: filterDateFrom ? formatDate(filterDateFrom) : formatDate(new Date()),
                    date_to: filterDateTo ? formatDate(filterDateTo) : formatDate(new Date()),
                    filter_type: currentFilterExpenses ? currentFilterExpenses?.value : '',
                    search_value: searchValue,
                    filter_user: filterByUser ? filterByUser?.value : '',
                    transaction_filter: filterTransactionStatus ? filterTransactionStatus?.value : '',
                    id: matter_id,
                },
                callback: res => {
                    setWallet(res.data.wallet);
                    setProfileClient(res.data.client_user);
                },
                catch: err => {
                }
            });
        }
    }, [filterTransactionStatus, slug, filterByUser, costCurrency, costType, transactionCurrentPage, changeWallet]);// eslint-disable-line react-hooks/exhaustive-deps

    //submit transaction data
    const submitTransactions = (e) => {
        e.preventDefault();
        e.stopPropagation();
        let fd = new FormData();
        if (currenEditTransaction?.id) {
            fd.append('expense_id', currenEditTransaction?.id);
        }

        fd.append('transaction_type_id', transactionType ? transactionType?.value : '');
        fd.append('currency_id', transactionCurrency ? transactionCurrency?.value : '');
        fd.append('receiver_id', transactionTo ? transactionTo?.value : '');
        fd.append('currency', costCurrency ? costCurrency : '');
        fd.append('user_id', Number(user_id));
        fd.append('amount', transactionAmount ? transactionAmount : '');
        fd.append('date', addTransactionDate ? formatDate(addTransactionDate) : '');
        fd.append('note', addTransactionNote ? addTransactionNote : '');
        fd.append('page', transactionCurrentPage);
        fd.append('date_from', filterDateFrom ? formatDate(filterDateFrom) : formatDate(new Date()));
        fd.append('date_to', filterDateTo ? formatDate(filterDateTo) : formatDate(new Date()));
        fd.append('filter_type', currentFilterExpenses ? currentFilterExpenses?.value : '');
        fd.append('finance_page', 1);
        fd.append('search_value', searchValue ? searchValue : '');
        fd.append('filter_user', filterByUser ? filterByUser?.value : '');
        fd.append('transaction_filter', filterTransactionStatus ? filterTransactionStatus?.value : '');
        fd.append('id', matter_id);

        setGeneralDataApiOptions({
            url: 'add-litigation-transaction',
            method: 'post',
            data: fd,
            callback: res => {
                setWallet(res.data.wallet);
                setErrorsReturned({});
                closeAddTransaction(e);
                setOpenEditTransaction(null);
            },
            catch: err => {
                if (err) {
                    setErrorsReturned(err);
                }
            }
        })
    };

    //select custom styles
    const customStylesUsersPopup = {
        placeholder: (defaultStyles) => {
            return {
                ...defaultStyles,
                display: "flex",
                fontFamily: 'Poppins',
                fontWeight: '400',
                textTransform: "capitalize",
                fontSize: '14px',
                color: '#07407C',
            }
        },
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                color: '#07407C',
                fontFamily: 'Poppins',
                fontWeight: '400',
                backgroundColor: 'white',
                textTransform: "capitalize",
                margin: "auto",
                paddingRight: "10px",
                maxWidth: "100%",
                paddingLeft: "10px",
                paddingBottom: "2px",
                paddingTop: "2px",
            };
        },
        singleValue: base => ({
            ...base,
            color: "#00366A",
            display: "flex",
            fontSize: "14px",
            fontFamily: "Poppins",
            fontWeight: "400",
            textTransform: "capitalize",
        }),
        control: provided => ({
            ...provided,
            height: "40px",
            border: "none",
            backgroundColor: 'white',
            outline: "none",
            '&:hover': { borderColor: 'transparent' },
            textTransform: 'uppercase',
            width: "100%",
            borderRadius: "7px",
            fontFamily: 'Poppins',
            fontWeight: '400',
            boxShadow: "0px 3px 6px rgb(0 0 0 / 16%)",
        }),
        menu: (provided, state) => ({
            ...provided,
            borderRadius: "7px",
            textTransform: 'uppercase',
            border: "1px solid transparent",
        }),
        dropdownIndicator: base => ({
            ...base,
            color: "#00366A !important",
            // marginTop: "-18px !important"
        }),
        input: (base, state) => ({
            ...base,
            color: "#393939",
            fontSize: "16px",
            height: "100%",
            fontFamily: 'Poppins',
            textTransform: 'uppercase',
            fontWeight: '400',
            marginTop: "14px",
            '& input': {
                font: 'inherit',
            },
        })
    };

    // custom styles for the select
    const customStylesPopup = {
        placeholder: (defaultStyles) => {
            return {
                ...defaultStyles,
                display: "flex",
                fontFamily: 'Poppins',
                fontWeight: '400',
                textTransform: "capitalize",
                fontSize: '14px',
                color: '#07407C',
            }
        },
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                color: '#07407C',
                fontFamily: 'Poppins',
                fontWeight: '400',
                backgroundColor: 'white',
                textTransform: "capitalize",
                margin: "auto",
                paddingRight: "10px",
                maxWidth: "100%",
                paddingLeft: "10px",
                paddingBottom: "0",
                paddingTop: "0",
            };
        },
        singleValue: base => ({
            ...base,
            color: "#00366A",
            display: "flex",
            fontSize: "14px",
            fontFamily: "Poppins",
            fontWeight: "400",
            textTransform: "capitalize",
        }),
        control: provided => ({
            ...provided,
            height: "40px",
            border: "none",
            backgroundColor: 'white',
            outline: "none",
            '&:hover': { borderColor: 'transparent' },
            textTransform: 'uppercase',
            width: "100%",
            borderRadius: "7px",
            fontFamily: 'Poppins',
            fontWeight: '400',
            boxShadow: "0px 3px 6px rgb(0 0 0 / 16%)",
        }),
        menu: (provided, state) => ({
            ...provided,
            borderRadius: "7px",
            textTransform: 'uppercase',
            border: "1px solid transparent",
        }),
        dropdownIndicator: base => ({
            ...base,
            color: "#00366A !important",
            // marginTop: "-18px !important"
        }),
        input: (base, state) => ({
            ...base,
            color: "#393939",
            fontSize: "16px",
            height: "100%",
            fontFamily: 'Poppins',
            textTransform: 'uppercase',
            fontWeight: '400',
            marginTop: "14px",
            '& input': {
                font: 'inherit',
            },
        })
    };

    //set cost currency
    useEffect(() => {
        if (filterCurrency) {
            setCostCurrency(filterCurrency?.slug);
        }
    }, [filterCurrency])

    //custom select styles
    const customStylesPopupCurr = {
        placeholder: (defaultStyles) => {
            return {
                ...defaultStyles,
                display: "flex",
                fontFamily: 'Poppins',
                fontWeight: '400',
                textTransform: "capitalize",
                fontSize: '14px',
                color: '#07407C',
            }
        },
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                color: '#07407C',
                fontFamily: 'Poppins',
                fontWeight: '400',
                backgroundColor: 'white',
                textTransform: "capitalize",
                margin: "auto",
                paddingRight: "10px",
                maxWidth: "100%",
                paddingLeft: "10px",
                paddingBottom: "0",
                paddingTop: "0",
            };
        },
        singleValue: base => ({
            ...base,
            color: "#00366A",
            display: "flex",
            fontSize: "14px",
            fontFamily: "Poppins",
            fontWeight: "400",
            textTransform: "capitalize",
        }),
        control: provided => ({
            ...provided,
            height: "40px",
            border: "none",
            backgroundColor: 'transparent !important',
            outline: "none",
            '&:hover': { borderColor: 'transparent' },
            textTransform: 'uppercase',
            width: "100%",
            borderRadius: "7px",
            fontFamily: 'Poppins',
            fontWeight: '400',
            boxShadow: "0px 3px 6px rgb(0 0 0 / 16%)",
        }),
        menu: (provided, state) => ({
            ...provided,
            borderRadius: "7px",
            textTransform: 'uppercase',
            border: "1px solid transparent",
        }),
        dropdownIndicator: base => ({
            ...base,
            color: "#00366A !important",
            marginTop: "-18px !important"
        }),
        input: (base, state) => ({
            ...base,
            color: "#393939",
            fontSize: "16px",
            height: "100%",
            fontFamily: 'Poppins',
            textTransform: 'uppercase',
            fontWeight: '400',
            marginTop: "14px",
            '& input': {
                font: 'inherit',
            },
        })
    };

    //custom select styles
    const customStylesUsersFilter = {
        placeholder: (defaultStyles) => {
            return {
                ...defaultStyles,
                display: "flex",
                fontFamily: 'Poppins',
                fontWeight: '400',
                textTransform: "capitalize",
                fontSize: '14px',
                color: '#07407C',
            }
        },
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                color: '#00366A',
                fontFamily: 'Poppins',
                fontWeight: '500',
                backgroundColor: 'white',
                textTransform: "capitalize",
                margin: "auto",
                paddingRight: "4px",
                maxWidth: "100%",
                paddingLeft: "4px",
                paddingBottom: "2px",
                paddingTop: "2px",
            };
        },
        singleValue: base => ({
            ...base,
            color: "#00366A",
            display: "flex",
            fontSize: "14px",
            padding: '2px',
            fontFamily: "Poppins",
            fontWeight: "500",
            textTransform: "capitalize",
        }),
        control: provided => ({
            ...provided,
            height: "40px",
            border: "1px solid transparent",
            backgroundColor: 'transparent !important',
            outline: "none",
            '&:hover': { borderColor: 'transparent' },
            textTransform: 'uppercase',
            width: "100%",
            borderRadius: "7px",
            fontFamily: 'Poppins',
            fontWeight: '500',
            boxShadow: "none",
        }),
        menu: (provided, state) => ({
            ...provided,
            borderRadius: "7px",
            textTransform: 'uppercase',
            border: "1px solid white",
            marginTop: "-1px",
        }),
        dropdownIndicator: base => ({
            ...base,
            color: "#00366A !important",
            opacity: "0.4",
        }),
        input: (base, state) => ({
            ...base,
            color: "#00366A",
            fontSize: "14px",
            height: "100%",
            fontFamily: 'Poppins',
            textTransform: 'uppercase',
            fontWeight: '500',
            padding: "0",
            marginTop: "1px",
            '& input': {
                font: 'inherit',
            },
        })
    };

    //custom select styles
    const customStylesDateFilter = {
        placeholder: (defaultStyles) => {
            return {
                ...defaultStyles,
                display: "flex",
                fontFamily: 'Poppins',
                fontWeight: '400',
                textTransform: "capitalize",
                fontSize: '14px',
                color: '#07407C',
            }
        },
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                color: '#00366A',
                fontFamily: 'Poppins',
                fontWeight: '500',
                backgroundColor: 'white',
                textTransform: "capitalize",
                margin: "auto",
                paddingRight: "4px",
                maxWidth: "100%",
                paddingLeft: "4px",
                paddingBottom: "0",
                paddingTop: "0",
            };
        },
        singleValue: base => ({
            ...base,
            color: "#00366A",
            display: "flex",
            fontSize: "14px",
            fontFamily: "Poppins",
            fontWeight: "500",
            textTransform: "capitalize",
        }),
        control: provided => ({
            ...provided,
            height: "40px",
            border: "1px solid transparent",
            backgroundColor: 'transparent !important',
            outline: "none",
            '&:hover': { borderColor: 'transparent' },
            textTransform: 'uppercase',
            width: "100%",
            borderRadius: "7px",
            fontFamily: 'Poppins',
            fontWeight: '500',
            boxShadow: "none",
        }),
        menu: (provided, state) => ({
            ...provided,
            borderRadius: "7px",
            textTransform: 'uppercase',
            border: "1px solid white",
            marginTop: "-1px",
        }),
        dropdownIndicator: base => ({
            ...base,
            color: "#00366A !important",
            opacity: "0.4",
        }),
        input: (base, state) => ({
            ...base,
            color: "#00366A",
            fontSize: "14px",
            height: "100%",
            fontFamily: 'Poppins',
            textTransform: 'uppercase',
            fontWeight: '500',
            padding: "0",
            marginTop: "1px",
            '& input': {
                font: 'inherit',
            },
        })
    };

    //handle add transactions click
    const addTransactionsClick = (e) => {
        if (authUser?.id === Number(authUser?.id)) {
            setTransactionTo('');
        }
        setAddTransaction(true);
    }

    return (
        <>
            <Layout activePage="matters">
                {
                    authUser?.id ?
                        <>
                            <div className="row">
                                {
                                    slug === 'transactions' ?
                                        <>
                                            {
                                                wallet['total'] !== 'x' ?
                                                    <>
                                                        <div className="col-12">
                                                            <div className="row justify-content-between">
                                                                <div className="col-auto d-flex pe-0">
                                                                    <Link to={'/matters/litigations/' + matter_id + '/costs?transactions'} className="m-0 page-titles d-flex align-items-center">
                                                                        <BackIcon className='me-2 back-icon' /> {generalData?.website_titles['fees']}</Link>
                                                                    <img className="add-more-click cursor-pointer" onClick={(e) => addTransactionsClick(e)} src={'/assets/images/plus.svg'} alt="add" />
                                                                </div>
                                                                <div className="col-auto ps-0">
                                                                    <button className=" popup-button my-auto mx-0 mx-md-2 w-auto px-3" onClick={(e) => exportExcel(e)}>
                                                                        <ExportIcon className="export-button-svg me-1" /> {generalData?.fixed_titles['export']}</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 pt-2 pb-3">
                                                            <div className="row">
                                                                <div className="col-12">
                                                                    <div className="row">
                                                                        <div className="col-12">
                                                                            <div className="row">
                                                                                <div className="col-12">
                                                                                    <div className="costs-box-styling bg-white">
                                                                                        <div className="row py-2">
                                                                                            <div className="col-12 col-md-8 col-xl-4 pb-2 pt-1">
                                                                                                <input onKeyDown={(e) => (e.key === "Enter" ? slug === 'transactions' ? (setChangeWallet(changeWallet + 1), setTransactionCurrentPage(1)) : (setChangeExpenses(changeExpenses + 1), setExpensesCurrentPage(1)) : null)} name="search" className="table-search add-user-inputs" placeholder={generalData?.fixed_titles['search']} value={searchValue} onChange={(e) => setSearchValue(e.target.value)} />
                                                                                            </div>
                                                                                            <div className="col-12 col-xl-8 pb-2 pt-1">
                                                                                                <div className="row w-100 justify-content-end">
                                                                                                    <div style={{ zIndex: "999" }} className="col-auto filter-picker-expenses postion-relative">
                                                                                                        <Select
                                                                                                            classNamePrefix="filters-date-select"
                                                                                                            className="filters-date-select w-100"
                                                                                                            styles={customStylesDateFilter}
                                                                                                            options={allExpenseFilters()}
                                                                                                            components={{
                                                                                                                IndicatorSeparator: () => null
                                                                                                            }}
                                                                                                            isSearchable={false}
                                                                                                            value={currentFilterExpenses?.value !== 8 ? currentFilterExpenses : (filterDateFrom && filterDateTo ? { value: 8, label: '' + formatDate(filterDateFrom) + ' / ' + formatDate(filterDateTo) + '' } : currentFilterExpenses)}
                                                                                                            onChange={setCurrentFilterExpenses}
                                                                                                        />
                                                                                                    </div>
                                                                                                    <div style={{ zIndex: "998" }} className="col-auto filters-status filters-date-select postion-relative">
                                                                                                        <Select
                                                                                                            classNamePrefix="filter-picker-users"
                                                                                                            className="filters-status w-100"
                                                                                                            placeholder={generalData?.fixed_titles['status']}
                                                                                                            // styles={customStylesDateFilter}
                                                                                                            styles={customStylesDateFilter}
                                                                                                            options={transactionStatuses()}
                                                                                                            components={{
                                                                                                                IndicatorSeparator: () => null
                                                                                                            }}
                                                                                                            isSearchable={false}
                                                                                                            value={filterTransactionStatus}
                                                                                                            onChange={setFilterTransactionStatus}
                                                                                                        />
                                                                                                    </div>
                                                                                                    <div style={{ zIndex: "997" }} className="col-auto filters-date-select postion-relative">
                                                                                                        <Select
                                                                                                            classNamePrefix="filter-picker-users"
                                                                                                            className="filters-date-select filter-picker-users w-100"
                                                                                                            styles={customStylesUsersFilter}
                                                                                                            options={allUsers()}
                                                                                                            placeholder={generalData?.fixed_titles['user']}
                                                                                                            components={{
                                                                                                                IndicatorSeparator: () => null
                                                                                                            }}
                                                                                                            isSearchable={false}
                                                                                                            value={filterByUser}
                                                                                                            onChange={setFilterByUser}
                                                                                                        />
                                                                                                    </div>
                                                                                                    <div style={{ zIndex: "995" }} className="col-auto filters-status filter-picker-expenses postion-relative">
                                                                                                        <Select
                                                                                                            classNamePrefix="filters-date-select"
                                                                                                            className="filters-date-select w-100"
                                                                                                            styles={customStylesDateFilter}
                                                                                                            options={allCurrenciesFilters()}
                                                                                                            components={{
                                                                                                                IndicatorSeparator: () => null
                                                                                                            }}
                                                                                                            isSearchable={false}
                                                                                                            value={filterCurrency}
                                                                                                            onChange={setFilterCurrency}
                                                                                                        />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-12">
                                                                                            <div className="table-wrapper-settings">
                                                                                                <table className="users-table" width="100%">
                                                                                                    <thead>
                                                                                                        <tr>
                                                                                                            <td></td>
                                                                                                            <td className="table-sorting" style={{ minWidth: "170px" }}>{generalData?.users_cost_settings['transaction-type']}</td>
                                                                                                            <td className="table-sorting" style={{ minWidth: "170px" }}>{generalData?.users_cost_settings['date']}</td>
                                                                                                            <td className="table-sorting" style={{ minWidth: "170px" }}>{generalData?.users_cost_settings['amount']}</td>
                                                                                                            <td className="table-sorting" style={{ minWidth: "200px" }}>{generalData?.users_cost_settings['related-to']}</td>
                                                                                                            <td className="table-sorting" style={{ minWidth: "220px" }}>{generalData?.users_cost_settings['status']}</td>
                                                                                                            <td className="table-sorting" style={{ minWidth: "400px", width: "400px", maxWidth: "400px" }}>{generalData?.users_cost_settings['note']}</td>
                                                                                                        </tr>
                                                                                                    </thead>
                                                                                                    <tbody>
                                                                                                        {
                                                                                                            wallet?.data?.data?.length > 0 ?
                                                                                                                wallet?.data?.data?.map((singleTransaction, indexxx) => (
                                                                                                                    <tr key={indexxx} >
                                                                                                                        <td className="text-start p-0 pe-2 position-relative" >
                                                                                                                            {
                                                                                                                                authUser?.id === Number(singleTransaction?.user_id) ?
                                                                                                                                    <>
                                                                                                                                        <ThreeDotsMenu onClick={(e) => { e.stopPropagation(); openEditTransaction?.id === singleTransaction?.id ? setOpenEditTransaction(null) : setOpenEditTransaction(singleTransaction) }} />
                                                                                                                                        <div style={indexxx + 1 === wallet?.data?.data?.length ? { top: "-15px", zIndex: 201 } : { zIndex: 201 }} className={"settings-clickmenu first-td-buttons text-start " + (singleTransaction?.id === openEditTransaction?.id ? ' d-block' : '')}>
                                                                                                                                            <p style={{ zIndex: "99" }} onClick={(e) => { e.stopPropagation(); e.preventDefault(); editClick(e, singleTransaction) }} className="p-0 py-1 m-0 ms-2">{generalData?.settings_section_settings['edit-title']}</p>
                                                                                                                                            <p style={{ zIndex: "200" }} onClick={(e) => { e.stopPropagation(); e.preventDefault(); setDeletePopup(singleTransaction); setDeleteType('transaction') }} className="p-0 py-1 m-0 ms-2">{generalData?.settings_section_settings['delete-title']}</p>
                                                                                                                                        </div>
                                                                                                                                    </>
                                                                                                                                    :
                                                                                                                                    null
                                                                                                                            }

                                                                                                                        </td>
                                                                                                                        <td>{singleTransaction?.transaction_type?.title}</td>
                                                                                                                        <td>{singleTransaction?.date}</td>
                                                                                                                        <td>{singleTransaction?.amount} {singleTransaction?.currency?.title}</td>
                                                                                                                        <td>
                                                                                                                            {
                                                                                                                                singleTransaction?.litigation_id === matter_id ?
                                                                                                                                    <div style={{ display: "-webkit-box" }}>
                                                                                                                                        <div className="position-relative user-image-in-table">
                                                                                                                                            <img className="table-user-image" src={singleTransaction?.user?.picture ? singleTransaction?.user?.full_path_picture : generalData?.fixed_settings?.full_path_no_profile_picture} alt="user" />
                                                                                                                                            <div className="triangle-down"></div>
                                                                                                                                            <p className="hover-username">{singleTransaction?.user_id === authUser?.id ? generalData?.fixed_titles['me'] : singleTransaction?.user?.name}</p>
                                                                                                                                        </div>
                                                                                                                                        <RedArrowRight className="ms-2 me-2 flip-ar" />
                                                                                                                                        <div className="position-relative user-image-in-table">
                                                                                                                                            <img className="table-user-image" src={singleTransaction?.receiver?.picture ? singleTransaction?.receiver?.full_path_picture : generalData?.fixed_settings?.full_path_no_profile_picture} alt="user" />
                                                                                                                                            <div className="triangle-down"></div>
                                                                                                                                            <p className="hover-username">{singleTransaction?.receiver_id === authUser?.id ? generalData?.fixed_titles['me'] : singleTransaction?.receiver?.name}</p>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                    :
                                                                                                                                    <div style={{ display: "-webkit-box" }}>
                                                                                                                                        <div className="position-relative user-image-in-table">
                                                                                                                                            <img className="table-user-image" src={singleTransaction?.receiver?.picture ? singleTransaction?.receiver?.full_path_picture : generalData?.fixed_settings?.full_path_no_profile_picture} alt="user" />
                                                                                                                                            <div className="triangle-down"></div>
                                                                                                                                            <p className="hover-username">{singleTransaction?.receiver_id === authUser?.id ? generalData?.fixed_titles['me'] : singleTransaction?.receiver?.name}</p>
                                                                                                                                        </div>
                                                                                                                                        <GreenArrowLeft className="ms-2 me-2 flip-ar" />
                                                                                                                                        <div className="position-relative user-image-in-table">
                                                                                                                                            {/* <img className="table-user-image" src={profileClient?.picture ? profileClient?.full_path_picture : generalData?.fixed_settings?.full_path_no_profile_picture} alt="user" /> */}
                                                                                                                                            <MattersIcon className="active" />
                                                                                                                                            <div className="triangle-down"></div>
                                                                                                                                            <p className="hover-username">{activeMatter?.title}</p>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                            }
                                                                                                                        </td>
                                                                                                                        <td>
                                                                                                                            <div style={{ width: "fit-content" }} className="position-relative user-image-in-table">
                                                                                                                                <span style={singleTransaction?.transaction_stat_id === 1 ? { color: "#30D309" } : (singleTransaction?.transaction_stat_id === 2 ? { color: "#D30915" } : { color: "#FDC32D" })} >{singleTransaction?.transaction_stat?.title}</span>
                                                                                                                                {
                                                                                                                                    singleTransaction?.rejection_reason ?
                                                                                                                                        <>
                                                                                                                                            <div className="triangle-down"></div>
                                                                                                                                            <p className="hover-username">{singleTransaction?.rejection_reason}</p>
                                                                                                                                        </>
                                                                                                                                        :
                                                                                                                                        null
                                                                                                                                }
                                                                                                                            </div>
                                                                                                                        </td>
                                                                                                                        <td>{singleTransaction?.note}</td>
                                                                                                                    </tr>
                                                                                                                ))
                                                                                                                :
                                                                                                                null
                                                                                                        }
                                                                                                    </tbody>
                                                                                                </table>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-12 text-center py-2 d-flex justify-content-center">
                                                                                            <Pagination
                                                                                                className="pagination-bar"
                                                                                                currentPage={transactionCurrentPage}
                                                                                                totalCount={12 * transactionLastPage}
                                                                                                pageSize={12}
                                                                                                onPageChange={(e) => e <= transactionLastPage && e > 0 ? setTransactionCurrentPage(e) : null}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                    :
                                                    null
                                            }
                                        </>
                                        :
                                        <>

                                            {
                                                (expenses['total'] !== 'x' && slug === 'expenses') ?
                                                    <>
                                                        <div className="col-12">
                                                            <div className="row justify-content-between">
                                                                <div className="col-auto d-flex pe-0">
                                                                    <Link to={'/matters/litigations/' + matter_id + '/costs'} className="m-0 page-titles d-flex align-items-center">
                                                                        <BackIcon className='me-2 back-icon' /> {generalData?.users_cost_settings['expenses-title']}</Link>
                                                                    <img onClick={() => setAddExpenses(true)} className="add-more-click cursor-pointer" src="/assets/images/plus.svg" alt="add" />
                                                                </div>
                                                                <div className="col-auto ps-0">
                                                                    <button className=" popup-button my-auto mx-0 mx-md-2 w-auto px-3" onClick={(e) => exportExpensesExcel(e)}>
                                                                        <ExportIcon className="export-button-svg me-1" /> {generalData?.fixed_titles['export']}</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 pt-2 pb-3">
                                                            <div className="row">
                                                                <div className="col-12 pt-lg-0 height-100">
                                                                    <div className={'costs-box-styling bg-white'}>
                                                                        <div className="row py-2">
                                                                            <div className="col-12">
                                                                                <div className="row">

                                                                                    <div className="col-12 col-md-8 col-xl-4 pb-2 pt-1">
                                                                                        <input onKeyDown={(e) => (e.key === "Enter" ? slug === 'transactions' ? (setChangeWallet(changeWallet + 1), setTransactionCurrentPage(1)) : (setChangeExpenses(changeExpenses + 1), setExpensesCurrentPage(1)) : null)} name="search" className="table-search add-user-inputs" placeholder={generalData?.fixed_titles['search']} value={searchValue} onChange={(e) => setSearchValue(e.target.value)} />
                                                                                    </div>
                                                                                    <div className="col-12 col-xl-8 pb-2 pt-1">
                                                                                        <div className="row w-100 justify-content-end">
                                                                                            <div style={{ zIndex: "999" }} className="col-12 col-auto filter-picker-expenses postion-relative">
                                                                                                <Select
                                                                                                    classNamePrefix="filters-date-select"
                                                                                                    className="filters-date-select w-100"
                                                                                                    styles={customStylesDateFilter}
                                                                                                    options={allExpenseFilters()}
                                                                                                    components={{
                                                                                                        IndicatorSeparator: () => null
                                                                                                    }}
                                                                                                    isSearchable={false}
                                                                                                    value={currentFilterExpenses?.value !== 8 ? currentFilterExpenses : (filterDateFrom && filterDateTo ? { value: 8, label: '' + formatDate(filterDateFrom) + ' / ' + formatDate(filterDateTo) + '' } : currentFilterExpenses)}
                                                                                                    onChange={setCurrentFilterExpenses}
                                                                                                />
                                                                                            </div>
                                                                                            {
                                                                                                authUser?.user_role_id === 1 ?
                                                                                                    <div style={{ zIndex: "998" }} className="col-auto filters-date-select postion-relative">
                                                                                                        <Select
                                                                                                            classNamePrefix="filter-picker-users"
                                                                                                            className="filters-date-select filter-picker-users w-100"
                                                                                                            styles={customStylesUsersFilter}
                                                                                                            options={allUsers()}
                                                                                                            placeholder={generalData?.fixed_titles['user']}
                                                                                                            components={{
                                                                                                                IndicatorSeparator: () => null
                                                                                                            }}
                                                                                                            isSearchable={false}
                                                                                                            value={filterByUser}
                                                                                                            onChange={setFilterByUser}
                                                                                                        />
                                                                                                    </div>
                                                                                                    :
                                                                                                    null
                                                                                            }
                                                                                            <div style={{ zIndex: "997" }} className="col-auto  filter-status-expeneses  filters-date-select postion-relative">
                                                                                                <Select
                                                                                                    classNamePrefix="filter-picker-users"
                                                                                                    className="filter-status-expeneses w-100"
                                                                                                    placeholder={generalData?.fixed_titles['status']}
                                                                                                    styles={customStylesDateFilter}
                                                                                                    options={ExpensesStatuses()}
                                                                                                    components={{
                                                                                                        IndicatorSeparator: () => null
                                                                                                    }}
                                                                                                    isSearchable={false}
                                                                                                    value={filterExpensesStatus}
                                                                                                    onChange={setFilterExpensesStatus}
                                                                                                />
                                                                                            </div>
                                                                                            <div style={{ zIndex: "995" }} className="col-auto filter-picker-expenses postion-relative">
                                                                                                <Select
                                                                                                    classNamePrefix="filters-currencies-select"
                                                                                                    className="filters-date-select filters-currencies-select w-100"
                                                                                                    styles={customStylesDateFilter}
                                                                                                    options={allCurrenciesFilters()}
                                                                                                    components={{
                                                                                                        IndicatorSeparator: () => null
                                                                                                    }}
                                                                                                    isSearchable={false}
                                                                                                    value={filterCurrency}
                                                                                                    onChange={setFilterCurrency}
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-12 pt-1">
                                                                            <div className="table-wrapper-settings">
                                                                                <table className="users-table" width="100%">
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <td></td>
                                                                                            <td className="table-sorting" style={{ minWidth: "170px" }}>{generalData?.users_cost_settings['expenses-type']}</td>
                                                                                            <td className="table-sorting" style={{ minWidth: "170px" }}>{generalData?.users_cost_settings['related-to']}</td>
                                                                                            <td className="table-sorting" style={{ minWidth: "170px" }}>{generalData?.users_cost_settings['date']}</td>
                                                                                            <td className="table-sorting" style={{ minWidth: "200px" }}>{generalData?.users_cost_settings['amount']}</td>
                                                                                            <td className="table-sorting" style={{ minWidth: "190px" }}>{generalData?.users_cost_settings['added-by']}</td>
                                                                                            <td className="table-sorting" style={{ minWidth: "190px" }}>{generalData?.users_cost_settings['status']}</td>
                                                                                            <td className="table-sorting" style={{ minWidth: "190px" }}>{generalData?.users_cost_settings['document']}</td>
                                                                                            <td className="table-sorting" style={{ minWidth: "400px", width: "400px", maxWidth: "400px" }}>{generalData?.users_cost_settings['note']}</td>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {
                                                                                            expenses?.data?.data?.length > 0 ?
                                                                                                expenses?.data?.data?.map((singleExpense, index) => (
                                                                                                    <tr key={index} >
                                                                                                        <td className="text-end p-0 pe-2 position-relative" >
                                                                                                            {
                                                                                                                authUser?.id === singleExpense?.user_id ?
                                                                                                                    <>
                                                                                                                        <ThreeDotsMenu onClick={(e) => { e.stopPropagation(); editExpense?.id === singleExpense?.id ? setEditExpense(null) : setEditExpense(singleExpense) }} />
                                                                                                                        <div style={{ zIndex: '201' }} className={"settings-clickmenu first-td-buttons text-start " + (singleExpense?.id === editExpense?.id ? ' d-block' : '')}>
                                                                                                                            <p style={{ zIndex: "99" }} onClick={(e) => { e.stopPropagation(); e.preventDefault(); editExpenses(e, singleExpense) }} className="p-0 py-1 m-0 ms-2">{generalData?.settings_section_settings['edit-title']}</p>
                                                                                                                            <p style={{ zIndex: "200" }} onClick={(e) => { e.stopPropagation(); setDeletePopup(singleExpense); setDeleteType('expense') }} className="p-0 py-1 m-0 ms-2">{generalData?.settings_section_settings['delete-title']}</p>
                                                                                                                        </div>
                                                                                                                    </>
                                                                                                                    :
                                                                                                                    null
                                                                                                            }
                                                                                                        </td>
                                                                                                        <td>{singleExpense?.expense_type?.title}</td>
                                                                                                        <td></td>
                                                                                                        <td>{singleExpense?.date}</td>
                                                                                                        <td>{singleExpense?.amount} {singleExpense?.currency?.title}</td>
                                                                                                        <td><img className="table-user-image" src={singleExpense?.user?.picture ? singleExpense?.user?.full_path_picture : generalData?.fixed_settings?.full_path_no_profile_picture} alt="user" /></td>
                                                                                                        <td>{singleExpense?.billable === 1 ? generalData?.users_cost_settings['billable'] : generalData?.users_cost_settings['not-billable']}</td>
                                                                                                        <td>{singleExpense?.file ? <a className="text-underlined" href={singleExpense?.full_path_file} target="_blank" rel="noreferrer">{generalData?.users_cost_settings['document-name']} {index + 1}</a> : null}</td>
                                                                                                        <td>{singleExpense?.note}</td>
                                                                                                    </tr>
                                                                                                ))
                                                                                                :
                                                                                                null
                                                                                        }
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </div>
                                                                        {
                                                                            expensesLastPage > 1 ?
                                                                                <div className="col-12 text-center py-2 d-flex justify-content-center">
                                                                                    <Pagination
                                                                                        className="pagination-bar"
                                                                                        currentPage={expensesCurrentPage}
                                                                                        totalCount={12 * expensesLastPage}
                                                                                        pageSize={12}
                                                                                        onPageChange={(e) => setExpensesCurrentPage(e)}
                                                                                    />
                                                                                </div>
                                                                                :
                                                                                null
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                    :
                                                    null
                                            }
                                        </>
                                }
                            </div>
                        </>
                        :
                        null
                }
                {
                    //custom dates popup
                    customDatesPopup ?
                        <div id="errors-popup" className=" error-popup-api d-flex align-items-center justify-content-center px-3 fade-in">
                            <div className="pop-up-api-box">
                                <CloseIcon className="cursor-pointer" onClick={() => { setCurrentFilterExpenses(allExpenseFilters()[2]); setCustomDatesPopup(false); setFilteDateFrom(new Date()); setFilteDateTo(new Date()) }} />
                                <div className="text-center ">
                                    <div className="containter-fluid">
                                        <div className="row">
                                            <div className="col-12">
                                                <h1 className="pop-up-title m-0 py-2 color-dark-blue">Custom Dates</h1>
                                            </div>
                                            <div className="col-12 pt-2 pb-4">
                                                <div className="row justify-content-center">
                                                    <div className="col-12 col-md-6 pt-2">
                                                        <div className="postion-relative text-start justify-content-start w-100">
                                                            <DatePicker
                                                                className="popup-date add-user-inputs"
                                                                placeholderText={'Date'}
                                                                showMonthDropdown
                                                                showYearDropdown
                                                                dropdownMode="select"
                                                                dateFormat="dd/MM/yyyy"
                                                                onChange={onChangeDate}
                                                                startDate={filterDateFrom}
                                                                endDate={filterDateTo}
                                                                selectsRange
                                                                minDate={addYears(new Date(), -100)}
                                                                maxDate={new Date()}
                                                                locale={localStorage.getItem("BlpLanguage")}
                                                            />
                                                        </div>
                                                        {
                                                            errorsReturned?.date ?
                                                                <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text">{errorsReturned?.date}</h5>
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 d-flex justify-content-center">
                                                <button type="button" onClick={() => { setCustomDatesPopup(false); slug === 'transactions' ? setChangeWallet(changeWallet + 1) : setChangeExpenses(changeExpenses + 1) }} className="popup-button my-auto">Filter</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        null
                }

                {
                    //expenses popup
                    addExpenses ?
                        <div id="errors-popup" className=" error-popup-api d-flex align-items-center justify-content-center px-3 fade-in">
                            <div className="pop-up-api-box">
                                <CloseIcon className="cursor-pointer" onClick={(e) => closeAddExpense(e)} />
                                <div className="text-center ">
                                    <div className="containter-fluid">
                                        <div className="row">
                                            <div className="col-12">
                                                <h1 className="pop-up-title m-0 py-2 color-dark-blue">{currenEditExpense ? generalData?.users_cost_settings['edit-expenses-title'] : generalData?.users_cost_settings['add-expenses-title']}</h1>
                                            </div>
                                            <form onSubmit={submitExpenses}>
                                                <div className="col-12 pt-2 pb-4">
                                                    <div className="row">
                                                        <div className="col-12 col-md-6 pt-2">
                                                            <div className="postion-relative text-start justify-content-start w-100">
                                                                <Select
                                                                    classNamePrefix="popup-select"
                                                                    className="popup-select w-100"
                                                                    styles={customStylesPopup}
                                                                    options={allExpensesTypes()}
                                                                    components={{
                                                                        IndicatorSeparator: () => null
                                                                    }}
                                                                    placeholder={generalData?.users_cost_settings['expenses-type']}
                                                                    isSearchable={false}
                                                                    value={addExpensesType ? addExpensesType : null}
                                                                    onChange={setAddExpensesType} />
                                                            </div>
                                                            {
                                                                errorsReturned?.expense_type_id ?
                                                                    <h5 className="pt-2 m-0 d-flex flex-start text-start pop-errors-text">{errorsReturned?.expense_type_id}</h5>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                        <div className="col-12 col-md-6 pt-2">
                                                            <div className="postion-relative text-start justify-content-start w-100">
                                                                <Select
                                                                    classNamePrefix="popup-select"
                                                                    className="popup-select w-100"
                                                                    styles={customStylesPopup}
                                                                    options={allCurrencies()}
                                                                    components={{
                                                                        IndicatorSeparator: () => null
                                                                    }}
                                                                    placeholder={generalData?.users_cost_settings['currency']}
                                                                    isSearchable={false}
                                                                    value={addExpensesCurrency ? addExpensesCurrency : null}
                                                                    onChange={setAddExpensesCurrency} />
                                                            </div>
                                                            {
                                                                errorsReturned?.currency_id ?
                                                                    <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text">{errorsReturned?.currency_id}</h5>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                        <div className="col-12 col-md-6 pt-2">
                                                            <input name="amount" value={addExpensesAmount} onChange={(e) => setAddExpensesAmount(e.target.value)} className="add-user-inputs" placeholder={generalData?.users_cost_settings['amount']} />
                                                            {
                                                                errorsReturned?.amount ?
                                                                    <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text">{errorsReturned?.amount}</h5>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                        <div className="col-12 col-md-6 pt-2">
                                                            <div className="postion-relative text-start justify-content-start w-100">
                                                                <DatePicker
                                                                    className="popup-date add-user-inputs"
                                                                    placeholderText={'Date'}
                                                                    value={addExpensesDate ? addExpensesDate : ''}
                                                                    showMonthDropdown
                                                                    showYearDropdown
                                                                    dropdownMode="select"
                                                                    selected={addExpensesDate}
                                                                    dateFormat="d MMMM yyyy"
                                                                    onChange={(date) => setAddExpensesDate(date)}
                                                                    minDate={addYears(new Date(), -21)}
                                                                    maxDate={new Date()}
                                                                    locale={localStorage.getItem("BlpLanguage")}
                                                                />
                                                            </div>
                                                            {
                                                                errorsReturned?.date ?
                                                                    <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text">{errorsReturned?.date}</h5>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                        <div className="col-12 col-md-6 pt-2 position-relative">
                                                            <input hidden id='upload-document-file' type="file" onChange={(e) => { documentHandler(e.target.files) }} />
                                                            <label className="pop-upload-file position-relative" htmlFor="upload-document-file">{documentLink ? generalData?.documentsSectionSettings['change-file-placeholder'] : generalData?.documentsSectionSettings['upload-file-placeholder']}</label>

                                                            <AttachIcon className="download-icon download-svg-popup" />
                                                            {
                                                                errorsReturned?.file ?
                                                                    <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text text-start">{errorsReturned?.file}</h5>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                        <div className="col-12 pt-2">
                                                            <textarea type="text-area" name="note" className="add-user-inputs" placeholder={generalData?.users_cost_settings['note']} value={addExpensesNote} onChange={(e) => setAddExpensesNote(e.target.value)}></textarea>
                                                        </div>
                                                        <div className="col-12 pt-2 d-flex my-auto">
                                                            <div onClick={() => addExpensesBillable === 0 ? setAddExpensesBillable(1) : setAddExpensesBillable(0)} className={'my-auto billable-div ' + (addExpensesBillable ? 'active' : '')}>
                                                            </div>
                                                            <div onClick={() => addExpensesBillable === 0 ? setAddExpensesBillable(1) : setAddExpensesBillable(0)} className="ms-2 billable-popup">{generalData?.users_cost_settings['billable']}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 d-flex justify-content-center">
                                                    <button type="submit" className="popup-button my-auto">{currenEditExpense ? generalData?.users_cost_settings['edit-expenses-button'] : generalData?.users_cost_settings['add-expenses-button']}</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        null
                }
                {
                    //delete popup
                    deletePopup ?
                        <div id="errors-popup" className=" error-popup-api d-flex align-items-center justify-content-center px-3 fade-in">
                            <div className="pop-up-api-box">
                                <CloseIcon className="cursor-pointer" onClick={() => { setDeletePopup(null); setDeleteType(null); setOpenEditTransaction(null); setEditExpense(null); }} />
                                <div className="text-center ">
                                    <div className="containter-fluid">
                                        <div className="row d-flex justify-content-center align-items-center">
                                            <div className="col-9 col-sm-12 d-flex justify-content-center">
                                                <h1 className="pop-up-title m-0 pt-3 color-dark-blue">{generalData?.settings_section_settings['delete-popup-title']}</h1>
                                            </div>
                                            <div className="col-12 d-flex justify-content-center pt-4 pb-2">
                                                <button onClick={(e) => deleteAction(e)} type="button" className="popup-button my-auto mx-0 mx-md-2 w-auto px-3">{generalData?.settings_section_settings['delete-popup-first-button']}</button>
                                                <button onClick={() => { setDeletePopup(''); setDeleteType(''); setOpenEditTransaction(null); setEditExpense(null); }} type="button" className="auth-button border-dark-blue my-auto mx-2 background-transparent w-auto px-3">{generalData?.settings_section_settings['delete-popup-second-button']}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        null
                }

                {
                    //transactions popup
                    addTransaction ?
                        <div id="errors-popup" className=" error-popup-api d-flex align-items-center justify-content-center px-3 fade-in">
                            <div className="pop-up-api-box">
                                <CloseIcon className="cursor-pointer" onClick={(e) => closeAddTransaction(e)} />
                                <div className="text-center ">
                                    <div className="containter-fluid">
                                        <div className="row">
                                            <div className="col-12">
                                                <h1 className="pop-up-title m-0 py-2 color-dark-blue">{currenEditTransaction ? generalData?.users_cost_settings['edit-transaction-title'] : generalData?.users_cost_settings['add-transaction-title']}</h1>
                                            </div>
                                            <form onSubmit={submitTransactions}>
                                                <div className="col-12 pt-2 pb-4">
                                                    <div className="row">
                                                        <div className="col-12 col-md-6 pt-2">
                                                            <div className="postion-relative text-start justify-content-start w-100">
                                                                <Select
                                                                    classNamePrefix="popup-select"
                                                                    className="popup-select w-100"
                                                                    styles={customStylesPopup}
                                                                    options={allTransactionTypes()}
                                                                    components={{
                                                                        IndicatorSeparator: () => null
                                                                    }}
                                                                    placeholder={generalData?.users_cost_settings['transaction-type']}
                                                                    isSearchable={false}
                                                                    filterOption={(e) => filterTransactions(e)}
                                                                    value={transactionType}
                                                                    onChange={setTransactionType} />
                                                            </div>
                                                            {
                                                                errorsReturned?.transaction_type_id ?
                                                                    <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text">{errorsReturned?.transaction_type_id}</h5>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                        <div className="col-12 col-md-6 pt-2">
                                                            <div className="position-relative text-start justify-content-start w-100">
                                                                <input name="amount" value={transactionAmount} onChange={(e) => setTransactionAmount(e.target.value)} className="add-user-inputs" placeholder={generalData?.users_cost_settings['amount']} />
                                                                <Select
                                                                    classNamePrefix="curr-select"
                                                                    className="curr-select w-100"
                                                                    styles={customStylesPopupCurr}
                                                                    options={allCurrencies()}
                                                                    components={{
                                                                        IndicatorSeparator: () => null
                                                                    }}
                                                                    placeholder={generalData?.users_cost_settings['curr-transaction']}
                                                                    isSearchable={false}
                                                                    value={transactionCurrency ? transactionCurrency : null}
                                                                    onChange={setTransactionCurrency} />
                                                            </div>
                                                            {
                                                                errorsReturned?.currency_id ?
                                                                    <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text">{errorsReturned?.currency_id}</h5>
                                                                    :
                                                                    null
                                                            }
                                                            {
                                                                errorsReturned?.amount ?
                                                                    <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text">{errorsReturned?.amount}</h5>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                        <div className="col-12 col-md-6 pt-2">
                                                            <div className="postion-relative text-start justify-content-start w-100">
                                                                <DatePicker
                                                                    className="popup-date add-user-inputs"
                                                                    placeholderText={generalData?.users_cost_settings['date']}
                                                                    value={addTransactionDate ? addTransactionDate : ''}
                                                                    showMonthDropdown
                                                                    showYearDropdown
                                                                    dropdownMode="select"
                                                                    selected={addTransactionDate}
                                                                    dateFormat="d MMMM yyyy"
                                                                    onChange={(date) => setAddTransactionDate(date)}
                                                                    minDate={addYears(new Date(), -21)}
                                                                    maxDate={new Date()}
                                                                    locale={localStorage.getItem("BlpLanguage")}
                                                                />
                                                            </div>
                                                            {
                                                                errorsReturned?.date ?
                                                                    <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text">{errorsReturned?.date}</h5>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                        <div className="col-12 col-md-6 pt-2">
                                                            <div className="postion-relative text-start justify-content-start w-100">
                                                                <Select
                                                                    classNamePrefix="popup-select"
                                                                    className="popup-select w-100"
                                                                    styles={customStylesUsersPopup}
                                                                    options={allUsers()}
                                                                    components={{
                                                                        IndicatorSeparator: () => null
                                                                    }}
                                                                    placeholder={generalData?.users_cost_settings['transaction-to']}
                                                                    isSearchable={false}
                                                                    value={transactionTo ? transactionTo : null}
                                                                    onChange={setTransactionTo} />
                                                            </div>
                                                            {
                                                                errorsReturned?.receiver_id ?
                                                                    <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text">{errorsReturned?.receiver_id}</h5>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                        <div className="col-12 pt-2">
                                                            <textarea type="text-area" name="note" className="add-user-inputs" placeholder={generalData?.users_cost_settings['note']} value={addTransactionNote} onChange={(e) => setAddTransactionNote(e.target.value)}></textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 d-flex justify-content-center">
                                                    <button type="submit" className="popup-button my-auto">{currenEditTransaction ? generalData?.users_cost_settings['edit-transaction-button'] : generalData?.users_cost_settings['add-transaction-button']}</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        null
                }
                <Api options={usersApiOptions} />
                <Api options={generalDataApiOptions} />
                <Api options={expensesCall} />
            </Layout>
        </>
    )
}