import { useContext, useEffect, useState } from "react";
import Api from "../components/Api";
import Layout from "../components/Layout";
import { useLocation, useNavigate } from 'react-router-dom';
import Pagination from "../components/Pagination";
import UsersSelect from "../components/UsersSelect";
import StatusComponent from "../components/StatusComponent";
import GlobalState from "../contexts/GlobalState";
import PriorityComponent from "../components/PriorityComponent";

export default function Search() {

    const {
        generalDataGlobal: [generalData]
    } = useContext(GlobalState);

    const history = useNavigate();
    const [searchDataApiOptions, setSearchDataApiOptions] = useState({});
    const { search } = useLocation();
    const [activeEditUsers, setActiveEditUsers] = useState();
    const [activeSelect, setActiveSelect] = useState();
    const [currentPriority, setCurrentPriority] = useState();
    const [closeInput, setCloseInput] = useState(0);

    const [clients, setClients] = useState({});
    const [clientsLastPage, setClientsLastPage] = useState(1);
    const [clientsCurrentPage, setClientsCurrentPage] = useState(1);
    const [clientsPerPage, setClientsPerPage] = useState(1);
    const [clientsTotal, setClientsTotal] = useState(0);

    const [tasks, setTasks] = useState({});
    const [tasksLastPage, setTasksLastPage] = useState(1);
    const [tasksCurrentPage, setTasksCurrentPage] = useState(1);
    const [tasksPerPage, setTasksPerPage] = useState(1);
    const [tasksTotal, setTasksTotal] = useState(0);

    const [hearings, setHearings] = useState({});
    const [hearingsLastPage, setHearingsLastPage] = useState(1);
    const [hearingsCurrentPage, setHearingsCurrentPage] = useState(1);
    const [hearingsPerPage, setHearingsPerPage] = useState(1);
    const [hearingsTotal, setHearingsTotal] = useState(0);

    const [litigations, setLitigations] = useState({});
    const [litigationsLastPage, setLitigationsLastPage] = useState(1);
    const [litigationsCurrentPage, setLitigationsCurrentPage] = useState(1);
    const [litigationsPerPage, setLitigationsPerPage] = useState(1);
    const [litigationsTotal, setLitigationsTotal] = useState(0);

    const searchParams = new URLSearchParams(search);
    const searchValue = searchParams.get('search_value');

    useEffect(() => {
        setSearchDataApiOptions({
            url: 'search?search_value=' + searchValue + '&clients_page=' + clientsCurrentPage + '&tasks_page=' + tasksCurrentPage + '&hearings_page=' + hearingsCurrentPage + '&litigations_page=' + litigationsCurrentPage,
            method: 'get',
            callback: res => {
                setClients(res?.data?.clients?.data);
                setClientsCurrentPage(res?.data?.clients?.data?.length > 0 ? res?.data?.clients?.current_page : 1);
                setClientsLastPage(res?.data?.clients?.last_page ? res?.data?.clients?.last_page : 1);
                setClientsPerPage(res?.data?.clients?.per_page ? res?.data?.clients?.per_page : 1);
                setClientsTotal(res?.data?.clientsCount);

                setTasks(res?.data?.tasks?.data);
                setTasksCurrentPage(res?.data?.tasks?.data?.length > 0 ? res?.data?.tasks?.current_page : 1);
                setTasksLastPage(res?.data?.tasks?.last_page ? res?.data?.tasks?.last_page : 1);
                setTasksPerPage(res?.data?.tasks?.per_page ? res?.data?.tasks?.per_page : 1);
                setTasksTotal(res?.data?.tasksCount);


                setHearings(res?.data?.hearings?.data);
                setHearingsCurrentPage(res?.data?.hearings?.data?.length > 0 ? res?.data?.hearings?.current_page : 1);
                setHearingsLastPage(res?.data?.hearings?.last_page ? res?.data?.hearings?.last_page : 1);
                setHearingsPerPage(res?.data?.hearings?.per_page ? res?.data?.hearings?.per_page : 1);
                setHearingsTotal(res?.data?.hearingsCount);


                setLitigations(res?.data?.litigations?.data);
                setLitigationsCurrentPage(res?.data?.litigations?.data?.length > 0 ? res?.data?.litigations?.current_page : 1);
                setLitigationsLastPage(res?.data?.litigations?.last_page ? res?.data?.litigations?.last_page : 1);
                setLitigationsPerPage(res?.data?.litigations?.per_page ? res?.data?.litigations?.per_page : 1);
                setLitigationsTotal(res?.data?.litigationsCount);
            },
            catch: err => {
                if (err) {
                }
            }
        })
    }, [searchValue, clientsCurrentPage, tasksCurrentPage, hearingsCurrentPage, litigationsCurrentPage]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (activeSelect) {
            setActiveEditUsers('');
            setCurrentPriority('');
            setCloseInput('');
        }
    }, [activeSelect]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (activeEditUsers) {
            setActiveSelect('');
            setCurrentPriority('');
            setCloseInput('');
        }
    }, [activeEditUsers]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (closeInput !== '') {
            setActiveSelect('');
            setActiveEditUsers('');
        }
    }, [closeInput]);// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <Layout activePage="search">
                <div className="row">
                    <div className="col-12 col-sm-auto">
                        <h1 style={{ textTransform: "capitalize" }} className="page-titles color-dark-blue pb-3">{generalData?.website_titles['showing-search-results-for']} ({searchValue}) </h1>
                    </div>
                </div>
                <div className="row bg-white finances-box pb-4">
                    <div className="col-12">
                        <div className="row">
                            <div className="col-12 pb-3">
                                <h1 style={{ textTransform: "capitalize" }} className="d-flex page-titles color-dark-blue m-0 pt-4 align-items-center">{generalData?.fixed_titles['contacts']}
                                    <div className="ms-3 text-gray circle border-gray border d-flex align-items-center justify-content-center" style={{ width: '20px', height: '20px' }}>
                                        <p className="text-gray" style={{ fontSize: '10px' }}>{clientsTotal}</p>
                                    </div>
                                </h1>
                            </div>
                            <div className="col-12">
                                {
                                    clients?.length > 0 ?
                                        <div className="row w-100 m-0 light-gray-bg overflow-hidden">
                                            <div className="table-wrapper-search">
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <td>
                                                                {generalData?.website_titles['name']}
                                                            </td>
                                                            <td>
                                                                {generalData?.contacts_settings['category-title']}
                                                            </td>
                                                            <td>
                                                                {generalData?.website_titles['type']}
                                                            </td>
                                                            <td>
                                                                {generalData?.website_titles['email']}
                                                            </td>
                                                            <td>
                                                                {generalData?.website_titles['phone']}
                                                            </td>
                                                            <td>
                                                                <p>{generalData?.website_titles['internal-ref-title']}</p>
                                                            </td>
                                                        </tr>
                                                    </thead>
                                                    <tbody >
                                                        {
                                                            clients?.map((singleClient, clientIndex) => (
                                                                <tr key={clientIndex} onClick={() => history('/client/' + singleClient?.id + '/general-info')}>
                                                                    <td>
                                                                        <p>{singleClient?.name}</p>
                                                                    </td>
                                                                    <td>
                                                                        <p>{singleClient?.client_category?.title}</p>
                                                                    </td>
                                                                    <td>
                                                                        <p>{singleClient?.client_type?.title}</p>
                                                                    </td>
                                                                    <td>
                                                                        <p>{singleClient?.email}</p>
                                                                    </td>
                                                                    <td>
                                                                        <p>{singleClient?.main_phone_number}</p>
                                                                    </td>
                                                                    <td>
                                                                        <p>{singleClient?.internal_ref ?? '-'}</p>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="col-12">
                                                {
                                                    clientsLastPage > 1 ?
                                                        <div className="col-12 text-center pb-3 d-flex justify-content-center">
                                                            <Pagination
                                                                className="pagination-bar cursor-pointer"
                                                                currentPage={clientsCurrentPage}
                                                                totalCount={clientsPerPage * clientsLastPage}
                                                                pageSize={clientsPerPage}
                                                                onPageChange={(e) => setClientsCurrentPage(e)}
                                                            />
                                                        </div>
                                                        :
                                                        null
                                                }
                                            </div>
                                        </div>
                                        :
                                        <div className="col-12">
                                            <p>{generalData?.website_titles['no-clients-found']}</p>
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-12 pt-4">
                        <div className="row">
                            <div className="col-12 pb-3">
                                <h1 style={{ textTransform: "capitalize" }} className="d-flex page-titles color-dark-blue m-0 pt-4 align-items-center">{generalData?.fixed_titles['tasks']}
                                    <div className="ms-3 text-gray circle border-gray border d-flex align-items-center justify-content-center" style={{ width: '20px', height: '20px' }}>
                                        <p className="text-gray" style={{ fontSize: '10px' }}>{tasksTotal}</p>
                                    </div>
                                </h1>
                            </div>
                            <div className="col-12">
                                {
                                    tasks?.length > 0 ?
                                        <div className="row w-100 m-0 light-gray-bg overflow-hidden">
                                            <div className="table-wrapper-search">
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <td>
                                                                {generalData?.website_titles['name']}
                                                            </td>
                                                            <td>
                                                                {generalData?.tasksFixedTitles['priority']}
                                                            </td>
                                                            <td>
                                                                {generalData?.website_titles['number']}
                                                            </td>
                                                            <td>
                                                                {generalData?.tasksFixedTitles['assignees']}
                                                            </td>
                                                            <td>
                                                                {generalData?.website_titles['status']}
                                                            </td>
                                                            <td>
                                                                <p>{generalData?.website_titles['date']}</p>
                                                            </td>
                                                        </tr>
                                                    </thead>
                                                    <tbody >
                                                        {
                                                            tasks?.map((singleTask, taskIndex) => (
                                                                <tr key={taskIndex} onClick={() => history('/tasks/' + singleTask?.id + '/general-info')}>
                                                                    <td>
                                                                        <p>{singleTask?.name}</p>
                                                                    </td>
                                                                    <td width="100px" onClick={(e) => { setCurrentPriority('task_id' + singleTask?.id); setActiveSelect(''); e.preventDefault(); e.stopPropagation() }}>
                                                                        <PriorityComponent
                                                                            key={'task-priority' + singleTask?.id}
                                                                            className={`p-0 mx-n3`}
                                                                            selectedPriority={singleTask?.priority}
                                                                            priorities={generalData?.tasksPriorities}
                                                                            api={'edit-task-priority'}
                                                                            target={singleTask.id}
                                                                            targetName={'task_id'}
                                                                            showFlagOnly={1}
                                                                            hideShadow={1}
                                                                            currentPriority={currentPriority}
                                                                            activeTasksType={singleTask?.event_type}
                                                                            editable={1}
                                                                            showToolTip={true}
                                                                            searchPage={1}
                                                                            closeInput={closeInput}
                                                                            setCloseInput={setCloseInput}
                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        <p>{'TA' + singleTask?.id}</p>
                                                                    </td>
                                                                    <td onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}>
                                                                        <div className="w-100">
                                                                            <UsersSelect
                                                                                key={'task-user' + singleTask?.id}
                                                                                url={'/add-edit-task-assignees'}
                                                                                activeEditUsers={activeEditUsers}
                                                                                setActiveEditUsers={setActiveEditUsers}
                                                                                data={singleTask}
                                                                                users={singleTask?.assignees}
                                                                                model={'Task'}
                                                                                type={'tasks'}
                                                                                clientId={''}
                                                                                className="litigation-user-select m-0"
                                                                                // onChange={handleAssigneesChange}
                                                                                // page={currentPage}
                                                                                return={'index'}
                                                                                searchPage={1}
                                                                            />
                                                                        </div>
                                                                    </td>
                                                                    <td className="" onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}>
                                                                        <StatusComponent
                                                                            key={'task-status' + singleTask?.id}
                                                                            api={'edit-task-status'}
                                                                            selectedStatus={singleTask?.status}
                                                                            statuses={generalData?.tasksStatuses}
                                                                            index={taskIndex}
                                                                            type="tasks"
                                                                            target={singleTask?.id}
                                                                            targetName={'task_id'}
                                                                            activeSelect={activeSelect}
                                                                            setActiveSelect={setActiveSelect}
                                                                            searchPage={1}
                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        <p>{singleTask?.date}</p>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="col-12">
                                                {
                                                    tasksLastPage > 1 ?
                                                        <div className="col-12 text-center pb-3 d-flex justify-content-center">
                                                            <Pagination
                                                                className="pagination-bar cursor-pointer"
                                                                currentPage={tasksCurrentPage}
                                                                totalCount={tasksPerPage * tasksLastPage}
                                                                pageSize={tasksPerPage}
                                                                onPageChange={(e) => setTasksCurrentPage(e)}
                                                            />
                                                        </div>
                                                        :
                                                        null
                                                }
                                            </div>
                                        </div>
                                        :
                                        <div className="col-12">
                                            <p>{generalData?.website_titles['no-tasks-found']}</p>
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-12 pt-4">
                        <div className="row">
                            <div className="col-12 pb-3">
                                <h1 style={{ textTransform: "capitalize" }} className="d-flex page-titles color-dark-blue m-0 pt-4 align-items-center">{generalData?.website_titles['hearings']}
                                    <div className="ms-3 text-gray circle border-gray border d-flex align-items-center justify-content-center" style={{ width: '20px', height: '20px' }}>
                                        <p className="text-gray" style={{ fontSize: '10px' }}>{hearingsTotal}</p>
                                    </div>
                                </h1>
                            </div>
                            <div className="col-12">
                                {
                                    hearings?.length > 0 ?
                                        <div className="row w-100 m-0 light-gray-bg overflow-hidden">
                                            <div className="table-wrapper-search">
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <td>
                                                                {generalData?.website_titles['name']}
                                                            </td>
                                                            <td>
                                                                {generalData?.tasksFixedTitles['assignees']}
                                                            </td>
                                                            <td>
                                                                {generalData?.website_titles['type']}
                                                            </td>
                                                            <td>
                                                                <p>{generalData?.website_titles['date']}</p>
                                                            </td>
                                                        </tr>
                                                    </thead>
                                                    <tbody >
                                                        {
                                                            hearings?.map((singleHearing, hearingIndex) => (
                                                                <tr key={hearingIndex} onClick={() => history('/matters/litigations/' + singleHearing?.litigation?.id + '/general-info')}>
                                                                    <td>
                                                                        <p>{singleHearing?.name}</p>
                                                                    </td>
                                                                    <td onClick={(e) => { e.preventDefault(); e.stopPropagation() }}>
                                                                        <div className="w-100" >
                                                                            <UsersSelect
                                                                                url={'/add-edit-assignees'}
                                                                                activeEditUsers={activeEditUsers}
                                                                                setActiveEditUsers={setActiveEditUsers}
                                                                                data={singleHearing}
                                                                                users={singleHearing?.assignees}
                                                                                model={'Hearing'}
                                                                                clientId={''}
                                                                                className="litigation-user-select m-0"
                                                                                page={hearingsCurrentPage}
                                                                                type={'hearing'}
                                                                                searchPage={1}
                                                                            />
                                                                        </div>
                                                                    </td>
                                                                    <td>{singleHearing?.hearing_type?.title ?? '-'}</td>
                                                                    <td>{singleHearing?.date}</td>
                                                                </tr>
                                                            ))
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="col-12">
                                                {
                                                    hearingsLastPage > 1 ?
                                                        <div className="col-12 text-center pb-3 d-flex justify-content-center">
                                                            <Pagination
                                                                className="pagination-bar cursor-pointer"
                                                                currentPage={hearingsCurrentPage}
                                                                totalCount={hearingsPerPage * hearingsLastPage}
                                                                pageSize={hearingsPerPage}
                                                                onPageChange={(e) => setHearingsCurrentPage(e)}
                                                            />
                                                        </div>
                                                        :
                                                        null
                                                }
                                            </div>
                                        </div>
                                        :
                                        <div className="col-12">
                                            <p>{generalData?.website_titles['no-hearings-found']}</p>
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-12 pt-4">
                        <div className="row">
                            <div className="col-12 pb-3">
                                <h1 style={{ textTransform: "capitalize" }} className="d-flex page-titles color-dark-blue m-0 pt-4 align-items-center">{generalData?.website_titles['litigations']}
                                    <div className="ms-3 text-gray circle border-gray border d-flex align-items-center justify-content-center" style={{ width: '20px', height: '20px' }}>
                                        <p className="text-gray" style={{ fontSize: '10px' }}>{litigationsTotal}</p>
                                    </div>
                                </h1>
                            </div>
                            <div className="col-12">
                                {
                                    litigations?.length > 0 ?
                                        <div className="row w-100 m-0 light-gray-bg overflow-hidden">
                                            <div className="table-wrapper-search">
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <td>
                                                                {generalData?.website_titles['name']}
                                                            </td>
                                                            <td>
                                                                {generalData?.tasksFixedTitles['priority']}
                                                            </td>
                                                            <td>
                                                                {generalData?.website_titles['number']}
                                                            </td>
                                                            <td>
                                                                <p>{generalData?.tasksFixedTitles['assignees']}</p>
                                                            </td>
                                                            <td>
                                                                <p>{generalData?.website_titles['status']}</p>
                                                            </td>
                                                            <td>
                                                                <p>{generalData?.matterSectionsSettings['litigation-subject']}</p>
                                                            </td>
                                                        </tr>
                                                    </thead>
                                                    <tbody >
                                                        {
                                                            litigations?.map((singleLitigation, litigationIndex) => (
                                                                <tr key={litigationIndex} onClick={() => history('/matters/litigations/' + singleLitigation?.id + '/general-info')}>
                                                                    <td>
                                                                        <p>{singleLitigation?.title}</p>
                                                                    </td>
                                                                    <td width="100px" onClick={(e) => { setCurrentPriority('litigation-id' + singleLitigation?.id); setActiveSelect(''); e.preventDefault(); e.stopPropagation() }}>
                                                                        <PriorityComponent
                                                                            key={'litigation-id' + singleLitigation?.id}
                                                                            className={`p-0 mx-n3`}
                                                                            selectedPriority={singleLitigation?.priority}
                                                                            priorities={generalData?.priorities}
                                                                            api={'edit-litigation-priority'}
                                                                            target={singleLitigation.id}
                                                                            targetName={'litigation_id'}
                                                                            showFlagOnly={1}
                                                                            hideShadow={1}
                                                                            currentPriority={currentPriority}
                                                                            activeTasksType={singleLitigation?.event_type}
                                                                            editable={1}
                                                                            showToolTip={true}
                                                                            searchPage={1}
                                                                            closeInput={closeInput}
                                                                            setCloseInput={setCloseInput}
                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        <p>{singleLitigation?.number}</p>
                                                                    </td>
                                                                    <td onClick={(e) => { e.stopPropagation(); e.preventDefault(); }}>
                                                                        <div className="w-100">
                                                                            <UsersSelect
                                                                                url={'/add-edit-assignees'}
                                                                                activeEditUsers={activeEditUsers}
                                                                                setActiveEditUsers={setActiveEditUsers}
                                                                                data={singleLitigation}
                                                                                users={singleLitigation?.assignees}
                                                                                model={'Litigation'}
                                                                                type={'litigations'}
                                                                                clientId={''}
                                                                                className="litigation-user-select m-0"
                                                                                return={'index'}
                                                                                searchPage={1}
                                                                            />
                                                                        </div>
                                                                    </td>
                                                                    <td className="" onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}>
                                                                        <StatusComponent
                                                                            api={'edit-litigation-status'}
                                                                            selectedStatus={singleLitigation?.status}
                                                                            statuses={generalData?.statuses}
                                                                            index={litigationIndex}
                                                                            target={singleLitigation.id}
                                                                            targetName={'litigation_id'}
                                                                            activeSelect={activeSelect}
                                                                            setActiveSelect={setActiveSelect}
                                                                            searchPage={1}
                                                                        />
                                                                    </td>
                                                                    <td>{singleLitigation?.subject}</td>
                                                                </tr>
                                                            ))
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="col-12">
                                                {
                                                    litigationsLastPage > 1 ?
                                                        <div className="col-12 text-center pb-3 d-flex justify-content-center">
                                                            <Pagination
                                                                className="pagination-bar cursor-pointer"
                                                                currentPage={litigationsCurrentPage}
                                                                totalCount={litigationsPerPage * litigationsLastPage}
                                                                pageSize={litigationsPerPage}
                                                                onPageChange={(e) => setLitigationsCurrentPage(e)}
                                                            />
                                                        </div>
                                                        :
                                                        null
                                                }
                                            </div>
                                        </div>
                                        :
                                        <div className="col-12">
                                            <p>{generalData?.website_titles['no-litigations-found']}</p>
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </Layout >
            <Api options={searchDataApiOptions} />
        </>
    )
}