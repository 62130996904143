import 'react-calendar/dist/Calendar.css';
import React, { useContext, useState } from 'react';
import GlobalState from '../contexts/GlobalState';
import "react-datepicker/dist/react-datepicker.css";
import Api from './Api';
import { AttachIcon, CloseIcon } from './Icons';
import RelationsSelect from './RelationsSelect';
import Select from 'react-select';
import Draggable from 'react-draggable';
import DatePicker from "react-datepicker";
import { addYears, formatDate, getOptions } from '../Helpers';

import { registerLocale } from "react-datepicker";
import ar from 'date-fns/locale/ar';
registerLocale('ar', ar)

export default function ShareHoldersManageComponent(props) {

    const { profileClientGlobal, generalDataGlobal } = useContext(GlobalState);
    const [generalDataApiOptions, setGeneralDataApiOptions] = useState({});
    const [profileClient, setProfileClient] = profileClientGlobal;
    const [generalData,] = generalDataGlobal;
    const [errorsReturned, setErrorsReturned] = useState({});
    const [comment, setComment] = useState('');
    const [numberOfShares, setNumberOfShares] = useState('');
    const [percentage, setPercentage] = useState('');
    const [selectedShareType, setSelectedShareType] = useState(getOptions(generalData.share_types, 'slug', 'id', 'title')[0]);
    const [selectedShareClass, setSelectedShareClass] = useState('');
    const [registrationDate, setRegistrationDate] = useState(new Date());

    const { setIsShareholdersPopupOpen } = props;
    const [selectedRelation, setSelectedRelation] = useState('');
    const [clientType, ] = useState(2);
    const [confirmationPopup, setConfirmationPopup] = useState(false);

    const [operationDocument, setOperationDocument] = useState();
    const [operationDocumentLink, setOperationDocumentLink] = useState();
    const [operationFileName, setOperationFileName] = useState('');

    // custom styles for the select
    const customStylesPopup = {
        placeholder: (defaultStyles) => {
            return {
                ...defaultStyles,
                display: "flex",
                fontFamily: 'Poppins',
                fontWeight: '400',
                textTransform: "capitalize",
                fontSize: '14px',
                color: '#07407C',
            }
        },
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                color: '#07407C',
                fontFamily: 'Poppins',
                fontWeight: '400',
                backgroundColor: 'white',
                textTransform: "capitalize",
                margin: "auto",
                paddingRight: "10px",
                maxWidth: "100%",
                paddingLeft: "10px",
                paddingBottom: "0",
                paddingTop: "0",
            };
        },
        singleValue: base => ({
            ...base,
            color: "#00366A",
            display: "flex",
            fontSize: "14px",
            fontFamily: "Poppins",
            fontWeight: "400",
            textTransform: "capitalize",
        }),
        control: provided => ({
            ...provided,
            height: "40px",
            border: "none",
            backgroundColor: 'white',
            outline: "none",
            '&:hover': { borderColor: 'transparent' },
            textTransform: 'uppercase',
            width: "100%",
            borderRadius: "7px",
            fontFamily: 'Poppins',
            fontWeight: '400',
            boxShadow: "0px 3px 6px rgb(0 0 0 / 16%)",
        }),
        menu: (provided, state) => ({
            ...provided,
            borderRadius: "7px",
            textTransform: 'uppercase',
            border: "1px solid transparent",
        }),
        dropdownIndicator: base => ({
            ...base,
            color: "#00366A !important",
        }),
        input: (base, state) => ({
            ...base,
            color: "#00366A",
            fontSize: "16px",
            height: "100%",
            fontFamily: 'Poppins',
            textTransform: 'uppercase',
            fontWeight: '400',
            minWidth: '40px !important'
        })
    };

    const operationDocumentHandler = (files) => {
        if (files.length > 0) {
            setOperationDocument(files);
            let file = URL.createObjectURL(files[0]);
            setOperationDocumentLink(file);
        }
    }

    // validate shareholder add request 
    const validateShareholderRequest = (e) => {
        e.preventDefault();
        e.stopPropagation();
        let fd = new FormData();
        fd.append('validation', true);
        fd.append('to_id', selectedRelation ? selectedRelation?.value : '');
        fd.append('number_of_shares', numberOfShares);
        fd.append('percentage', percentage);
        fd.append('comment', comment ? comment : '');
        fd.append('user_id', profileClient?.id);
        fd.append('company_id', profileClient?.id);
        fd.append('share_type_id', selectedShareType ? selectedShareType?.value : getOptions(generalData.share_types, 'slug', 'id', 'title')[0]?.value)
        fd.append('share_class_id', selectedShareClass ? selectedShareClass?.value : '')


        fd.append('force', 1);
        fd.append('parent_folder_id', '');
        fd.append('files_type_id', 5);
        fd.append('main_directory', 2);
        fd.append('folder_name', 'Shares Operations');
        fd.append('from_home', 1);

        fd.append('operation_file_name', operationFileName);


        if (operationDocument) {
            fd.append('operation_document', operationDocument[0]);
        }

        setGeneralDataApiOptions({
            url: 'add-edit-shareholders',
            method: 'post',
            data: fd,
            callback: res => {
                setConfirmationPopup(true);
            },
            catch: err => {
                setErrorsReturned(err);
            }
        });
    }

    //submit entity
    const submitShareholderForm = (e) => {

        e.preventDefault();
        e.stopPropagation();
        let fd = new FormData();

        fd.append('to_id', selectedRelation ? selectedRelation?.value : '');
        fd.append('number_of_shares', numberOfShares);
        fd.append('percentage', percentage);
        fd.append('comment', comment ? comment : '');
        fd.append('user_id', profileClient?.id);
        fd.append('company_id', profileClient?.id);
        fd.append('share_type_id', selectedShareType ? selectedShareType?.value : getOptions(generalData.share_types, 'slug', 'id', 'title')[0]?.value)
        fd.append('share_class_id', selectedShareClass ? selectedShareClass?.value : '')
        fd.append('registration_date', registrationDate ? formatDate(registrationDate) : '');


        fd.append('force', 1);
        fd.append('parent_folder_id', '');
        fd.append('files_type_id', 5);
        fd.append('main_directory', 2);
        fd.append('folder_name', 'Shares Operations');
        fd.append('from_home', 1);

        fd.append('operation_file_name', operationFileName);


        if (operationDocument) {
            fd.append('operation_document', operationDocument[0]);
        }

        setGeneralDataApiOptions({
            url: 'add-edit-shareholders',
            method: 'post',
            data: fd,
            callback: res => {
                setIsShareholdersPopupOpen(false);
                setProfileClient(res.data);
            },
            catch: err => {
                setErrorsReturned(err);
            }
        });
    }

    const handleNoOfSharesChange = (e) => {
        setNumberOfShares(e.target.value)
        setPercentage(e.target.value ? `${e.target.value * 100 / profileClient.total_issued_shares}` : '')
    }

    const handlePercentageChange = (e) => {
        e.preventDefault();
    }

    return (
        <>
            <div id="errors-popup" className=" error-popup-api d-flex align-items-center justify-content-center px-3 fade-in">
                {
                    <Draggable handle='.drag-handle' bounds={'parent'}>
                        <div className={`${confirmationPopup ? 'd-none' : ''} pop-up-api-box operation-popup overflow-visible`}>
                            <CloseIcon className="cursor-pointer" onClick={(e) => setIsShareholdersPopupOpen(false)} />
                            <div className="text-center ">
                                <div className="containter-fluid">
                                    <div className="row">
                                        <div className="col-12 drag-handle cursor-grab">
                                            <h1 className="pop-up-title m-0 py-2 color-dark-blue">{generalData?.website_titles['add'] + ' ' + generalData?.website_titles.shareholders}</h1>
                                        </div>
                                        <form onSubmit={validateShareholderRequest}>
                                            <div className="col-12 pt-2 pb-4">
                                                <div className="row">
                                                    <div className="col-12 col-md-6 pt-2">
                                                        <div className="position-relative text-start justify-content-start w-100">
                                                            <RelationsSelect
                                                                selectedRelation={selectedRelation}
                                                                setSelectedRelation={setSelectedRelation}
                                                                profile={profileClient}
                                                                defaultSelected={clientType ? (clientType === 1 ? 'company' : (clientType === 2 ? 'individual' : 'ngo')) : 'individual'}
                                                                excludeMatters

                                                            />
                                                            {
                                                                errorsReturned?.to_id ?
                                                                    <h5 className="pt-2 m-0 d-flex flex-start text-start pop-errors-text">{errorsReturned?.to_id}</h5>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-6 pt-2">
                                                        <div className="postion-relative text-start justify-content-start w-100">
                                                            <input name="no_of_shares" className="add-user-inputs" type='text' placeholder={generalData?.shareholdersFixedTitles['no-of-shares']} value={numberOfShares} onChange={handleNoOfSharesChange} required />
                                                        </div>
                                                        {
                                                            errorsReturned?.number_of_shares ?
                                                                <h5 className="pt-2 m-0 d-flex flex-start text-start pop-errors-text">{errorsReturned?.number_of_shares}</h5>
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                    <div className="col-12 col-md-6 pt-2">
                                                        <div className="position-relative text-start justify-content-start w-100">
                                                            <input disabled name="percentage" type='percentage' className="add-user-inputs" placeholder={generalData?.shareholdersFixedTitles['percentage']} value={percentage} onChange={handlePercentageChange} />
                                                            <span className='position-y-center end-0 me-3'>%</span>
                                                        </div>
                                                        {
                                                            errorsReturned?.percentage ?
                                                                <h5 className="pt-2 m-0 d-flex flex-start text-start pop-errors-text">{errorsReturned?.percentage}</h5>
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                    <div className="col-12 col-md-6 pt-2" >
                                                        <div className="position-relative text-start justify-content-start w-100" >
                                                            <Select
                                                                classNamePrefix="popup-select"
                                                                className="popup-select w-100"
                                                                styles={customStylesPopup}
                                                                options={getOptions(generalData.share_types, 'slug', 'id', 'title')}
                                                                components={{
                                                                    IndicatorSeparator: () => null
                                                                }}
                                                                placeholder={generalData?.website_titles['share-type']}
                                                                value={selectedShareType ? selectedShareType : getOptions(generalData.share_types, 'slug', 'id', 'title')[0]}
                                                                isSearchable={false}
                                                                onChange={setSelectedShareType}
                                                            />

                                                            {
                                                                errorsReturned?.share_type_id ?
                                                                    <h5 className="pt-2 m-0 d-flex flex-start text-start pop-errors-text">{errorsReturned?.share_type_id}</h5>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-6 pt-2" >
                                                        <div className="position-relative text-start justify-content-start w-100" >
                                                            <Select
                                                                classNamePrefix="popup-select"
                                                                className="popup-select w-100"
                                                                styles={customStylesPopup}
                                                                options={getOptions(generalData.share_class, 'slug', 'id', 'title')}
                                                                components={{
                                                                    IndicatorSeparator: () => null
                                                                }}
                                                                placeholder={generalData?.website_titles['share-class']}
                                                                value={selectedShareClass ? selectedShareClass : ''}
                                                                isSearchable={false}
                                                                onChange={setSelectedShareClass}
                                                            />

                                                            {
                                                                errorsReturned?.share_class_id ?
                                                                    <h5 className="pt-2 m-0 d-flex flex-start text-start pop-errors-text">{errorsReturned?.share_class_id}</h5>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                    </div>
                                                    <>
                                                        <div className="col-12 col-md-6 pt-2 position-relative">
                                                            <input
                                                                hidden
                                                                id='upload-document-file'
                                                                type="file"
                                                                onChange={(e) => { operationDocumentHandler(e.target.files) }}
                                                            />
                                                            <label
                                                                className="pop-upload-file position-relative"
                                                                htmlFor="upload-document-file"
                                                            >
                                                                {operationDocumentLink ? generalData?.documentsSectionSettings['change-file-placeholder'] : generalData?.documentsSectionSettings['upload-file-placeholder']}
                                                            </label>

                                                            <AttachIcon />
                                                            {
                                                                errorsReturned?.operation_document ?
                                                                    <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text text-start">{errorsReturned?.operation_document}</h5>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                        <div className="col-12 col-md-6 pt-2 position-relative" >
                                                            <input
                                                                className='add-user-inputs'
                                                                id='file_name'
                                                                type="text"
                                                                placeholder={generalData?.website_titles['file-name']}
                                                                onChange={(e) => { setOperationFileName(e.target.value) }}
                                                                value={operationFileName}
                                                            />
                                                            {
                                                                errorsReturned?.operation_file_name ?
                                                                    <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text text-start">{errorsReturned?.operation_file_name}</h5>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                    </>
                                                    <div className="col-12 col-md-6 pt-2" >
                                                        <div className="postion-relative text-start justify-content-start w-100" >
                                                            {/* <input name="to" className="add-user-inputs" placeholder={generalData?.website_titles['registration-date']} value={''} /> */}
                                                            <DatePicker
                                                                className="popup-date add-user-inputs"
                                                                placeholderText={generalData?.website_titles['registration-date']}
                                                                value={registrationDate ? registrationDate : ''}
                                                                showMonthDropdown
                                                                showYearDropdown
                                                                dropdownMode="select"
                                                                selected={registrationDate}
                                                                dateFormat="d MMMM yyyy"
                                                                onChange={(date) => setRegistrationDate(date)}
                                                                minDate={addYears(new Date(), -100)}
                                                                maxDate={addYears(new Date(), +1)}
                                                                locale={localStorage.getItem("BlpLanguage")}
                                                            />
                                                        </div>
                                                        {
                                                            errorsReturned?.registration_date ?
                                                                <h5 className="pt-2 m-0 d-flex flex-start text-start pop-errors-text">{errorsReturned?.registration_date}</h5>
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                    <div className="col-12 pt-2">
                                                        <textarea type="text-area" name="description" className="add-user-inputs" placeholder={generalData?.website_titles['comment']} value={comment} onChange={(e) => setComment(e.target.value)}></textarea>
                                                        {
                                                            errorsReturned?.comment ?
                                                                <h5 className="pt-2 m-0 d-flex flex-start text-start pop-errors-text">{errorsReturned?.comment}</h5>
                                                                :
                                                                null
                                                        }
                                                        {
                                                            errorsReturned?.board_exists ?
                                                                <h5 className="pt-2 m-0 d-flex flex-start text-start pop-errors-text">{errorsReturned?.board_exists}</h5>
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 d-flex justify-content-center">
                                                <button type="submit" className="popup-button my-auto">{generalData?.website_titles['add']}</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Draggable>
                }

                {
                    confirmationPopup &&
                    <Draggable handle='.drag-handle' bounds={'parent'}>
                        <div className="pop-up-api-box operation-popup">
                            <CloseIcon className="cursor-pointer" onClick={(e) => { setConfirmationPopup(false) }} />
                            <div className="">
                                <div className="containter-fluid">
                                    <div className="row d-flex align-items-center">
                                        <div className="col-9 col-sm-12 d-flex justify-content-center mb-4 drag-handle pt-5 cursor-grab">
                                            {/* <h1 className="pop-up-title m-0 pt-3 color-dark-blue">{generalData?.settings_section_settings['delete-popup-title']}</h1> */}
                                        </div>

                                        {
                                            <div className="col-6 mb-4 border-bottom text-start">
                                                <h5 className="text-start"><strong>{generalData?.website_titles['name']}</strong></h5>
                                                {
                                                    selectedRelation ? selectedRelation.label : '-'
                                                }
                                            </div>
                                        }

                                        {
                                            <div className="col-6 mb-3  text-start">
                                                <h5 className=" text-start"><strong>{generalData?.shareholdersSectionSettings['no-of-shares-title']}</strong></h5>
                                                {numberOfShares ? numberOfShares : '-'}
                                            </div>
                                        }

                                        {
                                            <div className="col-6 mb-4 border-bottom text-start">
                                                <h5 className=" text-start"><strong>{generalData?.shareholdersSectionSettings['percentage-title']}</strong></h5>
                                                {percentage ? `${percentage} %` : '-'}
                                            </div>
                                        }

                                        {
                                            <div className="col-6 mb-3  text-start">
                                                <h5 className=" text-start"><strong>{generalData?.website_titles['share-type']}</strong></h5>
                                                {selectedShareType ? selectedShareType.label : getOptions(generalData.share_types, 'slug', 'id', 'title')[0]?.label}
                                            </div>
                                        }

                                        {
                                            <div className="col-6 mb-3  text-start">
                                                <h5 className=" text-start"><strong>{generalData?.website_titles['share-class']}</strong></h5>
                                                {selectedShareClass ? selectedShareClass.label : '-'}
                                            </div>
                                        }

                                        {
                                            <div className="col-12 mb-3  text-start">
                                                <h5 className=" text-start"><strong>{generalData?.website_titles['comment']}</strong></h5>
                                                {comment ? comment : '-'}
                                            </div>
                                        }
                                        {
                                            errorsReturned?.invalid_operation &&
                                            <h5 className="pt-2 m-0 d-flex flex-start text-start pop-errors-text">{errorsReturned?.invalid_operation}</h5>
                                        }
                                        <div className="col-12 d-flex justify-content-center pt-4 pb-2">
                                            <button
                                                onClick={submitShareholderForm}
                                                type="button"
                                                className="popup-button my-auto mx-2 w-auto px-3">{generalData?.website_titles['save']}
                                            </button>
                                            <button onClick={(e) => { setConfirmationPopup(false) }} type="button" className="auth-button border-dark-blue my-auto mx-2 background-transparent w-auto px-3">{generalData?.settings_section_settings['delete-popup-second-button']}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Draggable>
                }
            </div>
            <Api options={generalDataApiOptions} />
        </>
    )
}