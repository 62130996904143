import React, { useContext, useState } from 'react'
import GlobalState from '../contexts/GlobalState'
import Api from './Api';
import CustomSnoozePopupComponent from './CustomSnoozePopupComponent';
import { formatDateTime } from '../Helpers';

export default function ReminderNotificationComponent({ customSnoozePopup, setCustomSnoozePopup, snoozeType, setSnoozeType, snoozeDate, setSnoozeDate, reminder, index, className, activeReminder, setActiveReminder, reminders, setReminders }) {
    const { generalDataGlobal: [generalData] } = useContext(GlobalState);
    const [notificationDataApiOptions, setNotificationDataApiOptions] = useState({});

    const handleSnoozeSelect = (e, singleType) => {
        e.stopPropagation();
        e.preventDefault();
        if (singleType?.slug === 'custom') {
            setCustomSnoozePopup(true);
        }
        setSnoozeType(singleType?.id);
    }

    const submitSnooze = (e) => {
        e.stopPropagation();
        setCustomSnoozePopup(false);
        // setSnoozeType(null)
    }

    const handleSaveClick = (e) => {
        if ((snoozeType === 3 && snoozeDate) || (snoozeType && snoozeType !== 3)) {
            let fd = new FormData();
            fd.append('snooze_type', snoozeType);
            fd.append('notification_id', activeReminder?.id);
            if (snoozeDate) {
                fd.append('snooze_date', formatDateTime(snoozeDate));
            }
            setNotificationDataApiOptions({
                url: 'snooze-reminder-notification',
                method: 'post',
                data: fd,
                callback: res => {
                    setReminders(res?.data);
                    setActiveReminder('');
                    setSnoozeType('');
                    setSnoozeDate('');
                },
                catch: err => {
                }
            })
        }
    }

    return (
        <>
            <div className={`w-100 ${className ? className : ''}`}>
                <div key={index} className="row gx-3 mt-1">
                    <div className="col-auto">
                        <img className="table-user-image" src={generalData?.fixed_settings?.full_path_no_profile_picture} alt="user" />
                    </div>
                    <div className="col">
                        <div className="row gx-0">
                            <div className="col-8 my-auto">
                                <p className="m-0 name-notification">{reminder?.reminder?.name}</p>
                                <p className="m-0 details-notifications">{reminder?.reminder_type === 'snoozed' ? generalData?.website_titles['you-have-a-snoozed-reminder'] : generalData?.website_titles['you-have-a-new-reminder']} {reminder?.reminder?.name}</p>
                            </div>
                            <div className="col-4 text-end">
                                <p className="m-0 date-notifications">{reminder?.date_human}</p>
                            </div>
                            {
                                reminder?.snoozed !== 1 ?
                                    <div className="col-12 justify-content-center align-items-center py-2">
                                        <button onClick={() => { setActiveReminder(reminder) }} className="popup-button my-auto">{generalData?.website_titles['snooze']}</button>
                                        {
                                            Number(activeReminder?.id) === Number(reminder?.id) &&
                                            <div className='row mt-2 gx-2'>
                                                {
                                                    generalData?.snooze_types.map((singleType, snoozeInd) => (
                                                        <div key={snoozeInd} onClick={(e) => { handleSnoozeSelect(e, singleType) }} className='col-4'>
                                                            <div className={`cursor-pointer w-100 h-100 text-center rounded shadow snooze-options text-primary ${snoozeType === singleType?.id ? 'selected' : ''}`}>
                                                                <p style={{ zIndex: "999" }} className="p-0 pt-1 m-0 snooze-details">{singleType?.title}</p>
                                                                {
                                                                    singleType?.explanation &&
                                                                    <p style={{ zIndex: "999" }} className="p-0 pt-1 m-0 snooze-details snooze-gray">{singleType?.explanation}</p>
                                                                }
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                                <div className='w-100 d-flex mt-2'>
                                                    <button onClick={(e) => { e.stopPropagation(); handleSaveClick(e) }} className="popup-button my-auto">{generalData?.website_titles['save']}</button>
                                                    <button onClick={(e) => { e.stopPropagation(); setActiveReminder(null); setSnoozeDate(''); setSnoozeType(''); }} className="ms-2 reverse popup-button my-auto">{generalData?.website_titles['cancel']}</button>
                                                </div>
                                            </div>
                                        }

                                    </div>
                                    : <p>{generalData?.website_titles['snoozed-reminder']}</p>
                            }
                        </div>
                    </div>

                </div>
            </div>
            {
                customSnoozePopup && Number(activeReminder?.id) === Number(reminder?.id) &&
                <CustomSnoozePopupComponent
                    shortcutLayout={1}
                    setCustomSnoozePopup={setCustomSnoozePopup}
                    setSnoozeType={setSnoozeType}
                    setCurrentReminderEdit={() => { }}
                    setCurrentReminderSnooze={() => { }}
                    setCurrentReminder={() => { }}
                    submitSnooze={submitSnooze}
                    snoozeDate={snoozeDate}
                    setSnoozeDate={setSnoozeDate}
                />
            }
            <Api options={notificationDataApiOptions} />
        </>

    )
}
