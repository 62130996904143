import React, { useContext, useState } from 'react'
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import GlobalState from '../contexts/GlobalState';
import { formatDate } from '../Helpers';

const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

export default function DashboardCalendarComponent({ data, setActiveView, activeDay, setActiveDay }) {

    function CustomDay({ date }) {
        const { generalDataGlobal: [generalData] } = useContext(GlobalState);
        return (
            <div className='d-flex align-items-center w-100 justify-content-center mt-1' >
                {
                    data?.reminders?.includes(formatDate(date)) &&
                    <div
                        style={{
                            width: 8,
                            height: 8,
                            borderRadius: '100%',
                            background: generalData?.eventsColor['reminder'],
                            marginLeft: 4

                        }}
                    ></div>
                }
                {
                    data?.meetings?.includes(formatDate(date)) &&
                    <div
                        style={{
                            width: 8,
                            height: 8,
                            borderRadius: '100%',
                            background: generalData?.eventsColor['meeting'],
                            marginLeft: 4

                        }}
                    ></div>
                }
                {
                    data?.tasks?.includes(formatDate(date)) &&
                    <div
                        style={{
                            width: 8,
                            height: 8,
                            borderRadius: '100%',
                            background: generalData?.eventsColor['task'],
                            marginLeft: 4

                        }}
                    ></div>

                }
                {
                    data?.hearings?.includes(formatDate(date)) &&
                    <div
                        style={{
                            width: 8,
                            height: 8,
                            borderRadius: '100%',
                            background: generalData?.eventsColor['hearing'],
                            marginLeft: 4

                        }}
                    ></div>

                }



            </div>
        );
    }

    const [currentMonth, setCurrentMonth] = useState(activeDay ? activeDay.getMonth() : new Date().getMonth());
    const [currentYear, setCurrentYear] = useState(activeDay ? activeDay.getFullYear() : new Date().getFullYear());

    function handlePrev() {
        if (currentMonth === 0) {
            setCurrentMonth(11);
            setCurrentYear(currentYear - 1);
        } else {
            setCurrentMonth(currentMonth - 1);
        }
    }

    function handleNext() {
        if (currentMonth === 11) {
            setCurrentMonth(0);
            setCurrentYear(currentYear + 1);
        } else {
            setCurrentMonth(currentMonth + 1);
        }
    }

    return (
        <div className="sfasfsf">
            <div className="calendar-header-month w-100 d-flex  bg-primary justify-content-between py-4 mb-4">
                <button className="btn text-white" onClick={handlePrev}>{'<'}</button>
                <div className='d-flex justify-content-around mx-3 flex-grow-1'>
                    <div className="month-button cursor-pointer mx-2 d-flex align-items-center flex-1 justify-content-center"
                        onClick={() => handlePrev()}
                    >
                        <p className='text-white text-center m-0'>
                            <span className='text-secondary'>
                                {
                                    currentMonth - 1 < 0 ?
                                        months[11]
                                        :
                                        months[currentMonth - 1]
                                }
                            </span>
                            <br />
                            <span className='fw-700'>
                                {
                                    currentMonth - 1 < 0 ?
                                        currentYear - 1
                                        :
                                        currentYear
                                }
                            </span>
                        </p>
                    </div>

                    <div className="month-button active p-3 mx-2 text-center  flex-1 justify-content-center" >
                        <p className='m-0'>
                            <span className='text-secondary'>
                                {months[currentMonth]}
                            </span>
                            <br />
                            <span className='fw-700'>
                                {currentYear}
                            </span>
                        </p>
                    </div>

                    <div className="month-button cursor-pointer mx-2 d-flex align-items-center flex-1 justify-content-center"
                        onClick={() => handleNext()}
                    >
                        <p className='text-white text-center m-0'>
                            <span className='text-secondary'>
                                {
                                    Number(currentMonth) === 11 ?
                                        months[0]
                                        :
                                        months[currentMonth + 1]
                                }
                            </span>
                            <br />
                            <span className='fw-700'>
                                {
                                    Number(currentMonth) === 11 ?
                                        currentYear + 1
                                        :
                                        currentYear
                                }
                            </span>
                        </p>
                    </div>

                </div>
                <button className="btn text-white text-center" onClick={handleNext}>{'>'}</button>
            </div>
            <Calendar
                value={ new Date((`${currentMonth + 1}-${activeDay ? new Date(activeDay).getDate() : new Date().getDate()}-${currentYear}`).replace(/-/g, "/"))}
                className="reminders-calendar pb-4 hide-header shadow-none dashboard"
                tileContent={({ date }) => <CustomDay date={date} />}
                onChange={(e) => {
                    setActiveView('day');
                    setActiveDay(e)
                }}
            />
            {/* Render the calendar component here */}
        </div>
    );

}




