import { useContext, useEffect, useRef, useState } from "react";
import GlobalState from "../contexts/GlobalState";
import PhoneInput from "react-phone-number-input";
import 'react-phone-number-input/style.css';
import showCommaSeperator from "../useCommaSeparator";
import { Countries } from "../AvailableCountries";
import RelationsSelect from "./RelationsSelect";
import DatePicker from "react-datepicker";
import { addYears, calcHeight, formatDate } from "../Helpers";

import { registerLocale } from "react-datepicker";
import ar from 'date-fns/locale/ar';
import { TrueSign, XSign } from "./Icons";
registerLocale('ar', ar)

export default function EditInput(props) {
    const {
        authUserGlobal,
        currentInputGlobal,
        currentInputValueGlobal,
        currentInputTypeGlobal,
        currentInputValidationsGlobal,
        changeInputGlobal,
    } = useContext(GlobalState);
    const [changeInput, setChangeInput] = changeInputGlobal;
    const [authUser,] = authUserGlobal;
    const [currentInput, setCurrentInput] = currentInputGlobal;
    const [currentInputValue, setCurrentInputValue] = currentInputValueGlobal;
    const [ , setCurrentInputValidations] = currentInputValidationsGlobal
    const [ , setCurrentInputType] = currentInputTypeGlobal;

    const textAreaRef = useRef(null);
    const lockedInputRef = useRef();
    const [ , setTextAreaHeight] = useState(lockedInputRef?.current?.scrollHeight);
    const [errorsReturned, setErrorsReturned] = useState({});

    const { data } = props
    // reset states on input close
    const closeInputEdit = (e) => {
        setCurrentInputValue('');
        setCurrentInput('');
        setErrorsReturned({});
    }

    //set current input value when input changes with value
    useEffect(() => {
        if (currentInput === 'emails' && props.singleRec?.id === currentInputValue?.id && props?.inputName === 'emails') {
            setCurrentInputValue({ ...currentInputValue, "id": props.singleRec?.id ? props.singleRec?.id : 0, "value": props.singleRec?.email ? props.singleRec?.email : '' });
        }
        if (currentInput === 'phones' && props.singleRec?.id === currentInputValue?.id && props?.inputName === 'phones') {
            setCurrentInputValue({ ...currentInputValue, "id": props.singleRec?.id ? props.singleRec?.id : 0, "value": props.singleRec?.phone ? props.singleRec?.phone : '' });
        }
        if (currentInput === 'addresses' && props.singleRec?.id === currentInputValue?.id && props?.inputName === 'addresses') {
            setCurrentInputValue({ ...currentInputValue, "id": props.singleRec?.id ? props.singleRec?.id : 0, "value": props.singleRec?.address ? props.singleRec?.address : '' });
        }
        if (currentInput === 'websites' && props.singleRec?.id === currentInputValue?.id && props?.inputName === 'websites') {
            setCurrentInputValue({ ...currentInputValue, "id": props.singleRec?.id ? props.singleRec?.id : 0, "value": props.singleRec?.website ? props.singleRec?.website : '' });
        }
        if (currentInput === 'user-description' && props.singleRec.id === currentInputValue?.id) {
            setCurrentInputValue({
                ...currentInputValue,
                'id': props.singleRec.id,
                'selectedRelation': props.selectedRelation,
                'description': props.description,
                firstInputValue: props?.selectedRelation.label.split('-')[0],
                secondInputValue: props.description
            })
        }
        if (currentInput === 'user-relation' && props.singleRec.id === currentInputValue?.id) {
            setCurrentInputValue({
                ...currentInputValue,
                'id': props.singleRec.id,
                'selectedRelation': props.selectedRelation,
                currentInputValue: props?.selectedRelation.label.split('-')[0],
            })
        }
        if (currentInput === 'date-description' && props?.singleRec?.id === currentInputValue.id) {
            setCurrentInputValue({
                ...currentInputValue,
                id: props?.singleRec?.id,
                firstInputValue: new Date(props?.firstInputValue),
                secondInputValue: props.secondInputValue,
            })
        }

    }, [currentInput]);// eslint-disable-line react-hooks/exhaustive-deps

    //handle click on inputs to open the input in edit mode
    const disabledInputClick = (name, value, props = {}) => {
        if (authUser?.id === props.data?.id || authUser?.user_role_id === 1) {
            if (currentInput !== name || currentInputValue.id !== value) {
                if (props.inputName === 'emails') {
                    setCurrentInput('emails');

                } else if (props.inputType === 'phones' && props.inputName === 'main_phone_number') {
                    setCurrentInput(name);
                } else if (props.inputName === 'phones') {
                    setCurrentInput('phones');
                } else if (props.inputName === 'addresses') {
                    setCurrentInput('addresses');
                } else if (props.inputName === 'websites') {
                    setCurrentInput('websites');
                } else {
                    setCurrentInput(name);
                }
                if (props?.relationModel) {
                    setCurrentInputValue({ ...value, ...props })
                } else {
                    setCurrentInputValue(value);
                }
                if (props?.validation) {
                    setCurrentInputValidations(props.validation || null)
                } else {
                    setCurrentInputValidations(null);
                }

            }
        }
    }

    // function auto_grow(element) {
    //     setTextAreaHeight(`${textAreaRef?.current?.scrollHeight}px`)
    // }

    const resizeTextArea = () => {
        if (textAreaRef.current) {
            textAreaRef.current.style.height = "auto";
            textAreaRef.current.style.height = textAreaRef.current.scrollHeight + "px";
        }

    };

    useEffect(resizeTextArea, [currentInputValue, currentInputValue?.secondInputValue]);

    return (
        <>
            {
                currentInput === props.inputName && (props.singleRec?.id === currentInputValue?.id) ?
                    <>
                        <div className="row w-100">
                            <div className={'col ' + (props?.withPadd !== 1 ? 'pe-0' : '') + (props?.positionAbsolute === 1 ? ' position-relative ' : ' ')}>
                                {
                                    props.inputType === 'text-area' ?
                                        <>
                                            <textarea
                                                value={currentInputValue ? currentInputValue : ''}
                                                resize={true}

                                                onChange={(e) => {
                                                    setCurrentInputValue(e.target.value);

                                                }}
                                                onFocus={() => { setTextAreaHeight(calcHeight(currentInputValue, 20)) }}

                                                onKeyDown={(e) => {
                                                    if (e.key === "Enter" && !e.shiftKey) {
                                                        e.preventDefault();
                                                        setChangeInput(changeInput + 1)
                                                    }
                                                    if (e.key === "Escape") {
                                                        closeInputEdit(e)
                                                    }
                                                }}
                                                className={`profile-general-input w-100  resizable ${props.withPadd === 1 ? '' : 'm-0 p-0'}`}
                                                style={{
                                                    resize: 'vertical',
                                                    maxHeight: 300,
                                                    minHeight: 30,
                                                }}
                                                autoFocus
                                                ref={textAreaRef}
                                            ></textarea>
                                        </>

                                        :
                                        props.inputType === 'date-description' ?
                                            <div className="row">
                                                <div className="col-xl-3 col-sm-6 position-relative">
                                                    <DatePicker
                                                        className="popup-date cursor-pointer profile-general-input ms-0 datepicker"
                                                        placeholderText={'date'}
                                                        value={currentInputValue?.firstInputValue}
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        dropdownMode="select"
                                                        selected={currentInputValue?.firstInputValue ? new Date(currentInputValue?.firstInputValue) : new Date()}
                                                        dateFormat="d MM yyyy"
                                                        onChange={(date) => setCurrentInputValue({ ...currentInputValue, firstInputValue: formatDate(date) })}
                                                        minDate={addYears(new Date(), -100)}
                                                        maxDate={addYears(new Date(), +100)}
                                                        locale={localStorage.getItem("BlpLanguage")}
                                                    />
                                                </div>
                                                <div className="col-xl-8 col-6">
                                                    <textarea
                                                        value={currentInputValue?.secondInputValue}
                                                        resize={true}
                                                        rows={1}
                                                        onChange={(e) => { setCurrentInputValue({ ...currentInputValue, secondInputValue: e.target.value }); setTextAreaHeight(calcHeight(e.target.value)) }}

                                                        onKeyDown={(e) => {
                                                            if (e.key === "Enter" && !e.shiftKey) {
                                                                e.preventDefault();
                                                                setChangeInput(changeInput + 1)
                                                            }
                                                            if (e.key === "Escape") {
                                                                closeInputEdit(e)
                                                            }
                                                        }}
                                                        className={`profile-general-input w-100 resizable ${props.withPadd === 1 ? '' : 'm-0 p-0'}`}
                                                        style={{
                                                            resize: 'vertical',

                                                            maxHeight: 300,
                                                            minHeight: 30,
                                                        }}
                                                        autoFocus
                                                        ref={textAreaRef}

                                                        onFocus={() => { setTextAreaHeight(calcHeight(currentInputValue?.secondInputValue)) }}
                                                    ></textarea>
                                                </div>
                                            </div>
                                            :
                                            props.inputType === 'user-description' ?
                                                <div style={{minHeight: '72px'}} className="row">
                                                    <div className="col-12 mb-1">
                                                        <RelationsSelect
                                                            className="editable"
                                                            selectedRelation={currentInputValue?.selectedClient}
                                                            setSelectedRelation={(e) => { setCurrentInputValue({ ...currentInputValue, selectedClient: e, firstInputValue: e.value.split('-')[1], }) }}
                                                            profile={data}
                                                            defaultSelected={'individual'}
                                                            withUsers={0}
                                                            excludeMultipleValues={props?.excludeMultipleValues}
                                                            excludeMatters
                                                        />
                                                    </div>
                                                    <div style={{minHeight: '26px'}} className="col-12 position-relative">
                                                        <input
                                                            type="text"
                                                            onKeyDown={(e) => (e.key === "Enter" ? setChangeInput(changeInput + 1) : (e.key === "Escape" ? closeInputEdit(e) : null))}
                                                            value={currentInputValue?.description}
                                                            onChange={(e) => setCurrentInputValue({ ...currentInputValue, "description": e.target.value, secondInputValue: e.target.value })}
                                                            className={'profile-general-input absolute-new-input-double w-100 ' + (props.withPadd === 1 ? '' : 'm-0 p-0')}
                                                        />
                                                    </div>
                                                </div>
                                                :
                                                props.inputType === 'user-relation' ?
                                                    <div className="row">
                                                        <div className="col-xl-6 col-lg-12 col-sm-6 mb-3">
                                                            <RelationsSelect
                                                                className="editable"
                                                                selectedRelation={currentInputValue?.selectedClient}
                                                                setSelectedRelation={(e) => { setCurrentInputValue({ ...currentInputValue, selectedClient: e, firstInputValue: e.value.split('-')[1], }) }}
                                                                profile={data}
                                                                defaultSelected={'individual'}
                                                                withUsers={0}
                                                                excludeMultipleValues={props?.excludeMultipleValues}
                                                                excludeMatters
                                                                excludeTasks
                                                            />
                                                        </div>
                                                    </div>

                                                    :
                                                    props.inputName === 'emails' ?
                                                        <input
                                                            autoFocus
                                                            type="email"
                                                            onKeyDown={(e) => (e.key === "Enter" ? setChangeInput(changeInput + 1) : (e.key === "Escape" ? closeInputEdit(e) : null))}
                                                            value={currentInputValue?.email}
                                                            onChange={(e) => setCurrentInputValue({ "id": props.singleRec?.id ? props.singleRec?.id : 0, "value": e.target.value })}
                                                            className={'profile-general-input w-100 ' + (props.withPadd === 1 ? '' : 'm-0 p-0')}
                                                        />
                                                        :
                                                        props.inputType === 'phones' && props.inputName === 'main_phone_number' ?
                                                            <PhoneInput
                                                                countries={Countries}
                                                                autoFocus
                                                                onKeyDown={(e) => (e.key === "Enter" ? setChangeInput(changeInput + 1) : (e.key === "Escape" ? closeInputEdit(e) : null))}
                                                                value={currentInputValue}
                                                                onChange={setCurrentInputValue}
                                                                className={'profile-general-input w-100 ' + (props.withPadd === 1 ? '' : 'm-0 p-0')}
                                                            />
                                                            :
                                                            props.inputType === 'phones' && props.inputName === 'phones' ?
                                                                <PhoneInput
                                                                    countries={Countries}
                                                                    autoFocus
                                                                    onKeyDown={(e) => (e.key === "Enter" ? setChangeInput(changeInput + 1) : (e.key === "Escape" ? closeInputEdit(e) : null))}
                                                                    value={currentInputValue.phone} onChange={(value) => setCurrentInputValue({ ...currentInputValue, "id": props.singleRec?.id ? props.singleRec?.id : 0, value })}
                                                                    className={'profile-general-input w-100 ' + (props.withPadd === 1 ? '' : 'm-0 p-0')}
                                                                />
                                                                :
                                                                props.inputName === 'addresses' ?
                                                                    <input
                                                                        autoFocus
                                                                        onKeyDown={(e) => (e.key === "Enter" ? setChangeInput(changeInput + 1) : (e.key === "Escape" ? closeInputEdit(e) : null))}
                                                                        value={currentInputValue?.address}
                                                                        onChange={(e) => setCurrentInputValue({ "id": props.singleRec?.id ? props.singleRec?.id : 0, "value": e.target.value })}
                                                                        className={'profile-general-input w-100 ' + (props.withPadd === 1 ? '' : 'm-0 p-0')}
                                                                    />
                                                                    :
                                                                    props.inputName === 'websites' ?
                                                                        <input
                                                                            autoFocus
                                                                            onKeyDown={(e) => (e.key === "Enter" ? setChangeInput(changeInput + 1) : (e.key === "Escape" ? closeInputEdit(e) : null))}
                                                                            value={currentInputValue?.website}
                                                                            onChange={(e) => setCurrentInputValue({ "id": props.singleRec?.id ? props.singleRec?.id : 0, "value": e.target.value })}
                                                                            className={'profile-general-input w-100 ' + (props.withPadd === 1 ? '' : 'm-0 p-0')}
                                                                        />
                                                                        :
                                                                        <input
                                                                            autoFocus
                                                                            onKeyDown={
                                                                                (e) => (
                                                                                    e.key === "Enter" ?
                                                                                        setChangeInput(changeInput + 1)
                                                                                        :
                                                                                        (e.key === "Escape" ? closeInputEdit(e) : null)
                                                                                )
                                                                            }
                                                                            value={currentInputValue ? currentInputValue : ''}
                                                                            onChange={(e) => { setCurrentInputValue(e.target.value); setCurrentInputType('number') }}
                                                                            className={'profile-general-input w-100 ' + (props.withPadd === 1 ? ' ' : 'm-0 p-0 ') + (props?.positionAbsolute === 1 ? ' absolute-new-input ' : '' )}
                                                                        />
                                }

                            </div>
                            <div className={'col-auto ps-2 ' + (props?.withPadd !== 1 ? 'ps-xl-0' : '')}>
                                <div className="d-flex">
                                    <div className="input-profile-buttons ms-1" onClick={(e) => setChangeInput(changeInput + 1)}>
                                        <TrueSign />
                                    </div>
                                    <div className="input-profile-buttons ms-1" onClick={(e) => closeInputEdit(e)}>
                                        <XSign />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            {
                                errorsReturned && errorsReturned[props.inputName] ?
                                    <div className="col-12">
                                        <p className={'errors-returned-profile ' + (props.withPadd === 1 ? '' : ' p-0')}>{errorsReturned[props.inputName]}</p>
                                    </div>
                                    :
                                    null
                            }
                        </div>
                    </>
                    :
                    <>
                        {
                            props?.inputType === 'date-description' ?
                                <div className="col-12"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        if (!props?.locked) {
                                            disabledInputClick(props.inputName, props.singleRec, props)
                                        }
                                    }}

                                >
                                    <div className="row">
                                        <div className="col">
                                            <div className="row gx-1">
                                                <div className="col-auto">


                                                    <h5 className="locked-inputs">

                                                        {`[${props?.firstInputValue ? props?.firstInputValue : props?.firstInputPlaceholder}]`}
                                                    </h5>
                                                </div>
                                                <div className="col-auto" >

                                                    <h5 className="locked-inputs " style={{ whiteSpace: 'break-spaces' }}>
                                                        {props?.secondInputValue ? props?.secondInputValue : props?.secondInputPlaceholder}
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                props.inputType === 'user-relation' ?
                                    <>
                                        {
                                            props?.multiple ?
                                                <div className="d-flex flex-xl-row flex-lg-column" onClick={
                                                    (e) => {
                                                        e.stopPropagation();
                                                        e.preventDefault();
                                                        disabledInputClick(props.inputName, props.singleRec, props)
                                                    }}
                                                >
                                                    <h5 className={'locked-inputs w-100 ' + (props.withPadd === 1 ? '' : 'm-0 p-0')}>{props?.selectedRelation ? props?.selectedRelation.label.split('-')[1] : props?.placeholder}</h5>
                                                </div>
                                                :

                                                <h5 onClick={(e) => { e.stopPropagation(); e.preventDefault(); disabledInputClick(props.inputName, props.data[props.inputName]?.name) }} className={'locked-inputs w-100 ' + (props.withPadd === 1 ? '' : 'm-0 p-0')}>{props?.selectedRelation ? props?.selectedRelation.label.split('-')[1] : props.placeholder}</h5>
                                        }
                                    </>
                                    :
                                    props.inputType === 'user-description' ?
                                        <>
                                            {
                                                props?.multiple ?
                                                    <div className="d-flex flex-column" onClick={
                                                        (e) => {
                                                            e.stopPropagation();
                                                            e.preventDefault();
                                                            disabledInputClick(props.inputName, props.singleRec, props)
                                                        }}
                                                    >
                                                        <h5 className={'locked-inputs w-100  mb-1 ' + (props.withPadd === 1 ? '' : 'm-0 p-0')}>{props?.selectedRelation ? props?.selectedRelation.label.split('-')[1] : props?.firstInputPlaceholder}</h5>
                                                        <h5 style={{minHeight: '26px'}} className={'locked-inputs w-100  mb-3 ' + (props.withPadd === 1 ? '' : 'm-0 p-0')}>{props?.description ? props?.description : props?.secondInputPlaceholder}</h5>
                                                    </div>
                                                    :

                                                    <h5 onClick={(e) => { e.stopPropagation(); e.preventDefault(); disabledInputClick(props.inputName, props.data[props.inputName]?.name) }} className={'locked-inputs w-100 ' + (props.withPadd === 1 ? '' : 'm-0 p-0')}>{props.value ? props.value : props.placeholder}</h5>
                                            }
                                        </>
                                        :
                                        props.inputName === 'emails' ?
                                            <h5 onClick={(e) => { e.stopPropagation(); e.preventDefault(); disabledInputClick(props.inputName, props.singleRec) }} className={'locked-inputs w-100 ' + (props.withPadd === 1 ? '' : 'm-0 p-0')}>{props.value ? props.value : props.placeholder}</h5>
                                            :
                                            props.inputType === 'phones' && props.inputName === "main_phone_number" ?
                                                <h5 onClick={(e) => { e.stopPropagation(); disabledInputClick(props.inputName, props.value) }} className={'locked-inputs w-100 ' + (props.withPadd === 1 ? '' : 'm-0 p-0')}>{props.value ? props.value : props.placeholder}</h5>
                                                :
                                                props.inputType === 'phones' && props.inputName === "phones" ?
                                                    <h5 onClick={(e) => { e.stopPropagation(); disabledInputClick(props.inputName, props.singleRec) }} className={'locked-inputs w-100 ' + (props.withPadd === 1 ? '' : 'm-0 p-0')}>{props.value ? props.value : props.placeholder}</h5>
                                                    :
                                                    props.inputName === 'phones' ?
                                                        <h5 onClick={(e) => { e.stopPropagation(); e.preventDefault(); disabledInputClick(props.inputName, props.singleRec) }} className={'locked-inputs w-100 ' + (props.withPadd === 1 ? '' : 'm-0 p-0')}>{props.value ? props.value : props.placeholder}</h5>
                                                        :
                                                        props.inputName === 'addresses' ?
                                                            <h5 onClick={(e) => { e.stopPropagation(); e.preventDefault(); disabledInputClick(props.inputName, props.singleRec) }} className={'locked-inputs w-100 ' + (props.withPadd === 1 ? '' : 'm-0 p-0 pe-4')}>{props.value ? props.value : props.placeholder}</h5>
                                                            // <h5 onClick={(e) => { e.preventDefault(); disabledInputClick(props.inputName, props.singleRec) }} className={'locked-inputs w-100 ' + (props.withPadd === 1 ? '' : 'm-0 p-0 pe-4')}>{'address'}</h5>
                                                            :
                                                            props.inputName === 'websites' ?
                                                                <h5 onClick={(e) => { e.stopPropagation(); e.preventDefault(); disabledInputClick(props.inputName, props.singleRec, props) }} className={'locked-inputs w-100 ' + (props.withPadd === 1 ? '' : 'm-0 p-0 pe-4')}>{props.value ? props.value : props.placeholder}</h5>
                                                                :
                                                                <h5 ref={lockedInputRef} onClick={(e) => { e.stopPropagation(); e.preventDefault(); disabledInputClick(props.inputName, props.data[props.inputName], props) }} className={'locked-inputs w-100 ' + (props.withPadd === 1 ? 'ps-4' : 'm-0 p-0')} style={{ whiteSpace: 'break-spaces' }}>{
                                                                    props.value ?
                                                                        <>
                                                                            {

                                                                                props.commaSep ?
                                                                                    showCommaSeperator(props.value) :
                                                                                    (props.value)
                                                                            }
                                                                        </>
                                                                        : props.placeholder}
                                                                </h5>

                        }
                    </>
            }
            {
                props?.errorsReturned && props?.errorsReturned[props.inputName] && currentInput === props.inputName &&
                < div className="col-12">
                    <p className={'errors-returned-profile ' + (props.withPadd === 1 ? '' : ' p-0')}>{props?.errorsReturned[props.inputName]}</p>
                </div >
            }

        </>
    )
}