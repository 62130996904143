import React, { useContext, useRef, useState } from 'react'
import Layout from '../components/Layout'
import GlobalState from '../contexts/GlobalState';
import { AddIcon, CloseIcon } from '../components/Icons';
import useOutsideClick from '../useOutsideClick';
import EventCardComponent from '../components/EventsCardComponent';
import { useEffect } from 'react';
import HearingPopupComponent from '../components/HearingPopupComponent';
import ReminderPopup from '../components/ReminderPopup';
import Api from '../components/Api';
import MeetingPopupComponent from '../components/MeetingPopupComponent';
import TaskPopupComponent from '../components/TaskPopupComponent';
import MyCalendar from '../components/CalendarComponent';
import { formatDate } from '../Helpers';

export default function Events() {

    const {
        generalDataGlobal: [generalData],
        setAllLitigations,
        authUserGlobal: [authUser],
        currentReminderEditGlobal: [, setCurrentReminderEditGl]
    } = useContext(GlobalState);

    const [apiOptions, setApiOptions] = useState({});
    const [mattersApiOptions, setMattersApiOptions] = useState({});

    // general states 
    const [target, setTarget] = useState();
    const [openId, setOpenId] = useState(-1);
    const [addMore, setAddMore] = useState(false);
    const [addPopup, setAddPopup] = useState(false);
    const [upcomingEvents, setUpcomingEvents] = useState([]);
    const [calendarEvents, setCalendarEvents] = useState([]);
    const [deleteEvent, setDeleteEvent] = useState();
    const [deletePopup, setDeletePopup] = useState(false);


    // matters states

    const [popupTitle, setPopupTitle] = useState('')
    const [editHearing, setEditHearing] = useState();
    const [, setTotalMatters] = useState(0)
    const [, setTotalHearings] = useState(0);
    const [, setTotalLitigations] = useState(0);

    // reminders states 
    const [selectedDate,] = useState(new Date());
    const [currentReminderEdit, setCurrentReminderEdit] = useState()


    // meetings state

    const [editMeeting, setEditMeeting] = useState();

    // tasks states
    const [editTask, setEditTask] = useState();


    // calendar states 
    const [meetingCheckbox, setMeetingCheckbox] = useState(true)
    const [hearingCheckbox, setHearingCheckbox] = useState(true)
    const [tasksCheckbox, setTasksCheckbox] = useState(true)
    const [remindersCheckbox, setRemindersCheckbox] = useState(true)
    const [calendarDate, setCalendarDate] = useState(new Date());


    const ref = useRef();
    const calendarContainerRef = useRef();


    let permissions = authUser?.user_role?.slug !== 'admin' ?
        authUser?.user_role?.role_permissions?.filter(permission => permission?.user_pages?.slug === "events")[0] :
        {
            add: 1,
        }

    const handleAddClick = (e, type) => {
        e.preventDefault();
        setAddPopup({
            open: true,
            type: type,
        });
        setPopupTitle(generalData?.website_titles[type]);
        setApiOptions({});
    }

    const handleEditClick = (e, data) => {
        setApiOptions({});

        e.stopPropagation();
        if (data?.event_type === 'hearing') {
            setEditHearing(data);
        }
        if (data?.event_type === 'reminder') {
            setCurrentReminderEditGl(data);
        }
        if (data?.event_type === 'meeting') {
            setEditMeeting(data);
        }
        if (data?.event_type === 'task') {
            setEditTask(data);
        }
        setAddPopup({
            open: true,
            type: data?.event_type,
            target: data
        });
        setPopupTitle(generalData?.website_titles[data?.event_type]);
    }

    const handleDeleteClick = (e, event) => {
        e.stopPropagation();
        setDeleteEvent(event);
        setDeletePopup(true);
        setApiOptions({});

    }

    const submitDeleteEvent = (event) => {
        setApiOptions({
            url: '/delete-event',
            method: 'post',
            data: {
                model: event?.event_type,
                target_id: event?.id,
                meetings: meetingCheckbox,
                hearings: hearingCheckbox,
                tasks: tasksCheckbox,
                reminders: remindersCheckbox,
            },
            callback: ({ data: { upcomingEvents, calendarEvents } }) => {
                setUpcomingEvents(upcomingEvents);
                setCalendarEvents(calendarEvents);
                setDeleteEvent(null);
                setDeletePopup(false);
                setApiOptions({});

            },
            catch: err => {
                if (err) {
                    triggerEditMenu(null);
                }
            }


        })
    }

    useEffect(() => {
        setMattersApiOptions({
            url: 'matters',
            method: 'post',
            data: {},
            showLoader: false,
            callback: ({ data }) => {
                setAllLitigations(data?.total['litigations']);
            }
        })

    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    useOutsideClick(ref, () => {
        setAddMore(false);
    });

    const handleAccordionClick = (e, i) => {
        e.preventDefault();
        setOpenId(Number(openId) === Number(i) ? -1 : Number(i))
        // setCurrentInput('')
    }

    const triggerEditMenu = (event, data) => {
        event.preventDefault()
        event.stopPropagation()
        setTarget(target?.id === data?.id ? null : data)
    }

    const handleClendarNavigate = (date) => {
        setApiOptions({
            url: 'events',
            method: 'post',
            data: {
                date: formatDate(date),
                meetings: meetingCheckbox,
                hearings: hearingCheckbox,
                tasks: tasksCheckbox,
                reminders: remindersCheckbox,
            },
            callback: ({ data: { upcomingEvents, calendarEvents } }) => {
                setCalendarEvents(calendarEvents)
            },
            catch: (err => {
            })
        })
        setCalendarDate(date);
    }

    useEffect(() => {
        setApiOptions({
            url: 'events',
            method: 'post',
            data: {
                meetings: meetingCheckbox,
                hearings: hearingCheckbox,
                tasks: tasksCheckbox,
                reminders: remindersCheckbox,
            },
            callback: ({ data: { upcomingEvents, calendarEvents } }) => {
                setCalendarEvents(calendarEvents)
                setUpcomingEvents(upcomingEvents)
            },
            catch: (err => {
            })
        })
    }, [meetingCheckbox, hearingCheckbox, tasksCheckbox, remindersCheckbox]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Layout activePage="events">
            <div className='row'>

                <div className='col-lg-8 d-block d-lg-none events-calendar'>
                    <h1 className='page-titles color-dark-blue pb-3 mb-0'>{generalData?.eventsFixedTitles['calendar']}</h1>
                    <div className='w-100 bg-white shadow position-relative scrollable-profile-height' style={{ minHeight: `calc(100vh-220px) !important`, paddingTop: '85px' }}>
                        <div ref={calendarContainerRef} className='p-4 calendar-container'>
                            <MyCalendar
                                events={calendarEvents}
                                onNavigate={handleClendarNavigate}
                                editFunction={(e, event) => { handleEditClick(e, event) }}
                                deleteFunction={(e, event) => { handleDeleteClick(e, event) }}
                                meetingCheckbox={meetingCheckbox}
                                setMeetingCheckbox={setMeetingCheckbox}
                                hearingCheckbox={hearingCheckbox}
                                setHearingCheckbox={setHearingCheckbox}
                                tasksCheckbox={tasksCheckbox}
                                setTasksCheckbox={setTasksCheckbox}
                                remindersCheckbox={remindersCheckbox}
                                setRemindersCheckbox={setRemindersCheckbox}
                                authUser={authUser}
                            />
                        </div>
                    </div>
                </div>

                <div className='col-lg-4  mt-4 mt-lg-0'>
                    <h1 className='page-titles color-dark-blue pb-3 mb-0'>{generalData?.eventsFixedTitles['upcoming-events']}</h1>
                    {
                        upcomingEvents?.map((event, i) => (
                            <EventCardComponent
                                className={`mb-3`}
                                index={i}
                                data={event}
                                target={target}
                                setTarget={setTarget}
                                openId={openId}
                                setOpenId={setOpenId}
                                onClick={(e) => handleAccordionClick(e, i)}
                                editFunction={(e) => { handleEditClick(e, event) }}
                                deleteFunction={(e) => { handleDeleteClick(e, event) }}
                                menuClick={e => { triggerEditMenu(e, event) }}
                                authUser={authUser}
                            />
                        ))

                    }

                </div>

                <div className='col-lg-8 d-none d-lg-block  events-calendar'>
                    <h1 className='page-titles color-dark-blue pb-3 mb-0'>{generalData?.eventsFixedTitles['calendar']}</h1>
                    <div className='w-100 bg-white shadow position-relative scrollable-profile-height' style={{ minHeight: `calc(100vh-220px) !important`, paddingTop: '85px' }}>
                        <div ref={calendarContainerRef} className='p-4 calendar-container'>
                            <MyCalendar
                                events={calendarEvents}
                                onNavigate={handleClendarNavigate}
                                editFunction={(e, event) => { handleEditClick(e, event) }}
                                deleteFunction={(e, event) => { handleDeleteClick(e, event) }}
                                meetingCheckbox={meetingCheckbox}
                                setMeetingCheckbox={setMeetingCheckbox}
                                hearingCheckbox={hearingCheckbox}
                                setHearingCheckbox={setHearingCheckbox}
                                tasksCheckbox={tasksCheckbox}
                                setTasksCheckbox={setTasksCheckbox}
                                remindersCheckbox={remindersCheckbox}
                                setRemindersCheckbox={setRemindersCheckbox}
                            />
                        </div>
                    </div>
                </div>
                {
                    permissions?.add &&
                    <div className="col-12 pe-3 d-flex justify-content-end">
                        <div ref={ref} onClick={() => setAddMore(!addMore)} className={'add-button-fixed ' + (addMore ? ' active' : '')}>
                            <AddIcon />
                            <div className="menu-fixed-button">
                                <p
                                    className={'pb-0'}
                                    onClick={(e) => {
                                        e.preventDefault(); e.stopPropagation(); handleAddClick(e, 'hearing')
                                    }}
                                >
                                    {generalData?.matterTypes[1]?.title}
                                </p>

                                <p
                                    className={'pb-0'}
                                    onClick={(e) => {
                                        e.preventDefault(); e.stopPropagation(); handleAddClick(e, 'reminder')
                                    }}
                                >
                                    {generalData?.website_titles['reminder']}
                                </p>

                                <p
                                    className={'pb-0'}
                                    onClick={(e) => {
                                        e.preventDefault(); e.stopPropagation(); handleAddClick(e, 'meeting')
                                    }}
                                >
                                    {generalData?.website_titles['meeting']}
                                </p>
                                <p
                                    className={''}
                                    onClick={(e) => {
                                        e.preventDefault(); e.stopPropagation(); handleAddClick(e, 'task')
                                    }}
                                >
                                    {generalData?.website_titles['task']}
                                </p>

                                {/* {
                                generalData?.matterTypes?.map((singleType, ind) => (
                                    <p
                                        className={ind === 0 ? 'pb-0' : ''}
                                        onClick={(e) => {
                                            e.preventDefault(); e.stopPropagation(); handleAddMatterClick(e, singleType)
                                        }}
                                    >
                                        {singleType?.title}
                                    </p>
                                ))
                            } */}
                                {/* <p onClick={(e) => { e.preventDefault(); e.stopPropagation(); handleAddMatterClick(e, 'hearings') }} className="pb-0">Hearing</p> */}
                            </div>
                        </div>
                    </div>
                }
            </div>

            {
                // hearing popup 
                addPopup?.open && addPopup?.type === 'hearing' &&
                <HearingPopupComponent
                    editHearing={editHearing}
                    setEditHearing={setEditHearing}
                    popupTitle={popupTitle}
                    setAddMatterPopup={setAddPopup}
                    addMatterPopup={addPopup}
                    activePage={'calendar'}
                    setTotalMatters={setTotalMatters}
                    setTotalLitigations={setTotalLitigations}
                    setTotalHearings={setTotalHearings}
                    // returnTable={'events'}
                    onSuccess={(res) => {
                        setAddPopup(null);
                        setCalendarEvents(res?.data?.calendarEvents);
                        setUpcomingEvents(res?.data?.upcomingEvents);
                    }}
                    returnEvents={1}
                    meetingCheckbox={meetingCheckbox}
                    hearingCheckbox={hearingCheckbox}
                    tasksCheckbox={tasksCheckbox}
                    remindersCheckbox={remindersCheckbox}
                />
            }
            {
                //reminder popup
                (addPopup?.open && addPopup?.type === 'reminder') &&
                <ReminderPopup
                    selectedDate={selectedDate}
                    handleClose={() => { setAddPopup(false) }}
                    onSuccess={(res) => {
                        setAddPopup(null);
                        setCalendarEvents(res?.calendarEvents);
                        setUpcomingEvents(res?.upcomingEvents);
                    }}
                    currentReminderEdit={currentReminderEdit}
                    setCurrentReminderEdit={setCurrentReminderEdit}
                    showClient={true}
                    // marks={profileClient?.marks}
                    clientId={''}
                    returnEvents={1}
                    meetingCheckbox={meetingCheckbox}
                    hearingCheckbox={hearingCheckbox}
                    tasksCheckbox={tasksCheckbox}
                    remindersCheckbox={remindersCheckbox}
                />
            }

            {
                addPopup?.open && addPopup?.type === 'meeting' &&
                <MeetingPopupComponent
                    handleClose={() => { setAddPopup(false) }}
                    popupTitle={popupTitle}
                    setEditMeeting={setEditMeeting}
                    editMeeting={editMeeting}
                    onSuccess={(res) => {
                        setAddPopup(null);
                        setCalendarEvents(res?.data?.calendarEvents);
                        setUpcomingEvents(res?.data?.upcomingEvents);
                    }}
                    returnEvents={1}
                    meetingCheckbox={meetingCheckbox}
                    hearingCheckbox={hearingCheckbox}
                    tasksCheckbox={tasksCheckbox}
                    remindersCheckbox={remindersCheckbox}
                />
            }
            {
                addPopup?.open && addPopup?.type === 'task' &&
                <TaskPopupComponent
                    setAddPopup={setAddPopup}
                    editTask={editTask}
                    setEditTask={setEditTask}
                    onSuccess={(res) => {
                        setAddPopup(null);
                        setCalendarEvents(res?.data?.calendarEvents);
                        setUpcomingEvents(res?.data?.upcomingEvents);
                    }}
                    returnEvents={1}
                    meetingCheckbox={meetingCheckbox}
                    hearingCheckbox={hearingCheckbox}
                    tasksCheckbox={tasksCheckbox}
                    remindersCheckbox={remindersCheckbox}
                />
            }

            {
                //delete Hearing popup
                deletePopup &&
                <div id="errors-popup" className=" error-popup-api d-flex align-items-center justify-content-center px-3 fade-in">
                    <div className="pop-up-api-box">
                        <CloseIcon className="cursor-pointer" onClick={() => { setDeleteEvent(null); setTarget(null); setDeletePopup(false); }} />
                        <div className="text-center ">
                            <div className="containter-fluid">
                                <div className="row d-flex justify-content-center align-items-center">
                                    <div className="col-9 col-sm-12 d-flex justify-content-center">
                                        <h1 className="pop-up-title m-0 pt-3 color-dark-blue">{generalData?.settings_section_settings['delete-popup-title']}</h1>
                                    </div>
                                    <div className="col-12 d-flex justify-content-center pt-4 pb-2">
                                        <button onClick={() => submitDeleteEvent(deleteEvent || null)} type="button" className="popup-button my-auto mx-2 w-auto px-3">{generalData?.settings_section_settings['delete-popup-first-button']}</button>
                                        <button onClick={() => { setDeleteEvent(null); setTarget(null); setDeletePopup(false); }} type="button" className="auth-button border-dark-blue my-auto mx-2 background-transparent w-auto px-3">{generalData?.settings_section_settings['delete-popup-second-button']}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            }

            <Api options={apiOptions} />
            <Api options={mattersApiOptions} />
        </Layout>
    )
}
