import React, { useState } from 'react'
import GlobalState from '../contexts/GlobalState';
import { useContext } from 'react';
import { useEffect } from 'react';
import { LockClose, LockOpen } from './Icons';

export default function LockUnlockComponent({ onChange, defaultState }) {

    const [isPublic, setIsPublic] = useState(defaultState)

    const {
        generalDataGlobal: [generalData],
    } = useContext(GlobalState);

    const handleClick = () => {
        setIsPublic(!isPublic)
    }

    useEffect(() => {
        onChange(isPublic)
    }, [isPublic]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setIsPublic(defaultState);
    }, [defaultState]);// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div onClick={handleClick} className="position-absolute top-0 end-0 d-flex align-items-center justify-content-center shadow rounded-3 bg-white lockIcon" style={{ width: 40, height: 40 }}>
            <div className="position-relative">
                <div className="triangle-down-expl"></div>
                <h5 className="commet-type-expl">{isPublic ? generalData?.website_titles['make-private'] : generalData?.website_titles['make-public']}</h5>
            </div>
            {
                isPublic ?
                    <LockOpen />
                    :
                    <LockClose />
            }
        </div>
    )
}
