import { useEffect } from "react";

export default function useOutsideClick(ref, callback) {
    const handleClick = e => {
        if (ref.current && !ref.current.contains(e.target)) {
            callback();
        }
    };

    useEffect(() => {
        window?.document?.addEventListener("click", handleClick);

        return () => {
            window?.document?.removeEventListener("click", handleClick);
        };
    });
};