import React, { useEffect, useRef, useState } from 'react'
import { useContext } from 'react';
import GlobalState from '../contexts/GlobalState';
import useOutsideClick from '../useOutsideClick';
import { ThreeDotsMenu } from './Icons';

export default function CardComponent({ reference, index, image, title, subTitle, children, data, editFunction, deleteFunction, editMenuClick, target, setTarget, copyFunction, createReportFunction, determineJudgmentFunction, costsFunction, remindersFunction, viewJudgmentFunction }) {
    const { generalDataGlobal } = useContext(GlobalState);
    const [generalData,] = generalDataGlobal;
    const menuRef = useRef();
    const [openMenu, setOpenMenu] = useState(false);

    useEffect(() => {
        setOpenMenu(Number(target?.id) === Number(data?.id));
    }, [target]);// eslint-disable-line react-hooks/exhaustive-deps

    useOutsideClick(menuRef, () => {
        setOpenMenu(false);
        setTarget(null)
    });

    return (
        <div className="cms-table-content position-relative h-100">
            <div className="row">
                <div className="col-12">
                    <div className="gray-background">
                        <div className="row py-2 px-3">
                            {
                                image &&
                            <div className="col-auto">
                                
                                <img className="image-user-chat" src={image} alt="chat user" />
                            </div>
                            }
                            <div className="col ps-0">
                                <div className="row justify-content-between">
                                    <div className="col-auto">
                                        <p className="m-0 font-semi-bold">{title}</p>
                                        <h5 className="locked-inputs w-100 fs-14">{subTitle}</h5>
                                    </div>
                                </div>
                            </div>
                            {/* edit menu on click */}
                            <div className="col-auto pe-0 d-flex align-items-center cursor-pointer">
                                <div className="text-end p-0 position-relative" >
                                    <ThreeDotsMenu ref={menuRef} onClick={editMenuClick} />
                                    <div style={{ width:150 }} className={`settings-clickmenu text-start ${openMenu ? 'd-block' : ''} `}>
                                        {
                                            editFunction &&
                                            <p style={{ zIndex: "99" }} onClick={editFunction} className="p-0 py-1 m-0 ms-2">{generalData?.settings_section_settings['edit-title']}</p>
                                        }
                                        {
                                            deleteFunction &&
                                            <p style={{ zIndex: "200" }} onClick={deleteFunction} className="p-0 py-1 m-0 ms-2">{generalData?.settings_section_settings['delete-title']}</p>
                                        }
                                        {
                                            copyFunction &&
                                            <p style={{ zIndex: "99" }} onClick={copyFunction} className="p-0 py-1 m-0 ms-2">{generalData?.hearingFixedTitles['copy']}</p>
                                        }
                                        {
                                            createReportFunction && 
                                            <p style={{ zIndex: "99" }} onClick={createReportFunction} className="p-0 py-1 m-0 ms-2">{target?.report ? generalData?.hearingFixedTitles['view-report'] : generalData?.hearingFixedTitles['create-a-report']}</p>
                                        }
                                        {
                                            determineJudgmentFunction && 
                                            <p style={{ zIndex: "99" }} onClick={determineJudgmentFunction} className="p-0 py-1 m-0 ms-2">{generalData?.hearingFixedTitles['determine-judgment']}</p>
                                        }
                                        {
                                            viewJudgmentFunction && 
                                            <p style={{ zIndex: "99" }} onClick={viewJudgmentFunction} className="p-0 py-1 m-0 ms-2">{generalData?.hearingFixedTitles['view-judgment']}</p>
                                        }
                                        
                                        {
                                            costsFunction &&
                                            <p style={{ zIndex: "99" }} onClick={costsFunction} className="p-0 py-1 m-0 ms-2">{generalData?.website_titles['costs']}</p>
                                        }
                                        {
                                            remindersFunction &&
                                            <p style={{ zIndex: "99" }} onClick={remindersFunction} className="p-0 py-1 m-0 ms-2">{generalData?.website_titles['reminder']}</p>
                                        }


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='p-3'>
                {children}
            </div>
        </div>
    )
}
