import { useContext, useState, useEffect } from "react";
import GlobalState from '../contexts/GlobalState';
import { useLocation, useParams } from "react-router";
import React from 'react';
import Api from "../components/Api";
import "react-datepicker/dist/react-datepicker.css";
import 'swiper/css';

import Layout from "../components/Layout";
import { Link } from "react-router-dom";
import { BackIcon, ExportIcon } from "../components/Icons";

import { registerLocale } from "react-datepicker";
import ar from 'date-fns/locale/ar';
import LogsComponent from "../components/LogsComponent";
registerLocale('ar', ar)

export default function DocumentsHistory(props) {

    const { generalDataGlobal, authUserGlobal, profileClientGlobal } = useContext(GlobalState);
    const [generalDataApiOptions, setGeneralDataApiOptions] = useState({});
    const [ , setProfileClient] = profileClientGlobal;

    const [generalData] = generalDataGlobal;
    const [authUser,] = authUserGlobal;
    const router = useParams();
    const { user_id, slug, matter_id } = router;
    const [ , setErrorsReturned] = useState({});
    const [ , setLastPage] = useState(1);
    const [ , setHeadings] = useState();

    /* expenses end */

    const [returnedFiles, setReturnedFiles] = useState([]);

    const { pathname } = useLocation();
    const baseUrl = pathname.split('/')[1];
    const backUrl = pathname.split('/').slice(1, -1).join('/');

    //get directories list
    useEffect(() => {
        if (slug === 'documents') {
            setGeneralDataApiOptions({
                url: 'directories-list',
                method: 'post',
                data: {
                    user_id: user_id || matter_id,
                    main_directory: baseUrl === 'client' ? 2 : baseUrl === 'matters' ? 3 : 1,
                    all: true,
                    noEmptyComment: true,
                    orderByDate: true
                },
                callback: res => {
                    setReturnedFiles(res.data?.files);
                    setLastPage(res.data.files.last_page);
                    setProfileClient(res?.data?.client_user);
                    setHeadings(res?.data?.reportHeadings);
                    setErrorsReturned({});
                },
                catch: err => {
                    if (err) {
                    }
                }
            })
        }
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const clickUrl = (e, url) => {
        e.preventDefault();
        window.open(url, "_blank");
    };
    
    const exportReport = (e) => {
        setGeneralDataApiOptions({
            url: 'export-directories-list',
            method: 'post',
            data: {
                user_id: user_id || matter_id,
                main_directory: baseUrl === 'client' ? 2 : baseUrl === 'matters' ? 3 : 1,
                all: true,
                noEmptyComment: true,
                orderByDate: true
            },
            callback: res => {
                clickUrl(e, res.data);
            },
            catch: err => {
                if (err) {
                }
            }
        })
    }

    return (
        <>
            <Layout activePage={baseUrl === 'client' ?'contacts' : baseUrl}>
                {
                    authUser?.id ?
                        <>

                            <div className="row py-2 flex-wrap">
                                <div className="col-12">
                                    <div className="row justify-content-between">
                                        <div className="col-auto d-flex pe-0">
                                            <Link to={`/${backUrl}`} className="m-0 page-titles d-flex align-items-center">
                                                <BackIcon className='me-2 back-icon' /> {generalData?.documentsSectionSettings['view-history']}
                                            </Link>
                                        </div>
                                        <div className="col-auto ps-0">
                                            <button className=" popup-button my-auto mx-0 mx-md-2 w-auto px-3" onClick={(e) => exportReport(e)}>
                                                <ExportIcon className="export-button-svg me-1" /> {generalData?.fixed_titles['export']}</button>
                                        </div>
                                    </div>
                                </div>
                                <LogsComponent showCount={returnedFiles?.length} returnedFiles={returnedFiles} generalData={generalData} />
                                {/* <div className="col-12 mb-4 mt-4">
                                    <div className="row g-0 p-0 py-4 costs-box-styling custom-shadow px-2">
                                        {
                                            returnedFiles.length > 0 ?
                                                returnedFiles.map((file, index) => (
                                                    <div key={index} className="col-12">
                                                        <div className="row pb-3 flex-wrap">
                                                            <div className="col-auto position-relative">
                                                                <div className="blue-dot-logs" />
                                                                {
                                                                    returnedFiles?.length !== index + 1 &&
                                                                    <div className="blue-dot-logs-lines" ></div>

                                                                }
                                                            </div>
                                                            <div className="col ps-0">
                                                                <div className="row flex-wrap">
                                                                    <div className="col">
                                                                        {
                                                                            file?.deleted_at ?
                                                                                <div className="d-flex align-items-md-center flex-md-row flex-column ">
                                                                                    <del>

                                                                                        {
                                                                                            file?.comment ?
                                                                                                <div className="d-flex align-items-md-center flex-md-row flex-column flex-wrap ">
                                                                                                    <p className={`fs-12 m-0 fs-smaller`}>
                                                                                                        {file?.comment}
                                                                                                    </p>

                                                                                                    <a target='_blank' href={file?.full_path_file} className={`fs-12 m-0 fs-smaller`}>
                                                                                                        <FileIcon className="p-1" />
                                                                                                        {file?.file_name}

                                                                                                    </a>

                                                                                                    <p className={`fs-12 ms-md-2 m-0 fs-smaller`}>
                                                                                                        {file?.file_date}
                                                                                                    </p>

                                                                                                </div>
                                                                                                :
                                                                                                null
                                                                                        }
                                                                                    </del>
                                                                                    <p className="ms-md-3 fs-12 m-0 fs-smaller">deleted by : {file?.delete_user?.name}</p>
                                                                                </div>
                                                                                :
                                                                                <div className="d-flex align-items-md-center flex-md-row flex-column flex-wrap">
                                                                                    {
                                                                                        file?.comment ?
                                                                                            <>
                                                                                                <p className={`fs-12 m-0 fs-smaller`}>

                                                                                                    {file?.comment}
                                                                                                </p>
                                                                                                <p className={`fs-12 m-0 fs-smaller ms-md-1`}>
                                                                                                    {file?.file_date}
                                                                                                </p>

                                                                                                <a target='_blank' className={`fs-12 m-0 fs-smaller`} href={file?.full_path_file}>
                                                                                                    <FileIcon className="p-1" />
                                                                                                    {file?.file_name}
                                                                                                </a>

                                                                                            </>
                                                                                            :
                                                                                            null
                                                                                    }
                                                                                </div>


                                                                        }


                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                                :
                                                <h5 className="py-3 locked-inputs w-100 m-0 p-0">{generalData?.website_titles['no-logs-yet']}</h5>
                                        }
                                    </div>
                                </div> */}
                            </div>
                        </>
                        : null

                }

                {/* <Api options={historyCall} /> */}
                <Api options={generalDataApiOptions} />
                {/* <Api options={expensesCall} /> */}
            </Layout>
        </>
    )
}